import React from "react";
import { Box } from "@mui/material";
import logo from "../images/branding/orkes-logo-purple-inverted-2x.png";
import logoMark from "../images/branding/orkes-logo-mark-purple-2x.png";

export default function AppLogo({ open = true }: { open: boolean }) {
  return (
    <>
      <Box sx={{ display: "flex" }}>
        {open && (
          <img
            src={logo}
            alt="Orkes Cloud"
            style={{ height: 60, marginTop: 1, marginLeft: -3 }}
          />
        )}
        {!open && (
          <img
            src={logoMark}
            alt="Orkes Cloud"
            style={{ height: 45, marginTop: 8, marginLeft: 8 }}
          />
        )}
      </Box>
    </>
  );
}
