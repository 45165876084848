import "../../css/LoginFooter.css";
import { Box } from "@mui/material";

const Footer = () => {
  return (
    <footer>
      <Box className="footer-content-container">
        <Box className="footer-icon-container">
          <a
            href="https://twitter.com/orkesio"
            target="_blank"
            className="nw_footersociallinks w-inline-block"
            rel="noreferrer"
          >
            <img
              src="https://assets-global.website-files.com/6466c9e390eac6bcd0399387/656d513f20c5cb6bde6c7609_TwitterX.svg"
              loading="lazy"
              alt="Twitter or X Socials link"
              className="nw_socialcircularicon"
            />
          </a>

          <a
            href="https://www.linkedin.com/company/orkes-inc"
            target="_blank"
            className="nw_footersociallinks w-inline-block"
            rel="noreferrer"
          >
            <img
              src="https://assets-global.website-files.com/6466c9e390eac6bcd0399387/656d513fb149e5acd8545a8d_LinkedIn.svg"
              loading="lazy"
              alt="LinkedIn Socials link"
              className="nw_socialcircularicon"
            />
          </a>
          <a
            href="https://www.youtube.com/channel/UCI7sk4DD6F6r9CWg9gHRlVg"
            target="_blank"
            className="nw_footersociallinks w-inline-block"
            rel="noreferrer"
          >
            <img
              src="https://assets-global.website-files.com/6466c9e390eac6bcd0399387/656d513f5e078c290d9a1aa0_Youtube.svg"
              loading="lazy"
              alt="YouTube Socials link"
              className="nw_socialcircularicon"
            />
          </a>
          <a
            href="https://join.slack.com/t/orkes-conductor/shared_invite/zt-xyxqyseb-YZ3hwwAgHJH97bsrYRnSZg"
            className="nw_footersociallinks w-inline-block"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://assets-global.website-files.com/6466c9e390eac6bcd0399387/656d513f88c14fc984988906_Slack.svg"
              loading="lazy"
              alt="Slack Socials link"
              className="nw_socialcircularicon"
            />
          </a>
          <a
            href="https://github.com/conductor-oss/conductor"
            target="_blank"
            className="nw_footersociallinks w-inline-block"
            rel="noreferrer"
          >
            <img
              src="https://assets-global.website-files.com/6466c9e390eac6bcd0399387/656d513f3a1fc8aff4e56f61_Github.svg"
              loading="lazy"
              alt="Github Socials link"
              className="nw_socialcircularicon"
            />
          </a>
        </Box>
        <Box className="copyrightText">
          © {new Date().getFullYear()} Orkes Inc. All Rights Reserved
        </Box>
      </Box>
    </footer>
  );
};

export default Footer;
