import GroupIcon from "@mui/icons-material/Group";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import { Tab, Tabs } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import {
  PERMITTED_DOMAINS_ROUTE_URL,
  PERMITTED_USERS_ROUTE_URL,
  PERMITTED_PROVISIONS_ROUTE_URL,
} from "utils/routePaths";

const tabs = [
  { icon: <GroupIcon />, label: "Users", pathname: PERMITTED_USERS_ROUTE_URL },
  {
    icon: <DomainVerificationIcon />,
    label: "Domains",
    pathname: PERMITTED_DOMAINS_ROUTE_URL,
  },
  {
    icon: <PlaylistAddCheckIcon />,
    label: "Provisions",
    pathname: PERMITTED_PROVISIONS_ROUTE_URL,
  },
];

const PermittedManagementTabs = () => {
  const location = useLocation();

  const renderTabs = (items: typeof tabs) =>
    items.map((item) => (
      <Tab
        disableRipple
        key={item.pathname}
        icon={item.icon}
        iconPosition="start"
        label={item.label}
        component={Link}
        to={item.pathname}
        value={item.pathname}
      />
    ));

  return <Tabs value={location.pathname}>{renderTabs(tabs)}</Tabs>;
};

export default PermittedManagementTabs;
