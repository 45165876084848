import "../css/ClusterListings.css";
import { Box, Paper } from "@mui/material";
import { Helmet } from "react-helmet";
import * as React from "react";
import { BootstrapOutlineButton } from "../components/buttons/CustomButtons";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import StyledMainButton from "../components/buttons/StyledMainButton";

export default function CreateNewEnvironment() {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Orkes Cloud - Create New Cluster</title>
      </Helmet>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          borderBottom: "1px solid rgb(200,200,200, 0.5)",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "left",
            flexDirection: "column",
            paddingLeft: 25,
            paddingRight: 0,
            paddingTop: 5,
          }}
        >
          <h2 style={{ marginBottom: 15, fontWeight: 500, fontSize: 28 }}>
            Create New Cluster
          </h2>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "left",
            flexDirection: "row",
            paddingRight: 20,
            paddingTop: 5,
          }}
        >
          <BootstrapOutlineButton
            color={"inherit"}
            style={{
              width: 100,
              fontSize: 15,
              lineHeight: "27px",
              marginRight: 10,
              fontFamily: "Lexend, sans-serif",
              fontWeight: 600,
              textAlign: "center",
              borderRadius: 5,
            }}
            //@ts-ignore
            component={Link}
            to={"/home/clusters"}
          >
            Cancel
          </BootstrapOutlineButton>
        </Box>
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
            flexDirection: "column",
            width: "100%",
            // borderRight: "2px solid lightgrey",
          }}
        >
          <Paper
            style={{
              marginTop: 30,
              marginLeft: 30,
              marginRight: 30,
              padding: 20,
              fontFamily: "Lexend, sans-serif",
              minWidth: 400,
              minHeight: 300,
            }}
          >
            <p
              style={{
                fontSize: 18,
                marginTop: 0,
                fontFamily: "Lexend, sans-serif",
                fontWeight: 400,
              }}
            >
              Custom Cluster in your Cloud Account
            </p>
            <p className={"headerText"}>Cloud Provider</p>
            <p className={"headerValue"}>Choice of AWS, Azure and GCP</p>
            <p className={"headerText"}>Provisioning</p>
            <p className={"headerValue"}>Fully managed via Assume Roles</p>
            <p className={"headerText"}>Capacity</p>
            <p className={"headerValue"}>
              Choose your own capacity from multiple options
            </p>
            <p className={"headerText"}>Updates</p>
            <p className={"headerValue"}>Automated updates, security patches</p>
            <p className={"headerText"}>Monitoring</p>
            <p className={"headerValue"}>
              Cloud monitoring, alerting on cluster health
            </p>
            <StyledMainButton
              size="small"
              color={"inherit"}
              style={{
                width: 280,
                fontSize: 15,
                marginRight: 10,
                marginTop: 30,
              }}
              onClick={() => {
                navigate("/home/newcustomcluster");
              }}
            >
              Create New Custom Cluster
            </StyledMainButton>
          </Paper>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
            flexDirection: "column",
            width: "100%",
            // borderRight: "2px solid lightgrey",
          }}
        >
          <Paper
            style={{
              marginTop: 30,
              marginLeft: 30,
              marginRight: 30,
              padding: 20,
              fontFamily: "Lexend, sans-serif",
              minWidth: 400,
              minHeight: 300,
            }}
          >
            <p
              style={{
                fontSize: 18,
                marginTop: 0,
                fontFamily: "Lexend, sans-serif",
                fontWeight: 400,
              }}
            >
              Orkes Hosted Cluster
            </p>
            <p className={"headerText"}>Cloud Provider</p>
            <p className={"headerValue"}>Choice of AWS, Azure and GCP</p>
            <p className={"headerText"}>Connectivity</p>
            <p className={"headerValue"}>
              Choose between VPC peering or Internet
            </p>
            <p className={"headerText"}>Security</p>
            <p className={"headerValue"}>
              All your data is encrypted and accessible only to you
            </p>
            <p className={"headerText"}>Updates</p>
            <p className={"headerValue"}>Automated updates, security patches</p>
            <p className={"headerText"}>Monitoring</p>
            <p className={"headerValue"}>
              Cloud monitoring, alerting on cluster health
            </p>
            <StyledMainButton
              size="small"
              style={{
                width: 330,
                fontSize: 15,
                marginRight: 10,
                marginTop: 30,
              }}
              onClick={() => {
                navigate("/home/neworkescluster");
              }}
            >
              Create New Orkes Cluster
            </StyledMainButton>
          </Paper>
        </Box>
      </Box>
    </>
  );
}
