declare global {
  interface Window {
    dataLayer: any[];
  }
}

export const postDataLayer = (eventName: string, userId: string) => {
  const dataLayer = (window.dataLayer = window.dataLayer || []);
  dataLayer.push({
    event: eventName,
    orkes_user_id: userId,
  });
};
