import { useMutation, useQuery } from "react-query";
import { fetchWithContext, useFetchContext } from "../fetch";
import { IsAuth0Auth, IsFirebaseAuth, useAuth } from "../AuthProvider";
import { useMemo } from "react";
import {
  CertificateDto,
  CloudProvider,
  CloudProviderRegion,
  ClusterCapacityType,
  CompatibilityDto,
  ConductorReleaseDto,
  IObject,
  IProvisioningPermittedValueDto,
  ISignupPermittedValueDto,
  PackageVersionDto,
  Region,
  ReleaseDto,
  WorkflowStatus,
} from "../saastypes";
import { UseQueryOptions, UseQueryResult } from "react-query/types/react/types";
import _isArray from "lodash/isArray";
import _get from "lodash/get";
import {
  FETCH_ALL_RELEASES_PATH,
  FETCH_COMPATIBILITY_LIST,
  SYNC_RELEASES_PATH,
} from "../apiPaths";
import { useAuthMachine } from "auth/state/hook";
import { IdTitle, UserInvite, RefetchFunction } from "./types";
import {
  STALE_TIME_SHORT_SECONDS,
  STALE_TIME_UNLIMITED,
  STALE_TIME_10_MINS,
} from "./constants";

export type CustomQueryOptions = UseQueryOptions<
  any,
  unknown,
  any,
  string[]
> & {
  useLocalMessage: boolean;
};

export const defaultHeaders: (token: string) => object = (token: string) => {
  return {
    Authorization: `Bearer ${token}`,
    Accept: `application/json`,
    "Content-Type": `application/json`,
  };
};

export function useAuthHeaders(): object {
  const obj = useAuth();

  if (IsAuth0Auth && obj?.isAuthenticated) {
    const currentUser = obj?.currentUser;
    return defaultHeaders(currentUser?.stsTokenManager);
  }

  if (IsFirebaseAuth) {
    const currentState = obj.authService.getSnapshot();
    return defaultHeaders(currentState?.context?.currentUser?.accessToken);
  }

  return {};
}

export function useFetch<T>(
  pathORPathAndCriteria: string | string[],
  reactQueryOptions: any,
  additionalFetchParams: any = {}
): UseQueryResult<T> {
  const fetchContext = useFetchContext();
  const headers: any = useAuthHeaders();
  const fetchParams = { headers: headers };
  const additionalEnabled =
    additionalFetchParams.enabled !== undefined
      ? additionalFetchParams.enabled
      : true;

  // Work arround to allow a criteria attribute
  const [path] = Array.isArray(pathORPathAndCriteria)
    ? pathORPathAndCriteria
    : [pathORPathAndCriteria];

  const resultQueryOptions = {
    enabled:
      additionalEnabled &&
      fetchContext.ready &&
      fetchParams.headers["Authorization"] !== undefined &&
      ("paramsAvailable" in reactQueryOptions
        ? reactQueryOptions.paramsAvailable
        : true),
    keepPreviousData: true,
    retry: (_failureCount: any, error: Response) => error?.status !== 403 && 1,
    ...reactQueryOptions,
  };

  const key = [fetchContext.stack].concat(pathORPathAndCriteria);

  const result = useQuery<T>(
    key,
    () => fetchWithContext(path, fetchContext, fetchParams),
    resultQueryOptions
  );

  return result;
}

export function useAction(
  path: string,
  method = "post",
  callbacks: object,
  isText: boolean
) {
  const fetchContext = useFetchContext();
  const authHeaders = useAuthHeaders();

  return useMutation(
    (mutateParams) =>
      fetchWithContext(
        path,
        fetchContext,
        {
          method,
          headers: authHeaders,
          body: _get(mutateParams, "body"),
        },
        isText
      ),
    callbacks
  );
}

export function useActionWithPath(
  callbacks: object,
  isText?: boolean,
  headers = {}
) {
  const fetchContext = useFetchContext();
  const authHeaders = useAuthHeaders();
  return useMutation((mutateParams) => {
    const actionPath = _get(mutateParams, "path");
    const method = _get(mutateParams, "method");
    return fetchWithContext(
      actionPath,
      fetchContext,
      {
        method,
        headers: {
          "Content-Type": "application/json",
          ...authHeaders,
          ...headers,
        },
        body: _get(mutateParams, "body"),
      },
      isText
    );
  }, callbacks);
}

export function useWorkflowStatus(clusterName: string): {
  data: WorkflowStatus | undefined;
  isFetching: any;
  refetch: RefetchFunction;
} {
  const { data, ...rest } = useFetch<WorkflowStatus>(
    `/getWorkflowStatus?clusterName=${clusterName}`,
    {
      staleTime: STALE_TIME_SHORT_SECONDS,
    }
  );
  return {
    data: data,
    ...rest,
  };
}

export function useAgentCommands(): {
  data: any | undefined;
  isFetching: any;
  refetch: RefetchFunction;
  error: any;
} {
  const { data, ...rest } = useFetch(`/agent/commands`, {});
  return {
    data: data,
    ...rest,
  };
}

export function useHistoryEntry(
  clusterName: string,
  logId: string
): {
  data: any | undefined;
  isFetching: any;
  refetch: RefetchFunction;
  error: any;
} {
  const { data, ...rest } = useFetch(
    `/agent/workflowsResult/${clusterName}?id=${logId}`,
    {},
    {
      enabled: logId !== undefined && logId !== null && logId.trim().length > 0,
    }
  );
  return {
    data: data,
    ...rest,
  };
}

export function usePackageVersions(resourceName = "conductor"): {
  data: PackageVersionDto[] | undefined;
  isFetching: any;
  refetch: RefetchFunction;
  error: any;
} {
  const { data, ...rest } = useFetch<PackageVersionDto[]>(
    `/getPackageVersions?resource=${resourceName}`,
    {}
  );
  return {
    data,
    ...rest,
  };
}

export function useHealthChecks(clusterName: string): {
  data: any | undefined;
  isFetching: any;
  refetch: RefetchFunction;
  error: any;
} {
  const { data, ...rest } = useFetch(
    `/getHealthChecks?clusterName=${clusterName}`,
    {}
  );
  return {
    data: data,
    ...rest,
  };
}

export function useClusterHealth(clusterName: string): {
  data: any | undefined;
  isFetching: any;
  refetch: RefetchFunction;
  error: any;
} {
  const { data, ...rest } = useFetch(
    `/getClusterHealth?clusterName=${clusterName}`,
    {}
  );
  return {
    data: data,
    ...rest,
  };
}

export function useBulkHistoryEntry(
  clusterName: string,
  logIds: Array<string>
): {
  data: any | undefined;
  isFetching: any;
  refetch: RefetchFunction;
  error: any;
} {
  const { data, ...rest } = useFetch(
    `/agent/workflowsResultsBulk/${clusterName}?id=${logIds.join("&id=")}`,
    {},
    {
      enabled: _isArray(logIds) && logIds.length > 0,
    }
  );
  return {
    data: data,
    ...rest,
  };
}

export function useDeploymentInfo(clusterName: string): {
  data: any | undefined;
  isFetching: any;
  refetch: RefetchFunction;
  error: any;
} {
  const { data, ...rest } = useFetch(
    `/getDeploymentsInfo/?clusterName=${clusterName}`,
    {}
  );
  return {
    data: data,
    ...rest,
  };
}

export function useDeploymentInfoFull(clusterName: string): {
  data: any | undefined;
  isFetching: any;
  refetch: RefetchFunction;
  error: any;
} {
  const { data, ...rest } = useFetch(
    `/getDeploymentsInfoFull/?clusterName=${clusterName}`,
    {}
  );
  return {
    data: data,
    ...rest,
  };
}

export function useGetPodsAction(clusterName: string): {
  data: any | undefined;
  isFetching: any;
  refetch: RefetchFunction;
  error: any;
} {
  const { data, ...rest } = useFetch(
    `/agent/command`,
    {},
    {
      method: "post",
      body: JSON.stringify({
        clusterName: clusterName,
        command: "GET_PODS_NAMES",
        parameters: {},
      }),
    }
  );
  return {
    data: data,
    ...rest,
  };
}

export function useAllUserInvites(): {
  data: UserInvite[] | undefined;
  isFetching: any;
  refetch: RefetchFunction;
  isError: any;
} {
  const { data, ...rest } = useFetch<UserInvite[]>("/users/pendingInvites", {
    staleTime: STALE_TIME_UNLIMITED,
  });
  return {
    data: data,
    ...rest,
  };
}

function isValid(str: string | undefined | null) {
  return str !== undefined && str !== null && str.trim().length > 0;
}

export function useCustomerRegions(
  clusterName: string | undefined,
  customerAccountId: string | undefined
): {
  data: IdTitle[] | undefined;
  isFetching: any;
  refetch: RefetchFunction;
  isError: any;
} {
  const { data, ...rest } = useFetch<string[]>(
    `/getRegions?clusterName=${clusterName}&customerAccountId=${customerAccountId}`,
    {
      paramsAvailable: isValid(clusterName) && isValid(customerAccountId),
      staleTime: STALE_TIME_UNLIMITED,
    }
  );

  // Filter latest versions only
  const regions = useMemo(() => {
    if (data) {
      return data.map((region) => ({ id: region, title: region }));
    }
  }, [data]);

  return {
    data: regions,
    ...rest,
  };
}

export function useCustomerVPCIds(
  clusterName: string | undefined,
  customerAccountId: string | undefined,
  region: string | undefined
): {
  data: IdTitle[] | undefined;
  isFetching: any;
  refetch: RefetchFunction;
  isError: any;
} {
  const { data, ...rest } = useFetch<string[]>(
    `/getVpcIdsByRegion?clusterName=${clusterName}&customerAccountId=${customerAccountId}&region=${region}`,
    {
      paramsAvailable:
        isValid(clusterName) && isValid(customerAccountId) && isValid(region),
      staleTime: STALE_TIME_UNLIMITED,
    }
  );

  // Filter latest versions only
  const selectItems = useMemo(() => {
    if (data) {
      return data.map((value) => ({ id: value, title: value }));
    }
  }, [data]);

  return {
    data: selectItems,
    ...rest,
  };
}

export function useDomainTags(entityId = ""): {
  data: any[] | undefined;
  isFetching: any;
  refetch: RefetchFunction;
} {
  const { data, ...rest } = useFetch<any[]>(
    `/tags?entityType=CLUSTER_URL&entityId=${entityId}`,
    {
      staleTime: STALE_TIME_UNLIMITED,
      refetchOnMount: true,
      keepPreviousData: false,
    }
  );
  return {
    data: data,
    ...rest,
  };
}

export function useSignupPermittedValues(): {
  data?: ISignupPermittedValueDto;
  isFetching: any;
  refetch: RefetchFunction;
} {
  const { data, ...rest } = useFetch<ISignupPermittedValueDto>(
    "/signupPermittedValues",
    {
      staleTime: STALE_TIME_UNLIMITED,
    }
  );
  return {
    data,
    ...rest,
  };
}

export function useProvisioningPermittedValues(): {
  data: IProvisioningPermittedValueDto | undefined;
  isFetching: any;
  refetch: RefetchFunction;
} {
  const { data, ...rest } = useFetch<IProvisioningPermittedValueDto>(
    "/provisioningPermittedValues",
    {
      staleTime: STALE_TIME_UNLIMITED,
    }
  );
  return {
    data,
    ...rest,
  };
}

export function useCurrentRoles(): {
  data?: string[];
  isFetching: boolean;
  isFetched: boolean;
  refetch: RefetchFunction;
} {
  const { data, ...rest } = useFetch<any[]>(`/currentRoles`, {
    staleTime: STALE_TIME_UNLIMITED,
  });
  return {
    data,
    ...rest,
  };
}
export function useClusterVersionHistory(
  clustername: any,
  resourceName: any
): {
  data: any | undefined;
  isFetching: any;
  refetch: RefetchFunction;
  error: any;
} {
  const { data, ...rest } = useFetch(
    `/getClusterVersionsHistory/?clusterName=${clustername}&resource=${resourceName}`,
    {}
  );
  return {
    data: data,
    ...rest,
  };
}
export function useClusterVersionEnvironamentVariables(
  clustername: any,
  versionid: string
): {
  data: any | undefined;
  isFetching: any;
  refetch: RefetchFunction;
  error: any;
} {
  const { data, ...rest } = useFetch(
    `/getClusterVersionEnvVars/?clusterName=${clustername}&versionId=${versionid}`,
    {
      paramsAvailable: isValid(versionid),
    }
  );
  return {
    data: data,
    ...rest,
  };
}

export function useCloudRegion() {
  const { data, ...rest } = useFetch(`/cloudProviderRegions`, {});

  return {
    data: (data as CloudProviderRegion[])?.reduce((result, item) => {
      return { ...result, [item.clusterType]: item.cloudToRegionsMap };
    }, {}) as {
      [key in ClusterCapacityType]: { [key in CloudProvider]: Region[] };
    },
    ...rest,
  };
}

export function useAllReleases() {
  const { authService } = useAuth();
  const [{ isLoggedInAndVerified }] = useAuthMachine(authService);
  return useFetch<ReleaseDto[]>(FETCH_ALL_RELEASES_PATH, {
    enabled: isLoggedInAndVerified,
    staleTime: STALE_TIME_UNLIMITED,
  });
}

export function useSyncReleases(options?: any) {
  const { authService } = useAuth();
  const [{ isLoggedInAndVerified }] = useAuthMachine(authService);
  return useFetch<{ ready: boolean; workflowId: string }>(SYNC_RELEASES_PATH, {
    ...options,
    enabled: isLoggedInAndVerified && options?.enabled,
    staleTime: 0,
  });
}

export function useGetAllReleases(workflowId?: string, options?: object) {
  const { data, ...rest } = useFetch<{
    ready: boolean;
    releases?: ReleaseDto[];
    workflowId?: string;
  }>(`${SYNC_RELEASES_PATH}?workflowId=${workflowId}`, {
    ...options,
    staleTime: STALE_TIME_UNLIMITED,
  });
  return {
    data: data,
    ...rest,
  };
}

export function useGetAlConductorReleases() {
  return useFetch<ConductorReleaseDto[]>("/release/fetchConductorReleases", {
    staleTime: STALE_TIME_UNLIMITED,
  });
}

export function useFetchReleaseNotes(version: string, options?: object) {
  const { authService } = useAuth();
  const [{ isLoggedInAndVerified }] = useAuthMachine(authService);
  return useFetch<{ release_notes: string }>(
    `/release/fetchReleaseNotes?version=${version}`,
    {
      ...options,
      staleTime: 0,
      enabled: isLoggedInAndVerified && !!version,
      retry: 0,
      useLocalMessage: true,
    }
  );
}

export function useFetchCompatibilityList(option?: IObject) {
  const { authService } = useAuth();
  const [{ isLoggedInAndVerified }] = useAuthMachine(authService);
  return useFetch<CompatibilityDto[]>(FETCH_COMPATIBILITY_LIST, {
    ...option,
    enabled: isLoggedInAndVerified,
    staleTime: STALE_TIME_10_MINS,
  });
}

export function useFetchAllCertificates(clusterName: string, options?: object) {
  return useFetch<CertificateDto[]>(
    `/customCertificates/getAll?clusterName=${clusterName}`,
    {
      ...options,
      staleTime: STALE_TIME_UNLIMITED,
    }
  );
}
