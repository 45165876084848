export function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const ColumnHeader = ({ children }: { children: any }) => {
  return (
    <h6
      style={{
        margin: 0,
        fontWeight: "normal",
        fontSize: 14,
        color: "#777",
      }}
    >
      {children}
    </h6>
  );
};
