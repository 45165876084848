import styled from "@mui/styles/styled";
import Button from "@mui/material/Button";
import { colorOverrides } from "../../theme/colorOverrides";

export const fontFamilyList = [
  "Lexend",
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(",");

const hoverCss = {
  // backgroundColor: "#857aff!important",
  // borderColor: "#857aff!important",
  boxShadow: "none!important",
  "&> .MuiButton-label": {
    color: "white!important",
  },
};

const buttonBaseStyle = {
  boxShadow: "none!important",
  textTransform: "none!important",
  fontSize: "15px!important",
  padding: "6px 12px!important",
  border: "1px solid",
  lineHeight: "1.3!important",
  color: "#ffffff!important",
  backgroundColor: "#6558F5!important",
  borderColor: "#6558F5!important",
  fontFamily: fontFamilyList,
  "&:hover": hoverCss,
  "&:active": hoverCss,
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
  "&> .MuiButton-label": {
    color: "#ffffff",
  },
};

export const BootstrapButton = styled(Button)(buttonBaseStyle);

const outlineHoverCss = {
  ...hoverCss,
  opacity: 1,
  "&> .MuiButton-label": {
    color: colorOverrides.backgroundLeftTop,
  },
};

const actionHoverCss = {
  ...hoverCss,
  backgroundColor: "#6558F5",
  borderColor: "#6558F5",
  opacity: 1,
};

export const BootstrapOutlineButton = styled(Button)({
  ...buttonBaseStyle,
  color: "#1976d2",
  backgroundColor: colorOverrides.backgroundLeftTop,
  borderColor: "#1976d2",
  "&> .MuiButton-label": {
    color: "#1976d2",
  },
  opacity: 0.7,
  "&:hover": outlineHoverCss,
  "&:active": outlineHoverCss,
});

export const BootstrapOutlineActionButton = styled(Button)({
  ...buttonBaseStyle,
  color: "#ffffff",
  backgroundColor: colorOverrides.backgroundLeftTop,
  borderColor: "#30499f",
  "&> .MuiButton-label": {
    color: "#30499f",
  },
  "&:hover": actionHoverCss,
  "&:active": actionHoverCss,
});

export const BootstrapTextButton = styled(Button)({
  ...buttonBaseStyle,
  color: "#ffffff",
  fontSize: 15,
  fontWeight: "normal",
  backgroundColor: colorOverrides.backgroundLeftTop,
  borderColor: "transparent",
  "&> .MuiButton-label": {
    color: "#6558F5",
  },
  "&:hover": outlineHoverCss,
  "&:active": outlineHoverCss,
});

export const BootstrapActionButton = styled(Button)({
  ...buttonBaseStyle,
  fontSize: 14,
  lineHeight: 1.5,
  backgroundColor: "#6558F5",
  borderColor: "#6558F5",
  "&> .MuiButton-label": {
    color: "#ffffff",
  },
  opacity: 0.8,
  "&:hover": actionHoverCss,
  "&:active": actionHoverCss,
});
