import { Box, Button, CardMedia } from "@mui/material";
import { Helmet } from "react-helmet";
import ErrorImg from "images/401-Error.png";
import { useNavigate } from "react-router-dom";

const UnauthorizedPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Unauthorized</title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          overflow: "auto",
          width: "100%",
        }}
      >
        <Box>
          <CardMedia
            sx={{ height: "calc(70vh)", objectFit: "contain" }}
            component="img"
            image={ErrorImg}
            alt="401 Error"
          />
        </Box>
        <Box sx={{ fontWeight: 800, fontSize: "20px", marginBottom: 2 }}>
          Not Authorized
        </Box>
        <Button variant="contained" onClick={() => navigate("/home")}>
          Back To Home
        </Button>
      </Box>
    </>
  );
};

export default UnauthorizedPage;
