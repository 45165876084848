import { useEffect, useRef, useState } from "react";
import { useFetchReleaseNotes, useGetAlConductorReleases } from "utils/query";
import { TabPanel } from "./TabPanel";
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import DataTable from "components/DataTable/DataTable";
import { ConductorReleaseDto } from "utils/saastypes";
import { customStyles } from "utils/utils";
import Markdown from "react-markdown";

const overlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  padding: "20px",
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.2)",
  backdropFilter: "blur(3px)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1201, //greater than sidebar
  cursor: "zoom-out",
};
const imageStyle = {
  maxWidth: "90%",
  maxHeight: "90%",
  minWidth: "50%",
  minHeight: "50%",
  margin: 0,
};

const ReleaseTab = ({
  selectedTabIndex,
  index,
}: {
  selectedTabIndex: number;
  index: number;
}) => {
  const { data, isFetching } = useGetAlConductorReleases();

  const [fetchVersion, setFetchVersion] = useState("");
  const [zoomedImage, setZoomedImage] = useState<string | null>(null);
  const releaseNotesRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (data && data?.length > 0) {
      setFetchVersion(data[0].version);
    }
  }, [data]);

  const { data: releaseNotesData, isFetching: isReleaseNotesFetching } =
    useFetchReleaseNotes(fetchVersion);

  const columns = [
    {
      name: "version",
      selector: (row: ConductorReleaseDto) => row.version,
      label: "Release #",
      renderer(val: string, row: ConductorReleaseDto) {
        return (
          <Button
            disableRipple
            onClick={() => {
              setFetchVersion(val);
            }}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              ":hover": {
                color: "#2196f3",
                backgroundColor: "unset",
              },
            }}
          >
            {val}
          </Button>
        );
      },
    },
    {
      name: "dateOfRelease",
      selector: (row: ConductorReleaseDto) => row.dateOfRelease,
      label: "Date Released",
      grow: 1.5,
      type: "date",
    },
    {
      name: "releaseSummary",
      selector: (row: ConductorReleaseDto) => row.releaseSummary,
      label: "Release Summary",
      grow: 1.5,
    },
  ];

  const handleImageClick = (imageUrl: string) => {
    setZoomedImage(imageUrl);
  };

  useEffect(() => {
    const handleImageClickEvent = (event: MouseEvent) => {
      const target = event.target as HTMLImageElement;
      if (target.tagName === "IMG") {
        handleImageClick(target.src);
      }
    };

    document.addEventListener("click", handleImageClickEvent);

    return () => document.removeEventListener("click", handleImageClickEvent);
  }, []);

  useEffect(() => {
    if (releaseNotesRef.current) {
      releaseNotesRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [releaseNotesData]);

  return (
    <TabPanel value={selectedTabIndex} index={index}>
      <Grid container spacing={4} sx={{ padding: 8 }}>
        <Grid item sm={12} md={6}>
          <Paper elevation={1}>
            <DataTable
              localStorageKey="conductorVersions"
              customStyles={customStyles}
              progressPending={isFetching}
              pagination
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 20, 50]}
              noDataComponent={
                <Box padding={5} fontWeight={600}>
                  No versions found
                </Box>
              }
              data={data}
              columns={columns}
              quickSearchEnabled
              quickSearchPlaceholder="Search Releases"
            />
          </Paper>
        </Grid>
        <Grid item sm={12} md={6} ref={releaseNotesRef}>
          {isReleaseNotesFetching || isFetching ? (
            <LinearProgress />
          ) : (
            <>
              {releaseNotesData?.release_notes ? (
                <Paper
                  elevation={1}
                  sx={{
                    p: [2, 4],
                    img: {
                      maxWidth: "80%",
                      my: 8,
                      cursor: "zoom-in",
                    },
                  }}
                >
                  <Markdown>{releaseNotesData?.release_notes}</Markdown>
                </Paper>
              ) : (
                <Typography sx={{ textAlign: "center", fontWeight: "400" }}>
                  Release Notes not available for version {fetchVersion}
                </Typography>
              )}
            </>
          )}
        </Grid>
      </Grid>
      {zoomedImage && (
        <Box onClick={() => setZoomedImage(null)} sx={overlayStyle}>
          <img
            src={zoomedImage}
            style={imageStyle}
            alt="full screen"
            onClick={(e) => {
              e.nativeEvent.stopImmediatePropagation();
              setZoomedImage(null);
            }}
          />
        </Box>
      )}
    </TabPanel>
  );
};

export default ReleaseTab;
