import React, { useCallback, useMemo, useState } from "react";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Message } from "components/SnackbarMessage";
import { MessageContext } from "utils/MessageProvider";
import { CLUSTER_ACTION_TYPE, LINEAR_PROGRESS_INDEX } from "constants/common";
import {
  ClusterActionTypeOptionsProp,
  ClusterType,
  CustomerEnvironment,
  Pod,
  RolloutRestartType,
} from "utils/saastypes";
import AlertDialog from "components/AlertDialog";
import ScaleDeploymentDialog from "components/dialogs/ScaleDeploymentDialog";
import UpgradeDeploymentDialog from "components/dialogs/UpgradeDeployment/UpgradeDeploymentDialog";
import RollbackDeploymentDialog from "components/dialogs/RollbackDeploymentDialog";
import { Box } from "@mui/system";
import { ClearRounded } from "@mui/icons-material";
import AddEKSToAgentDialog from "components/dialogs/AddEKSToAgentDialog";
import UploadCertificateDialog from "components/dialogs/UploadCertificateDialog";
import RolloutRestartDialog from "components/dialogs/RolloutRestartDialog";
import ChangeEnvironmentTypeDialog from "components/dialogs/ChangeEnvironmentTypeDialog";
import ChangeTelemetryDialog from "components/dialogs/ChangeTelemetryDialog";
import {
  useActionWithPath,
  useAgentLogs,
  useDeploymentInfo,
  useFetchCompatibilityList,
} from "utils/query";
import { AGENT_COMMAND_PATH, DEPLOY_METRICS_PROXY_PATH } from "utils/apiPaths";
import {
  contextFlagOptions,
  fetchOptions,
  INTEGER_AT_LEAST_ONE_NUMBER,
  rolloutRestartResourceBaseTypes,
} from "./constants";
import { CLUSTER_DETAIL_URL } from "utils/routePaths";

interface ActionTypeSelectorProps {
  isOrkesUser: boolean;
  clusterDetail: CustomerEnvironment | undefined;
  refetchClusterDetail: () => void;
  selectedActionType: string;
  podName: string;
  handleSelectedActionType: (val: string) => void;
  handleChangePodName: (val: string) => void;
}

function ActionTypeSelector({
  isOrkesUser,
  clusterDetail,
  selectedActionType,
  podName,
  refetchClusterDetail,
  handleSelectedActionType,
  handleChangePodName,
}: ActionTypeSelectorProps) {
  const navigate = useNavigate();
  const { clusterName } = useParams();
  const { refetch: refetchLogs } = useAgentLogs(clusterName as string);
  const { data: compatibilityVersionList } = useFetchCompatibilityList();

  const { data: deploymentInfo } = useDeploymentInfo(clusterDetail?.name ?? "");

  const podsData = deploymentInfo?.pods_data ?? [];
  const podsNames = podsData.map((item: Pod) => item?.name);

  // Add options to rollout restart options
  const rolloutRestartResourceTypes = useMemo<
    {
      name: string;
      value: RolloutRestartType;
    }[]
  >(() => {
    const result = [...rolloutRestartResourceBaseTypes];

    if (
      clusterDetail &&
      [ClusterType.STANDARD, ClusterType.ENTERPRISE].includes(
        clusterDetail.type
      )
    ) {
      result.push({
        name: "Metrics Proxy",
        value: RolloutRestartType.METRICS_PROXY,
      });
    }

    return result;
  }, [clusterDetail]);

  const [selectedRestartResourceType, setSelectedRestartResourceType] =
    useState(rolloutRestartResourceTypes[0]);

  const { pathname } = useLocation();
  const basePath = `${CLUSTER_DETAIL_URL.ROOT}/${clusterName}`;
  const relativePath = pathname.split(basePath)[1];
  const [grep, setGrep] = useState("");
  const [sqlQuery, setSqlQuery] = useState("");
  const [expectedRowCount, setExpectedRowCount] = useState("");
  const [contextLength, setContextLength] = useState("1");
  const [contextFlag, setContextFlag] = useState("N");

  const [fetchOption, setFetchOption] = useState("tail");

  const [startLine, setStartLine] = useState("");

  const [lines, setLines] = useState("100");

  const [forceDelete, setForceDelete] = useState(false);

  const { setMessage } = React.useContext(MessageContext);

  const showError = (message: string) => {
    setMessage(new Message(message, "error"));
  };

  const showSuccess = (message: string) => {
    setMessage(new Message(message, "success"));
  };

  const clusterActionTypeOptions: ClusterActionTypeOptionsProp[] = [
    {
      label: "Upgrade Deployment",
      value: CLUSTER_ACTION_TYPE.UPGRADE_DEPLOYMENT,
      hidden: false,
    },
    {
      label: "Scale Deployment",
      value: CLUSTER_ACTION_TYPE.SCALE_DEPLOYMENT,
      hidden: !isOrkesUser && clusterDetail?.type !== ClusterType.ENTERPRISE,
    },
    {
      label: "Add EKS Administrator",
      value: CLUSTER_ACTION_TYPE.ADD_EKS_ADMIN,
      hidden: clusterDetail?.type !== ClusterType.ENTERPRISE,
    },
    {
      label: "Pull Pod Logs",
      value: CLUSTER_ACTION_TYPE.PULL_LOGS,
      hidden: false,
    },
    {
      label: "Get Deployments Info",
      value: CLUSTER_ACTION_TYPE.GET_DEPLOYMENTS_INFO,
      hidden: true,
    },
    {
      label: "Health check",
      value: CLUSTER_ACTION_TYPE.HEALTH_CHECK,
      hidden: true,
    },
    {
      label: "Get Pod Names",
      value: CLUSTER_ACTION_TYPE.GET_PODS_NAMES,
      hidden: true,
    },
    {
      label: "Rollout Restart",
      value: CLUSTER_ACTION_TYPE.ROLLOUT_RESTART,
      hidden: false,
    },
    {
      label: "Rollback Deployment",
      value: CLUSTER_ACTION_TYPE.ROLLBACK_DEPLOYMENT,
      hidden: false,
    },
    {
      label: "Change Cluster Environment Type",
      value: CLUSTER_ACTION_TYPE.CHANGE_ENVIRONMENT_TYPE,
      hidden: false,
    },
    {
      label: "Deploy Metrics Proxy",
      value: CLUSTER_ACTION_TYPE.DEPLOY_METRICS_PROXY,
      hidden: !isOrkesUser || clusterDetail?.type === ClusterType.STARTER,
    },
    {
      label: "Change Telemetry State",
      value: CLUSTER_ACTION_TYPE.CHANGE_TELEMETRY_STATE,
      hidden: !isOrkesUser,
    },
    {
      label: "Deploy Debugger Pod",
      value: CLUSTER_ACTION_TYPE.DEPLOY_DEBUGGER_POD,
      hidden: !isOrkesUser,
    },
    {
      label: "Upload Certificate",
      value: CLUSTER_ACTION_TYPE.UPLOAD_CERTIFICATE,
      hidden: false,
    },
    {
      label: "Download all logs from Pod",
      value: CLUSTER_ACTION_TYPE.DOWNLOAD_ALL_POD_LOGS,
      hidden: false,
    },
    {
      label: "Get Heap Dump",
      value: CLUSTER_ACTION_TYPE.DOWNLOAD_HEAP_DUMP,
      hidden: !isOrkesUser,
    },
    {
      label: "Download Thread Dump",
      value: CLUSTER_ACTION_TYPE.DOWNLOAD_THREAD_DUMP,
      hidden: !isOrkesUser,
    },
    {
      label: "Get Top Output From Pod",
      value: CLUSTER_ACTION_TYPE.GET_TOP_OUTPUT_FROM_POD,
      hidden: false,
    },
    {
      label: "Run SQL Query in Conductor",
      value: CLUSTER_ACTION_TYPE.RUN_SQL_QUERY_IN_CONDUCTOR,
      hidden: !isOrkesUser,
    },
  ];

  const closeDialog = () => {
    handleSelectedActionType("");
  };

  const renderActionTypeOptions = (options: ClusterActionTypeOptionsProp[]) =>
    options.map((option: ClusterActionTypeOptionsProp, index: number) =>
      option.hidden ? null : (
        <MenuItem key={index} value={option.value}>
          {option.label}
        </MenuItem>
      )
    );

  const deployMetricProxyAction = useActionWithPath({
    onSuccess: (data: { status?: string }) => {
      if (data?.status === "ERROR") {
        showError("Could not deploy metrics-proxy");
      } else {
        showSuccess(`Metrics-proxy have been scheduled successfully.`);
      }
    },
  });

  const handleDeployMetrics = useCallback(() => {
    if (clusterDetail) {
      // @ts-ignore
      deployMetricProxyAction.mutate({
        method: "POST",
        path: `${DEPLOY_METRICS_PROXY_PATH}?clusterName=${clusterDetail.name}`,
        errorMessage: "Could not deploy metrics-proxy:",
      });
    }
  }, [deployMetricProxyAction, clusterDetail]);

  const deployDebuggerPodAction = useActionWithPath({
    onSuccess: () => {
      refetchClusterDetail();
    },
    onError: () => {
      refetchClusterDetail();
    },
  });

  const handleDeployDebuggerPod = useCallback(() => {
    if (clusterDetail) {
      // @ts-ignore
      deployDebuggerPodAction.mutate({
        method: "post",
        path: AGENT_COMMAND_PATH,
        body: JSON.stringify({
          clusterName: clusterDetail?.name,
          command: CLUSTER_ACTION_TYPE.DEPLOY_DEBUGGER_POD,
          parameters: {},
        }),
        errorMessage: "Could not deploy debugger pod",
        successMessage: "Debugger Pod have been successfully deployed.",
      });
    }
  }, [clusterDetail, deployDebuggerPodAction]);

  const pullLogsAction = useActionWithPath({
    onSuccess: (data: any) => {
      if (data?.status === "ERROR") {
        showError("Could not pull pod logs");
      } else {
        if (data.id) {
          navigate(
            `${CLUSTER_DETAIL_URL.ROOT}/${clusterName}/${CLUSTER_DETAIL_URL.HISTORY}/${data.id}`
          );
        }
        showSuccess(`Pod logs have been scheduled successfully.`);
      }
    },
  });

  const sqlQueryAction = useActionWithPath({
    onSuccess: (data: any) => {
      if (data?.status === "ERROR") {
        showError("Could not run sql action");
      } else {
        if (data.id) {
          navigate(
            `${CLUSTER_DETAIL_URL.ROOT}/${clusterName}/${CLUSTER_DETAIL_URL.HISTORY}/${data.id}`
          );
        }
        showSuccess(`Sql command ran successfully `);
      }
    },
  });
  const downloadAllPodLogsAction = useActionWithPath({
    onSuccess: (data: any) => {
      if (data?.status === "ERROR") {
        showError("Could not download all pod logs");
      } else {
        if (data.id) {
          navigate(
            `${CLUSTER_DETAIL_URL.ROOT}/${clusterName}/${CLUSTER_DETAIL_URL.HISTORY}/${data.id}`
          );
        }
        showSuccess(`Download has been scheduled successfully.`);
      }
    },
  });

  const deletePodAction = useActionWithPath({
    onSuccess: (data: any) => {
      if (data?.status === "ERROR") {
        showError("Could not delete the pod");
      } else {
        if (data.id) {
          navigate(
            `${CLUSTER_DETAIL_URL.ROOT}/${clusterName}/${CLUSTER_DETAIL_URL.HISTORY}/${data.id}`
          );
        }
        showSuccess(`Pod deletion has been scheduled successfully.`);
      }
    },
  });

  const healthCheckAction = useActionWithPath({
    onSuccess: (data: any) => {
      if (data?.status === "ERROR") {
        showError("Health check failed");
      } else {
        if (data.id) {
          navigate(
            `${CLUSTER_DETAIL_URL.ROOT}/${clusterName}/${CLUSTER_DETAIL_URL.HISTORY}/${data.id}`
          );
        }
        showSuccess(`Health check has been scheduled successfully.`);
      }
    },
  });

  const getPodNamesAction = useActionWithPath({
    onSuccess: (data: any) => {
      if (data?.status === "ERROR") {
        showError("Pod names fetch failed");
      } else {
        if (data.id) {
          navigate(
            `${CLUSTER_DETAIL_URL.ROOT}/${clusterName}/${CLUSTER_DETAIL_URL.HISTORY}/${data.id}`
          );
        }
        showSuccess(`Pod names request has been scheduled successfully.`);
      }
    },
  });

  const getDeploymentsInfoAction = useActionWithPath({
    onSuccess: (data: any) => {
      if (data?.status === "ERROR") {
        showError("Deployment info fetch failed");
      } else {
        if (relativePath?.startsWith(`/${CLUSTER_DETAIL_URL.HISTORY}`)) {
          // refetch the logs
          refetchLogs();
        } else {
          navigate(
            `${CLUSTER_DETAIL_URL.ROOT}/${clusterName}/${CLUSTER_DETAIL_URL.HISTORY}`
          );
        }

        showSuccess(`Deployment info request has been scheduled successfully.`);
      }
    },
  });

  const downloadThreadDumpAction = useActionWithPath({
    onSuccess: (data: any) => {
      if (data?.status === "ERROR") {
        showError("Could not download thread dump.");
      } else {
        if (data.id) {
          navigate(
            `${CLUSTER_DETAIL_URL.ROOT}/${clusterName}/${CLUSTER_DETAIL_URL.HISTORY}/${data.id}`
          );
        }
        showSuccess(`Download thread dumb has been scheduled successfully.`);
      }
    },
  });

  const PodSelector = () => {
    return (
      <Box sx={{ minWidth: 120, marginTop: "16px", display: "flex" }}>
        <Box style={{ width: "100%" }}>
          <Box style={{ marginBottom: 16 }}>
            <Autocomplete
              id="select-pod-name"
              freeSolo
              options={podsData.map((option: Pod) => option.name)}
              renderInput={(params) => (
                <TextField {...params} label="Pod Name" />
              )}
              value={podName}
              onInputChange={(_event, value) => {
                handleChangePodName(value ?? "");
              }}
              onChange={(_event, value) => {
                handleChangePodName(value ?? "");
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  };
  const getHeapDumpAction = useActionWithPath({
    onSuccess: (data: any) => {
      if (data?.status === "ERROR") {
        showError("Could not fetch heap dump");
      } else {
        if (data.id) {
          navigate(
            `${CLUSTER_DETAIL_URL.ROOT}/${clusterName}/${CLUSTER_DETAIL_URL.HISTORY}/${data.id}`
          );
        }
        showSuccess(`Get heap dump has been scheduled successfully.`);
      }
    },
  });
  const getTopOutputFromPodAction = useActionWithPath({
    onSuccess: (data: any) => {
      if (data?.status === "ERROR") {
        showError("Could not fetch top output from pod");
      } else {
        if (data.id) {
          navigate(
            `${CLUSTER_DETAIL_URL.ROOT}/${clusterName}/${CLUSTER_DETAIL_URL.HISTORY}/${data.id}`
          );
        }
        showSuccess(`Get Top output from pod has been scheduled successfully.`);
      }
    },
  });

  return (
    <>
      <FormControl
        style={{
          width: 300,
          marginLeft: "auto",
          marginRight: 10,
        }}
      >
        <InputLabel id="action-type-label">Select an Action</InputLabel>
        <Select
          labelId="action-type-label"
          id="action-type-select"
          value={selectedActionType}
          label="Select an Action"
          onChange={(ev) => {
            handleSelectedActionType(ev.target.value);
          }}
          MenuProps={{
            sx: { zIndex: LINEAR_PROGRESS_INDEX + 1 },
          }}
        >
          {renderActionTypeOptions(clusterActionTypeOptions)}
        </Select>
      </FormControl>
      <>
        {selectedActionType === CLUSTER_ACTION_TYPE.DELETE_POD && (
          <AlertDialog
            textTitle={"Deleting Confirmation"}
            textContent={
              <>
                Please confirm if you want to proceed to delete the Pod&nbsp;
                <strong className="danger-text">{podName}</strong>. This action
                cannot be undone.
              </>
            }
            open={selectedActionType === CLUSTER_ACTION_TYPE.DELETE_POD}
            setOpen={closeDialog}
            handleConfirm={() => {
              closeDialog();
              // @ts-ignore
              deletePodAction.mutate({
                method: "post",
                path: `/agent/command`,
                body: JSON.stringify({
                  clusterName: clusterDetail?.name,
                  command: CLUSTER_ACTION_TYPE.DELETE_POD,
                  parameters: {
                    podName,
                    ...(forceDelete && { force: forceDelete }),
                  },
                }),
                errorMessage: "Could not delete the pod:",
              });
              setForceDelete(false);
            }}
            isInputConfirmation
            valueToBeDeleted={podName}
          >
            <Box style={{ position: "absolute", bottom: 15 }}>
              <FormControl fullWidth>
                <FormControlLabel
                  label="Force delete"
                  control={
                    <Checkbox
                      size="small"
                      checked={forceDelete}
                      onChange={(e) => setForceDelete(e.target.checked)}
                    />
                  }
                />
              </FormControl>
            </Box>
          </AlertDialog>
        )}

        {selectedActionType === CLUSTER_ACTION_TYPE.SCALE_DEPLOYMENT && (
          <ScaleDeploymentDialog
            open={selectedActionType === CLUSTER_ACTION_TYPE.SCALE_DEPLOYMENT}
            closeDialog={closeDialog}
            clusterName={clusterName}
            clusterDetail={clusterDetail as CustomerEnvironment}
            showError={showError}
            showSuccess={showSuccess}
            refetchLogs={refetchLogs}
          />
        )}

        {selectedActionType === CLUSTER_ACTION_TYPE.UPGRADE_DEPLOYMENT && (
          <UpgradeDeploymentDialog
            open={selectedActionType === CLUSTER_ACTION_TYPE.UPGRADE_DEPLOYMENT}
            closeDialog={closeDialog}
            clusterName={clusterName}
            clusterDetail={clusterDetail as CustomerEnvironment}
            showError={showError}
            showSuccess={showSuccess}
            refetchLogs={refetchLogs}
            compatibilityVersionList={compatibilityVersionList || []}
          />
        )}

        {selectedActionType === CLUSTER_ACTION_TYPE.ROLLBACK_DEPLOYMENT && (
          <RollbackDeploymentDialog
            open={
              selectedActionType === CLUSTER_ACTION_TYPE.ROLLBACK_DEPLOYMENT
            }
            closeDialog={closeDialog}
            clusterName={clusterName}
            clusterDetail={clusterDetail as CustomerEnvironment}
            showError={showError}
            showSuccess={showSuccess}
            refetchLogs={refetchLogs}
          />
        )}
        <AlertDialog
          maxWidth="sm"
          textTitle={`Run sql query`}
          textContent={``}
          open={
            selectedActionType ===
            CLUSTER_ACTION_TYPE.RUN_SQL_QUERY_IN_CONDUCTOR
          }
          setOpen={closeDialog}
          handleConfirm={() => {
            closeDialog();
            // @ts-ignore
            sqlQueryAction.mutate({
              method: "post",
              path: `/agent/command`,
              body: JSON.stringify({
                clusterName: clusterDetail?.name,
                command: CLUSTER_ACTION_TYPE.RUN_SQL_QUERY_IN_CONDUCTOR,
                parameters: {
                  query: sqlQuery,
                  expectedRowCount: expectedRowCount,
                },
              }),
              errorMessage: "Could not execute sql query",
            });
          }}
        >
          Run Sql in conductor. Cluster: <strong>{clusterDetail?.name}</strong>
          <Box sx={{ minWidth: 120, marginTop: "16px", display: "flex" }}>
            <Box style={{ width: "100%" }}>
              <Box style={{ marginBottom: 16 }}>
                <FormControl fullWidth>
                  <TextField
                    label="Query"
                    placeholder="Select * from ...."
                    value={sqlQuery}
                    onChange={(ev) => {
                      setSqlQuery(ev.target.value);
                    }}
                    InputProps={{
                      endAdornment: sqlQuery.length > 0 && (
                        <InputAdornment
                          position="end"
                          style={{ marginRight: -8 }}
                        >
                          <IconButton
                            size="small"
                            onClick={() => setSqlQuery("")}
                          >
                            <ClearRounded style={{ fontSize: 18 }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Box>
              <Box style={{ marginBottom: 16 }}>
                <FormControl fullWidth>
                  <TextField
                    label="Expected row count"
                    placeholder="10"
                    value={expectedRowCount}
                    onChange={(ev) => {
                      setExpectedRowCount(ev.target.value);
                    }}
                    InputProps={{
                      endAdornment: expectedRowCount.length > 0 && (
                        <InputAdornment
                          position="end"
                          style={{ marginRight: -8 }}
                        >
                          <IconButton
                            size="small"
                            onClick={() => setExpectedRowCount("")}
                          >
                            <ClearRounded style={{ fontSize: 18 }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Box>
            </Box>
          </Box>
        </AlertDialog>

        <AlertDialog
          maxWidth="sm"
          textTitle={`Pull Pod Logs`}
          textContent={``}
          open={selectedActionType === CLUSTER_ACTION_TYPE.PULL_LOGS}
          setOpen={closeDialog}
          handleConfirm={() => {
            closeDialog();
            // @ts-ignore
            pullLogsAction.mutate({
              method: "post",
              path: `/agent/command`,
              body: JSON.stringify({
                clusterName: clusterDetail?.name,
                command: CLUSTER_ACTION_TYPE.PULL_LOGS,
                parameters: {
                  grep,
                  startLine: (Number.parseInt(startLine) || 0) + 1,
                  podName,
                  lines: Number.parseInt(lines) || 100,
                  contextFlag,
                  contextLength,
                  fetchOption,
                },
              }),
              errorMessage: "Could not pull pod logs:",
            });
          }}
        >
          Pull logs from the cluster <strong>{clusterDetail?.name}</strong>
          <Box sx={{ minWidth: 120, marginTop: "16px", display: "flex" }}>
            <Box style={{ width: "100%" }}>
              <Box style={{ marginBottom: 16 }}>
                <FormControl fullWidth>
                  <TextField
                    label="Pod Name"
                    placeholder="Pod Name"
                    value={podName}
                    onChange={(ev) => {
                      handleChangePodName(ev.target.value);
                    }}
                  />
                </FormControl>
              </Box>
              <Box style={{ marginBottom: 16 }}>
                <FormControl fullWidth>
                  <InputLabel id="fetch-option-label">Fetching</InputLabel>
                  <Select
                    labelId="fetch-option-label"
                    id="fetch-option-select"
                    value={fetchOption}
                    label="Fetching"
                    onChange={(ev) => {
                      setFetchOption(ev.target.value);
                      setStartLine("");
                    }}
                  >
                    {fetchOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box style={{ marginBottom: 16 }}>
                <FormControl fullWidth>
                  <TextField
                    label="Lines (up to 1000)"
                    placeholder="How many lines will be fetched"
                    value={lines}
                    inputProps={{
                      inputMode: "numeric",
                      type: "number",
                      min: 1,
                      max: 1000,
                      pattern: INTEGER_AT_LEAST_ONE_NUMBER,
                    }}
                    onChange={(ev) => {
                      setLines(ev.target.value.replace(/[^\d.-]/g, ""));
                    }}
                  />
                </FormControl>
              </Box>
              {fetchOption === "head" && (
                <Box style={{ marginBottom: 16 }}>
                  <FormControl fullWidth>
                    <TextField
                      label="Skipping"
                      placeholder="How many lines to skip"
                      inputProps={{
                        inputMode: "numeric",
                        type: "number",
                        min: 0,
                        pattern: INTEGER_AT_LEAST_ONE_NUMBER,
                      }}
                      value={startLine}
                      onChange={(ev) => {
                        setStartLine(ev.target.value.replace(/[^\d.-]/g, ""));
                      }}
                    />
                  </FormControl>
                </Box>
              )}
              <Box style={{ marginBottom: 16 }}>
                <FormControl fullWidth>
                  <TextField
                    label="Grep"
                    placeholder="Grep"
                    value={grep}
                    onChange={(ev) => {
                      setGrep(ev.target.value.trim());
                    }}
                    InputProps={{
                      endAdornment: grep.length > 0 && (
                        <InputAdornment
                          position="end"
                          style={{ marginRight: -8 }}
                        >
                          <IconButton size="small" onClick={() => setGrep("")}>
                            <ClearRounded style={{ fontSize: 18 }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Box>
              {grep.length > 0 && (
                <Box style={{ marginBottom: 16 }}>
                  <FormControl fullWidth>
                    <InputLabel id="context-flag-label">
                      With context
                    </InputLabel>
                    <Select
                      labelId="context-flag-label"
                      id="context-flag-select"
                      value={contextFlag}
                      label="With context"
                      onChange={(ev) => {
                        setContextFlag(ev.target.value);
                      }}
                    >
                      {contextFlagOptions.map((flag) => (
                        <MenuItem key={flag.value} value={flag.value}>
                          {flag.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}
              {grep.length > 0 && contextFlag !== "N" && (
                <Box style={{ marginBottom: 16 }}>
                  <FormControl fullWidth>
                    <TextField
                      label="Context Length"
                      placeholder="Context Length"
                      inputProps={{
                        inputMode: "numeric",
                        type: "number",
                        min: 1,
                        pattern: INTEGER_AT_LEAST_ONE_NUMBER,
                      }}
                      value={contextLength}
                      onChange={(ev) => {
                        setContextLength(ev.target.value);
                      }}
                    />
                  </FormControl>
                </Box>
              )}
            </Box>
          </Box>
        </AlertDialog>

        {selectedActionType === CLUSTER_ACTION_TYPE.ADD_EKS_ADMIN && (
          <AddEKSToAgentDialog
            open={selectedActionType === CLUSTER_ACTION_TYPE.ADD_EKS_ADMIN}
            closeDialog={closeDialog}
            clusterName={clusterName}
            clusterDetail={clusterDetail as CustomerEnvironment}
            showError={showError}
            showSuccess={showSuccess}
          />
        )}

        {selectedActionType === CLUSTER_ACTION_TYPE.DEPLOY_METRICS_PROXY && (
          <AlertDialog
            textTitle={"Deploying Metrics Proxy Confirmation"}
            textContent={
              <>
                Please confirm if you want to proceed to deploy metrics-proxy
                for this cluster&nbsp;
                <strong className="danger-text">{clusterDetail?.name}</strong>.
              </>
            }
            open={
              selectedActionType === CLUSTER_ACTION_TYPE.DEPLOY_METRICS_PROXY
            }
            setOpen={closeDialog}
            handleConfirm={() => {
              closeDialog();
              handleDeployMetrics();
            }}
          />
        )}

        {selectedActionType === CLUSTER_ACTION_TYPE.DEPLOY_DEBUGGER_POD && (
          <AlertDialog
            textTitle={"Deploying Debugger Pod"}
            textContent={
              <>
                Please confirm if you want to proceed to deploy pod for this
                cluster&nbsp;
                <strong className="danger-text">{clusterDetail?.name}</strong>.
              </>
            }
            open={
              selectedActionType === CLUSTER_ACTION_TYPE.DEPLOY_DEBUGGER_POD
            }
            setOpen={closeDialog}
            handleConfirm={() => {
              closeDialog();
              handleDeployDebuggerPod();
            }}
          />
        )}

        {selectedActionType === CLUSTER_ACTION_TYPE.UPLOAD_CERTIFICATE && (
          <UploadCertificateDialog
            open={selectedActionType === CLUSTER_ACTION_TYPE.UPLOAD_CERTIFICATE}
            closeDialog={closeDialog}
            clusterName={clusterDetail?.name || ""}
          />
        )}

        <AlertDialog
          textTitle={"Get Pod Names"}
          textContent={`Once the command is executed successfully, the pod names will be available in the Actions History.`}
          open={selectedActionType === CLUSTER_ACTION_TYPE.GET_PODS_NAMES}
          setOpen={closeDialog}
          handleConfirm={() => {
            closeDialog();
            // @ts-ignore
            getPodNamesAction.mutate({
              method: "post",
              path: `/agent/command`,
              body: JSON.stringify({
                clusterName: clusterDetail?.name,
                command: CLUSTER_ACTION_TYPE.GET_PODS_NAMES,
                parameters: {},
              }),
              errorMessage: "Pod names fetch failed:",
            });
          }}
        />

        <AlertDialog
          textTitle={"Perform Health Check"}
          textContent={`Once the command is executed successfully, the health of the pod will be available in the Actions History.`}
          open={selectedActionType === CLUSTER_ACTION_TYPE.HEALTH_CHECK}
          setOpen={closeDialog}
          handleConfirm={() => {
            closeDialog();
            // @ts-ignore
            healthCheckAction.mutate({
              method: "post",
              path: `/agent/command`,
              body: JSON.stringify({
                clusterName: clusterDetail?.name,
                command: CLUSTER_ACTION_TYPE.HEALTH_CHECK,
                parameters: {},
              }),
              errorMessage: "Health check failed:",
            });
          }}
        />

        <AlertDialog
          textTitle={"Get Deployments Info"}
          textContent={`Once the command is executed successfully, the Deployments Info be available in the Actions History.`}
          open={selectedActionType === CLUSTER_ACTION_TYPE.GET_DEPLOYMENTS_INFO}
          setOpen={closeDialog}
          handleConfirm={() => {
            closeDialog();
            // @ts-ignore
            getDeploymentsInfoAction.mutate({
              method: "post",
              path: `/agent/command`,
              body: JSON.stringify({
                clusterName: clusterDetail?.name,
                command: CLUSTER_ACTION_TYPE.GET_DEPLOYMENTS_INFO,
                parameters: {},
              }),
              errorMessage: "Deployment info fetch failed:",
            });
          }}
        />

        <AlertDialog
          textTitle={"Download all logs from Pod"}
          textContent={`Once the command is executed successfully, will be able to download all logs from Pod.`}
          open={
            selectedActionType === CLUSTER_ACTION_TYPE.DOWNLOAD_ALL_POD_LOGS
          }
          setOpen={closeDialog}
          handleConfirm={() => {
            closeDialog();
            // @ts-ignore
            downloadAllPodLogsAction.mutate({
              method: "post",
              path: `/agent/command`,
              body: JSON.stringify({
                clusterName: clusterDetail?.name,
                command: CLUSTER_ACTION_TYPE.DOWNLOAD_ALL_POD_LOGS,
                parameters: {
                  podName,
                },
              }),
              errorMessage: "Download all Pod logs failed:",
            });
            handleChangePodName("");
          }}
        >
          <PodSelector />
        </AlertDialog>

        <AlertDialog
          textTitle={"Download thread dump"}
          textContent={`Once the command is executed successfully, will be able to download thread dump.`}
          open={selectedActionType === CLUSTER_ACTION_TYPE.DOWNLOAD_THREAD_DUMP}
          setOpen={closeDialog}
          handleConfirm={() => {
            closeDialog();
            // @ts-ignore
            downloadThreadDumpAction.mutate({
              method: "post",
              path: `/agent/command`,
              body: JSON.stringify({
                clusterName: clusterDetail?.name,
                command: CLUSTER_ACTION_TYPE.DOWNLOAD_THREAD_DUMP,
                parameters: {
                  podName,
                },
              }),
              errorMessage: "Download thread fetch failed:",
            });
            handleChangePodName("");
          }}
        >
          <PodSelector />
        </AlertDialog>

        <AlertDialog
          textTitle={"Get Heap Dump"}
          textContent={`Once the command is executed successfully, the Heap dump will be available in the Actions History.`}
          open={selectedActionType === CLUSTER_ACTION_TYPE.DOWNLOAD_HEAP_DUMP}
          setOpen={closeDialog}
          handleConfirm={() => {
            closeDialog();
            // @ts-ignore
            getHeapDumpAction.mutate({
              method: "post",
              path: `/agent/command`,
              body: JSON.stringify({
                clusterName: clusterDetail?.name,
                command: CLUSTER_ACTION_TYPE.DOWNLOAD_HEAP_DUMP,
                parameters: {
                  podName,
                },
              }),
              errorMessage: "Heap dump fetch failed:",
            });
            handleChangePodName("");
          }}
        >
          <Box sx={{ minWidth: 120, marginTop: "16px", display: "flex" }}>
            <Box style={{ width: "100%" }}>
              <Box style={{ marginBottom: 16 }}>
                <Autocomplete
                  id="select-pod-name"
                  freeSolo
                  options={podsNames.filter(
                    (name: string) =>
                      name?.includes("conductor") || name?.includes("workers")
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Pod Name" />
                  )}
                  value={podName}
                  onInputChange={(_event, value) => {
                    handleChangePodName(value ?? "");
                  }}
                  onChange={(_event, value) => {
                    handleChangePodName(value ?? "");
                  }}
                />
              </Box>
            </Box>
          </Box>
        </AlertDialog>

        <AlertDialog
          textTitle={"Get Top Output From Pod"}
          textContent={`Once the command is executed successfully, the Top Output Info be available in the Actions History.`}
          open={
            selectedActionType === CLUSTER_ACTION_TYPE.GET_TOP_OUTPUT_FROM_POD
          }
          setOpen={closeDialog}
          handleConfirm={() => {
            closeDialog();

            // @ts-ignore
            getTopOutputFromPodAction.mutate({
              method: "post",
              path: `/agent/command`,
              body: JSON.stringify({
                clusterName: clusterDetail?.name,
                command: CLUSTER_ACTION_TYPE.GET_TOP_OUTPUT_FROM_POD,
                parameters: {
                  podName,
                },
              }),
              errorMessage: "Top Output info fetch failed:",
            });
            handleChangePodName("");
          }}
        >
          <Box sx={{ minWidth: 120, marginTop: "16px", display: "flex" }}>
            <Box style={{ width: "100%" }}>
              <Box style={{ marginBottom: 16 }}>
                <Autocomplete
                  id="select-pod-name"
                  freeSolo
                  options={podsData.map((option: Pod) => option.name)}
                  renderInput={(params) => (
                    <TextField {...params} label="Pod Name" />
                  )}
                  value={podName}
                  onInputChange={(_event, value) => {
                    handleChangePodName(value ?? "");
                  }}
                  onChange={(_event, value) => {
                    handleChangePodName(value ?? "");
                  }}
                />
              </Box>
            </Box>
          </Box>
        </AlertDialog>

        <RolloutRestartDialog
          textTitle={"Rollout Restart"}
          textContent={""}
          open={selectedActionType === CLUSTER_ACTION_TYPE.ROLLOUT_RESTART}
          setOpen={closeDialog}
          handleConfirm={() => {
            closeDialog();
            // @ts-ignore
            getDeploymentsInfoAction.mutate({
              method: "post",
              path: `/agent/command`,
              body: JSON.stringify({
                clusterName: clusterDetail?.name,
                command: CLUSTER_ACTION_TYPE.ROLLOUT_RESTART,
                parameters: {
                  resource: selectedRestartResourceType.value?.toUpperCase(),
                },
              }),
            });
          }}
          isConfirmDisabled={!selectedRestartResourceType}
          selectedResourceType={selectedRestartResourceType}
          setSelectedResourceType={setSelectedRestartResourceType}
          resourceTypeOptions={rolloutRestartResourceTypes}
        />

        <ChangeEnvironmentTypeDialog
          clusterName={clusterDetail?.name || ""}
          open={
            selectedActionType === CLUSTER_ACTION_TYPE.CHANGE_ENVIRONMENT_TYPE
          }
          setOpen={closeDialog}
          environmentType={clusterDetail?.environmentType || "PRODUCTION"}
          refetchCluster={refetchClusterDetail}
        />

        <ChangeTelemetryDialog
          open={
            selectedActionType === CLUSTER_ACTION_TYPE.CHANGE_TELEMETRY_STATE
          }
          closeDialog={closeDialog}
          currentTelemetryState={clusterDetail?.telemetryState ?? "NORMAL"}
          clusterName={clusterDetail?.name || ""}
          refetchCluster={refetchClusterDetail}
        />
      </>
    </>
  );
}

export default ActionTypeSelector;
