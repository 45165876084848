import Button, { ButtonProps } from "@mui/material/Button";
import { fontFamilyList } from "./CustomButtons";

const OutlinedButton = ({ children, ...props }: ButtonProps) => {
  return (
    <Button
      sx={{
        fontFamily: fontFamilyList,
        textTransform: "none",
        height: 40,
      }}
      variant="outlined"
      {...props}
    >
      {children}
    </Button>
  );
};

export default OutlinedButton;
