import { Menu, MenuItem, CircularProgress } from "@mui/material";
import { PopoverProps } from "@mui/material/Popover";
import { UserProfileType } from "utils/saastypes";

export const userProfileLabel = {
  [UserProfileType.NONE]: "Myself",
  [UserProfileType.CLOUD_SUPPORT_ENGINEER_READ]: "Support engineer read",
  [UserProfileType.CLOUD_SUPPORT_ENGINEER_WRITE]: "Support engineer write",
  [UserProfileType.ENGINEERING]: "Engineering",
};

type UserProfileProps = {
  anchorEl?: PopoverProps["anchorEl"];
  onClose?: PopoverProps["onClose"];
  onClick?: () => void;
  onItemClick?: (value: UserProfileType) => void;
  allProfiles: UserProfileType[];
  currentProfile?: UserProfileType;
  isLoading: boolean;
};

export const UserProfile = ({
  anchorEl,
  onClose,
  onClick,
  onItemClick,
  allProfiles,
  currentProfile = UserProfileType.NONE,
  isLoading,
}: UserProfileProps) => {
  const handleClickItem = (profile: UserProfileType) => {
    if (onItemClick && profile !== currentProfile) {
      onItemClick(profile);
    }
  };

  return (
    <Menu
      id="user-profile-menu"
      anchorEl={anchorEl}
      open={anchorEl !== null}
      onClose={onClose}
      onClick={onClick}
      PaperProps={{
        elevation: 0,
        sx: {
          marginTop: "35px",
          border: "1px solid gray",
          "& li": {
            fontSize: "14px",
          },
        },
      }}
      transformOrigin={{ horizontal: "left", vertical: "bottom" }}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        allProfiles.map((profile) => {
          const label = userProfileLabel[profile];

          return (
            <MenuItem
              key={label}
              selected={currentProfile === profile}
              onClick={() => handleClickItem(profile)}
            >
              {label}
            </MenuItem>
          );
        })
      )}
    </Menu>
  );
};
