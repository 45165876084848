import {
  Autocomplete,
  FormControl,
  InputLabel,
  ListItemIcon,
} from "@mui/material";
import Input from "./Input";

export default function Dropdown({
  label,
  className,
  style,
  error,
  size,
  helperText,
  showIcons,
  iconHandler,
  ...props
}) {
  return (
    <FormControl style={style} className={className}>
      {label && <InputLabel>{label}</InputLabel>}
      <Autocomplete
        renderInput={(params) => (
          <Input
            {...params}
            error={error}
            size={size}
            helperText={helperText}
          />
        )}
        renderOption={(props, option) => (
          <li {...props}>
            {showIcons && iconHandler && (
              <ListItemIcon
                sx={{
                  minWidth: "25px",
                  mr: 2,
                }}
              >
                <img src={iconHandler(option?.id)} alt="env" />
              </ListItemIcon>
            )}
            {option.title}
          </li>
        )}
        {...props}
      />
    </FormControl>
  );
}
