import { Box, Paper, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import logo from "../images/branding/orkes-logo-purple-2x.png";
import Footer from "components/Login/LoginFooter";
import Input from "components/Input";
import { useMemo, useState } from "react";
import { useLocation } from "react-router";
import { useLogin } from "components/Login/useLogin";
import ActionButton from "components/buttons/ActionButton";
import { PasswordInput } from "components/PasswordInput";
import {
  PasswordStrengthIndicator,
  getValidationItems,
} from "components/PasswordStrengthIndicator";

const styleObj = {
  paperStyle: {
    maxWidth: 450,
    width: "100%",
    minHeight: 525,
    my: 4,
    textAlign: "center",
    background: "#FAFAFA",
    borderRadius: 4,
  },
  buttonStyle: {
    margin: "auto",
    background: "#0D94DB",
    borderRadius: "40px",
    textTransform: "none",
  },
};

const ResetPasswordPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");
  const token = searchParams.get("token");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [{ isSubmittingNewPassword }, { submitResetPassword }] = useLogin();

  const isError = useMemo(() => {
    return password && confirmPassword && password !== confirmPassword;
  }, [confirmPassword, password]);

  const validationItems = getValidationItems(password);
  const isInvalidPassword = validationItems.some((item) => !item.condition);

  const handleResetPassword = () => {
    if (email && token && !isError) {
      submitResetPassword(email, password, token);
    }
  };

  return (
    <>
      <Helmet>
        <title>Orkes Cloud - Reset Password</title>
      </Helmet>
      <Box className={"container"}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginY: 2,
          }}
        >
          <img src={logo} alt="logo" width="300px" />
          <Typography
            sx={{ fontSize: 36, fontWeight: "bold", textAlign: "center" }}
          >
            Orkes Cloud Platform
          </Typography>
        </Box>
        <main
          style={{
            overflowX: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              my: 6,
              px: 2,
            }}
          >
            <Paper variant="outlined" sx={styleObj.paperStyle}>
              <Typography paddingTop={8} fontSize={31} fontWeight={300}>
                Reset Password
              </Typography>
              <Typography my={2}>
                Let's update your password and log you in.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 6,
                  mt: 8,
                  ">div": {
                    width: "80%",
                    margin: "auto",
                  },
                }}
              >
                <Input
                  value={email}
                  type="email"
                  disabled
                  sx={{
                    "& .MuiOutlinedInput-input.Mui-disabled": {
                      WebkitTextFillColor: "#00000082",
                      backgroundColor: "#ececec",
                    },
                  }}
                />

                <PasswordInput
                  label="New Password"
                  placeHolder="Enter your password"
                  value={password}
                  onChange={(val) => setPassword(val)}
                  showPassword={showPassword}
                  togglePasswordVisibility={() =>
                    setShowPassword(!showPassword)
                  }
                />
                <PasswordInput
                  label="Confirm Password"
                  placeHolder="Re-enter your password"
                  value={confirmPassword}
                  onChange={(val) => setConfirmPassword(val)}
                  showPassword={showConfirmPassword}
                  togglePasswordVisibility={() =>
                    setShowConfirmPassword(!showConfirmPassword)
                  }
                />
                <PasswordStrengthIndicator password={password} />
                <ActionButton
                  variant="contained"
                  size="large"
                  sx={styleObj.buttonStyle}
                  onClick={handleResetPassword}
                  disabled={
                    isInvalidPassword ||
                    !!isError ||
                    !password ||
                    !confirmPassword
                  }
                  progress={isSubmittingNewPassword}
                >
                  Save Password & Login
                </ActionButton>
              </Box>
              {(isError ||
                (isInvalidPassword && password && confirmPassword)) && (
                <Typography
                  sx={{
                    py: 4,
                    fontSize: 12,
                    color: "#D6423B",
                    fontWeight: "400",
                  }}
                >
                  {isError
                    ? "Passwords need to match."
                    : "Password should match above criteria."}
                </Typography>
              )}
            </Paper>
          </Box>
        </main>
        <Footer />
      </Box>
    </>
  );
};

export default ResetPasswordPage;
