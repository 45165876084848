import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useAuth } from "auth/AuthContext";
import ActionButton from "components/buttons/ActionButton";
import { useState } from "react";
import { useActionWithPath } from "utils/query";
import { TelemetryType, telemetryStateOption } from "utils/saastypes";
import { getTelemetryStateIcon } from "utils/utils";

interface ChangeTelemetryDialogProps {
  open: boolean;
  closeDialog: () => void;
  currentTelemetryState: string;
  clusterName: string;
  refetchCluster: () => void;
}

const ChangeTelemetryDialog = ({
  open,
  closeDialog,
  currentTelemetryState,
  clusterName,
  refetchCluster,
}: ChangeTelemetryDialogProps) => {
  const [selectedTelemetryState, setSelectedTelemetryState] = useState(
    currentTelemetryState
  );

  const { setErrorToast, setSuccessToast } = useAuth();

  const { mutate, isLoading } = useActionWithPath({
    onSuccess: (data: any) => {
      if (data?.status === "ERROR") {
        setErrorToast("Updated telemetry state failed");
      } else {
        setSuccessToast(`Updated telemetry state successfully.`);
        closeDialog();

        if (typeof refetchCluster === "function") {
          refetchCluster();
        }
      }
    },
    onError: async (response: any) => {
      try {
        const json = await response.json();
        setErrorToast(`Updated telemetry state failed: ${json.message}`);
      } catch (e) {
        setErrorToast("Updated telemetry state failed");
      }
    },
  });

  const onSubmit = () => {
    // @ts-ignore
    mutate({
      method: "POST",
      path: `/updateTelemetryState?clusterName=${clusterName}&telemetryState=${selectedTelemetryState}`,
    });
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={closeDialog}>
      <DialogTitle>Change Telemetry State</DialogTitle>

      <DialogContent>
        <Grid container spacing={2} sx={{ paddingTop: 2 }}>
          <Grid item xs={12} md={12}>
            <FormControl fullWidth>
              <InputLabel id="telemetryStateLabel">Telemetry State</InputLabel>
              <Select
                value={selectedTelemetryState}
                onChange={(event) =>
                  setSelectedTelemetryState(event.target.value as TelemetryType)
                }
                labelId="telemetryStateLabel"
                id="telemetryStateSelect"
                label="Telemetry State"
                sx={{
                  ">div": {
                    display: "flex",
                    "& .MuiTypography-root": { display: "none" },
                  },
                }}
              >
                {telemetryStateOption.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    <ListItemIcon
                      sx={{
                        minWidth: "30px",
                      }}
                    >
                      <img
                        src={getTelemetryStateIcon(type.id)}
                        alt="telemetry"
                        width="20px"
                      />
                    </ListItemIcon>
                    <Box>
                      <Box>{type.title}</Box>
                      <Typography variant="body2" color="textSecondary">
                        {type.description}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12}></Grid>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          onClick={closeDialog}
          style={{ margin: 8, fontSize: 14, textTransform: "none" }}
          sx={{
            display: "inline",
            mx: 0.5,
          }}
        >
          Cancel
        </Button>
        <ActionButton
          variant="contained"
          color="primary"
          disabled={selectedTelemetryState === currentTelemetryState}
          progress={isLoading}
          onClick={onSubmit}
          style={{ margin: 8, fontSize: 14, textTransform: "none" }}
          sx={{
            display: "inline",
            mx: 0.5,
          }}
        >
          Confirm
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeTelemetryDialog;
