export const TeamsIcon = ({ size = 20, ...props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 22 21"
    fill="none"
    {...props}
  >
    <g clip-path="url(#clip0_8964_5464)">
      <path
        d="M15.345 7.5625H21.0276C21.5645 7.5625 21.9997 7.99137 21.9997 8.52041V13.6211C21.9997 15.5654 20.4002 17.1417 18.427 17.1417H18.4101C16.437 17.1419 14.8372 15.5659 14.8369 13.6216C14.8369 13.6214 14.8369 13.6212 14.8369 13.6211V8.06314C14.8369 7.78664 15.0644 7.5625 15.345 7.5625Z"
        fill="#5059C9"
      />
      <path
        d="M19.1861 6.5541C20.4577 6.5541 21.4884 5.53835 21.4884 4.28535C21.4884 3.03236 20.4577 2.0166 19.1861 2.0166C17.9146 2.0166 16.8838 3.03236 16.8838 4.28535C16.8838 5.53835 17.9146 6.5541 19.1861 6.5541Z"
        fill="#5059C9"
      />
      <path
        d="M12.0229 6.55417C13.8595 6.55417 15.3484 5.08697 15.3484 3.27709C15.3484 1.4672 13.8595 0 12.0229 0C10.1862 0 8.69727 1.4672 8.69727 3.27709C8.69727 5.08697 10.1862 6.55417 12.0229 6.55417Z"
        fill="#7B83EB"
      />
      <path
        d="M16.4573 7.5625H7.0771C6.54662 7.57544 6.12698 8.00926 6.13929 8.53202V14.3496C6.06521 17.4866 8.58382 20.0899 11.7672 20.1667C14.9506 20.0899 17.4692 17.4866 17.3951 14.3496V8.53202C17.4074 8.00926 16.9878 7.57544 16.4573 7.5625Z"
        fill="#7B83EB"
      />
      <path
        opacity="0.1"
        d="M12.2795 7.5625V15.7149C12.2769 16.0887 12.047 16.4246 11.6962 16.5669C11.5845 16.6135 11.4645 16.6375 11.3432 16.6375H6.59015C6.52364 16.4711 6.46225 16.3048 6.41108 16.1333C6.23199 15.5548 6.1406 14.9534 6.13991 14.3486V8.5305C6.12761 8.00858 6.54656 7.57544 7.07619 7.5625H12.2795Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.7678 7.5625V16.219C11.7678 16.3386 11.7435 16.4568 11.6962 16.5669C11.5518 16.9126 11.2109 17.1392 10.8316 17.1417H6.83062C6.74364 16.9753 6.66178 16.8089 6.59015 16.6375C6.51852 16.4661 6.46225 16.3048 6.41108 16.1333C6.23199 15.5548 6.1406 14.9534 6.13991 14.3486V8.5305C6.12761 8.00858 6.54656 7.57544 7.07619 7.5625H11.7678Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.7678 7.5625V15.2107C11.7639 15.7187 11.347 16.1295 10.8315 16.1333H6.41108C6.23199 15.5548 6.1406 14.9534 6.13991 14.3486V8.5305C6.12761 8.00858 6.54656 7.57544 7.07619 7.5625H11.7678Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.2562 7.5625V15.2107C11.2523 15.7187 10.8354 16.1295 10.3199 16.1333H6.41108C6.23199 15.5548 6.1406 14.9534 6.13991 14.3486V8.5305C6.12761 8.00858 6.54656 7.57544 7.07619 7.5625H11.2562Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M12.2793 4.95582V6.54394C12.1924 6.54898 12.1105 6.55403 12.0235 6.55403C11.9366 6.55403 11.8547 6.54899 11.7677 6.54394C11.595 6.53265 11.4237 6.50565 11.2561 6.46328C10.22 6.2215 9.3641 5.5055 8.95376 4.53737C8.88316 4.37479 8.82835 4.20597 8.79004 4.0332H11.3431C11.8593 4.03513 12.2774 4.44706 12.2793 4.95582Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.7671 5.46021V6.54417C11.5944 6.53288 11.4231 6.50588 11.2555 6.46351C10.2194 6.22174 9.36346 5.50573 8.95312 4.5376H10.8308C11.3471 4.53952 11.7651 4.95147 11.7671 5.46021Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.7671 5.46021V6.54417C11.5944 6.53288 11.4231 6.50588 11.2555 6.46351C10.2194 6.22174 9.36346 5.50573 8.95312 4.5376H10.8308C11.3471 4.53952 11.7651 4.95147 11.7671 5.46021Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.2555 5.46022V6.46351C10.2194 6.22174 9.36346 5.50573 8.95312 4.5376H10.3192C10.8355 4.53953 11.2535 4.95148 11.2555 5.46022Z"
        fill="black"
      />
      <path
        d="M0.937809 4.5376H10.318C10.8359 4.5376 11.2558 4.95135 11.2558 5.46173V14.7051C11.2558 15.2155 10.8359 15.6293 10.318 15.6293H0.937809C0.419867 15.6293 0 15.2155 0 14.7051V5.46173C0 4.95135 0.419877 4.5376 0.937809 4.5376Z"
        fill="url(#paint0_linear_8964_5464)"
      />
      <path
        d="M8.09634 8.05565H6.22122V13.0872H5.02658V8.05565H3.16016V7.07959H8.09634V8.05565Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_8964_5464"
        x1="1.95536"
        y1="3.81549"
        x2="9.13979"
        y2="16.4428"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#5A62C3" />
        <stop offset="0.5" stop-color="#4D55BD" />
        <stop offset="1" stop-color="#3940AB" />
      </linearGradient>
      <clipPath id="clip0_8964_5464">
        <rect width="22" height="20.1667" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
