import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
} from "react";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "react-query";

import { Message, SnackbarMessage } from "components/SnackbarMessage";
import { getErrorsMessage } from "./utils";

type MessageType = {
  message: Message | null;
  setMessage: Dispatch<SetStateAction<Message | null>>;
  prefixMessage: string;
  setPrefixMessage: Dispatch<SetStateAction<string>>;
};

export const MessageContext = createContext<MessageType>({
  message: null,
  setMessage: () => null,
  prefixMessage: "",
  setPrefixMessage: () => "",
});

interface ErrorProviderProps {
  children?: ReactNode;
}

export default function MessageProvider({ children }: ErrorProviderProps) {
  const [message, setMessage] = useState<Message | null>(null);
  const [prefixMessage, setPrefixMessage] = useState("");

  const handleSuccess = (__: any, variables: any) => {
    if (variables?.successMessage) {
      setMessage({ text: variables.successMessage, severity: "success" });
    }
  };

  const handleError = async (error: any, variables: any) => {
    console.error("handleError ~ error:", error);

    const message = await getErrorsMessage(error);

    if (message && !variables?.useLocalMessage) {
      setMessage({
        text: variables?.errorMessage
          ? `${variables?.errorMessage} ${message}`
          : message,
        severity: "error",
      });
    }
  };

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        cacheTime: 600000, // 10 mins
      },
    },
    queryCache: new QueryCache({
      onError: (error, query) => handleError(error, query.options),
      onSuccess: (data, query) => handleSuccess(data, query.options),
    }),
    mutationCache: new MutationCache({
      onError: handleError,
      onSuccess: handleSuccess,
    }),
  });

  const handleClose = () => {
    setMessage(null);
    setPrefixMessage("");
  };

  return (
    <MessageContext.Provider
      value={{ message, setMessage, prefixMessage, setPrefixMessage }}
    >
      {message ? (
        <SnackbarMessage
          message={{
            text: prefixMessage
              ? `${prefixMessage}: ${message.text}`
              : message.text,
            severity: message.severity,
          }}
          onDismiss={handleClose}
          autoHideDuration={5000}
        />
      ) : null}

      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </MessageContext.Provider>
  );
}
