import { IconButton, InputAdornment } from "@mui/material";
import Input from "./Input";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { CSSProperties } from "react";

interface PasswordInputProps {
  label: string;
  value: string;
  onChange: (val: string) => void;
  showPassword: boolean;
  togglePasswordVisibility: () => void;
  placeHolder?: string;
  customStyle?: CSSProperties;
}

export const PasswordInput = ({
  label,
  value,
  onChange,
  showPassword,
  togglePasswordVisibility,
  placeHolder,
  customStyle,
}: PasswordInputProps) => (
  <Input
    placeholder={placeHolder}
    value={value}
    onChange={(val) => onChange(val)}
    required
    label={label}
    style={customStyle}
    type={showPassword ? "text" : "password"}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton onClick={togglePasswordVisibility} edge="end">
            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
);
