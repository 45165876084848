import { ReactElement, CSSProperties } from "react";
import { Helmet } from "react-helmet";
import { Box, Link, Paper } from "@mui/material";
import Heading from "components/Heading";
import { useLocation, useParams } from "react-router-dom";
import { useHistoryEntry } from "utils/query";
import useInterval from "utils/useInterval";
import { HistoryCommand, HistoryDTO } from "utils/saastypes";
import {
  capitalizeFirstLetter,
  customStyles,
  useDownloadFromPresignedLinks,
} from "utils/utils";
import { CAPITALIZED_CHARS_REGEX, CLUSTER_ACTION_TYPE } from "constants/common";
import StyledMainButton from "components/buttons/StyledMainButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OutlinedButton from "components/buttons/OutlinedButton";
import { useAuth } from "auth/AuthContext";
import { useAuthMachine } from "auth/state/hook";
import { generateTableLinkData } from "utils/helpers";
import DownloadIcon from "@mui/icons-material/Download";
import DataTable from "components/DataTable/DataTable";

const spanStyle = {
  display: "inline-block",
  width: "220px",
  marginRight: "5px",
  wordBreak: "break-all",
} as CSSProperties;

const downloadLogs = (logs: string[]) => {
  const blob = new Blob([logs.join("\n")], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "logs.txt";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export default function HistoryEntryPage() {
  const { entryId } = useParams();
  const { clusterName } = useParams();

  const {
    data: historyEntry,
    refetch,
    error,
    isFetching,
  } = useHistoryEntry(clusterName as string, entryId as string);

  useInterval(
    () => {
      refetch();
    },
    historyEntry?.executionStatus === "SCHEDULED" ||
      historyEntry?.executionStatus === "IN_PROGRESS"
      ? 2000
      : null
  );

  return (
    <>
      <Helmet>
        <title>History Entry</title>
      </Helmet>
      <Box
        style={{
          marginTop: 66,
          padding: 16,
        }}
      >
        {historyEntry && !error && <HistoryEntry historyEntry={historyEntry} />}
        {isFetching && <Box>Loading...</Box>}
        {error && (
          <Box>Error when fetching History Entry. Please try again.</Box>
        )}
      </Box>
    </>
  );
}

const renderItems = ({
  data,
  keys,
}: {
  data: HistoryDTO;
  keys: (keyof HistoryDTO)[];
}) => {
  let result: ReactElement[] = [];

  keys.forEach((key) => {
    if (typeof data[key] === "object") {
      const tempKeys = Object.keys(data[key] as any) as (keyof HistoryDTO)[];

      result = result.concat(
        renderItems({ data: data[key] as any, keys: tempKeys })
      );
    }

    if (typeof data[key] === "string") {
      result.push(
        <div>
          <span style={spanStyle}>
            {CAPITALIZED_CHARS_REGEX.test(key.charAt(0))
              ? key
              : capitalizeFirstLetter(key)
                  ?.split(CAPITALIZED_CHARS_REGEX)
                  ?.join(" ")}
            :
          </span>
          {data[key]}
        </div>
      );
    }
  });

  return result;
};

export function HistoryEntry({ historyEntry }: { historyEntry: any }) {
  const HistoryEntryInfo = () => {
    const { authService, setSuccessToast } = useAuth();
    const { pathname } = useLocation();

    const [{ currentUser }] = useAuthMachine(authService);

    const { downloadLinks, error, loading } = useDownloadFromPresignedLinks(
      historyEntry,
      currentUser?.accessToken
    );

    const pathParts = pathname.split("/");
    const id = pathParts[pathParts.length - 1];

    const CommonItems = () => (
      <>
        <div>
          <span style={spanStyle}>Status:</span>{" "}
          <strong>{historyEntry.executionStatus}</strong>
        </div>
        {id && (
          <div>
            <span style={spanStyle}>ID:</span> {id}
          </div>
        )}
        <div
          style={{
            marginBottom: 16,
            paddingBottom: 16,
            borderBottom: "1px solid lightgrey",
          }}
        >
          <span style={spanStyle}>Created At:</span>{" "}
          {new Date(historyEntry.createdAt).toString()}
        </div>
      </>
    );

    if ([HistoryCommand.UPGRADE_DEPLOYMENT].includes(historyEntry.command)) {
      return (
        <div>
          <CommonItems />
          {renderItems({
            data: historyEntry,
            keys: [
              "lastTaskError",
              "message",
              "deploymentPackage",
              "previousVersion",
              "newVersion",
              "deploymentName",
              "triggeredBy",
              "parameters",
            ],
          })}
        </div>
      );
    }
    if (historyEntry.command === HistoryCommand.HEALTH_CHECK) {
      return (
        <div>
          <CommonItems />
          {historyEntry.lastTaskError && (
            <div>
              <span style={spanStyle}>Last task error:</span>
              {historyEntry.lastTaskError}
            </div>
          )}
          {historyEntry.health?.agent && (
            <div>
              <span style={spanStyle}>Agent Healthy:</span>{" "}
              {historyEntry.health.agent.healthy.toString()}
            </div>
          )}
          {historyEntry.health?.conductor && (
            <div>
              <span style={spanStyle}>Conductor Healthy:</span>{" "}
              {historyEntry.health.conductor.healthy.toString()}
            </div>
          )}
          {historyEntry.health?.workers && (
            <div>
              <span style={spanStyle}>Workers Healthy:</span>{" "}
              {historyEntry.health.workers.healthy.toString()}
            </div>
          )}
        </div>
      );
    }
    if (historyEntry.command === HistoryCommand.ADD_EKS_ADMIN) {
      return (
        <div>
          <CommonItems />
          {historyEntry.lastTaskError && (
            <div>
              <span style={spanStyle}>Last task error:</span>
              {historyEntry.lastTaskError}
            </div>
          )}
          {historyEntry.message && (
            <div>
              <span style={spanStyle}>Message:</span> {historyEntry.message}
            </div>
          )}
          {historyEntry.error && (
            <div>
              <span style={spanStyle}>Error:</span> {historyEntry.error}
            </div>
          )}
          {historyEntry?.parameters?.roleArn && (
            <div>
              <span style={spanStyle}>Role ARN:</span>{" "}
              {historyEntry.parameters.roleArn}
            </div>
          )}
        </div>
      );
    }
    if (historyEntry.command === HistoryCommand.GET_PODS_NAMES) {
      return (
        <div>
          <CommonItems />
          {historyEntry.lastTaskError && (
            <div>
              <span style={spanStyle}>Last task error:</span>
              {historyEntry.lastTaskError}
            </div>
          )}
          {historyEntry.names &&
            historyEntry.names.map((x: any, index: number) => {
              return (
                <div
                  key={index}
                  style={{
                    padding: 6,
                    background: index % 2 === 0 ? "#f2f2f2" : "#fafafa",
                  }}
                >
                  {x}
                </div>
              );
            })}
        </div>
      );
    }
    if (historyEntry.command === CLUSTER_ACTION_TYPE.GET_TOP_OUTPUT_FROM_POD) {
      const formatLog = (log: string) => {
        return log?.split("\n")?.map((line: string, index: number) => {
          if (line?.startsWith("+")) {
            return (
              <span key={index} style={{ color: "blue" }}>
                {line}
                <br />
              </span>
            );
          }
          return (
            <span key={index}>
              {line}
              <br />
            </span>
          );
        });
      };
      return (
        <div>
          <CommonItems />
          <div
            style={{
              padding: "10px",
              backgroundColor: "#f5f5f5",
              borderRadius: "5px",
              fontFamily: "monospace",
              whiteSpace: "pre-wrap",
              maxWidth: "100%",
              overflow: "scroll",
            }}
          >
            <pre>{formatLog(historyEntry.result)}</pre>
          </div>
        </div>
      );
    }
    if (historyEntry.command === HistoryCommand.PULL_LOGS) {
      const copyContent = () => {
        navigator.clipboard
          .writeText(historyEntry?.logs)
          .then(() => {
            setSuccessToast("Logs copied");
          })
          .catch((e) => {
            console.log("Unable to copy to clipboard!", e);
          });
      };
      return (
        <div>
          <CommonItems />
          {historyEntry.lastTaskError && (
            <div>
              <span style={spanStyle}>Last task error:</span>
              {historyEntry.lastTaskError}
            </div>
          )}
          {historyEntry.logs && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 10,
                  marginBottom: "15px",
                }}
              >
                <StyledMainButton
                  onClick={() => downloadLogs(historyEntry.logs)}
                >
                  Download Logs
                </StyledMainButton>

                <OutlinedButton
                  endIcon={<ContentCopyIcon />}
                  onClick={copyContent}
                >
                  Copy Logs
                </OutlinedButton>
              </div>
              {historyEntry.logs.map((x: any, index: number) => {
                return (
                  <div
                    key={index}
                    style={{
                      padding: "3px 6px",
                      fontFamily: "monospace",
                      fontSize: 14,
                      background: index % 2 === 0 ? "#f2f2f2" : "#fafafa",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {x}
                  </div>
                );
              })}
            </>
          )}
        </div>
      );
    }
    if (
      (historyEntry.command === CLUSTER_ACTION_TYPE.DOWNLOAD_HEAP_DUMP ||
        historyEntry.command === CLUSTER_ACTION_TYPE.DOWNLOAD_ALL_POD_LOGS ||
        historyEntry.command === CLUSTER_ACTION_TYPE.DOWNLOAD_THREAD_DUMP) &&
      historyEntry.executionStatus === "COMPLETED"
    ) {
      const tableData = generateTableLinkData(downloadLinks);
      return (
        <>
          <CommonItems />
          {error && <Box>{error}</Box>}
          <Paper elevation={1}>
            <DataTable
              customStyles={customStyles}
              progressPending={loading}
              pagination={false}
              noDataComponent={
                <Box padding={5} fontWeight={600}>
                  No data found
                </Box>
              }
              data={tableData}
              columns={[
                {
                  name: "fileName",
                  label: "File Name",
                  selector: (row: any) => row.fileName,
                  grow: 1,
                },
                {
                  name: "link",
                  label: "Link",
                  selector: (row: any) => row.link,
                  renderer: (link: string) => (
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Link target={"_blank"} href={link}>
                        <DownloadIcon />
                      </Link>
                    </Box>
                  ),
                  width: "100px",
                },
              ]}
            />
          </Paper>
        </>
      );
    }
    return (
      <div>
        <div>
          <span style={spanStyle}>Status:</span>{" "}
          <strong>{historyEntry.executionStatus}</strong>
        </div>
        {id && (
          <div>
            <span style={spanStyle}>ID:</span> {id}
          </div>
        )}
        <div>
          <span style={spanStyle}>Created At:</span>{" "}
          {new Date(historyEntry.createdAt).toString()}
        </div>
        <div
          style={{
            marginBottom: 16,
            paddingBottom: 16,
            borderBottom: "1px solid lightgrey",
          }}
        >
          <span style={spanStyle}>Result:</span> {historyEntry.result}
        </div>
      </div>
    );
  };

  return (
    <div id="history-section-wrapper">
      <Heading sx={{ marginBottom: "24px" }} level={1}>
        {historyEntry.commandLabel}
      </Heading>
      <HistoryEntryInfo />
    </div>
  );
}
