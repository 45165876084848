import { useActionWithPath, useFetchAllCertificates } from "utils/query";
import { TabPanel } from "./TabPanel";
import { Box, Chip, IconButton } from "@mui/material";
import { CertificateDto, CertificateStateType } from "utils/saastypes";
import DataTable from "components/DataTable/DataTable";
import { customStyles } from "utils/utils";
import useInterval from "utils/useInterval";
import LinearProgress from "components/LinearProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import AlertDialog from "components/AlertDialog";

const stateColorMap: { [key in CertificateStateType]: string } = {
  READY: "#9FDCAA",
  SCHEDULED: "#8de0f9",
  PROCESSING: "#fcd181",
  ERROR: "#f47676",
  SCHEDULE_DELETION: "#dddddd",
  DELETING: "#dddddd",
};

function formatState(val: CertificateStateType): string {
  if (val === CertificateStateType.SCHEDULE_DELETION) {
    const words = val.split("_");
    return words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  } else {
    return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
  }
}

export const CertificateTab = ({
  selectedTabIndex,
  index,
  clusterName,
}: {
  selectedTabIndex: number;
  index: number;
  clusterName?: string;
}) => {
  const { data, isFetching, refetch } = useFetchAllCertificates(clusterName!);
  const [selectedRow, setSelectedRow] = useState<CertificateDto | null>(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const deleteCertificate = (row: CertificateDto) => {
    setSelectedRow(row);
    setShowDeleteConfirmation(true);
  };

  const deleteEnvironmentAction = useActionWithPath({
    onSuccess: () => {
      refetch();
    },
    onError: async (response: any) => {
      refetch();
    },
  });

  const triggerDeletion = () => {
    if (selectedRow && clusterName) {
      setShowDeleteConfirmation(false);
      // @ts-ignore
      deleteEnvironmentAction.mutate({
        method: "delete",
        path: `/customCertificates/delete`,
        body: JSON.stringify({
          clusterName: clusterName,
          alias: selectedRow.alias,
        }),
        successMessage: `Certificate is successfully scheduled for deletion`,
      });
    }
  };

  useInterval(() => {
    if (!isFetching) {
      refetch();
    }
  }, 5000);

  const columns = [
    {
      name: "filename",
      selector: (row: CertificateDto) => row.filename,
      label: "File Name",
    },
    {
      name: "alias",
      selector: (row: CertificateDto) => row.alias,
      label: "Alias",
    },
    {
      name: "paramStorePath",
      selector: (row: CertificateDto) => row.paramStorePath,
      label: "Path",
      grow: 3,
    },
    {
      name: "state",
      selector: (row: CertificateDto) => row.state,
      label: "State",
      renderer(val: CertificateStateType) {
        const formattedVal = formatState(val);
        return (
          <Chip
            label={formattedVal}
            sx={{
              backgroundColor: stateColorMap[val],
              color: "#000",
              px: 2,
              fontSize: 12,
            }}
            size="small"
          />
        );
      },
    },
    {
      name: "actions",
      label: "Actions",
      right: true,
      selector: (row: CertificateDto) => row,
      renderer(_val: string, row: CertificateDto) {
        const isCertificateReady = row.state === CertificateStateType.READY;
        return isCertificateReady ? (
          <IconButton onClick={() => deleteCertificate(row)}>
            <DeleteIcon />
          </IconButton>
        ) : null;
      },
    },
  ];

  return (
    <TabPanel
      value={selectedTabIndex}
      index={index}
      style={{
        padding: 16,
        position: "relative",
        borderBottom: "1px solid lightgrey",
      }}
    >
      <AlertDialog
        textTitle={"Delete Certificate"}
        textContent=""
        open={showDeleteConfirmation}
        setOpen={() => setShowDeleteConfirmation(false)}
        handleConfirm={triggerDeletion}
        isInputConfirmation
        valueToBeDeleted={selectedRow?.alias}
      />

      {isFetching && <LinearProgress />}
      <DataTable
        localStorageKey="certificates"
        customStyles={customStyles}
        pagination
        noDataComponent={
          <Box padding={5} fontWeight={600}>
            No certificate found
          </Box>
        }
        data={data}
        columns={columns}
      />
    </TabPanel>
  );
};
