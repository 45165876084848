import { CLUSTER_STATE } from "constants/common";
import { colorOverrides } from "theme/colorOverrides";
import { State, CloudProvider } from "utils/saastypes";
import {
  SettingsRounded,
  Cancel,
  CheckCircle,
  DeleteForever,
} from "@mui/icons-material";
import { ReactComponent as AWSLogo } from "images/svg/chip/aws-logo.svg";
import { ReactComponent as AzureLogo } from "images/svg/chip/azure-logo.svg";
import { ReactComponent as GCPLogo } from "images/svg/chip/gcp-logo.svg";
export const bottomSectionFontSize = 14;

export const buttonSx = {
  color: colorOverrides.leftBlueAlt,
  fontFamily: "Lexend, sans-serif",
  fontWeight: 600,
  fontSize: 22,
  lineHeight: "20px",
};

export const IconFromState = ({ state }: { state: State }) => {
  switch (state) {
    case CLUSTER_STATE.PROVISIONED:
      return <CheckCircle />;
    case CLUSTER_STATE.DELETED:
      return <DeleteForever />;
    case CLUSTER_STATE.FAILED:
      return <Cancel />;
    default:
      return (
        <SettingsRounded
          sx={
            [
              CLUSTER_STATE.PROVISIONING,
              CLUSTER_STATE.DELETING,
              CLUSTER_STATE.SCHEDULE_DELETION,
              CLUSTER_STATE.MODIFYING,
            ].includes(state)
              ? {
                  animation: "spin 2s linear infinite",
                  "@keyframes spin": {
                    "0%": {
                      transform: "rotate(360deg)",
                    },
                    "100%": {
                      transform: "rotate(0deg)",
                    },
                  },
                }
              : {}
          }
        />
      );
  }
};
export const ProviderLogo = ({ provider }: { provider: CloudProvider }) => {
  switch (provider) {
    case CloudProvider.AWS:
      return <AWSLogo />;

    case CloudProvider.AZURE:
      return <AzureLogo />;

    case CloudProvider.GCP:
      return <GCPLogo />;

    default:
      return <SettingsRounded />;
  }
};

export const clickableChipSx = {
  opacity: 1,
  boxShadow: "1px 1px 1px #999",
  px: 2,
  "& span": {
    fontFamily: "Lexend, sans-serif",
    fontWeight: 400,
    fontSize: 12,
    paddingRight: 2,
    paddingTop: 0.5,
  },
};
export const commonStyle = {
  buttonSx: {
    color: colorOverrides.leftBlueAlt,
    fontFamily: "Lexend, sans-serif",
    fontWeight: 600,
    fontSize: 22,
    lineHeight: "20px",
    cursor: "text",
  },
  paperSx: {
    padding: 0,
    ml: 2.5,
    mr: 3.0,
    mb: 2.0,
    mt: 3.0,
  },
  clickableChipSx,
};

export const getColorFromStateForTrialCluster = (state: State) => {
  switch (state) {
    case CLUSTER_STATE.DELETED:
    case CLUSTER_STATE.DELETING:
    case CLUSTER_STATE.SCHEDULE_DELETION:
    case CLUSTER_STATE.FAILED:
      return "error";
    case CLUSTER_STATE.MODIFYING:
    case CLUSTER_STATE.PROVISIONING:
      return "#81c6fcb3";
    case CLUSTER_STATE.PROVISIONED:
      return "#9fdcaa";
    default:
      return "info";
  }
};
