import { getAuth } from "firebase/auth";
import app from "./configs/firebase-config";
import React, { useCallback, useContext } from "react";
import { useInterpret } from "@xstate/react";
import { authProviderMachine } from "./state/machine";
import { useLocation, useNavigate } from "react-router";
import { AuthProviderEventTypes } from "./state/types";
import LogRocket from "logrocket";
import { MessageContext } from "utils/MessageProvider";
import { Message } from "components/SnackbarMessage";
import { LOGIN_ROUTE, UNAUTHORIZED_ROUTE_URL } from "utils/routePaths";
import { useQueryClient } from "react-query";
import { assign } from "xstate";

export const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const auth = getAuth(app);

const toReadableUser = (user) =>
  user && {
    displayName: user.displayName ?? false,
    email: user.email ?? false,
    uid: user.uid,
  };

export default function AuthProvider({ children, ...props }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { setMessage } = useContext(MessageContext);
  const queryClient = useQueryClient();
  const setSuccessToast = useCallback(
    (message) => {
      setMessage(new Message(message, "success"));
    },
    [setMessage]
  );

  const setErrorToast = useCallback(
    (message) => {
      setMessage(new Message(message, "error"));
    },
    [setMessage]
  );

  const handleFBAuth = (context, event) => (callback) => {
    const isLogoutEvent = event.type === AuthProviderEventTypes.LOG_OUT_EVENT;
    auth?.onAuthStateChanged(function (user) {
      const urlToNavigate = localStorage.getItem("urlToNavigate");
      if (!user && !urlToNavigate?.startsWith("/acceptInvite")) {
        const path = context.inviteId ? `/?inviteId=${context.inviteId}` : "/";
        navigate(path);
      }
      if (user && !isLogoutEvent) {
        LogRocket.identify(user?.uid, toReadableUser(user));
        callback({
          type: AuthProviderEventTypes.SET_USER_EVENT,
          data: user,
        });
      }
    });

    auth?.onIdTokenChanged(function (user) {
      if (user && !isLogoutEvent) {
        callback({
          type: AuthProviderEventTypes.SET_USER_EVENT,
          data: user,
        });
      }
    });
  };

  const authService = useInterpret(authProviderMachine, {
    ...(process.env.NODE_ENV === "development" ? { devTools: true } : {}),
    context: {
      auth: auth,
    },
    actions: {
      navigateToHome: () => {
        const urlToNavigate = localStorage.getItem("urlToNavigate");
        if (
          !urlToNavigate ||
          [LOGIN_ROUTE, UNAUTHORIZED_ROUTE_URL].includes(urlToNavigate) ||
          urlToNavigate.startsWith("/acceptInvite")
        ) {
          navigate("/home");
        } else {
          navigate(urlToNavigate);
        }
        localStorage.removeItem("urlToNavigate");
      },
      navigateToLogin: () => navigate("/"),
      navigateToSignup: () => navigate("/signup"),
      setErrorMessage: (__, { data }) => {
        setErrorToast(data?.message);
      },
      persistUrlToLocalStorage: () => {
        localStorage.setItem("urlToNavigate", location.pathname);
      },
      logout: assign(() => {
        // FIXME: This should be re-thought since there is a promise involved
        auth?.signOut();
        return {
          currentUser: undefined,
          verificationStatus: undefined,
        };
      }),
      // any additional action
    },
    services: {
      handleFBAuth,
      logUserOut: async () => {
        await auth?.signOut();
        queryClient.clear();
      },
      //  any service that we need to call
    },
  });

  const value = {
    authService,
    setSuccessToast,
    setErrorToast,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
