import { useAuth } from "auth/AuthContext";
import { SupportContent } from "./types";
import { styles } from "./common";
import { Box, Paper, Typography, Button } from "@mui/material";
import sendIcon from "images/send-icon.svg";
import SlackIcon from "../icons/SlackIcon";
import { TeamsIcon } from "../icons/TeamsIcon";
import orkesLogo from "images/branding/orkes-logo-mark-purple-2x.png";

const SupportPortalCta = () => (
  <Button
    variant="text"
    sx={styles.supportPrimaryCta}
    disableRipple
    startIcon={<img src={orkesLogo} alt="logo" width={24} />}
    onClick={() =>
      window.open("https://orkeshelp.zendesk.com/hc/en-us/requests/new")
    }
  >
    Orkes Support Portal
  </Button>
);

export const SupportCard = ({
  support,
  disabled = false,
  premiumSupport = false,
}: {
  support: SupportContent;
  disabled?: boolean;
  premiumSupport?: boolean;
}) => {
  const { setSuccessToast } = useAuth();

  const copyEmail = () => {
    navigator.clipboard
      .writeText("support@orkes.io")
      .then(() => setSuccessToast("Email copied!"))
      .catch((e) => {
        console.log("Unable to copy to clipboard!", e);
      });
  };

  return (
    <Box sx={{ ...styles.cardContainer, ...(disabled && styles.disabledCard) }}>
      <Paper variant="outlined" sx={styles.paper}>
        <Typography sx={styles.supportTitle}>{support.title}</Typography>
        <Typography sx={styles.supportDescription}>
          {support.description}
        </Typography>
      </Paper>

      {support.title === "Basic" && (
        <Button
          variant="text"
          sx={styles.supportPrimaryCta}
          disableRipple
          startIcon={<img src={sendIcon} alt="logo" width={26} />}
          onClick={copyEmail}
        >
          Email Us
        </Button>
      )}
      {support.title === "Standard" && <SupportPortalCta />}
      {support.title === "Premium" && (
        <Box
          sx={
            premiumSupport
              ? styles.premiumSupportCtaContainer
              : styles.premiumCtaContainer
          }
        >
          <SupportPortalCta />
          <Button
            sx={styles.premiumSecondaryCta}
            startIcon={<SlackIcon size={18} />}
            disableRipple
            onClick={() => window.open("https://app.slack.com/")}
          >
            Slack
          </Button>
          <Button
            sx={styles.premiumSecondaryCta}
            startIcon={<TeamsIcon size={18} />}
            disableRipple
            onClick={() => window.open("https://teams.microsoft.com/")}
          >
            Teams
          </Button>
        </Box>
      )}
    </Box>
  );
};
