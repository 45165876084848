import {
  Button,
  Checkbox,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { MouseEvent, useState } from "react";
import { ViewColumnRounded } from "@mui/icons-material";
import { getColumnId, getColumnLabel } from "./utils";
import { SerializableColumn } from "./types";
import { tableButtonStyle } from "theme/styles";

export function ColumnsSelector({
  columns,
  selected,
  setSelected,
  defaultColumns,
}: {
  columns: SerializableColumn[];
  selected: string[];
  setSelected: (selected: string[]) => void;
  defaultColumns: string[];
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (columnId: string, checked: boolean) => {
    if (!checked && selected.includes(columnId)) {
      setSelected(selected.filter((v) => v !== columnId));
    } else {
      setSelected([...selected, columnId]);
    }
  };

  const reset = () => {
    setSelected(defaultColumns);
  };
  return (
    <>
      <Tooltip title="Show Columns">
        <Button
          onClick={handleClick}
          startIcon={<ViewColumnRounded fontSize="small" />}
          sx={tableButtonStyle}
        >
          Column
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        // getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {[
          ...columns.map((column) => (
            <MenuItem
              key={getColumnId(column)}
              value={getColumnId(column)}
              dense
            >
              <Checkbox
                checked={selected.includes(getColumnId(column))}
                onChange={(e) =>
                  handleChange(getColumnId(column), e.target.checked)
                }
              />
              <ListItemText primary={getColumnLabel(column)} />
            </MenuItem>
          )),
          <MenuItem key="_reset" value="_reset" onClick={reset}>
            <ListItemText>Reset to default</ListItemText>
          </MenuItem>,
        ]}
      </Menu>
    </>
  );
}
