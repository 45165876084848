import { createMachine } from "xstate";
import {
  UserInfoModalContext,
  UserInfoModalEventTypes,
  UserInfoModalStates,
} from "./types";

import * as actions from "./actions";
import * as services from "./services";
import { checkFreeTrialParamInUrl } from "./helper";
import { OTP_DISABLED } from "constants/properties";

export const userInfoModalMachine = createMachine<UserInfoModalContext>(
  {
    id: "userInfoModalMachine",
    predictableActionArguments: true,
    initial: UserInfoModalStates.PERSONAL_INFO_STEP,
    context: {
      open: checkFreeTrialParamInUrl(),
      countDown: 0,
      userInfo: {
        name: "",
        company: "",
        phoneNumber: "",
        marketingPreference: true,
        termsAndConditionsAccepted: true,
      },
      verificationCode: "",
      selectedRegion: "",
      uiUrl: null,
    },
    on: {
      [UserInfoModalEventTypes.HANDLE_OPEN_MODAL_EVENT]: {
        actions: ["openModal"],
      },
      [UserInfoModalEventTypes.HANDLE_CLOSE_MODAL_EVENT]: {
        actions: ["closeModal"],
      },
    },
    states: {
      [UserInfoModalStates.PERSONAL_INFO_STEP]: {
        initial: UserInfoModalStates.IDLE,
        states: {
          [UserInfoModalStates.IDLE]: {
            on: {
              [UserInfoModalEventTypes.HANDLE_FIELD_CHANGE_EVENT]: {
                actions: ["clearErrorMessage", "persistFieldChanges"],
              },
              [UserInfoModalEventTypes.HANDLE_PERSIST_CAPTCHA_EVENT]: {
                actions: "persistCaptchaValue",
              },
              [UserInfoModalEventTypes.HANDLE_CONTINUE_EVENT]: [
                {
                  cond: () => OTP_DISABLED,
                  actions: "clearErrorMessage",
                  target: `#userInfoModalMachine.${UserInfoModalStates.SUBMIT_USER_INFO}`,
                },
                {
                  actions: "clearErrorMessage",
                  target: UserInfoModalStates.TRIGGER_OTP,
                },
              ],
            },
          },
          [UserInfoModalStates.TRIGGER_OTP]: {
            tags: ["loading"],
            invoke: {
              src: "sendOtpCode",
              onDone: {
                actions: ["setSuccessMessage", "setCountDown"],
                target: `#userInfoModalMachine.${UserInfoModalStates.PHONE_VERIFICATION_STEP}`,
              },
              onError: {
                actions: ["setErrorMessage"],
                target: [UserInfoModalStates.IDLE],
              },
            },
          },
        },
      },
      [UserInfoModalStates.PHONE_VERIFICATION_STEP]: {
        initial: UserInfoModalStates.IDLE,
        states: {
          [UserInfoModalStates.IDLE]: {
            invoke: {
              src: "runCountDown",
            },
            on: {
              [UserInfoModalEventTypes.HANDLE_VERIFICATION_CODE_EVENT]: {
                actions: ["clearErrorMessage", "persistVerificationCode"],
              },
              [UserInfoModalEventTypes.HANDLE_CONTINUE_EVENT]: {
                actions: "clearErrorMessage",
                target: UserInfoModalStates.VALIDATE_OTP,
              },
              [UserInfoModalEventTypes.GO_BACK_TO_PERSONAL_INFO_STEP_EVENT]: {
                target: `#userInfoModalMachine.${UserInfoModalStates.PERSONAL_INFO_STEP}`,
              },
              [UserInfoModalEventTypes.TICK]: {
                cond: (context) => context.countDown > 0,
                actions: "decrementCounter",
              },
              [UserInfoModalEventTypes.STOP_TICKING]: {
                target: UserInfoModalStates.RUNNED_OUT_OF_TIME,
                actions: "clearCaptchaValue",
              },
            },
          },
          [UserInfoModalStates.RUNNED_OUT_OF_TIME]: {
            on: {
              [UserInfoModalEventTypes.HANDLE_PERSIST_CAPTCHA_EVENT]: {
                actions: "persistCaptchaValue",
              },
              [UserInfoModalEventTypes.SEND_OTP_EVENT]: {
                target: `#userInfoModalMachine.${UserInfoModalStates.PHONE_VERIFICATION_STEP}.${UserInfoModalStates.IDLE}`,
                actions: ["setCountDown"],
              },
              [UserInfoModalEventTypes.GO_BACK_TO_PERSONAL_INFO_STEP_EVENT]: {
                target: `#userInfoModalMachine.${UserInfoModalStates.PERSONAL_INFO_STEP}`,
              },
            },
          },
          [UserInfoModalStates.VALIDATE_OTP]: {
            tags: ["loading"],
            invoke: {
              src: "validateOtp",
              onDone: {
                actions: ["setSuccessMessage"],
                target: `#userInfoModalMachine.${UserInfoModalStates.SUBMIT_USER_INFO}`,
              },
              onError: {
                actions: ["setErrorMessage"],
                target: [UserInfoModalStates.IDLE],
              },
            },
          },
        },
      },
      [UserInfoModalStates.SUBMIT_USER_INFO]: {
        tags: ["loading"],
        invoke: {
          src: "submitUserInfo",
          onDone: {
            actions: ["setSuccessMessage", "persistUiUrl"],
            target: `#userInfoModalMachine.${UserInfoModalStates.ASSIGN_TRIAL_CLUSTER_CONFIRMATION_STEP}`,
          },
          onError: {
            actions: ["setErrorMessage"],
            target: `#userInfoModalMachine.${UserInfoModalStates.PERSONAL_INFO_STEP}`,
          },
        },
      },
      [UserInfoModalStates.ASSIGN_TRIAL_CLUSTER_CONFIRMATION_STEP]: {
        type: "final",
      },
    },
  },
  {
    actions: actions as any,
    services: services as any,
  }
);
