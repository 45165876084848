import { UNAUTHORIZED_ROUTE_URL } from "utils/routePaths";
import { Navigate } from "react-router-dom";
import { useAuthMachine } from "auth/state/hook";
import { useAuth } from "auth/AuthContext";

export const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const { authService } = useAuth();
  const [{ userHasLoggedIn }] = useAuthMachine(authService);

  if (authService.status === 0) {
    // Machine did not initialize
    return null;
  }

  return userHasLoggedIn ? (
    children
  ) : (
    <Navigate to={UNAUTHORIZED_ROUTE_URL} replace />
  );
};
