import {
  Box,
  Button,
  FormControl,
  IconButton,
  Input,
  TextField,
} from "@mui/material";
import AlertDialog from "components/AlertDialog";
import { useAuth } from "auth/AuthContext";
import { useRef, useState } from "react";
import { useActionWithPath } from "utils/query";
import { useNavigate } from "react-router";
import { CloseOutlined } from "@mui/icons-material";

const allowedExtensions = [
  ".crt",
  ".cer",
  ".ca-bundle",
  ".p7b",
  ".p7c",
  ".p7s",
  ".pem",
];

const UploadCertificateDialog = ({
  open,
  closeDialog,
  clusterName,
}: {
  open: boolean;
  closeDialog: () => void;
  clusterName: string;
}) => {
  const [fileName, setFileName] = useState("");
  const [fileContent, setFileContent] = useState("");
  const { setErrorToast, setSuccessToast } = useAuth();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();
  const { mutate } = useActionWithPath({
    onSuccess: (data: any) => {
      if (data?.status === "ERROR") {
        setErrorToast("Certificate Upload Failed");
      } else {
        setSuccessToast(`Certificate Successfully Uploaded`);
        closeDialog();
        navigate(`/home/clusterDetails/${clusterName}/certificate`);
      }
    },
    onError: async (response: any) => {
      try {
        const json = await response.json();
        setErrorToast(`Certificate Upload Failed: ${json.message}`);
      } catch (e) {
        setErrorToast("Certificate Upload Failed");
      }
    },
  });

  const uploadCertificate = ({
    fileName,
    fileContent,
  }: {
    fileName: String;
    fileContent: String;
  }) => {
    const body = JSON.stringify({
      clusterName,
      fileName,
      fileContent,
    });
    // @ts-ignore
    mutate({
      method: "POST",
      path: `/customCertificates/upload`,
      body,
    });
  };

  const loadFile = (event: any) => {
    setFileName("");
    setFileContent("");
    const reader = new FileReader();
    const file = event?.target?.files[0];
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        setFileName(file.name);
        setFileContent(reader.result as string);
      };
      reader.onerror = console.error;
    }
  };

  const handleBrowseClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const clearFile = () => {
    setFileName("");
    setFileContent("");
  };

  const inputKey = fileName + Math.random();

  return (
    <AlertDialog
      maxWidth="sm"
      textTitle="Upload Certificate"
      textContent={`Upload certificate for ${clusterName} from here`}
      open={open}
      setOpen={() => closeDialog()}
      isConfirmDisabled={fileName.length === 0}
      handleConfirm={() => {
        uploadCertificate({ fileName, fileContent });
      }}
    >
      <Box sx={{ minWidth: 80, marginTop: "16px", display: "flex" }}>
        <FormControl fullWidth>
          <Input
            type="file"
            name="file"
            key={inputKey}
            onChange={loadFile}
            inputProps={{ accept: allowedExtensions.join(",") }}
            style={{ display: "none" }}
            ref={fileInputRef}
            id="file-input"
          />
          <TextField
            value={fileName}
            placeholder="No file chosen"
            InputProps={{
              readOnly: true,
              endAdornment: fileName ? (
                <IconButton onClick={clearFile}>
                  <CloseOutlined fontSize="small" />
                </IconButton>
              ) : (
                <Button
                  variant="contained"
                  component="label"
                  htmlFor="file-input"
                  sx={{ ml: 2 }}
                  onClick={handleBrowseClick}
                >
                  Browse
                </Button>
              ),
            }}
          />
        </FormControl>
      </Box>
    </AlertDialog>
  );
};

export default UploadCertificateDialog;
