import { CLUSTER_STATE } from "constants/common";
import { AuthenticationType } from "components/dialogs/ChangeAuthenticationTypeDialog";

export interface IObject {
  [key: string]: any;
}

export interface IAppVersion extends IObject {
  conductor: string;
  agent: string;
  workers: string;
  metricsProxy?: string;
}

export interface CustomerEnvironment extends IObject {
  orkesAccount: boolean;
  type: ClusterType;
  region: string;
  domainUrl: string;
  vpcId: string;
  vpcCidr: string;
  azIds: string[];
  ipRangeStart: string;
  ipRanges?: string;
  ingressType: IngressType;
  computeUnits: number;
  computeUnitDescription: number;
  cloudEnvironmentTag: string;
  subnetIds: string[]; // deprecated, use subnets
  utilitySubnetIds: string[]; // deprecated, use subnets
  s3Bucket: string;
  oidcS3Bucket: string;
  ipRangeEnd: string;
  certificateArn: string;
  loadBalancerName: string;
  elasticCacheConfig: ElasticCacheConfig;
  openSearchConfig: OpenSearchConfig;
  state: State;
  createdAt: number;
  createdBy: string;
  updatedAt: number;
  updatedBy: string;
  environmentType: EnvironmentType;
  deleteProduction: boolean;
  urls: Array<CustomerEnvironmentUrl>;
  appVersions: IAppVersion;
  legacyId: string;
  organizationId: string;
  name: string;
  domain: string;
  accountId: string;
  subnets: Array<{
    awsId: string;
    type: "MAIN" | "UTILITY";
  }>;
  elasticacheReplicationGroupId: string;
  elasticacheSubnetGroupId: string; //deprecated
  authenticationType: AuthenticationType;
  authenticationParams?: {
    genericOidcConfig: {
      audience: string;
      clientId: string;
      issuer: string;
      oidcConfigUrl: string;
      emailClaim: string;
    };
  };
  multiTenant: boolean;
}

interface CustomerEnvironmentUrl {
  urlType: string;
  urlValue: string;
  display: boolean;
}

interface Subnet {
  id: string;
  az: string;
}

export interface ElasticCacheConfig {
  url: string;
  replicationGroupId: string;
  nodeType: string;
  numberCacheClusters: string;
  port: string;
  engine: string;
  engineVersion: string;
}

export interface OpenSearchConfig {
  url: string;
  domainName: string;
  masterCount: string;
  masterInstanceType: string;
  nodeCount: string;
  nodeInstanceType: string;
  ebsVolumeSize: string;
  ebsVolumeType: string;
}

export class ProvisionRequest {
  customerAccountId?: string;

  azureResourceGroup?: string;
  azureSubscriptionId?: string;
  azureCreateVnet?: boolean;
  azureVnetName?: string;
  azureVnetCidr?: string;
  azureCreateMainSubnet?: string;
  azureMainSubnetCidr?: string;
  azureCreateDbSubnet?: string;
  azureDbSubnetCidr?: string;
  azureCreateStorageAccount?: string;
  azureStorageAccountName?: string;
  azureCreateKeyVault?: string;
  azureKeyVaultName?: string;
  azureTenantId?: string;
  azureServicePrincipalApplicationId?: string;
  azureServicePrincipalPassword?: string;
  azureCredentialsValidated?: boolean;

  clusterName?: string;
  cloudProvider?: string;
  domainUrl?: string;
  subDomain?: any;
  region?: string;
  vpcId?: string | null;
  prevVpcId?: string | null;
  vpcCidr?: string;
  azIds?: string[];
  computeUnits?: number;
  ingressType?: IngressType;
  environmentType?: EnvironmentType;
  publicSubnetIds?: Subnet[];
  privateZone?: string;

  constructor(
    cloudProvider?: string,
    customerAccountId?: string,
    clusterName?: string,
    computeUnits?: number,
    environmentType?: EnvironmentType
  ) {
    this.cloudProvider = cloudProvider;
    this.customerAccountId = customerAccountId;
    this.clusterName = clusterName;
    this.computeUnits = computeUnits;
    this.environmentType = environmentType;
    this.vpcId = null;
  }
}

export class CustomerCftDto {
  customerId?: string;
  customerAccountId?: string;
  clusterName?: string;
  s3Url?: string;
  uniqueId?: string;
  consistentId?: string;
  tempFileReference?: string;
  customerUrl?: string;
}

export class AzureScriptDto {
  id?: string;
  organizationId?: string;
  clusterName?: string;
  resourceGroupName?: string;
  s3Url?: string;
  consistentId?: string;
  tempFileReference?: string;
}

export enum ClusterType {
  STARTER = "STARTER",
  STANDARD = "STANDARD",
  ENTERPRISE = "ENTERPRISE",
  TRIAL = "TRIAL",
}

type IngressType = "INTERNAL" | "EXTERNAL";

export type State = keyof typeof CLUSTER_STATE;

export type EnvironmentType =
  | "POC"
  | "PRODUCTION"
  | "TEST"
  | "STAGING"
  | "DEVELOPMENT"
  | "ORKESTEST"
  | "TRIAL";

export interface EnvironmentTypeOption {
  id: EnvironmentType;
  title: string;
}

// the first one will be the default option
export const environmentTypeOptions: EnvironmentTypeOption[] = [
  { id: "PRODUCTION", title: "Production" },
  { id: "STAGING", title: "Staging" },
  { id: "DEVELOPMENT", title: "Development" },
  { id: "TEST", title: "Test" },
  { id: "POC", title: "Proof of Concept" },
  { id: "ORKESTEST", title: "Orkes Test" }, // this MUST be the last to be popped out if necessary
];

export interface VpcInfoResponse {
  vpcCIDR: string;
  vpcSubnets: VpcSubnet[];
  internetGatewayID: string;
  natGateways: VpcNatGateway[];
  availabilityZones: string[];
  routeTables: VpcRouteTable[];
  route53: Route53Info[];
}

interface VpcSubnet {
  subnetID: string;
  availabilityZone: string;
  availabilityZoneID: string;
  availableIpAddressCount: number;
  cidrBlock: string;
  routeTableId: string;
}

interface VpcNatGateway {
  natGatewayId: string;
  routeTableId: string;
}

interface VpcRouteTable {
  routeTableID: string;
  subnetAssociations: string[];
  routes: VpcRoutes[];
}

export interface Route53Info {
  domainName: string;
  hostedZoneId: string;
  privateZone: boolean;
}

interface VpcRoutes {
  destinationCIDR: string;
  gateway: string;
}

export interface WorkflowStatus {
  status: string;
  taskStatusList: TaskStatus[];
}

export interface TaskStatus {
  parentTask: string;
  childTasks: string[];
  status: string;
  label: string;
  timeOfExecution: number;
}

export interface ISignupPermittedValueDto {
  DOMAIN: string[];
  EMAIL: string[];
}

export interface IProvisioningPermittedValueDto {
  CUSTOMER_HOSTED_AWS_DOMAIN: string[];
  CUSTOMER_HOSTED_AWS_EMAIL: string[];
  CUSTOMER_HOSTED_AZURE_DOMAIN: string[];
  CUSTOMER_HOSTED_AZURE_EMAIL: string[];
  ORKES_HOSTED_AWS_DOMAIN: string[];
  ORKES_HOSTED_AWS_EMAIL: string[];
  ORKES_HOSTED_AZURE_DOMAIN: string[];
  ORKES_HOSTED_AZURE_EMAIL: string[];
}

export enum HistoryCommand {
  ADD_EKS_ADMIN = "ADD_EKS_ADMIN",
  GET_PODS_NAMES = "GET_PODS_NAMES",
  HEALTH_CHECK = "HEALTH_CHECK",
  PULL_LOGS = "PULL_LOGS",
  UPGRADE_DEPLOYMENT = "UPGRADE_DEPLOYMENT",
}

export enum ExecutionsStatus {
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  RUNNING = "RUNNING",
  TERMINATED = "TERMINATED",
  TIMED_OUT = "TIMED_OUT",
}

export enum Resource {
  CONDUCTOR = "CONDUCTOR",
}

export interface ParameterDTO {
  deploymentVersion: string;
  environmentVariables: { [key: string]: string }[];
  resource: Resource;
}

export interface HistoryDTO {
  command: HistoryCommand;
  createdAt: string;
  createdBy: string;
  deploymentName: string;
  deploymentPackage: string;
  executionStatus: ExecutionsStatus;
  message: string;
  newVersion: string;
  parameters: ParameterDTO;
  previousVersion: string;
  triggeredBy: string;
  lastTaskError?: string;
}

export enum Role {
  COMPANY_ADMIN = "COMPANY_ADMIN",
  COMPANY_ROOT = "COMPANY_ROOT",
  COMPANY_USER = "COMPANY_USER",
}

export interface User {
  email: string;
  name: string;
  roles: Role[];
  userId: string;
}

export interface InvitedUser {
  id: string;
  invitedBy: string;
  organizationId: string;
  role: Role;
  userEmail: string;
}

export interface Pod {
  name: string;
  status: string;
  restarts?: number;
  creationTime?: string;
  cpuUsagePercentage?: string;
  memoryUsagePercentage?: string;
}

export interface SubRegion {
  country: string;
  enabled: boolean;
  name: string;
  regionId: string;
}
export interface Region {
  regionName: string;
  subRegions: SubRegion[];
}

export enum CloudProvider {
  AWS = "AWS",
  AZURE = "AZURE",
  GCP = "GCP",
}

export enum ClusterCapacityType {
  STANDARD = "STANDARD",
  STARTER = "STARTER",
}

export interface CloudProviderRegion {
  cloudToRegionsMap: { [key in CloudProvider]: Region[] };
  clusterType: ClusterCapacityType;
}

export interface PackageVersionDto {
  metadata: string[];
  name: string;
  updated_at: string;
}

export enum PackageGroupLabel {
  MAJOR = "Major Versions",
  MINOR = "Minor Versions",
}

export interface PackageVersion {
  group: PackageGroupLabel;
  title: string;
  value: string;
}

export interface AgentLogsResults {
  id: string;
  commandLabel: string;
  commandName: string;
  executionStatus: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
}

export interface AgentLogsDto {
  currentPage: number;
  pageSize: number;
  pages: number;
  total: number;
  results: AgentLogsResults[];
}

export enum ResourceType {
  AGENT = "AGENT",
  CONDUCTOR = "CONDUCTOR",
  BOTH_CONDUCTOR_AND_WORKERS = "BOTH_CONDUCTOR_AND_WORKERS",
  CONDUCTOR_SERVER_WORKERS_AND_METRICS_PROXY = "CONDUCTOR_SERVER_WORKERS_AND_METRICS_PROXY",
  WORKERS = "WORKERS",
  METRICS_PROXY = "METRICS_PROXY",
}

export enum ProvisioningStatus {
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
  PAUSED = "PAUSED",
  PENDING = "PENDING",
  RUNNING = "RUNNING",
  SCHEDULED = "SCHEDULED",
  TERMINATED = "TERMINATED",
  TIMED_OUT = "TIMED_OUT",
}

export enum DeploymentType {
  AGENT = "AGENT",
  CONDUCTOR = "CONDUCTOR",
  METRICS_PROXY = "METRICS_PROXY",
  WORKERS = "WORKERS",
}

export enum ReleaseStatus {
  TESTING = "TESTING",
  AVAILABLE = "AVAILABLE",
  RETIRED = "RETIRED",
}

export interface ReleaseDto {
  version: string;
  deploymentType: DeploymentType;
  dateOfRelease: string;
  status?: ReleaseStatus;
  endOfLife?: string;
  generalNotes?: string;
  releaseSummary?: string;
}

export type TelemetryType =
  | "NORMAL"
  | "DISABLED"
  | "SUPPRESSED"
  | "AUDIT"
  | "DEBUG";
export interface TelemetryStateOption {
  id: TelemetryType;
  title: string;
  description: string;
}

export const telemetryStateOption: TelemetryStateOption[] = [
  {
    id: "NORMAL",
    title: "Normal/On",
    description: "Checks health and failure alerts sent (default)",
  },
  {
    id: "DISABLED",
    title: "Off",
    description: "No health data stored nor failure alerts sent",
  },
  {
    id: "SUPPRESSED",
    title: "Mute",
    description: "Stores health data but failure alerts disabled",
  },
  {
    id: "AUDIT",
    title: "Audit",
    description: "Stores health data + wf id for analysis (investigation)",
  },
  {
    id: "DEBUG",
    title: "Debug",
    description: "Stores health data + wf id and prevents further alerts (ack)",
  },
];
export interface ConductorReleaseDto {
  dateOfRelease: string;
  deploymentType: DeploymentType;
  markdown: string;
  releaseCandidate: boolean;
  releaseSummary?: string;
  version: string;
}

export enum RolloutRestartType {
  CONDUCTOR = "CONDUCTOR",
  WORKERS = "WORKERS",
  AGENT = "AGENT",
  METRICS_PROXY = "METRICS_PROXY",
}

export interface IResourceType {
  name: string;
  value: RolloutRestartType;
}

export enum UserProfileType {
  NONE = "NONE",
  CLOUD_SUPPORT_ENGINEER_WRITE = "CLOUD_SUPPORT_ENGINEER_WRITE",
  CLOUD_SUPPORT_ENGINEER_READ = "CLOUD_SUPPORT_ENGINEER_READ",
  ENGINEERING = "ENGINEERING",
}

export enum CompatibilityOperator {
  GREATER_OR_EQUAL = "GREATER_OR_EQUAL",
  LESS = "LESS",
}

export interface CompatibilityDto {
  from: {
    version: string;
    operator: CompatibilityOperator;
  };
  to: {
    version: string;
    operator: CompatibilityOperator;
  };
}

export interface CertificateDto {
  filename: string;
  alias: string;
  paramStorePath: string;
  state: string;
}

export enum CertificateStateType {
  SCHEDULED = "SCHEDULED",
  PROCESSING = "PROCESSING",
  READY = "READY",
  ERROR = "ERROR",
  SCHEDULE_DELETION = "SCHEDULE_DELETION",
  DELETING = "DELETING",
}

export type StatusHistoryType = {
  date: string;
  up: number;
  down: number;
};

export interface ClusterHealthHistoryItem {
  history: StatusHistoryType[];
  last: {
    status: string;
    dateTime: string;
  };
  name: string;
  uptime: number;
}

export type HealthWorkerResponseTimingHistoryType = {
  date: string;
  value: string;
};

export type SnapshotDomainType = {
  certValidity: string;
  domain: string;
  healthy: boolean;
  ips: string[];
};

export interface ClusterHealthHistoryDTO {
  clusters: { [key: string]: ClusterHealthHistoryItem };
  healthWorkerResponseTimingHistory: HealthWorkerResponseTimingHistoryType[];
  snapshot: {
    domain: SnapshotDomainType[];
  };
  window: {
    unit: string;
    width: number;
  };
}

export enum OrganizationType {
  FREE_TRIAL = "TRIAL",
  ENTERPRISE = "ENTERPRISE",
}

export type CardInfoType = {
  last4: string;
  brand: string;
  country: string;
  exp_year: number;
  exp_month: number;
};

export type BraavosBillingProfile = {
  id: String;
  organizationId: String;
  name: String;
  email: String;
  phone: String;
  meta: {
    card: CardInfoType;
  };
  organizationType?: OrganizationType;
};

export enum SupportType {
  BASIC = "Basic Support",
  STANDARD = "Standard Support",
  PREMIUM = "Premium Support",
}

type BraavosMeter = {
  id: string;
  name: string;
  unit: string;
};
type BraavosProduct = {
  id: string;
  name: string;
  description: string;
};

type BraavosRate = {
  id: string;
  meter: BraavosMeter;
  product: BraavosProduct;
  quota: number | null;
};

export type BraavosSubscription = {
  id: string;
  rates: BraavosRate[];
};

export type ClusterActionTypeOptionsProp = {
  label: string;
  value: string;
  hidden: boolean;
};
