import { createMachine } from "xstate";
import {
  AuthProviderContext,
  AuthProviderEventTypes,
  AuthProviderStates,
} from "./types";
import * as actions from "./actions";
import * as services from "./services";
import * as guards from "./guards";

const checkForInviteId = {
  always: [
    {
      cond: (context: AuthProviderContext) => !!context.inviteId,
      target: AuthProviderStates.FORM_WITH_PASSWORD,
    },
    {
      target: AuthProviderStates.PASSWORD_LESS_FORM,
    },
  ],
};

export const authProviderMachine = createMachine<AuthProviderContext>(
  {
    id: "authProviderMachine",
    predictableActionArguments: true,
    initial: AuthProviderStates.INIT,
    context: {
      auth: undefined,
      inviteId: undefined,
      currentUser: undefined,
      verificationStatus: undefined,
      currentOrganization: undefined,
      billingProfile: undefined,
      messageType: undefined,
      currentUserRoles: undefined,
      availableProfiles: undefined,
      currentProfile: undefined,
    },

    states: {
      [AuthProviderStates.INIT]: {
        tags: ["loading"],
        always: [
          {
            cond: () => window.location.pathname.startsWith("/acceptInvite"),
            target: AuthProviderStates.ACCEPT_INVITE_FLOW,
          },
          {
            cond: (context) => !!context.currentUser,
            target: AuthProviderStates.IS_EMAIL_VERIFIED,
          },
          {
            cond: () => window.location.pathname === "/user/verify",
            target: `${AuthProviderStates.UNLOGGED}.${AuthProviderStates.VERIFY_EMAIL}`,
          },
          {
            cond: () => window.location.pathname === "/resetPassword",
            target: `#authProviderMachine.${AuthProviderStates.UNLOGGED}.${AuthProviderStates.RESET_PASSWORD_SECTION}`,
          },
          {
            cond: () => window.location.pathname === "/signup",
            target: `${AuthProviderStates.UNLOGGED}.${AuthProviderStates.SIGNUP_SECTION}`,
          },
          {
            target: AuthProviderStates.UNLOGGED,
            actions: ["persistUrlToLocalStorage"],
          },
        ],
      },
      [AuthProviderStates.CLEAN_AND_UNLOG]: {
        // This should be the correct way to logout
        invoke: {
          src: "logUserOut",
          onDone: {
            actions: ["cleanup", "navigateToLogin"],
            target: AuthProviderStates.UNLOGGED,
          },
        },
      },
      [AuthProviderStates.UNLOGGED]: {
        on: {
          [AuthProviderEventTypes.CONTINUE_WITH_GOOGLE_EVENT]: {
            target: `.${AuthProviderStates.CONTINUE_WITH_GOOGLE}`,
          },
          [AuthProviderEventTypes.SET_USER_EVENT]: {
            actions: "persistCurrentUser",
            target: AuthProviderStates.IS_EMAIL_VERIFIED,
          },
          [AuthProviderEventTypes.GO_BACK_EVENT]: {
            target: AuthProviderStates.UNLOGGED,
            actions: ["clearMessageType", "navigateToLogin"],
          },
          [AuthProviderEventTypes.SIGN_IN_WITH_EMAIL_LINK_EVENT]: {
            target: `.${AuthProviderStates.INVOKE_LOGIN_WITH_EMAIL_LINK}`,
          },
        },
        initial: AuthProviderStates.LOGIN_SECTION,
        states: {
          [AuthProviderStates.LOGIN_SECTION]: {
            initial: AuthProviderStates.IDLE,
            states: {
              [AuthProviderStates.IDLE]: {
                on: {
                  [AuthProviderEventTypes.CONTINUE_WITH_EMAIL_EVENT]: {
                    target: AuthProviderStates.LOGIN_FORM,
                  },
                  [AuthProviderEventTypes.SWITCH_TO_SIGNUP_EVENT]: {
                    target: `#authProviderMachine.${AuthProviderStates.UNLOGGED}.${AuthProviderStates.SIGNUP_SECTION}`,
                  },
                },
                invoke: {
                  id: "callbackInvoke",
                  src: "handleFBAuth",
                },
              },
              [AuthProviderStates.LOGIN_FORM]: {
                initial: AuthProviderStates.CHECK_FOR_INVITE_ID,
                states: {
                  [AuthProviderStates.CHECK_FOR_INVITE_ID]: checkForInviteId,
                  [AuthProviderStates.PASSWORD_LESS_FORM]: {
                    on: {
                      [AuthProviderEventTypes.SEND_MAGIC_LINK_EVENT]: {
                        target: `#authProviderMachine.${AuthProviderStates.UNLOGGED}.${AuthProviderStates.CONTINUE_WITH_MAGIC_LINK}`,
                      },
                      [AuthProviderEventTypes.SWITCH_TO_PASSWORD_EVENT]: {
                        target: AuthProviderStates.FORM_WITH_PASSWORD,
                      },
                    },
                  },
                  [AuthProviderStates.FORM_WITH_PASSWORD]: {
                    on: {
                      [AuthProviderEventTypes.SWITCH_TO_PASSWORD_LESS_EVENT]: {
                        target: AuthProviderStates.PASSWORD_LESS_FORM,
                      },
                      [AuthProviderEventTypes.SWITCH_TO_FORGOT_PASSWORD_EVENT]:
                        {
                          target: `#authProviderMachine.${AuthProviderStates.UNLOGGED}.${AuthProviderStates.FORGOT_PASSWORD_SECTION}`,
                        },
                      [AuthProviderEventTypes.LOGIN_WITH_PASSWORD_EVENT]: {
                        target: `#authProviderMachine.${AuthProviderStates.UNLOGGED}.${AuthProviderStates.LOGIN_WITH_PASSWORD}`,
                      },
                    },
                  },
                },
              },
            },
          },
          [AuthProviderStates.SIGNUP_SECTION]: {
            initial: AuthProviderStates.IDLE,
            states: {
              [AuthProviderStates.IDLE]: {
                entry: "navigateToSignup",
                on: {
                  [AuthProviderEventTypes.CONTINUE_WITH_EMAIL_EVENT]: {
                    target: AuthProviderStates.SIGNUP_FORM,
                  },
                  [AuthProviderEventTypes.SWITCH_TO_LOGIN_EVENT]: {
                    target: `#authProviderMachine.${AuthProviderStates.UNLOGGED}.${AuthProviderStates.LOGIN_SECTION}`,
                  },
                },
              },
              [AuthProviderStates.SIGNUP_FORM]: {
                initial: AuthProviderStates.CHECK_FOR_INVITE_ID,
                states: {
                  [AuthProviderStates.CHECK_FOR_INVITE_ID]: checkForInviteId,
                  [AuthProviderStates.PASSWORD_LESS_FORM]: {
                    on: {
                      [AuthProviderEventTypes.SEND_MAGIC_LINK_EVENT]: {
                        target: `#authProviderMachine.${AuthProviderStates.UNLOGGED}.${AuthProviderStates.CONTINUE_WITH_MAGIC_LINK}`,
                      },
                      [AuthProviderEventTypes.SWITCH_TO_PASSWORD_EVENT]: {
                        target: AuthProviderStates.FORM_WITH_PASSWORD,
                      },
                    },
                  },
                  [AuthProviderStates.FORM_WITH_PASSWORD]: {
                    on: {
                      [AuthProviderEventTypes.SWITCH_TO_PASSWORD_LESS_EVENT]: {
                        target: AuthProviderStates.PASSWORD_LESS_FORM,
                      },

                      [AuthProviderEventTypes.SIGNUP_WITH_PASSWORD_EVENT]: {
                        target: `#authProviderMachine.${AuthProviderStates.UNLOGGED}.${AuthProviderStates.SIGNUP_WITH_PASSWORD}`,
                      },
                    },
                  },
                },
              },
            },
          },
          [AuthProviderStates.FORGOT_PASSWORD_SECTION]: {
            initial: AuthProviderStates.IDLE,
            states: {
              [AuthProviderStates.IDLE]: {
                on: {
                  [AuthProviderEventTypes.SEND_RESET_PASSWORD_EVENT]: {
                    target: AuthProviderStates.SENDING_RESET_PASSWORD_LINK,
                  },
                  [AuthProviderEventTypes.GO_BACK_EVENT]: {
                    target: `#authProviderMachine.${AuthProviderStates.UNLOGGED}.${AuthProviderStates.LOGIN_SECTION}.${AuthProviderStates.LOGIN_FORM}.${AuthProviderStates.FORM_WITH_PASSWORD}`,
                  },
                },
              },
              [AuthProviderStates.SENDING_RESET_PASSWORD_LINK]: {
                tags: ["loading"],
                invoke: {
                  src: "triggerResetPasswordEmail",
                  onDone: {
                    actions: "persistResetPasswordMessageType",
                    target: `#authProviderMachine.${AuthProviderStates.UNLOGGED}.${AuthProviderStates.SHOW_MESSAGE_TO_USER}`,
                  },
                  onError: {
                    actions: "setErrorMessage",
                    target: AuthProviderStates.IDLE,
                  },
                },
              },
            },
          },

          [AuthProviderStates.CONTINUE_WITH_GOOGLE]: {
            on: {
              [AuthProviderEventTypes.SWITCH_TO_SIGNUP_EVENT]: {
                target: `#authProviderMachine.${AuthProviderStates.UNLOGGED}.${AuthProviderStates.SIGNUP_SECTION}`,
              },
              [AuthProviderEventTypes.SWITCH_TO_LOGIN_EVENT]: {
                target: `#authProviderMachine.${AuthProviderStates.UNLOGGED}.${AuthProviderStates.LOGIN_SECTION}`,
              },
              [AuthProviderEventTypes.CONTINUE_WITH_EMAIL_EVENT]: [
                {
                  cond: () => window.location.pathname === "/signup",
                  target: `#authProviderMachine.${AuthProviderStates.UNLOGGED}.${AuthProviderStates.SIGNUP_SECTION}.${AuthProviderStates.SIGNUP_FORM}`,
                },
                {
                  target: `#authProviderMachine.${AuthProviderStates.UNLOGGED}.${AuthProviderStates.LOGIN_SECTION}.${AuthProviderStates.LOGIN_FORM}`,
                },
              ],
              [AuthProviderEventTypes.CONTINUE_WITH_GOOGLE_EVENT]: {
                target: `#authProviderMachine.${AuthProviderStates.UNLOGGED}.${AuthProviderStates.CONTINUE_WITH_GOOGLE}`,
              },
            },
            invoke: {
              src: "invokeLoginWithGoogle",
              onDone: {
                actions: "persistCurrentUser",
                target: `#authProviderMachine.${AuthProviderStates.VERIFY_USER}`,
              },
              onError: {
                actions: ["setErrorMessage", "logout"],
                target: `#authProviderMachine.${AuthProviderStates.UNLOGGED}`,
              },
            },
          },
          [AuthProviderStates.CONTINUE_WITH_MAGIC_LINK]: {
            tags: ["loading"],
            invoke: {
              src: "requestMagicLink",
              onDone: {
                actions: "persistMagicLinkMessageType",
                target: `#authProviderMachine.${AuthProviderStates.UNLOGGED}.${AuthProviderStates.SHOW_MESSAGE_TO_USER}`,
              },
              onError: {
                actions: "setErrorMessage",
                target: `#authProviderMachine.${AuthProviderStates.UNLOGGED}.${AuthProviderStates.LOGIN_SECTION}.${AuthProviderStates.LOGIN_FORM}`,
              },
            },
          },
          [AuthProviderStates.LOGIN_WITH_PASSWORD]: {
            tags: ["loading"],

            invoke: {
              src: "loginWithEmailPassword",
              onDone: {
                actions: "persistCurrentUser",
                target: `#authProviderMachine.${AuthProviderStates.IS_EMAIL_VERIFIED}`,
              },
              onError: {
                actions: "setErrorMessage",
                target: `${AuthProviderStates.LOGIN_SECTION}.${AuthProviderStates.LOGIN_FORM}.${AuthProviderStates.FORM_WITH_PASSWORD}`,
              },
            },
          },
          [AuthProviderStates.SIGNUP_WITH_PASSWORD]: {
            tags: ["loading"],
            invoke: {
              src: "signupWithEmailPassword",
              onDone: {
                actions: "persistVerifyEmailMessageType",
                target: `#authProviderMachine.${AuthProviderStates.UNLOGGED}.${AuthProviderStates.SHOW_MESSAGE_TO_USER}`,
              },
              onError: [
                {
                  cond: (__context, { data }) =>
                    data.message === "Email or domain are not permitted",
                  actions: [
                    "persistPermissionDeniedMessageType",
                    "clearLoginEmail",
                  ],
                  target: `#authProviderMachine.${AuthProviderStates.UNLOGGED}.${AuthProviderStates.SHOW_MESSAGE_TO_USER}`,
                },

                {
                  actions: ["setErrorMessage"],
                  target: `${AuthProviderStates.SIGNUP_SECTION}.${AuthProviderStates.SIGNUP_FORM}.${AuthProviderStates.FORM_WITH_PASSWORD}`,
                },
              ],
            },
          },
          [AuthProviderStates.SHOW_MESSAGE_TO_USER]: {
            initial: AuthProviderStates.IDLE,
            states: {
              [AuthProviderStates.IDLE]: {
                on: {
                  [AuthProviderEventTypes.RESEND_VERIFICATION_EMAIL_EVENT]: {
                    target: AuthProviderStates.RESENDING_VERIFICATION_EMAIL,
                    actions: "clearMessageType",
                  },
                  [AuthProviderEventTypes.SEND_MAGIC_LINK_EVENT]: {
                    target: `#authProviderMachine.${AuthProviderStates.UNLOGGED}.${AuthProviderStates.CONTINUE_WITH_MAGIC_LINK}`,
                  },
                  [AuthProviderEventTypes.SEND_RESET_PASSWORD_EVENT]: {
                    target: `#authProviderMachine.${AuthProviderStates.UNLOGGED}.${AuthProviderStates.FORGOT_PASSWORD_SECTION}.${AuthProviderStates.SENDING_RESET_PASSWORD_LINK}`,
                  },
                },
              },
              [AuthProviderStates.RESENDING_VERIFICATION_EMAIL]: {
                tags: ["loading"],
                invoke: {
                  src: "resendVerificationEmail",
                  onDone: {
                    actions: "persistVerifyEmailMessageType",
                    target: AuthProviderStates.IDLE,
                  },
                  onError: {
                    actions: "setErrorMessage",
                    target: `#authProviderMachine.${AuthProviderStates.UNLOGGED}.${AuthProviderStates.SIGNUP_SECTION}.${AuthProviderStates.SIGNUP_FORM}.${AuthProviderStates.FORM_WITH_PASSWORD}`,
                  },
                },
              },
            },
          },
          [AuthProviderStates.INVOKE_LOGIN_WITH_EMAIL_LINK]: {
            tags: ["loading"],
            invoke: {
              src: "invokeSignInWithEmailLink",
              onDone: {
                actions: "persistCurrentUser",
                target: `#authProviderMachine.${AuthProviderStates.VERIFY_USER}`,
              },
              onError: {
                actions: ["logout", "setErrorMessage"],
                target: `#authProviderMachine.${AuthProviderStates.UNLOGGED}`,
              },
            },
          },
          [AuthProviderStates.RESET_PASSWORD_SECTION]: {
            initial: AuthProviderStates.IDLE,
            states: {
              [AuthProviderStates.IDLE]: {
                on: {
                  [AuthProviderEventTypes.SUBMIT_NEW_PASSWORD_EVENT]: {
                    target: AuthProviderStates.SUBMIT_NEW_PASSWORD,
                  },
                },
              },
              [AuthProviderStates.SUBMIT_NEW_PASSWORD]: {
                invoke: {
                  src: "submitNewPasswordAndLogin",
                  onDone: {
                    actions: "persistCurrentUser",
                    target: `#authProviderMachine.${AuthProviderStates.IS_EMAIL_VERIFIED}`,
                  },
                  onError: {
                    actions: "setErrorMessage",
                    target: AuthProviderStates.IDLE,
                  },
                },
              },
            },
          },
          [AuthProviderStates.VERIFY_EMAIL]: {
            initial: AuthProviderStates.IS_VERIFY_EMAIL_URL_VALID,
            states: {
              [AuthProviderStates.IS_VERIFY_EMAIL_URL_VALID]: {
                always: [
                  {
                    cond: "isEmailAndIdExist",
                    target: AuthProviderStates.VERIFYING_EMAIL,
                  },
                  {
                    target: AuthProviderStates.INVALID_EMAIL_VERIFICATION_URL,
                  },
                ],
              },
              [AuthProviderStates.VERIFYING_EMAIL]: {
                invoke: {
                  src: "verifyEmail",
                  onDone: {
                    target: AuthProviderStates.EMAIL_VERIFICATION_SUCCESS,
                  },
                  onError: {
                    target: AuthProviderStates.EMAIL_VERIFICATION_FAILED,
                  },
                },
              },
              [AuthProviderStates.EMAIL_VERIFICATION_SUCCESS]: {
                on: {
                  [AuthProviderEventTypes.NAVIGATE_TO_LOGIN_EVENT]: {
                    actions: "navigateToLogin",
                    target: `#authProviderMachine.${AuthProviderStates.UNLOGGED}`,
                  },
                },
              },
              [AuthProviderStates.EMAIL_VERIFICATION_FAILED]: {},
              [AuthProviderStates.INVALID_EMAIL_VERIFICATION_URL]: {},
            },
          },
        },
      },
      [AuthProviderStates.ACCEPT_INVITE_FLOW]: {
        on: {
          [AuthProviderEventTypes.ACCEPT_INVITE_EVENT]: [
            {
              cond: (context) => !!context?.currentUser,
              actions: ["persistInviteInfo"],
              target: AuthProviderStates.ACCEPT_INVITE,
            },

            {
              actions: ["persistInviteInfo", "navigateToLogin"],
              target: AuthProviderStates.UNLOGGED,
            },
          ],
        },
      },
      [AuthProviderStates.IS_EMAIL_VERIFIED]: {
        always: [
          {
            cond: ({ currentUser }) => !currentUser?.emailVerified,
            actions: ["persistEmailNotVerifiedMessage", "logout"],
            target: `#authProviderMachine.${AuthProviderStates.UNLOGGED}.${AuthProviderStates.SHOW_MESSAGE_TO_USER}`,
          },
          {
            cond: (context) => {
              const inviteIdInLocalStorage = localStorage.getItem(
                "inviteIdInLocalStorage"
              );

              return !!context?.inviteId || !!inviteIdInLocalStorage;
            },
            target: AuthProviderStates.ACCEPT_INVITE,
          },
          {
            target: AuthProviderStates.VERIFY_USER,
          },
        ],
      },
      [AuthProviderStates.ACCEPT_INVITE]: {
        tags: ["loading"],
        invoke: {
          src: "acceptInvite",
          onDone: {
            target: AuthProviderStates.VERIFY_USER,
            actions: "cleanInvitation",
          },
          onError: {
            actions: ["setErrorMessage", "cleanInvitation"],
            target: AuthProviderStates.VERIFY_USER,
          },
        },
      },
      [AuthProviderStates.VERIFY_USER]: {
        tags: ["logged", "loading"],
        invoke: {
          src: "verifyUser",
          onDone: {
            target: AuthProviderStates.LOGGED_USER,
            actions: [
              "persistVerification",
              "navigateToHome",
              "clearLoginEmail",
            ],
          },
          onError: [
            {
              cond: (_, { data }) =>
                data.message === "Either email or domain must be Permitted",
              actions: ["logout", "persistPermissionDeniedMessageType"],
              target: `${AuthProviderStates.UNLOGGED}.${AuthProviderStates.SHOW_MESSAGE_TO_USER}`,
            },

            {
              actions: ["logout", "setErrorMessage"],
              target: AuthProviderStates.UNLOGGED,
            },
          ],
        },
      },
      [AuthProviderStates.LOGGED_USER]: {
        tags: ["logged"],
        on: {
          [AuthProviderEventTypes.LOG_OUT_EVENT]: {
            target: AuthProviderStates.CLEAN_AND_UNLOG,
          },
          [AuthProviderEventTypes.REFETCH_VERIFY_USER_STATUS_EVENT]: {
            target: AuthProviderStates.VERIFY_USER,
          },
          [AuthProviderEventTypes.SWITCH_CURRENT_ORGANIZATION]: {
            target: `.${AuthProviderStates.SWITCHING_CURRENT_ORGANIZATION}`,
          },
        },
        initial: AuthProviderStates.FETCHING_ORGANIZATION,
        states: {
          [AuthProviderStates.SWITCHING_CURRENT_ORGANIZATION]: {
            tags: ["loading"],
            invoke: {
              src: "switchCurrentOrganization",
              onDone: {
                target: AuthProviderStates.FETCHING_ORGANIZATION,
                actions: "navigateToHome",
              },
              onError: {
                actions: () =>
                  console.log(
                    "This is unexpected could not switch organization"
                  ),
                target: AuthProviderStates.FETCHING_ORGANIZATION,
              },
            },
          },
          [AuthProviderStates.FETCHING_ORGANIZATION]: {
            tags: ["loading"],
            invoke: {
              src: "fetchForCurrentOrganizations",
              onDone: [
                {
                  cond: ({ currentUser }) =>
                    currentUser?.email?.endsWith("@orkes.io"),
                  actions: "persistUserOrganization",
                  target: AuthProviderStates.LOGGED_USER_IS_ORKES_USER,
                },
                {
                  cond: (_context, { data }) => data?.id !== "",
                  actions: "persistUserOrganization",
                  target: AuthProviderStates.LOGGED_USER_HAS_ORGANIZATION,
                },
                {
                  target: AuthProviderStates.LOGGED_USER_HAS_NO_ORGANIZATION,
                },
              ],
              onError: {
                target: AuthProviderStates.LOGGED_USER_HAS_NO_ORGANIZATION,
              },
            },
          },
          [AuthProviderStates.LOGGED_USER_HAS_ORGANIZATION]: {
            tags: ["loggedUserHasOrganization"],
            initial: "fetchBillingProfile",
            states: {
              fetchBillingProfile: {
                tags: ["loading"],
                invoke: {
                  src: "fetchBillingProfileAndSubscription",
                  onDone: {
                    target: "withBillingProfile",
                    actions: "persistBillingProfileAndBraavosSubscription",
                  },
                  onError: {
                    target: "noBillingProfile",
                  },
                },
              },
              withBillingProfile: {
                on: {
                  [AuthProviderEventTypes.REFETCH_BILLING_PROFILE]: {
                    target: "fetchBillingProfile",
                  },
                },
              },
              noBillingProfile: {
                //This might not be an issue. for example its an orkes user.
              },
            },
          },
          [AuthProviderStates.LOGGED_USER_HAS_NO_ORGANIZATION]: {},
          [AuthProviderStates.LOGGED_USER_IS_ORKES_USER]: {
            tags: ["loggedUserHasOrganization"],
            initial: AuthProviderStates.LOGGED_USER_FETCH_PROFILE_INFORMATION,
            states: {
              [AuthProviderStates.LOGGED_USER_FETCH_PROFILE_INFORMATION]: {
                tags: ["loading"],
                invoke: {
                  src: "fetchForProfileInfo",
                  onDone: {
                    actions: "persistUserProfiles",
                    target: "idle",
                  },
                  onError: {
                    actions: "setErrorMessage",
                    target: "idle",
                  },
                },
              },
              idle: {
                on: {
                  [AuthProviderEventTypes.SWITCH_CURRENT_PROFILE]: {
                    target: AuthProviderStates.SWITCH_CURRENT_PROFILE,
                  },
                },
              },
              [AuthProviderStates.SWITCH_CURRENT_PROFILE]: {
                tags: ["loading"],
                invoke: {
                  src: "switchCurrentProfile",
                  onDone: {
                    target:
                      AuthProviderStates.LOGGED_USER_FETCH_PROFILE_INFORMATION,
                  },
                  onError: {
                    actions: "setErrorMessage",
                    target: "idle",
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    actions: actions as any,
    services: services as any,
    guards: guards as any,
  }
);
