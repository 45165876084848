import React from "react";
import { ThemeProvider } from "@mui/material";
import { theme } from "./";

export const Provider = ({ children, ...rest }) => {
  return (
    <ThemeProvider theme={theme} {...rest}>
      {children}
    </ThemeProvider>
  );
};
