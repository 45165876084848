import LoginBackground from "images/login_background.png";

export const commonStyle = {
  container: {
    backgroundImage: `url(${LoginBackground})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    mx: "auto",
    mt: 5,
    mb: 15,
    width: "100%",
    maxWidth: 1400,
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: 20,
    mt: 20,
  },
  paperStyle: {
    maxWidth: 420,
    width: "100%",
    my: 4,
    textAlign: "center",
    pb: 2,
    background: "#FAFAFA",
    borderRadius: 4,
  },
  linkStyle: {
    fontWeight: "700",
    textDecoration: "none",
    color: "#1976D2",
  },
  goBackBtnStyle: {
    fontWeight: "700",
    textDecoration: "none",
    color: "#1976D2",
    display: "flex",
    alignItems: "center",
    width: "100%",
    maxWidth: "420px",
    cursor: "pointer",
  },
  freeTrialStyle: {
    container: { display: "flex", flexDirection: "column", gap: 2, mt: 25 },
    title: {
      textAlign: "center",
      fontSize: 28,
      fontWeight: 500,
      color: "#4e5456",
    },
    subTitle: { textAlign: "center", fontWeight: 300, color: "#313435" },
    featureContainer: { my: 8, display: "flex", justifyContent: "center" },
    featureItem: { display: "flex", gap: 4, alignItems: "center", mb: 3 },
    featureText: { fontWeight: 300, color: "#677172" },
    linkText: { fontWeight: 600, color: "#2868f7", cursor: "pointer" },
  },
};

export const freeTrialFeatures = [
  "All Conductor OSS features",
  "All Enterprise Base features",
  "Enterprise Add-ons",
];
