import { auth, useAuth } from "auth/AuthContext";
import { useAuthMachine } from "auth/state/hook";
import { AuthProviderEventTypes } from "auth/state/types";
import { isSignInWithEmailLink } from "firebase/auth";
import { useCallback, useEffect, useState } from "react";

export const useLogin = (signup = false) => {
  const { authService } = useAuth();
  const [
    {
      isFormFieldsActive,
      isPasswordLess,
      isSignupSection,
      isLoginSection,
      isLoadingState,
      isMagicLinkRequestPending,
      isResetPasswordSection,
      messageType,
      isSubmittingNewPassword,
      inviteId,
    },
    {
      continueWithEmail,
      goBackToLoginSection,
      attemptToSendEmailLink,
      showForgotPasswordSection,
    },
  ] = useAuthMachine(authService);

  const [formState, setFormState] = useState({
    userEmail: "",
    userPassword: "",
    isPasswordVisible: false,
  });

  const { userEmail, userPassword, isPasswordVisible } = formState;

  const updateInputValue = (field: string) => (val: string) =>
    setFormState((prev) => ({ ...prev, [field]: val }));

  const handleEmailInput = updateInputValue("userEmail");
  const handlePasswordInput = updateInputValue("userPassword");

  const togglePasswordVisibility = () =>
    setFormState((prev) => ({
      ...prev,
      isPasswordVisible: !prev.isPasswordVisible,
    }));

  useEffect(() => {
    const emailFromLocalStorage = localStorage.getItem("emailForSignIn");
    if (
      auth &&
      isSignInWithEmailLink(auth, window.location.href) &&
      emailFromLocalStorage
    ) {
      authService.send({
        type: AuthProviderEventTypes.SIGN_IN_WITH_EMAIL_LINK_EVENT,
        email: emailFromLocalStorage,
        pageUrl: window.location.href,
      });
    }
  }, [authService]);

  //   sign in with google
  const attemptLoginWithGoogle = () => {
    authService.send({
      type: AuthProviderEventTypes.CONTINUE_WITH_GOOGLE_EVENT,
    });
  };

  const switchToSignupSection = useCallback(() => {
    authService.send({
      type: AuthProviderEventTypes.SWITCH_TO_SIGNUP_EVENT,
    });
  }, [authService]);

  const switchToLoginSection = () => {
    authService.send({
      type: AuthProviderEventTypes.SWITCH_TO_LOGIN_EVENT,
    });
  };

  // toggle login-signup
  const toggleSignupHandler = () => {
    if (isLoginSection) {
      switchToSignupSection();
    } else {
      switchToLoginSection();
    }
  };

  // if signup route, show signup section
  useEffect(() => {
    if (signup) {
      switchToSignupSection();
    }
  }, [signup, switchToSignupSection]);

  // on form submit
  const handleLoginOrSignup = () => {
    if (isPasswordLess) {
      attemptToSendEmailLink(userEmail);
    } else if (isSignupSection) {
      handleSignup(userEmail, userPassword);
    } else {
      handleLogin();
    }
  };

  // toggle password to password less form
  const switchToFormWithPassword = () => {
    authService.send({
      type: AuthProviderEventTypes.SWITCH_TO_PASSWORD_EVENT,
    });
  };

  const switchToPasswordLessForm = () => {
    authService.send({
      type: AuthProviderEventTypes.SWITCH_TO_PASSWORD_LESS_EVENT,
    });
  };

  const togglePasswordLessLogin = () => {
    if (isPasswordLess) {
      switchToFormWithPassword();
    } else {
      switchToPasswordLessForm();
    }
  };

  // sign up with email-password handler
  const handleSignup = (email: string, password: string) => {
    authService.send({
      type: AuthProviderEventTypes.SIGNUP_WITH_PASSWORD_EVENT,
      data: {
        email,
        password,
      },
    });
  };

  const resendVerificationEmail = () => {
    authService.send({
      type: AuthProviderEventTypes.RESEND_VERIFICATION_EMAIL_EVENT,
    });
  };

  //   login with email-password
  const handleLogin = async (emailParam?: string, passwordParam?: string) => {
    authService.send({
      type: AuthProviderEventTypes.LOGIN_WITH_PASSWORD_EVENT,
      data: {
        email: emailParam ?? userEmail,
        password: passwordParam ?? userPassword,
      },
    });
  };

  const resendMagicLink = () => {
    const emailFromLocalStorage = localStorage.getItem("emailForSignIn");
    if (emailFromLocalStorage) {
      authService.send({
        type: AuthProviderEventTypes.SEND_MAGIC_LINK_EVENT,
        email: emailFromLocalStorage,
      });
    }
  };

  const triggerResetPasswordEmail = () => {
    if (userEmail) {
      authService.send({
        type: AuthProviderEventTypes.SEND_RESET_PASSWORD_EVENT,
        email: userEmail,
      });
    }
  };

  const submitResetPassword = (
    email: string,
    password: string,
    token: string
  ) => {
    authService.send({
      type: AuthProviderEventTypes.SUBMIT_NEW_PASSWORD_EVENT,
      data: {
        email,
        password,
        token,
      },
    });
  };

  return [
    {
      userEmail,
      userPassword,
      isSignUpActive: isSignupSection || signup,
      isPasswordLess,
      messageType,
      isLoading: isLoadingState,
      isButtonLoading: isMagicLinkRequestPending,
      isFormFieldsActive,
      isResetPasswordSection,
      isPasswordVisible,
      isSubmittingNewPassword,
      inviteId,
    },
    {
      resendMagicLink,
      handleLoginOrSignup,
      toggleSignupHandler,
      handleEmailInput,
      handlePasswordInput,
      togglePasswordLessLogin,
      attemptLoginWithGoogle,
      goBackToLoginSection,
      showFormField: continueWithEmail,
      showForgotPasswordSection,
      triggerResetPasswordEmail,
      handleLogin,
      togglePasswordVisibility,
      resendVerificationEmail,
      submitResetPassword,
    },
  ] as const;
};
