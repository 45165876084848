import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import AlertDialog from "components/AlertDialog";
import { IResourceType, RolloutRestartType } from "utils/saastypes";

const RolloutRestartDialog = ({
  textTitle,
  textContent,
  open,
  setOpen,
  isConfirmDisabled,
  handleConfirm,
  selectedResourceType,
  setSelectedResourceType,
  resourceTypeOptions,
}: {
  textTitle: string;
  textContent: string;
  open: boolean;
  setOpen: () => void;
  isConfirmDisabled: boolean;
  handleConfirm: () => void;
  selectedResourceType: IResourceType;
  setSelectedResourceType: (type: IResourceType) => void;
  resourceTypeOptions: IResourceType[];
}) => {
  return (
    <AlertDialog
      textTitle={textTitle}
      textContent={textContent}
      open={open}
      setOpen={setOpen}
      isConfirmDisabled={isConfirmDisabled}
      handleConfirm={handleConfirm}
    >
      <Box sx={{ minWidth: 120, marginTop: "16px", display: "flex" }}>
        <Box style={{ width: "100%" }}>
          <FormControl fullWidth>
            <InputLabel id="rollout-resource-type-label">
              Resource Type
            </InputLabel>
            <Select
              labelId="rollout-resource-type-label"
              id="rollout-resource-type-select"
              value={selectedResourceType.value}
              label="Resource Type"
              onChange={(ev) => {
                setSelectedResourceType({
                  value: ev.target.value as RolloutRestartType,
                  name: resourceTypeOptions.find(
                    (x: any) => x.value === ev.target.value
                  )!.name,
                });
              }}
            >
              {resourceTypeOptions.map((x: any) => (
                <MenuItem key={x.value} value={x.value}>
                  {x.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </AlertDialog>
  );
};

export default RolloutRestartDialog;
