import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import ActionButton from "../buttons/ActionButton";
import * as yup from "yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const REPEATER_KEY_DOMAINS = "domains";

interface IFormData {
  domain: string;
}

export const DOMAIN_REGEX =
  /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]?\.(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/;

export default function PermitDomainDialog({
  handleAdd,
  handleClose,
  progressPending = false,
  open,
}: {
  handleAdd: (domains: { domain: string }[]) => void;
  handleClose: () => void;
  progressPending?: boolean;
  open: boolean;
}) {
  const domainSchema = yup.object().shape({
    domain: yup
      .string()
      .required("Domain is required")
      .matches(DOMAIN_REGEX, "Domain is invalid")
      .test("domain", "Domain need to be unique", function (value) {
        const parentValues =
          // @ts-ignore
          this.options?.from?.[1]?.value?.[REPEATER_KEY_DOMAINS];

        return (
          parentValues.filter((item: IFormData) => item.domain === value)
            ?.length === 1
        );
      }),
  });
  const formSchema = yup.object().shape({
    [REPEATER_KEY_DOMAINS]: yup.array().of(domainSchema),
  });
  const defaultValues = {
    [REPEATER_KEY_DOMAINS]: [{ domain: "" }],
  };
  const {
    control,
    formState: { errors: formErrors },
    handleSubmit,
    trigger,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(formSchema),
    defaultValues,
  });
  const {
    fields: domainFields,
    append: appendDomain,
    remove: removeDomain,
  } = useFieldArray({
    control,
    name: REPEATER_KEY_DOMAINS,
  });

  const onSubmit = (data: { domains: IFormData[] }) => {
    handleAdd(data.domains);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle>Permit Domains</DialogTitle>
      <DialogContent>
        <form>
          <Grid container spacing={2} sx={{ paddingTop: 2 }}>
            {domainFields.map((user, index) => {
              const domainKey = `${REPEATER_KEY_DOMAINS}[${index}].domain`;

              const errorMessage =
                formErrors && formErrors[REPEATER_KEY_DOMAINS]
                  ? (formErrors[REPEATER_KEY_DOMAINS] as any)?.[index]
                  : null;

              return (
                <Grid item xs={12} key={user.id}>
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      <FormControl fullWidth>
                        <Controller
                          control={control}
                          // @ts-ignore
                          name={domainKey}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              required
                              label="Domain"
                              placeholder="Domain"
                              error={!!errorMessage?.domain?.message}
                              helperText={errorMessage?.domain?.message}
                            />
                          )}
                          rules={{ required: true }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        title={"Remove"}
                        sx={{ marginTop: 1 }}
                        onClick={async () => {
                          removeDomain(index);
                          await trigger(REPEATER_KEY_DOMAINS);
                        }}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
            <Grid item md={1}>
              <IconButton
                title={"Permit email"}
                onClick={() => appendDomain({ domain: "" })}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClose}
          style={{ margin: 8, fontSize: 14, textTransform: "none" }}
          sx={{
            display: "inline",
            mx: 0.5,
          }}
        >
          Cancel
        </Button>
        <ActionButton
          variant="contained"
          color="primary"
          disabled={domainFields.length <= 0}
          progress={progressPending}
          onClick={() => {
            // @ts-ignore
            handleSubmit(onSubmit)();
          }}
          style={{ margin: 8, fontSize: 14, textTransform: "none" }}
          sx={{
            display: "inline",
            mx: 0.5,
          }}
        >
          Permit Domain{domainFields.length > 1 ? "s" : ""}
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
}
