import { Box, Button, TextField, Typography } from "@mui/material";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { ChangeEvent, FormEvent } from "react";
import { cardBrands, cardElementOption, paymentFormStyle } from "./common";
import { Stripe, StripeElements } from "@stripe/stripe-js";

export const PaymentForm = ({
  cardName,
  isSubmitBtnDisabled,
  isSetupIntentProgress,
  handleSubmit,
  handleNameChange,
}: {
  cardName: string;
  isSubmitBtnDisabled: boolean;
  isSetupIntentProgress: boolean;
  handleSubmit: (
    e: FormEvent<HTMLFormElement>,
    stripe: Stripe | null,
    elements: StripeElements | null
  ) => void;
  handleNameChange: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const stripe = useStripe();
  const elements = useElements();

  return (
    <form id="payment-form" onSubmit={(e) => handleSubmit(e, stripe, elements)}>
      <Box sx={{ mb: 5 }}>
        <Typography sx={paymentFormStyle.typographyLabel}>
          Cardholder Name
        </Typography>
        <TextField
          type="text"
          placeholder="John Doe"
          name="cardHolderName"
          fullWidth
          value={cardName}
          onChange={handleNameChange}
          required
          sx={paymentFormStyle.cardNameField}
        />
      </Box>

      <Typography sx={paymentFormStyle.typographyLabel}>Card Info</Typography>
      <Box style={paymentFormStyle.cardElementContainer}>
        <CardElement id="card" options={cardElementOption} />
      </Box>

      <Box sx={{ my: 5, display: "flex", gap: 1.5 }}>
        {cardBrands.map((card) => (
          <span
            style={{ height: "auto", margin: "4px", width: 45 }}
            key={card.title}
          >
            <img
              src={card.icon}
              alt=""
              style={{ width: 45, verticalAlign: "middle" }}
            />
          </span>
        ))}
      </Box>
      <Button
        disabled={isSubmitBtnDisabled}
        id="submit"
        sx={paymentFormStyle.submitPaymentBtn}
        type="submit"
        variant="contained"
      >
        {isSetupIntentProgress ? "Request in progress..." : "Save"}
      </Button>
    </form>
  );
};
