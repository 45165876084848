import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ActionButton from "../buttons/ActionButton";
import Input from "../Input";

export default function AddOrganizationDialog({
  handleAdd,
  handleClose,
  progressPending = false,
}: {
  handleAdd: (organizationName: string) => void;
  handleClose: () => void;
  progressPending?: boolean;
}) {
  const [organizationName, setOrganizationName] = useState<string>("");

  return (
    <Dialog fullWidth maxWidth="sm" open onClose={handleClose}>
      <DialogTitle>Add Organization</DialogTitle>
      <DialogContent>
        <Box mt={4}>
          <Input
            onBlur={(value) => {
              setOrganizationName(value);
            }}
            label="Organization Name"
            fullWidth
            required
            helperText={"Name of the organization"}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClose}
          style={{ margin: 8, fontSize: 14, textTransform: "none" }}
          sx={{
            display: "inline",
            mx: 0.5,
          }}
        >
          Cancel
        </Button>
        <ActionButton
          variant="contained"
          color="primary"
          progress={progressPending}
          onClick={() => handleAdd(organizationName)}
          style={{ margin: 8, fontSize: 14, textTransform: "none" }}
          sx={{
            display: "inline",
            mx: 0.5,
          }}
        >
          Add Organization
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
}
