export enum ModalStep {
  PERSONAL_INFO_STEP = 0,
  PHONE_VERIFICATION_STEP = 1,
  ASSIGN_TRIAL_CLUSTER_CONFIRMATION_STEP = 3,
}

export const stepTitles = {
  [ModalStep.PERSONAL_INFO_STEP]: {
    title: "Complete Your Account",
    description: "Please complete the following fields.",
  },
  [ModalStep.PHONE_VERIFICATION_STEP]: {
    title: "Phone Verification",
    description: "Please copy/paste the verification code.",
  },
  [ModalStep.ASSIGN_TRIAL_CLUSTER_CONFIRMATION_STEP]: {
    title: "Cluster successfully assigned!",
    description: "Let's explore all the features that you've unlocked.",
  },
};

export const regions = [
  {
    label: "Europe",
    value: "EU",
  },
  {
    label: "North America",
    value: "AME",
  },
  {
    label: "APAC",
    value: "APAC",
  },
];

export const modalStyle = {
  inputStyle: {
    minWidth: "90%",
    marginTop: "20px",
  },
  dialogTitle: {
    textAlign: "center",
    mt: 3,
    fontSize: "26px",
    color: "rgba(0,0,0, 0.8)",
  },
  dialogContent: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingX: 6,
  },
  btnStyle: {
    my: 2,
    textTransform: "capitalize",
    backgroundColor: "#1976D2",
  },
  regionBox: {
    border: "1px solid #D7DCDD",
    borderRadius: "6px",
    width: "160px",
    height: "110px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    position: "relative",
  },
  selectedRegion: {
    border: "2px solid #1976D2",
  },
};
