import { TabPanel, ColumnHeader } from "./TabPanel";
import { Box, Typography } from "@mui/material";

export default function SplitInfoColumnTab({
  selectedTabIndex,
  index,
  dataPage,
  secondDataPage,
}: {
  selectedTabIndex: number;
  index: number;
  dataPage: any;
  secondDataPage: any;
}) {
  const renderItems = (data: any[]) =>
    data.map((entry: any, index: number, originArr: any[]) => {
      return (
        <Box
          key={entry.name}
          style={{
            width: "100%",
            borderBottom:
              index < originArr.length - 1 ? "1px solid lightgrey" : "none",
          }}
          sx={{ p: 4 }}
        >
          {entry.name && (
            <>
              <ColumnHeader>{entry.name}</ColumnHeader>
              <Typography>{entry.value}</Typography>
            </>
          )}
          {!entry.name && <>{entry.value}</>}
        </Box>
      );
    });

  return (
    <TabPanel value={selectedTabIndex} index={index}>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          borderBottom: "1px solid lightgrey",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            paddingRight: 30,
          }}
        >
          {renderItems(dataPage)}
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            paddingRight: 30,
          }}
        >
          {renderItems(secondDataPage)}
        </Box>
      </Box>
    </TabPanel>
  );
}
