import AlertDialog from "components/AlertDialog";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel,
  Paper,
  Chip,
} from "@mui/material";
import {
  useActionWithPath,
  useClusterVersionHistory,
  useClusterVersionEnvironamentVariables,
} from "utils/query";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomerEnvironment } from "utils/saastypes";
import DataTable from "components/DataTable/DataTable";
import { AGENT_COMMAND_PATH } from "utils/apiPaths";
import { CLUSTER_ACTION_TYPE } from "constants/common";

const RollbackResourceTypes = [
  {
    name: "Conductor",
    value: "CONDUCTOR",
    reference: "conductor",
    resources: ["CONDUCTOR"],
  },
  {
    name: "Workers",
    value: "WORKERS",
    reference: "workers",
    resources: ["WORKERS"],
  },
  {
    name: "Agent",
    value: "AGENT",
    reference: "agent",
    resources: ["AGENT"],
  },
  {
    name: "Metrics Proxy",
    value: "METRICS_PROXY",
    reference: "metrics proxy",
    resources: ["METRICS_PROXY"],
  },
];
const getVersionLabel = (item: any) => {
  const timeformat: any = {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  if (item.timestamp) {
    return `${item.version} - Installed on ${new Date(
      item.timestamp
    ).toLocaleDateString("en-US", timeformat)}`;
  }
  return `${item.version}`;
};
const envstatus = (type: string) => {
  if (type === "NEW") {
    return <Chip label="New" color="success" size="small" />;
  } else if (type === "UPDATED") {
    return <Chip label="Updated" color="primary" size="small" />;
  } else if (type === "DELETED") {
    return <Chip label="Deleted" color="error" size="small" />;
  } else {
    return (
      <Chip label={type} color="primary" variant="outlined" size="small" />
    );
  }
};
const customStyles = {
  header: {
    style: {
      display: "none",
    },
  },
};

const RollbackDeploymentDialog = ({
  clusterName,
  open,
  closeDialog,
  clusterDetail,
  showError,
  showSuccess,
  refetchLogs,
}: {
  clusterName?: string;
  open: boolean;
  closeDialog: () => void;
  clusterDetail: CustomerEnvironment;
  showError: (message: string) => void;
  showSuccess: (message: string) => void;
  refetchLogs: () => void;
}) => {
  const navigate = useNavigate();

  const [selectedResourceType, setSelectedResourceType] = useState(
    RollbackResourceTypes[0].value
  );
  const [currentVersionUUID, setCurrentVersionUUID] = useState("");
  const [includeEnvChanges, setIncludeEnvChanges] = useState(true);
  const handleChangeEnvVariable = (event: any) => {
    setIncludeEnvChanges(event.target.checked);
  };

  const rollBackDeploymentAction = useActionWithPath({
    onSuccess: (data: any) => {
      if (data?.status === "ERROR") {
        showError("Rollback deployment failed");
      } else {
        showSuccess(`Rollback Deployment has been scheduled successfully.`);
        if (data.id) {
          navigate(`/home/clusterDetails/${clusterName}/history`);
        }
      }
      refetchLogs();
    },
    onError: async (response: any) => {
      const json = await response.json();
      showError(`Rollback deployment failed: ${json.message}`);
      refetchLogs();
    },
  });

  const { data: packageVersions, isFetching: isPackageVersionsFetching } =
    useClusterVersionHistory(clusterName, selectedResourceType);

  const { data: envVariables, isFetching: isEnvVariablesFetching } =
    useClusterVersionEnvironamentVariables(clusterName, currentVersionUUID);

  const onSubmit = () => {
    // @ts-ignore
    rollBackDeploymentAction.mutate({
      method: "POST",
      path: AGENT_COMMAND_PATH,
      body: JSON.stringify({
        clusterName: clusterDetail?.name,
        command: CLUSTER_ACTION_TYPE.ROLLBACK_DEPLOYMENT,
        parameters: {
          versionId: currentVersionUUID,
          includeEnvVars: includeEnvChanges,
        },
      }),
    });
    closeDialog();
  };

  const resourceversionlabel = useMemo(() => {
    return (
      RollbackResourceTypes.find((x) => x.value === selectedResourceType)
        ?.name + " version"
    );
  }, [selectedResourceType]);

  const resourceversion = useMemo(() => {
    return currentVersionUUID
      ? packageVersions.find((x: any) => x.id === currentVersionUUID)?.version
      : "";
  }, [currentVersionUUID, packageVersions]);

  useEffect(() => {
    if (packageVersions?.length > 0) {
      setCurrentVersionUUID(packageVersions[0].id);
    } else {
      setCurrentVersionUUID("");
    }
  }, [packageVersions]);

  useEffect(() => {
    if (envVariables?.length === 0) {
      setIncludeEnvChanges(false);
    } else {
      setIncludeEnvChanges(true);
    }
  }, [envVariables]);

  return (
    <AlertDialog
      textTitle={"Rollback Deployment"}
      textContent={``}
      open={open}
      setOpen={() => closeDialog()}
      isConfirmDisabled={
        isPackageVersionsFetching ||
        isEnvVariablesFetching ||
        !currentVersionUUID
      }
      handleConfirm={() => {
        onSubmit();
      }}
    >
      Rollback the cluster <strong>{clusterDetail?.name}</strong> to the
      following Conductor version:
      <form>
        <Box sx={{ minWidth: 120, marginTop: "16px", display: "flex" }}>
          <>
            <Box style={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel id="resource-type-label">Resource Type</InputLabel>
                <Select
                  labelId="resource-type-label"
                  id="resource-type-select"
                  label="Resource Type"
                  value={selectedResourceType}
                  onChange={(event) => {
                    setSelectedResourceType(event.target.value);
                  }}
                >
                  {RollbackResourceTypes.map((x) => (
                    <MenuItem key={x.value} value={x.value}>
                      {x.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                style={{
                  marginTop: 16,
                  marginBottom: 16,
                }}
                fullWidth
              >
                <InputLabel id="conductor-version-label">
                  {resourceversionlabel}
                </InputLabel>
                <Select
                  labelId="conductor-version-label"
                  id="conductor-version-select"
                  label={`${resourceversionlabel}`}
                  value={currentVersionUUID}
                  onChange={(event) => {
                    setCurrentVersionUUID(event.target.value);
                  }}
                >
                  {packageVersions?.map((x: any) => (
                    <MenuItem key={x.id} value={x.id}>
                      {getVersionLabel(x)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {resourceversion && (
                <p style={{ marginTop: 0, marginBottom: 0 }}>
                  Environment variables for {resourceversion}
                </p>
              )}
              {resourceversion && (
                <Box>
                  {envVariables?.length > 0 && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={includeEnvChanges}
                          onChange={handleChangeEnvVariable}
                        />
                      }
                      label="Include changes in the environment variables"
                    />
                  )}
                  <Paper
                    elevation={0}
                    style={{
                      margin: 0,
                    }}
                  >
                    <DataTable
                      customStyles={customStyles}
                      showColumnSelector={false}
                      progressPending={isEnvVariablesFetching}
                      pagination={false}
                      noDataComponent={
                        <Box padding={5} fontWeight={600}>
                          No changes in the environment variables found
                        </Box>
                      }
                      data={envVariables}
                      columns={[
                        {
                          name: "name",
                          label: "Name",
                          selector: (row: any) => row.name,
                          grow: 1,
                        },
                        {
                          name: "value",
                          label: "Value",
                          selector: (row: any) => row.value,
                          grow: 1,
                        },
                        {
                          name: "type",
                          label: " ",
                          selector: (row: any) => row.type,
                          renderer(val: any, row: any) {
                            return <>{envstatus(row.type)}</>;
                          },
                        },
                      ]}
                    />
                  </Paper>
                </Box>
              )}
            </Box>
          </>
        </Box>
      </form>
    </AlertDialog>
  );
};

export default RollbackDeploymentDialog;
