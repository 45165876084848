import { createMachine } from "xstate";
import {
  CheckoutStates,
  CheckoutContext,
  CheckoutEvents,
  CheckoutEventTypes,
} from "./types";
import * as actions from "./actions";
import * as services from "./services";

export const checkoutMachine = createMachine<CheckoutContext, CheckoutEvents>(
  {
    id: "checkoutMachine",
    predictableActionArguments: true,
    initial: CheckoutStates.FETCHING_CLIENT_SECRET,
    context: {
      clientSecret: undefined,
    },

    states: {
      [CheckoutStates.FETCHING_CLIENT_SECRET]: {
        invoke: {
          src: "getClientSecret",
          onDone: {
            actions: "persistClientSecret",
            target: CheckoutStates.IDLE,
          },
          onError: {
            actions: "showErrorMessage",
            target: CheckoutStates.CLIENT_SECRET_NOT_AVAILABLE,
          },
        },
      },
      [CheckoutStates.IDLE]: {
        on: {
          [CheckoutEventTypes.FETCH_SETUP_INTENT_STATUS]: {
            target: CheckoutStates.SETUP_INTENT,
          },
        },
      },
      [CheckoutStates.CLIENT_SECRET_NOT_AVAILABLE]: {
        on: {
          [CheckoutEventTypes.REFETCH_CLIENT_SECRET]: {
            target: CheckoutStates.FETCHING_CLIENT_SECRET,
          },
        },
      },

      [CheckoutStates.SETUP_INTENT]: {
        initial: CheckoutStates.FETCH_SETUP_INTENT_STATUS,
        states: {
          [CheckoutStates.FETCH_SETUP_INTENT_STATUS]: {
            invoke: {
              src: "getSetupIntentStatus",
              onDone: [
                {
                  cond: (_, event) => event.data === "succeeded",
                  actions: ["showSuccessToast", "maybeRefetchVerifyUserStatus"],
                  target: `#checkoutMachine.${CheckoutStates.SETUP_INTENT_SUCCESS}`,
                },
                { target: CheckoutStates.REFETCH_SETUP_INTENT },
              ],
              onError: {
                target: `#checkoutMachine.${CheckoutStates.IDLE}`,
                actions: "showErrorMessage",
              },
            },
          },
          [CheckoutStates.REFETCH_SETUP_INTENT]: {
            after: {
              5000: {
                target: CheckoutStates.FETCH_SETUP_INTENT_STATUS,
              },
            },
          },
        },
      },
      [CheckoutStates.SETUP_INTENT_SUCCESS]: {
        after: {
          5000: {
            actions: [
              "refetchBillingProfile",
              "navigateToHomeOrAccountSetting",
            ],
            target: CheckoutStates.FINAL,
          },
        },
      },
      [CheckoutStates.FINAL]: {
        type: "final",
      },
    },
  },
  {
    actions: actions as any,
    services: services as any,
  }
);
