import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import ActionButton from "../buttons/ActionButton";
import * as yup from "yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const REPEATER_KEY_EMAILS = "emails";

interface IFormData {
  email: string;
}

export default function PermitEmailDialog({
  handleAdd,
  handleClose,
  progressPending = false,
  open,
}: {
  handleAdd: (emails: { email: string }[]) => void;
  handleClose: () => void;
  progressPending?: boolean;
  open: boolean;
}) {
  const emailSchema = yup.object().shape({
    email: yup
      .string()
      .required("Email is required")
      .email("Invalid email")
      .test("name", "User's email need to be unique", function (value) {
        const parentValues =
          // @ts-ignore
          this.options?.from?.[1]?.value?.[REPEATER_KEY_EMAILS];

        return (
          parentValues.filter((item: IFormData) => item.email === value)
            ?.length === 1
        );
      }),
  });
  const formSchema = yup.object().shape({
    [REPEATER_KEY_EMAILS]: yup.array().of(emailSchema),
  });
  const defaultValues = {
    [REPEATER_KEY_EMAILS]: [{ email: "" }],
  };
  const {
    control,
    formState: { errors: formErrors },
    handleSubmit,
    trigger,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(formSchema),
    defaultValues,
  });
  const {
    fields: emailsFields,
    append: appendEmail,
    remove: removeEmail,
  } = useFieldArray({
    control,
    name: REPEATER_KEY_EMAILS,
  });

  const onSubmit = (data: { emails: IFormData[] }) => {
    handleAdd(data.emails);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle>Permit Emails</DialogTitle>
      <DialogContent>
        <form>
          <Grid container spacing={2} sx={{ paddingTop: 2 }}>
            {emailsFields.map((user, index) => {
              const userEmail = `${REPEATER_KEY_EMAILS}[${index}].email`;

              const errorMessage =
                formErrors && formErrors[REPEATER_KEY_EMAILS]
                  ? (formErrors[REPEATER_KEY_EMAILS] as any)?.[index]
                  : null;

              return (
                <Grid item xs={12} key={user.id}>
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      <FormControl fullWidth>
                        <Controller
                          control={control}
                          // @ts-ignore
                          name={userEmail}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              required
                              label="User email"
                              placeholder="User email"
                              error={!!errorMessage?.email?.message}
                              helperText={errorMessage?.email?.message}
                            />
                          )}
                          rules={{ required: true }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        title={"Remove"}
                        color="error"
                        variant="text"
                        sx={{ marginTop: 3, textTransform: "none" }}
                        onClick={async () => {
                          removeEmail(index);
                          await trigger(REPEATER_KEY_EMAILS);
                        }}
                        startIcon={<DeleteForeverIcon />}
                      >
                        Remove
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
            <Grid item md={12}>
              <Button
                color="success"
                variant="text"
                title={"Permit email"}
                onClick={() => appendEmail({ email: "" })}
                startIcon={<AddCircleOutlineIcon />}
                sx={{
                  textTransform: "none",
                }}
              >
                Add User
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClose}
          style={{ margin: 8, fontSize: 14, textTransform: "none" }}
          sx={{
            display: "inline",
            mx: 0.5,
          }}
        >
          Cancel
        </Button>
        <ActionButton
          variant="contained"
          color="primary"
          disabled={emailsFields.length <= 0}
          progress={progressPending}
          onClick={() => {
            // @ts-ignore
            handleSubmit(onSubmit)();
          }}
          style={{ margin: 8, fontSize: 14, textTransform: "none" }}
          sx={{
            display: "inline",
            mx: 0.5,
          }}
        >
          Permit Email{emailsFields.length > 1 ? "s" : ""}
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
}
