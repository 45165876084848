export const UnlockIcon = ({ size = 20, ...props }: any) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 7.15942H7.01V6.17391C7.01 5.38551 7.32 4.64638 7.88 4.08464C8.45 3.5229 9.2 3.21739 10 3.21739C10.98 3.21739 11.9 3.69044 12.46 4.4887C12.62 4.71536 12.93 4.76464 13.16 4.60696C13.39 4.44928 13.44 4.14377 13.28 3.9171C12.53 2.86261 11.3 2.23189 10 2.23189C8.93 2.23189 7.93 2.6458 7.17 3.38493C6.42 4.13391 6 5.11942 6 6.17391V7.15942H5C3.9 7.15942 3 8.04638 3 9.13044V16.029C3 17.113 3.9 18 5 18H15C16.1 18 17 17.113 17 16.029V9.13044C17 8.04638 16.1 7.15942 15 7.15942ZM16 16.029C16 16.571 15.55 17.0145 15 17.0145H5C4.45 17.0145 4 16.571 4 16.029V9.13044C4 8.58841 4.45 8.14493 5 8.14493H15C15.55 8.14493 16 8.58841 16 9.13044V16.029ZM10.41 12.491L11.14 14.6394C11.21 14.8464 11.05 15.0533 10.84 15.0533H9.17C8.95 15.0533 8.8 14.8464 8.87 14.6394L9.6 12.491C9.11 12.3235 8.76 11.88 8.76 11.338C8.76 10.658 9.32 10.1061 10.01 10.1061C10.7 10.1061 11.26 10.658 11.26 11.338C11.26 11.8701 10.91 12.3235 10.42 12.491H10.41ZM18 5.68116C18 5.9571 17.78 6.17391 17.5 6.17391H15.31C15.03 6.17391 14.81 5.9571 14.81 5.68116C14.81 5.40522 15.03 5.18841 15.31 5.18841H17.5C17.78 5.18841 18 5.40522 18 5.68116ZM14.44 4.03536C14.27 3.81855 14.32 3.51304 14.53 3.34551L16.18 2.10377C16.4 1.93623 16.71 1.97565 16.88 2.19246C17.05 2.40928 17 2.71478 16.79 2.88232L15.15 4.1142C15.06 4.18319 14.95 4.21275 14.85 4.21275C14.7 4.21275 14.55 4.14377 14.45 4.01565L14.44 4.03536Z"
      fill="#fff"
    />
  </svg>
);
