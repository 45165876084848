import { Popper, styled } from "@mui/material";
import { ResourceType } from "utils/saastypes";

export const upgradeResourceTypes = [
  {
    name: "Both Conductor Server and Workers",
    value: ResourceType.BOTH_CONDUCTOR_AND_WORKERS,
    reference: "conductor",
    resources: [ResourceType.CONDUCTOR, ResourceType.WORKERS],
  },
  {
    name: "Conductor Server",
    value: ResourceType.CONDUCTOR,
    reference: "conductor",
    resources: [ResourceType.CONDUCTOR],
  },
  {
    name: "Conductor Workers",
    value: ResourceType.WORKERS,
    reference: "workers",
    resources: [ResourceType.WORKERS],
  },
  {
    name: "Conductor Metrics Proxy",
    value: ResourceType.METRICS_PROXY,
    reference: "metricsProxy",
    resources: [ResourceType.METRICS_PROXY],
  },
  {
    name: "Orkes Agent",
    value: ResourceType.AGENT,
    reference: "agent",
    resources: [ResourceType.AGENT],
  },
  {
    name: "Conductor Server, Workers, and Metrics Proxy",
    value: ResourceType.CONDUCTOR_SERVER_WORKERS_AND_METRICS_PROXY,
    reference: "conductor",
    resources: [
      ResourceType.CONDUCTOR,
      ResourceType.WORKERS,
      ResourceType.METRICS_PROXY,
    ],
  },
];

export const resourceTypesToValidate = [
  ResourceType.WORKERS,
  ResourceType.METRICS_PROXY,
  ResourceType.BOTH_CONDUCTOR_AND_WORKERS,
  ResourceType.CONDUCTOR_SERVER_WORKERS_AND_METRICS_PROXY,
  ResourceType.CONDUCTOR,
  ResourceType.AGENT,
];
export const StyledPopper = styled(Popper)(({ theme }) => ({
  "& .MuiAutocomplete-groupLabel": {
    color: theme.palette.text.primary,
  },
}));
export const PARAMETERS_KEY = "parameters";

export function isMajorVersionUpgrade(
  newVersion: string,
  currentVersion: string
) {
  // Extract major version from the version string
  const extractMajorVersion = (version: string) =>
    version ? parseInt(version.split(".")[0], 10) : null;

  const newMajorVersion = extractMajorVersion(newVersion);
  const currentMajorVersion = extractMajorVersion(currentVersion);
  if (newMajorVersion && currentMajorVersion)
    return newMajorVersion > currentMajorVersion;
}
