import { Outlet } from "react-router-dom";
import PermittedManagementTabs from "pages/PermittedManagement/PermittedManagementTabs";
import { Box } from "@mui/material";

const PermittedManagement = () => {
  return (
    <Box sx={{ padding: 5, paddingTop: 1.5 }}>
      <PermittedManagementTabs />

      <Outlet />
    </Box>
  );
};

export default PermittedManagement;
