const UserIcon = ({ size = 20, ...props }: any) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 0C3.59 0 0 3.59 0 8C0 12.41 3.59 16 8 16C12.41 16 16 12.41 16 8C16 3.59 12.41 0 8 0ZM8 1C11.86 1 15 4.14 15 8C15 9.52 14.5 10.93 13.67 12.08C13.19 9.96 11.59 8.68 10.65 8.1C10.91 7.65 11.07 7.13 11.07 6.57C11.07 4.87 9.69 3.49 7.99 3.49C6.29 3.49 4.91 4.87 4.91 6.57C4.91 7.13 5.07 7.65 5.33 8.1C4.39 8.68 2.79 9.96 2.31 12.08C1.48 10.93 0.98 9.52 0.98 8C0.98 4.14 4.12 1 7.98 1H8ZM5.92 6.57C5.92 5.42 6.85 4.49 8 4.49C9.15 4.49 10.08 5.42 10.08 6.57C10.08 7.72 9.15 8.65 8 8.65C6.85 8.65 5.92 7.72 5.92 6.57ZM3.2 13.08C3.33 10.73 5.14 9.38 5.99 8.88C6.53 9.35 7.23 9.65 8 9.65C8.77 9.65 9.47 9.35 10.01 8.88C10.85 9.38 12.66 10.73 12.8 13.08C11.55 14.27 9.86 15 8 15C6.14 15 4.45 14.27 3.2 13.08Z"
      fill="currentColor"
    />
  </svg>
);

export default UserIcon;
