import { useInterpret, useSelector } from "@xstate/react";
import { checkoutMachine } from "./machine";
import { useLocation, useNavigate } from "react-router";
import { ChangeEvent, FormEvent, useMemo, useState } from "react";
import { CheckoutContext, CheckoutEventTypes, CheckoutStates } from "./types";
import { DoneInvokeEvent, State } from "xstate";
import { useAuth } from "auth/AuthContext";
import { useAuthMachine } from "auth/state/hook";
import { getRouteAndMessage } from "../common";
import { Stripe, StripeCardElement, StripeElements } from "@stripe/stripe-js";
import { CardElement } from "@stripe/react-stripe-js";

export const useCheckoutMachine = () => {
  const { authService, setErrorToast, setSuccessToast, refetchBillingProfile } =
    useAuth();
  const [{ currentUser }, { refetchVerifyUserStatus }] =
    useAuthMachine(authService);

  const location = useLocation();
  const navigate = useNavigate();

  const isAddingPaymentInfo = useMemo(
    () => location.pathname === "/home/add-payment-method",
    [location.pathname]
  );
  const { message, route } = getRouteAndMessage(isAddingPaymentInfo);

  const checkoutService = useInterpret(checkoutMachine, {
    ...(process.env.NODE_ENV === "development" ? { devTools: true } : {}),
    context: {
      clientSecret: undefined,
      accessToken: currentUser.accessToken,
    },
    actions: {
      maybeRefetchVerifyUserStatus: () => {
        if (isAddingPaymentInfo) {
          return refetchVerifyUserStatus();
        }
      },
      showErrorMessage: (_, { data }: DoneInvokeEvent<any>) => {
        setErrorToast(data.message);
      },
      showSuccessToast: () => setSuccessToast(message),
      refetchBillingProfile: () => refetchBillingProfile(),
      navigateToHomeOrAccountSetting: () => navigate(route),
    },
  });

  const clientSecret = useSelector(
    checkoutService,
    (state: State<CheckoutContext>) => state.context.clientSecret
  );

  const isClientSecretLoading = useSelector(
    checkoutService,
    (state: State<CheckoutContext>) =>
      state.matches([CheckoutStates.FETCHING_CLIENT_SECRET])
  );
  const isClientSecretError = useSelector(
    checkoutService,
    (state: State<CheckoutContext>) =>
      state.matches([CheckoutStates.CLIENT_SECRET_NOT_AVAILABLE])
  );

  const refetchClientSecret = () => {
    checkoutService.send({
      type: CheckoutEventTypes.REFETCH_CLIENT_SECRET,
    });
  };

  const handleBack = () =>
    navigate(
      isAddingPaymentInfo
        ? "/home/upgrade"
        : "/home/organization-setting/payments"
    );

  // payment form

  const getSetupIntentStatus = () => {
    checkoutService.send({
      type: CheckoutEventTypes.FETCH_SETUP_INTENT_STATUS,
    });
  };

  const [cardName, setCardName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCardName(e.target.value);
  };

  const handleSubmit = async (
    e: FormEvent<HTMLFormElement>,
    stripe: Stripe | null,
    elements: StripeElements | null
  ) => {
    e.preventDefault();

    if (!stripe || !elements || !clientSecret || !cardName) return;
    setIsLoading(true);

    const payload = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement) as StripeCardElement,
        billing_details: {
          name: cardName,
        },
      },
    });

    if (payload?.error) {
      setErrorToast(payload.error.message);
      setIsLoading(false);
      return;
    }

    if (payload?.setupIntent?.status === "succeeded") {
      getSetupIntentStatus();
    }
  };

  const isSetupIntentProgress =
    useSelector(checkoutService, (state: State<CheckoutContext>) =>
      state.matches([CheckoutStates.SETUP_INTENT])
    ) || isLoading;

  const isSetupIntentSucceeded = useSelector(
    checkoutService,
    (state: State<CheckoutContext>) =>
      state.matches([CheckoutStates.SETUP_INTENT_SUCCESS])
  );

  const isSubmitBtnDisabled = useMemo(() => {
    return isLoading || isSetupIntentProgress || isSetupIntentSucceeded;
  }, [isLoading, isSetupIntentProgress, isSetupIntentSucceeded]);

  return [
    {
      isAddingPaymentInfo,
      clientSecret,
      isClientSecretLoading,
      isClientSecretError,
      isSetupIntentSucceeded,
      isSetupIntentProgress,
      setErrorToast,
      cardName,
      isSubmitBtnDisabled,
    },
    {
      handleBack,
      handleSubmit,
      refetchClientSecret,
      getSetupIntentStatus,
      handleNameChange,
    },
  ] as const;
};
