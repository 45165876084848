import { ReactNode, useCallback, useMemo, useState } from "react";
import RawDataTable from "react-data-table-component";
import { timestampRenderer } from "../../utils/helpers";
import { useLocalStorage } from "../../utils/localstorage";
import _ from "lodash";
import { Box } from "@mui/material";
import { QuickSearch } from "./QuickSearch";
import { createTableTitle, getColumnId, getColumnLabel } from "./utils";
import { ColumnsSelector } from "./ColumnSelector";
import { Filter } from "./Filter";
import { DataTableProps, LegacyColumn } from "./types";

export const DEFAULT_ROWS_PER_PAGE = 15;

export default function DataTable(props: any) {
  const {
    localStorageKey,
    columns,
    customActions,
    data = [],
    defaultShowColumns,
    paginationPerPage = DEFAULT_ROWS_PER_PAGE,
    showFilter = true,
    showColumnSelector = true,
    paginationServer = false,
    title,
    titleComponent,
    noDataComponent,
    onFilterChange,
    onChangeRowsPerPage,
    initialFilterObj,
    paginationRowsPerPageOptions = [15, 30, 100],
    quickSearchEnabled = false,
    quickSearchPlaceholder = "Search",
    quickSearchComponent: QuickSearchComponent = QuickSearch,
    onQuickSearchChange = () => {},
    ...rest
  }: DataTableProps = props;

  const DEFAULT_FILTER_OBJ = {
    columnName: columns.find((col) => col.searchable !== false).name,
    substring: "",
  };

  // If no defaultColumns passed - use all columns
  const defaultColumns = useMemo(
    () =>
      props.defaultShowColumns ||
      props.columns.map((col: LegacyColumn) => col.name),
    [props.defaultShowColumns, props.columns]
  );

  const [tableState, setTableState] = useLocalStorage(
    localStorageKey,
    defaultColumns
  );

  const localStorageKeyTableProps = `tableProps-${localStorageKey}`;
  const tableProperties = {
    paginationPerPage: paginationPerPage,
  };
  const [tablePropertiesState, setTablePropertiesState] = useLocalStorage(
    localStorageKeyTableProps,
    tableProperties
  );

  const [filterObj, setFilterObj] = useState(
    initialFilterObj || DEFAULT_FILTER_OBJ
  );

  const handleFilterChange = (val: string) => {
    setFilterObj((prevFilterObj) => ({
      ...prevFilterObj,
      substring: val,
    }));

    if (onFilterChange) {
      if (!_.isEmpty(val)) {
        onFilterChange({ columnName: filterObj.columnName, substring: val });
      } else {
        onFilterChange(undefined);
      }
    }
  };

  // Append bodyRenderer for date fields;
  const dataTableColumns = useMemo(() => {
    let viewColumns = [];
    if (tableState) {
      for (let col of columns) {
        if (tableState.includes(getColumnId(col))) {
          viewColumns.push(col);
        }
      }
    } else {
      viewColumns = columns;
    }

    return viewColumns.map((column) => {
      let {
        id,
        name,
        label,
        type,
        renderer,
        wrap = true,
        sortable = true,
        ...rest
      } = column;

      const internalOptions: {
        format?: (row: string) => string | boolean;
      } = {};
      if (type === "date") {
        internalOptions.format = (row: string) =>
          timestampRenderer(_.get(row, name));
      } else if (type === "json") {
        internalOptions.format = (row: string) =>
          JSON.stringify(_.get(row, name));
      }

      if (renderer) {
        internalOptions.format = (row: string) =>
          renderer(_.get(row, name), row);
      }

      return {
        id: getColumnId(column),
        selector: name,
        name: getColumnLabel(column),
        sortable: sortable,
        wrap: wrap,
        type,
        ...internalOptions,
        ...rest,
      };
    });
  }, [tableState, columns]);

  // quick search
  const [searchTerm, setSearchTerm] = useState<string>("");
  const handleQuickSearchChange = useCallback(
    (value: string) => {
      setSearchTerm(value);
      onQuickSearchChange(value);
    },
    [onQuickSearchChange]
  );

  const searchTermMaybeFilterFn = useMemo(() => {
    if (!quickSearchEnabled) return _.stubTrue; // If quick search not enabled return true.

    const searchableColumns = columns.reduce(
      (result: string[], col: LegacyColumn): string[] => {
        if (col.searchable !== false) {
          return result.concat(col.name);
        }

        return result;
      },
      []
    );

    return (row: any) => {
      const rowSearchableColumns = Object.keys(row).filter((key) =>
        searchableColumns.includes(key)
      );
      return rowSearchableColumns.reduce((prev, curr) => {
        const fCol = columns.find((column) => column.name === curr);
        const rowVal = row[curr];

        const searchableFuncRes =
          fCol?.searchableFunc != null
            ? fCol
                ?.searchableFunc(rowVal)!
                .toLowerCase()
                .includes(searchTerm?.toLowerCase())
            : rowVal
                .toString()
                .toLowerCase()
                .includes(searchTerm?.toLowerCase());

        return searchableFuncRes || prev;
      }, false);
    };
  }, [columns, quickSearchEnabled, searchTerm]);

  const filteredItems = useMemo(() => {
    if (filterObj !== undefined) {
      const column = dataTableColumns.find(
        (col) => col.id === filterObj.columnName
      ); // This will search on all columns regardless of the column being omitted
      if (!filterObj.substring || !filterObj.columnName) {
        return data?.filter(searchTermMaybeFilterFn);
      } else {
        try {
          const regexp = new RegExp(filterObj.substring, "i");
          const filterObjFilterFn = (row: string, rowIdx: number) => {
            let target;
            if (
              !_.isNil(column?.type) &&
              (column.type === "json" ||
                column.type === "date" ||
                column.searchable === "calculated")
            ) {
              target = column.format(row, rowIdx);

              if (!_.isString(target)) {
                target = JSON.stringify(target);
              }
            } else {
              target = column?.selector(row, rowIdx);
            }

            return _.isString(target) && regexp.test(target);
          };

          return data.filter(
            (row: string, idx: number) =>
              filterObjFilterFn(row, idx) && searchTermMaybeFilterFn(row)
          );
        } catch (e) {
          return [];
        }
      }
    }

    return data.filter(searchTermMaybeFilterFn);
  }, [filterObj, data, searchTermMaybeFilterFn, dataTableColumns]);

  return (
    <>
      {quickSearchEnabled && (
        <QuickSearchComponent
          quickSearchPlaceholder={quickSearchPlaceholder}
          searchTerm={searchTerm}
          onChange={handleQuickSearchChange}
        />
      )}
      <RawDataTable
        title={
          titleComponent ? (
            titleComponent
          ) : (
            <Box id="data-table-title" style={{ fontSize: "14px" }}>
              {title
                ? title
                : createTableTitle({ filteredData: filteredItems, data })}
            </Box>
          )
        }
        columns={dataTableColumns}
        data={filteredItems}
        pagination
        paginationServer={paginationServer}
        paginationPerPage={tablePropertiesState.paginationPerPage}
        onChangeRowsPerPage={(rowsPerPage) => {
          setTablePropertiesState((prevState: any) => ({
            ...prevState,
            paginationPerPage: rowsPerPage,
          }));
          if (onChangeRowsPerPage) {
            onChangeRowsPerPage(rowsPerPage);
          }
        }}
        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
        noDataComponent={noDataComponent}
        actions={
          <>
            {customActions &&
              customActions.length > 0 &&
              customActions.map((component: ReactNode) => component)}
            {!paginationServer && showFilter && (
              <Filter
                columns={columns}
                filterObj={filterObj}
                setFilterObj={handleFilterChange}
              />
            )}
            {showColumnSelector && (
              <ColumnsSelector
                columns={columns}
                selected={tableState}
                setSelected={setTableState}
                defaultColumns={defaultColumns}
              />
            )}
          </>
        }
        {...rest}
      />
    </>
  );
}
