import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import ActionButton from "../buttons/ActionButton";
import * as yup from "yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Role } from "utils/saastypes";
import { hasAnyRole } from "utils/utils";
import { useMemo } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const USER_ROLES = [
  { id: Role.COMPANY_USER, label: "User" },
  { id: Role.COMPANY_ROOT, label: "Root" },
  { id: Role.COMPANY_ADMIN, label: "Admin" },
];
const REPEATER_KEY_USERS = "users";

interface IFormData {
  name: string;
  role: typeof USER_ROLES[0];
}

export default function InviteUserToOrgDialog({
  handleAdd,
  handleClose,
  progressPending = false,
  open,
  currentUserRoles,
}: {
  handleAdd: (userList: any[]) => void;
  handleClose: () => void;
  progressPending?: boolean;
  open: boolean;
  currentUserRoles?: Role[];
}) {
  const allowedRoleOptions = useMemo(
    () =>
      currentUserRoles && hasAnyRole(currentUserRoles, [Role.COMPANY_ROOT])
        ? USER_ROLES
        : [USER_ROLES[0]],
    [currentUserRoles]
  );
  const userSchema = yup.object().shape({
    name: yup
      .string()
      .required("Email is required")
      .email("Invalid email")
      .test("name", "User's email need to be unique", function (value) {
        const parentValues =
          // @ts-ignore
          this.options?.from?.[1]?.value?.[REPEATER_KEY_USERS];

        return (
          parentValues.filter((item: IFormData) => item.name === value)
            ?.length === 1
        );
      }),
    role: yup
      .object()
      .shape({
        id: yup.string(),
        label: yup.string(),
      })
      .typeError("Role is required")
      .required("Role is required"),
  });
  const formSchema = yup.object().shape({
    [REPEATER_KEY_USERS]: yup.array().of(userSchema),
  });
  const defaultValues = {
    [REPEATER_KEY_USERS]: [{ name: "", role: allowedRoleOptions[0] }],
  };
  const {
    control,
    formState: { errors: formErrors },
    handleSubmit,
    trigger,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(formSchema),
    defaultValues,
  });
  const {
    fields: usersFields,
    append: appendUser,
    remove: removeUser,
  } = useFieldArray({
    control,
    name: REPEATER_KEY_USERS,
  });

  const onSubmit = (data: { users: IFormData[] }) => {
    const usersToInvite = data.users.map((user) => {
      return {
        ...user,
        name: encodeURIComponent(user.name),
      };
    });

    handleAdd(usersToInvite);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle>Invite Users</DialogTitle>
      <DialogContent>
        <form>
          <Grid container spacing={2} sx={{ paddingTop: 2 }}>
            {usersFields.map((user, index) => {
              const userEmail = `${REPEATER_KEY_USERS}[${index}].name`;
              const userRole = `${REPEATER_KEY_USERS}[${index}].role`;
              const errorMessage =
                formErrors && formErrors[REPEATER_KEY_USERS]
                  ? (formErrors[REPEATER_KEY_USERS] as any)?.[index]
                  : null;

              return (
                <Grid item md={12} key={user.id}>
                  <Grid container spacing={2}>
                    <Grid item md={5}>
                      <FormControl fullWidth>
                        <Controller
                          control={control}
                          // @ts-ignore
                          name={userEmail}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              required
                              label="User email"
                              placeholder="User email"
                              error={!!errorMessage?.name?.message}
                              helperText={errorMessage?.name?.message}
                            />
                          )}
                          rules={{ required: true }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={5}>
                      <FormControl fullWidth>
                        <Controller
                          control={control}
                          // @ts-ignore
                          name={userRole}
                          render={({ field }) => (
                            // @ts-ignore
                            <Autocomplete
                              {...field}
                              id="userRole"
                              isOptionEqualToValue={(
                                option: any,
                                value: any
                              ) => {
                                return option.id === value.id;
                              }}
                              options={allowedRoleOptions}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Role"
                                  error={!!errorMessage?.role?.message}
                                  helperText={errorMessage?.role?.message}
                                />
                              )}
                              onChange={(event, item: any) => {
                                field.onChange(item);
                              }}
                            />
                          )}
                          rules={{ required: true }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={2}>
                      <Button
                        title={"Remove"}
                        color="error"
                        sx={{ marginTop: 3, textTransform: "none" }}
                        onClick={async () => {
                          removeUser(index);
                          await trigger(REPEATER_KEY_USERS);
                        }}
                        startIcon={<DeleteForeverIcon />}
                      >
                        Remove
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
            <Grid item md={12}>
              <Button
                title={"Add to organization"}
                onClick={() => appendUser({ name: "", role: USER_ROLES[0] })}
                startIcon={<AddCircleOutlineIcon />}
                color="success"
                sx={{
                  textTransform: "none",
                }}
              >
                Add User
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClose}
          style={{ margin: 8, fontSize: 14, textTransform: "none" }}
          sx={{
            display: "inline",
            mx: 0.5,
          }}
        >
          Cancel
        </Button>
        <ActionButton
          variant="contained"
          color="primary"
          disabled={usersFields.length <= 0}
          progress={progressPending}
          onClick={() => {
            // @ts-ignore
            handleSubmit(onSubmit)();
          }}
          style={{ margin: 8, fontSize: 14, textTransform: "none" }}
          sx={{
            display: "inline",
            mx: 0.5,
          }}
        >
          Invite User{usersFields.length > 1 ? "s" : ""}
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
}
