const TokenIcon = ({ size = 20, ...props }: any) => (
  <svg
    width={size - 2}
    height={size}
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.5 0H7.5C3.36 0 0 4.49 0 10C0 15.51 3.36 20 7.5 20H10.5C14.64 20 18 15.51 18 10C18 4.49 14.64 0 10.5 0ZM6.02 2H4.54C5.35 1.42 6.26 1.08 7.23 1.02C6.8 1.3 6.4 1.63 6.03 2H6.02ZM3.42 3H5.15C4.71 3.59 4.33 4.27 4.02 4.99H2.1C2.47 4.24 2.91 3.57 3.42 3ZM1.16 11.99H3.14C3.25 12.69 3.41 13.36 3.62 14H1.67C1.44 13.37 1.26 12.69 1.15 11.99H1.16ZM0.99 10C0.99 9.66 1.01 9.33 1.03 9H3.03C3.01 9.33 2.99 9.66 2.99 10C2.99 10.34 3 10.67 3.03 10.99H1.03C1 10.66 0.99 10.33 0.99 10ZM3.14 8H1.16C1.28 7.29 1.45 6.62 1.68 5.99H3.63C3.42 6.63 3.25 7.3 3.15 8H3.14ZM2.09 15H4.01C4.33 15.72 4.7 16.39 5.14 16.99H3.41C2.9 16.42 2.46 15.75 2.09 15ZM4.51 17.99H6C6.38 18.37 6.78 18.7 7.21 18.98C6.24 18.92 5.33 18.58 4.51 17.99ZM10.48 19C6.9 19 3.98 14.96 3.98 10C3.98 5.04 6.9 1 10.48 1C14.06 1 16.98 5.04 16.98 10C16.98 14.96 14.06 19 10.48 19Z"
      fill="currentColor"
    />
    <path
      d="M12.19 15.2299C11.97 15.4399 11.73 15.6199 11.49 15.7399C11.25 15.8699 11.15 16.1699 11.28 16.4099C11.37 16.5799 11.54 16.6799 11.72 16.6799C11.8 16.6799 11.88 16.6599 11.95 16.6199C12.28 16.4499 12.59 16.2199 12.88 15.9399C13.08 15.7499 13.08 15.4299 12.89 15.2299C12.7 15.0299 12.38 15.0299 12.18 15.2199L12.19 15.2299Z"
      fill="currentColor"
    />
    <path
      d="M13.57 4.86C13.35 4.54 13.11 4.25 12.85 4.01C12.65 3.82 12.33 3.83 12.14 4.03C11.95 4.23 11.96 4.55 12.16 4.74C12.37 4.93 12.56 5.17 12.75 5.43C12.85 5.57 13 5.65 13.16 5.65C13.26 5.65 13.36 5.62 13.44 5.56C13.67 5.4 13.72 5.09 13.57 4.86Z"
      fill="currentColor"
    />
    <path
      d="M14.2 12.5098C13.94 12.4298 13.66 12.5698 13.57 12.8298C13.47 13.1398 13.35 13.4398 13.23 13.7098C13.11 13.9598 13.23 14.2598 13.47 14.3798C13.54 14.4098 13.61 14.4298 13.68 14.4298C13.87 14.4298 14.05 14.3198 14.13 14.1398C14.28 13.8198 14.41 13.4898 14.52 13.1398C14.6 12.8798 14.46 12.5998 14.2 12.5098Z"
      fill="currentColor"
    />
    <path
      d="M14.5 9.47021C14.22 9.47021 14 9.72022 14 9.99022C14 10.3202 13.98 10.6402 13.95 10.9502C13.92 11.2202 14.12 11.4702 14.4 11.5002C14.42 11.5002 14.43 11.5002 14.45 11.5002C14.7 11.5002 14.92 11.3102 14.95 11.0502C14.98 10.7002 15 10.3502 15 9.99022V9.95021C15 9.67021 14.78 9.47021 14.5 9.47021Z"
      fill="currentColor"
    />
    <path
      d="M9.91001 15.9098C9.65001 15.8298 9.40001 15.6998 9.16001 15.5298C8.94001 15.3698 8.62001 15.4198 8.46001 15.6398C8.30001 15.8598 8.35001 16.1798 8.57001 16.3398C8.90001 16.5798 9.26001 16.7598 9.62001 16.8698C9.67001 16.8798 9.72001 16.8898 9.76001 16.8898C9.97001 16.8898 10.17 16.7498 10.24 16.5298C10.32 16.2698 10.17 15.9898 9.91001 15.9098Z"
      fill="currentColor"
    />
    <path
      d="M7.59 13.3C7.49 13.04 7.2 12.92 6.94 13.02C6.68 13.12 6.56 13.41 6.66 13.67C6.79 14.01 6.95 14.34 7.12 14.65C7.21 14.81 7.38 14.9 7.56 14.9C7.64 14.9 7.73 14.88 7.81 14.83C8.05 14.69 8.13 14.39 8 14.15C7.85 13.89 7.71 13.6 7.6 13.3H7.59Z"
      fill="currentColor"
    />
    <path
      d="M10.77 3.00994C10.37 2.97994 9.96 3.01994 9.57 3.14994C9.31 3.22994 9.16 3.51994 9.25 3.77994C9.32 3.98994 9.51 4.12994 9.73 4.12994C9.78 4.12994 9.83 4.12994 9.88 4.10994C10.14 4.02994 10.41 3.98994 10.68 4.01994C10.96 4.03994 11.2 3.83994 11.22 3.56994C11.25 3.28994 11.04 3.04994 10.77 3.02994V3.00994Z"
      fill="currentColor"
    />
    <path
      d="M7.11 11.4601C7.06 11.1501 7.03 10.8301 7.02 10.5001C7.01 10.2201 6.75 10.0001 6.5 10.0301C6.22 10.0401 6.01 10.2801 6.02 10.5501C6.04 10.9101 6.07 11.2601 6.12 11.6101C6.16 11.8601 6.37 12.0401 6.61 12.0401C6.63 12.0401 6.66 12.0401 6.68 12.0401C6.95 12.0001 7.14 11.7501 7.1 11.4701L7.11 11.4601Z"
      fill="currentColor"
    />
    <path
      d="M6.54 8.99985C6.54 8.99985 6.59 8.99985 6.61 8.99985C6.85 8.99985 7.07 8.81985 7.1 8.56985C7.15 8.23985 7.21 7.92985 7.29 7.62985C7.36 7.35985 7.2 7.08985 6.93 7.01985C6.66 6.94985 6.39 7.10985 6.32 7.37985C6.23 7.71985 6.16 8.06985 6.11 8.42985C6.07 8.69985 6.26 8.95985 6.53 8.99985H6.54Z"
      fill="currentColor"
    />
    <path
      d="M7.29001 6.06C7.37001 6.1 7.45001 6.12 7.53001 6.12C7.71001 6.12 7.88001 6.03 7.97001 5.86C8.13001 5.58 8.30001 5.32 8.49001 5.1C8.66001 4.89 8.63001 4.57 8.42001 4.4C8.21001 4.23 7.89001 4.26 7.72001 4.47C7.50001 4.74 7.29001 5.05 7.10001 5.38C6.97001 5.62 7.05001 5.93 7.29001 6.06Z"
      fill="currentColor"
    />
    <path
      d="M13.56 7.10986C13.66 7.40986 13.74 7.70986 13.8 8.02986C13.85 8.26986 14.06 8.42986 14.29 8.42986C14.32 8.42986 14.36 8.42986 14.39 8.41986C14.66 8.35986 14.83 8.09986 14.78 7.82986C14.71 7.46986 14.61 7.12986 14.51 6.79986C14.42 6.53986 14.14 6.38986 13.88 6.47986C13.62 6.55986 13.47 6.84986 13.56 7.10986Z"
      fill="currentColor"
    />
  </svg>
);

export default TokenIcon;
