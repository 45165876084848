import { CSSProperties, FC, ReactNode } from "react";
import { Box } from "@mui/material";

interface SectionHeaderProps {
  title: String;
  actions?: ReactNode;
  // This should be removed once we
  // move the top bar to the shared folder
  // and use the same layout in all apps
  _deprecate_marginTop?: number;
  style?: CSSProperties;
}

const SectionHeader: FC<SectionHeaderProps> = ({
  title,
  actions = null,
  _deprecate_marginTop = 4,
  style = {},
}) => {
  return (
    <Box
      className="pageHeaderSection"
      style={{
        marginTop: _deprecate_marginTop,
        ...style,
      }}
    >
      <Box className="pageHeaderTitle">
        <h2
          style={{
            margin: 0,
            fontSize: "1.5rem",
            fontWeight: 300,
            width: "100%",
            color: "#555",
          }}
        >
          {title}
        </h2>
      </Box>
      <Box className="pageHeaderActions">
        {actions ? <div>{actions}</div> : null}
      </Box>
    </Box>
  );
};

export default SectionHeader;
