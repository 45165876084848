import {
  Tooltip,
  Box,
  Button,
  Chip,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  IntegrationInstructionsOutlined,
  LaptopChromebookOutlined,
  WarningAmber,
} from "@mui/icons-material";
import { useUserIds } from "utils/query";
import { capitalizeFirstLetter, resolveVersion } from "utils/utils";
import StyledMainButton from "../../buttons/StyledMainButton";
import { CLUSTER_STATE } from "constants/common";
import { CustomerEnvironment } from "utils/saastypes";
import {
  bottomSectionFontSize,
  commonStyle,
  getColorFromStateForTrialCluster,
} from "../common";
import { useAuth } from "auth/AuthContext";
import { useAuthMachine } from "auth/state/hook";
import { ProviderLogo, buttonSx, IconFromState } from "../common";

export const TrialClusterCard = (props: {
  custEnv: CustomerEnvironment;
  skipMiddleBar: boolean;
  refetchAllClusters: () => void;
}) => {
  const { data: userData } = useUserIds([props?.custEnv?.createdBy as string]);
  const uiUrl = props.custEnv?.urls?.find(
    (x: any) => x?.urlType === "ConductorUIURL"
  )?.urlValue;
  const swaggerUrl = props.custEnv?.urls?.find(
    (x: any) => x?.urlType === "ConductorSwaggerURL"
  )?.urlValue;

  const { authService } = useAuth();
  const [{ isOrkesUser }] = useAuthMachine(authService);

  const navigate = useNavigate();

  const clusterStateLabel = capitalizeFirstLetter(props.custEnv?.state);

  const isClusterAvailable = props.custEnv?.state === CLUSTER_STATE.PROVISIONED;

  return (
    <Paper
      elevation={2}
      sx={commonStyle.paperSx}
      style={{
        margin: 25,
      }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          marginBottom: 10,
        }}
        className={`clusterTop`}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "start",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <Box
            className={"paperOrgHeader"}
            style={{ fontSize: 18, paddingTop: 15, paddingLeft: 25 }}
          >
            {isOrkesUser ? (
              <Tooltip title={"Cluster Details"}>
                <Button
                  component={Link}
                  to={`/home/clusterDetails/${props.custEnv?.name}`}
                  variant="text"
                  sx={{ ...buttonSx, p: 0, userSelect: "all" }}
                >
                  {props.custEnv?.name}
                  <Typography
                    component="span"
                    sx={{ width: 25, height: 20, ml: 1, mt: -4 }}
                  >
                    <ProviderLogo provider={props.custEnv?.cloudProvider} />
                  </Typography>
                </Button>
              </Tooltip>
            ) : (
              <Button
                variant="text"
                id={`trial-cluster-name-button-${props.custEnv?.name}`}
                sx={{ ...commonStyle.buttonSx, p: 0, userSelect: "all" }}
              >
                {props.custEnv?.name}
              </Button>
            )}
          </Box>
          <Box
            style={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: "25px",
              flexGrow: 1,
            }}
          >
            <Box
              className="ConductorclusterEnv"
              style={{
                fontSize: 15,
                paddingTop: 12,
                paddingLeft: 5,
                marginRight: 15,
                marginTop: 3,
                display: "flex",
                gap: 10,
              }}
            >
              <Chip
                label={"Free Trial"}
                sx={{ ...commonStyle.clickableChipSx, background: "#fcd181" }}
              />
              <Chip
                label={
                  props.custEnv?.state === "SCHEDULE_DELETION"
                    ? "Deletion Scheduled"
                    : clusterStateLabel ?? "NA"
                }
                sx={{
                  ...commonStyle.clickableChipSx,
                  backgroundColor: getColorFromStateForTrialCluster(
                    props.custEnv?.state
                  ),
                }}
                icon={<IconFromState state={props.custEnv?.state} />}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {!props.skipMiddleBar && (
        <>
          <Divider />
          <Box
            style={{
              display: "flex",
              justifyContent: "start",
              minHeight: "fit-content",
              flexWrap: "wrap",
              marginBottom: "22px",
            }}
          >
            <Box
              style={{
                display: "flex",
                width: 250,
                paddingLeft: 30,
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div
                className={"paperOrgHeader"}
                style={{
                  fontSize: bottomSectionFontSize,
                  marginTop: 10,
                  opacity: 0.6,
                }}
              >
                Ingress Type & Links:
              </div>
              <div
                className={"paperOrgHeader"}
                style={{ fontSize: bottomSectionFontSize + 2, opacity: 0.8 }}
              >
                <div>
                  {props.custEnv?.ingressType === "INTERNAL"
                    ? "Internal (VPC)"
                    : "External (Internet)"}
                </div>
                <ul
                  style={{
                    padding: 0,
                    margin: 0,
                    listStyle: "none",
                    marginTop: 20,
                  }}
                >
                  <li>
                    <a
                      target="_blank"
                      rel={"noreferrer noopener"}
                      href={`https://${uiUrl}`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 8,
                        pointerEvents: isClusterAvailable ? "auto" : "none",
                        opacity: isClusterAvailable ? 1 : 0.5,
                      }}
                    >
                      <LaptopChromebookOutlined />
                      &nbsp;Conductor UI
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel={"noreferrer noopener"}
                      href={`https://${swaggerUrl}`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 4,
                        pointerEvents: isClusterAvailable ? "auto" : "none",
                        opacity: isClusterAvailable ? 1 : 0.5,
                      }}
                    >
                      <IntegrationInstructionsOutlined />
                      &nbsp;API Documentation
                    </a>
                  </li>
                </ul>
              </div>
            </Box>
            <Box
              style={{
                display: "flex",
                width: 300,
                paddingLeft: 30,
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div
                className={"paperOrgHeader"}
                style={{
                  fontSize: bottomSectionFontSize,
                  marginTop: 10,
                  opacity: 0.6,
                }}
              >
                Versions:
              </div>
              <div
                className={"paperOrgHeader"}
                style={{
                  fontSize: bottomSectionFontSize + 2,
                  opacity: 0.8,
                }}
              >
                <Typography
                  style={{
                    fontSize: 16,
                    lineHeight: 1.7,
                  }}
                >
                  Conductor Server:{" "}
                  {resolveVersion(props.custEnv?.appVersions?.conductor)}
                </Typography>
                <Typography
                  style={{
                    fontSize: 16,
                    lineHeight: 1.7,
                  }}
                >
                  Conductor Workers:{" "}
                  {resolveVersion(props.custEnv?.appVersions?.workers)}
                </Typography>
                {props.custEnv?.appVersions?.metricsProxy && (
                  <Typography
                    style={{
                      fontSize: 16,
                      lineHeight: 1.7,
                    }}
                  >
                    Metrics Proxy:{" "}
                    {resolveVersion(props.custEnv?.appVersions?.metricsProxy)}
                  </Typography>
                )}
                <Typography
                  style={{
                    fontSize: 16,
                    lineHeight: 1.7,
                  }}
                >
                  Orkes Agent:{" "}
                  {resolveVersion(props.custEnv?.appVersions?.agent)}
                </Typography>
              </div>
            </Box>
            {/*  */}
            <Box
              style={{
                display: "flex",
                width: 200,
                paddingLeft: 30,
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div
                className={"paperOrgHeader"}
                style={{
                  fontSize: bottomSectionFontSize,
                  marginTop: 10,
                  opacity: 0.6,
                }}
              >
                Created:
              </div>

              <div
                className={"paperOrgHeader"}
                style={{ fontSize: bottomSectionFontSize + 1, opacity: 0.8 }}
              >
                <Typography
                  style={{
                    fontSize: 16,
                    lineHeight: 1.7,
                  }}
                >
                  {userData && userData[props.custEnv?.createdBy]
                    ? userData[props.custEnv?.createdBy]?.userEmail
                    : "Unknown"}
                </Typography>

                <Typography
                  style={{
                    fontSize: 16,
                    lineHeight: 1.7,
                  }}
                >
                  {props.custEnv?.createdAt
                    ? new Date(props.custEnv?.createdAt).toLocaleString()
                    : "N/A"}
                </Typography>
              </div>
            </Box>
          </Box>
          <Divider />
        </>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
        className={"clusterBottom"}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 4,
            mr: 5,
            ml: 5,
            width: "100%",
          }}
        >
          <Box>
            <WarningAmber sx={{ fontSize: 18 }} color="info" />
          </Box>
          <Box
            sx={{
              flexGrow: 2,
              fontSize: 12,
              opacity: 0.8,
            }}
          >
            You will need to <b>Sign Up</b> and create an account in your
            cluster.
            <br />
            You must use the <b>same email</b> when signing up.
          </Box>
          <Button
            sx={{
              textTransform: "none",
              height: 40,
            }}
            component={Link}
            variant="outlined"
            to={`https://${uiUrl}`}
            target="blank"
            disabled={!isClusterAvailable}
          >
            Open Cluster
          </Button>
          <StyledMainButton
            size="small"
            color={"inherit"}
            onClick={() => navigate("/home/upgrade")}
            disabled={!isClusterAvailable}
          >
            Upgrade Plan
          </StyledMainButton>
        </Box>
      </Box>
    </Paper>
  );
};
