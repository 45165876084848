import { assign, DoneInvokeEvent } from "xstate";
import {
  HandleCloseModalEvent,
  HandleFieldChangeEvent,
  HandleOpenModalEvent,
  HandlePersistCaptchaEvent,
  HandleSelectedRegionEvent,
  HandleVerificationCodeEvent,
  SetErrorMessageEvent,
  UserInfoModalContext,
} from "./types";

export const openModal = assign<UserInfoModalContext, HandleOpenModalEvent>({
  open: (_context) => true,
});

export const closeModal = assign<UserInfoModalContext, HandleCloseModalEvent>({
  open: (_context) => false,
  captchaValue: undefined,
});

export const clearCaptchaValue = assign<
  UserInfoModalContext,
  HandleCloseModalEvent
>({
  captchaValue: undefined,
});

export const clearErrorMessage = assign<UserInfoModalContext>({
  errorMessage: (_context) => "",
});

export const persistCaptchaValue = assign<
  UserInfoModalContext,
  HandlePersistCaptchaEvent
>({
  captchaValue: (_, { captchaValue }) => captchaValue,
});

export const setErrorMessage = assign<
  UserInfoModalContext,
  SetErrorMessageEvent
>({
  errorMessage: (_context, { data }) => data?.message,
});

export const persistFieldChanges = assign<
  UserInfoModalContext,
  HandleFieldChangeEvent
>(({ userInfo }, { name, value }) => {
  const result = {
    ...userInfo,
    [name]: value,
  };
  return {
    userInfo: result,
  };
});

export const persistVerificationCode = assign<
  UserInfoModalContext,
  HandleVerificationCodeEvent
>({
  verificationCode: (_context, { value }) => value,
});

export const persistSelectedRegion = assign<
  UserInfoModalContext,
  HandleSelectedRegionEvent
>({
  selectedRegion: (_context, { value }) => value,
});

export const setCountDown = assign<
  UserInfoModalContext,
  HandleSelectedRegionEvent
>({
  countDown: (_context) => 60,
});

export const persistUiUrl = assign<
  UserInfoModalContext,
  DoneInvokeEvent<{ result: { conductorUIUrl: string } }>
>({
  uiUrl: (_context, { data: { result } }) => {
    return result.conductorUIUrl;
  },
});

export const decrementCounter = assign<UserInfoModalContext>({
  countDown: (context) => context.countDown - 1,
});
