export const ResentSvg = ({ color = "#1976D2" }: { color?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 2C5.59 2 2 5.59 2 10C2 14.41 5.59 18 10 18C14.41 18 18 14.41 18 10C18 5.59 14.41 2 10 2ZM10 17C6.14 17 3 13.86 3 10C3 6.14 6.14 3 10 3C13.86 3 17 6.14 17 10C17 13.86 13.86 17 10 17ZM15.06 6.83C15.06 6.83 15.06 6.8 15.06 6.79C15.06 6.76 15.06 6.73 15.05 6.71C15.03 6.64 14.99 6.59 14.94 6.54C14.94 6.54 14.94 6.54 14.94 6.53C14.94 6.53 14.93 6.53 14.92 6.52C14.85 6.45 14.76 6.4 14.66 6.39H14.64C14.59 6.39 14.54 6.39 14.49 6.39L5.11 8.21C5.11 8.21 5.06 8.23 5.04 8.24C5.01 8.25 4.98 8.26 4.95 8.28C4.92 8.3 4.89 8.33 4.87 8.35C4.85 8.37 4.83 8.38 4.82 8.4C4.82 8.4 4.82 8.4 4.82 8.41C4.8 8.44 4.79 8.48 4.77 8.51C4.76 8.54 4.74 8.56 4.74 8.59C4.74 8.62 4.74 8.65 4.74 8.68C4.74 8.72 4.74 8.75 4.74 8.78C4.74 8.78 4.74 8.79 4.74 8.8C4.74 8.82 4.76 8.84 4.77 8.87C4.78 8.9 4.79 8.94 4.81 8.97C4.82 8.99 4.84 9.01 4.86 9.03C4.88 9.05 4.9 9.08 4.92 9.1L7.33 10.94V13.13C7.33 13.33 7.45 13.51 7.63 13.59C7.69 13.62 7.76 13.63 7.83 13.63C7.95 13.63 8.08 13.58 8.17 13.5L9.3 12.45L10.72 13.53H10.74C10.75 13.53 10.76 13.55 10.77 13.56C10.85 13.6 10.93 13.63 11.02 13.63C11.11 13.63 11.18 13.61 11.25 13.57C11.27 13.56 11.29 13.54 11.31 13.52C11.35 13.49 11.39 13.47 11.42 13.43C11.42 13.42 11.43 13.4 11.44 13.39C11.44 13.39 11.45 13.38 11.46 13.37L15.04 7.12C15.04 7.12 15.04 7.11 15.04 7.1C15.08 7.02 15.1 6.93 15.09 6.83H15.06ZM7.76 10.03L6.38 8.98L11.5 7.98L7.75 10.03H7.76ZM10.84 12.38L9.54 11.39L8.69 10.74L8.62 10.69L13.25 8.16L10.83 12.38H10.84Z"
        fill={color}
      />
    </svg>
  );
};
