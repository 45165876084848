import AlertDialog from "components/AlertDialog";
import { Box, FormControl, Grid, TextField } from "@mui/material";
import { useActionWithPath } from "utils/query";
import { useNavigate } from "react-router-dom";
import { CustomerEnvironment } from "utils/saastypes";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const ROLE_ARN_REGEX = /arn:aws:iam::[0-9]+:role\/[a-zA-Z0-9_]+/;
const USER_ARN_REGEX = /arn:aws:iam::[0-9]+:user\/[a-zA-Z0-9_]+/;

const AddEKSToAgentDialog = ({
  clusterName,
  open,
  closeDialog,
  clusterDetail,
  showError,
  showSuccess,
}: {
  clusterName?: string;
  open: boolean;
  closeDialog: () => void;
  clusterDetail: CustomerEnvironment;
  showError: (message: string) => void;
  showSuccess: (message: string) => void;
}) => {
  const navigate = useNavigate();

  const formSchema = yup.object().shape(
    {
      roleArn: yup
        .string()
        .matches(ROLE_ARN_REGEX, {
          message: "Role arn is invalid",
          excludeEmptyString: true,
        })
        .when("userArn", {
          is: (value: string) => !value,
          then: (schema) =>
            schema.required("Role must be required if user is empty"),
        }),
      userArn: yup
        .string()
        .matches(USER_ARN_REGEX, {
          message: "User arn is invalid",
          excludeEmptyString: true,
        })
        .when("roleArn", {
          is: (value: string) => !value,
          then: (schema) =>
            schema.required("User must be required if role is empty"),
        }),
    },
    // @ts-ignore
    ["roleArn", "userArn"]
  );
  const defaultValues = {
    roleArn: "",
    userArn: "",
  };
  const {
    control,
    formState: { errors: formErrors },
    handleSubmit,
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(formSchema),
    defaultValues,
  });

  const addEksAdminAction = useActionWithPath({
    onSuccess: (data: any) => {
      if (data?.status === "ERROR") {
        showError("EKS Admin role creation failed");
      } else {
        if (data.id) {
          navigate(`/home/clusterDetails/${clusterName}/history/${data.id}`);
        }
        showSuccess(`EKS Admin addition has been scheduled successfully.`);
      }
    },
    onError: async (response: any) => {
      const json = await response.json();
      showError(`EKS Admin role creation failed: ${json.message}`);
    },
  });

  const onSubmit = (data: { roleArn: string; userArn: string }) => {
    closeDialog();
    // @ts-ignore
    addEksAdminAction.mutate({
      method: "post",
      path: `/agent/command`,
      body: JSON.stringify({
        clusterName: clusterDetail?.name,
        command: "ADD_EKS_ADMIN",
        parameters: {
          rolesArn: data.roleArn ? [data.roleArn] : undefined,
          usersArn: data.userArn ? [data.userArn] : undefined,
        },
      }),
    });
  };

  return (
    <AlertDialog
      textTitle={"Add EKS Administrator"}
      textContent={``}
      open={open}
      setOpen={() => closeDialog()}
      isConfirmDisabled={false}
      handleConfirm={() => {
        // @ts-ignore
        handleSubmit(onSubmit)();
      }}
    >
      <form>
        <Box sx={{ minWidth: 120, marginTop: "16px", display: "flex" }}>
          <>
            <Box style={{ width: "100%" }}>
              <div>
                This feature will allow an AWS IAM Role or AWS IAM User to have
                administrator access to the EKS cluster running Conductor. To do
                this, you will need to fetch the ARN for your role or user from
                the AWS console.
              </div>
              <div style={{ marginTop: "10px" }}>
                Please note that when using a role ARN, you can't use/include
                the path.
              </div>
              <div style={{ marginTop: "5px" }}>
                For example, you can't specify an ARN such as arn:aws:iam::
                <strong style={{ color: "red" }}>111122223333</strong>
                :role/my-team/developers/
                <strong style={{ color: "red" }}>role-name</strong>.
              </div>
              <div style={{ marginTop: "5px" }}>
                The ARN needs to be arn:aws:iam::
                <strong style={{ color: "red" }}>111122223333</strong>
                :role/<strong style={{ color: "red" }}>role-name</strong>{" "}
                instead.
              </div>
              <div style={{ marginTop: "10px" }}>
                Please choose to enter either a role ARN or a user ARN and hit
                confirm to add as administrator:
              </div>
              <Grid container spacing={2} sx={{ marginTop: 1 }}>
                <Grid item md={12}>
                  <FormControl fullWidth>
                    <Controller
                      control={control}
                      name="roleArn"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          multiline
                          required={!watch("userArn")}
                          label="AWS EKS Admin Role"
                          placeholder="arn:aws:iam::<account-id>:role/<role-name>"
                          error={!!formErrors?.roleArn?.message}
                          helperText={formErrors?.roleArn?.message}
                        />
                      )}
                      rules={{ required: false }}
                    />
                  </FormControl>
                  <div style={{ marginTop: "15px", textAlign: "center" }}>
                    OR
                  </div>
                </Grid>

                <Grid item md={12}>
                  <FormControl fullWidth>
                    <Controller
                      control={control}
                      name="userArn"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          multiline
                          required={!watch("roleArn")}
                          label="AWS EKS Admin User"
                          placeholder="arn:aws:iam::<account-id>:user/<user-name>"
                          error={!!formErrors?.userArn?.message}
                          helperText={formErrors?.userArn?.message}
                        />
                      )}
                      rules={{ required: false }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </>
        </Box>
      </form>
    </AlertDialog>
  );
};

export default AddEKSToAgentDialog;
