import { Box, IconButton, Paper } from "@mui/material";
import { Helmet } from "react-helmet";
import StyledMainButton from "components/buttons/StyledMainButton";
import SectionHeader from "shared/SectionHeader";
import { useContext, useState } from "react";
import DataTable from "components/DataTable/DataTable";
import { customStyles } from "utils/utils";
import { DeleteForeverRounded } from "@mui/icons-material";
import { useActionWithPath, useProvisioningPermittedValues } from "utils/query";
import { Message } from "components/SnackbarMessage";
import AlertDialog from "components/AlertDialog";
import LinearProgress from "components/LinearProgress";
import PermitProvisionDialog, {
  AuthRequestType,
  IPermittedProvisioningForm,
} from "components/dialogs/PermitProvisionDialog";
import { MessageContext } from "utils/MessageProvider";

const ProvisioningPermittedList = () => {
  const {
    data: provisioningPermittedValues,
    isFetching: provisioningPermittedValueFetching,
    refetch: refetchProvisioningPermittedValue,
  } = useProvisioningPermittedValues();

  const [openPermitDialog, setOpenPermitDialog] = useState(false);
  const [selectedValue, setSelectedValue] =
    useState<IPermittedProvisioningForm | null>(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const { setMessage } = useContext(MessageContext);

  const showSuccess = (message: string) => {
    setMessage(new Message(message, "success"));
    setTimeout(() => setMessage(null), 10000);
  };

  const showError = (message: string) => {
    setMessage(new Message(message, "error"));
    setTimeout(() => setMessage(null), 10000);
  };

  const permitDomainAction = useActionWithPath({});

  const removePermittedDomainAction = useActionWithPath({
    onSuccess: () => {
      refetchProvisioningPermittedValue();
    },
  });

  const togglePermittedDialog = () => {
    setOpenPermitDialog(!openPermitDialog);
  };

  const handleAdd = async (data: IPermittedProvisioningForm) => {
    setOpenPermitDialog(false);

    try {
      // @ts-ignore
      await permitDomainAction.mutateAsync({
        method: "post",
        path: "/authorizeProvisioningV2",
        body: JSON.stringify(data),
      });

      showSuccess(`Successfully permitted provisioning value`);
    } catch (err: any) {
      console.error(err);
      try {
        const json = await err.json();
        if (json) {
          showError(`Error invoking request: ${JSON.stringify(json.message)}`);
        }
      } catch (e) {
        console.log("ERROR:", e);
        showError(`Error invoking request: ${JSON.stringify(err)}`);
      }
    } finally {
      refetchProvisioningPermittedValue();
    }
  };

  return (
    <>
      <Helmet>
        <title>Provisioning Permitted List</title>
      </Helmet>

      <SectionHeader
        title={`Provisioning Permitted List`}
        actions={
          <StyledMainButton
            size="small"
            color={"inherit"}
            style={{
              width: 250,
              fontSize: 14,
            }}
            onClick={togglePermittedDialog}
          >
            Add Provisions To Permitted
          </StyledMainButton>
        }
        _deprecate_marginTop={0}
        style={{ padding: "20px 0" }}
      />

      {openPermitDialog && (
        <PermitProvisionDialog
          open={openPermitDialog}
          progressPending={permitDomainAction.isLoading}
          handleAdd={handleAdd}
          handleClose={togglePermittedDialog}
        />
      )}

      <AlertDialog
        textTitle={"Removing Confirmation"}
        textContent={
          <>
            Are you sure you want to remove&nbsp;
            <strong className="danger-text">{selectedValue?.value}</strong>?
          </>
        }
        open={openConfirmation}
        setOpen={() => setOpenConfirmation(false)}
        handleConfirm={() => {
          // @ts-ignore
          removePermittedDomainAction.mutate({
            method: "post",
            path: "/authorizeProvisioningV2",
            body: JSON.stringify({
              ...selectedValue,
              type: undefined,
              clusterType: selectedValue?.type,
              authRequestType: AuthRequestType.REVOKE,
            }),
            errorMessage: "Error removing provisioning permitted value:",
            successMessage:
              "Successfully removed provisioning permitted value.",
          });
          setOpenConfirmation(false);
        }}
        isInputConfirmation
        valueToBeDeleted={selectedValue?.value}
      />

      {provisioningPermittedValueFetching && <LinearProgress />}

      <Paper elevation={1}>
        <DataTable
          customStyles={customStyles}
          progressPending={provisioningPermittedValueFetching}
          pagination={false}
          noDataComponent={
            <Box padding={5} fontWeight={600}>
              No users found
            </Box>
          }
          data={provisioningPermittedValues}
          columns={[
            {
              name: "cloudProvider",
              label: "Cloud Provider",
              selector: (row: any) => row.cloudProvider,
            },
            {
              name: "dataType",
              label: "Data Type",
              selector: (row: any) => row.dataType,
            },
            {
              name: "type",
              label: "Type",
              selector: (row: any) => row.type,
            },
            {
              name: "value",
              label: "Value",
              selector: (row: any) => row.value,
            },
            {
              name: "actions",
              label: "Actions",
              selector: (row: IPermittedProvisioningForm) => null,
              right: true,
              renderer(val: any, row: any) {
                return (
                  <IconButton
                    title={"Remove from organization"}
                    onClick={(ev) => {
                      setOpenConfirmation(true);
                      setSelectedValue(row);
                    }}
                  >
                    <DeleteForeverRounded />
                  </IconButton>
                );
              },
            },
          ]}
        />
      </Paper>
    </>
  );
};

export default ProvisioningPermittedList;
