import { Box, Typography } from "@mui/material";

export const getValidationItems = (password: string) => [
  { label: "8 Characters", condition: password.length >= 8 },
  { label: "1 Upper letter", condition: /[A-Z]/.test(password) },
  { label: "1 Symbol", condition: /[^A-Za-z0-9]/.test(password) },
  { label: "1 Number", condition: /\d/.test(password) },
];

export const PasswordStrengthIndicator = ({
  password,
}: {
  password: string;
}) => {
  const validationItems = getValidationItems(password);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", gap: 3 }}>
      {validationItems.map((item, index) => (
        <Typography
          key={index}
          sx={{
            fontSize: 12,
            px: 1,
            color: item.condition ? "#40BA56" : "initial",
            border: item.condition ? "1px solid #40BA56" : "",
          }}
        >
          {item.label}
        </Typography>
      ))}
    </Box>
  );
};
