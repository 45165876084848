import { Box, Chip, IconButton, Paper, Typography } from "@mui/material";
import DataTable from "components/DataTable/DataTable";
import { Role } from "utils/saastypes";
import { customStyles, hasAnyRole } from "utils/utils";
import {
  Add,
  AddCircleOutline,
  DeleteForeverRounded,
  RefreshRounded,
} from "@mui/icons-material";
import StyledMainButton from "components/buttons/StyledMainButton";
import InviteUserToOrgDialog from "components/dialogs/InviteUserToOrgDialog";
import AddRoleToUserDialog from "components/dialogs/AddRoleToUserDialog";
import AlertDialog from "components/AlertDialog";
import { USER_ROLES, useUserData } from "./useUserData";
import { UnifiedUser, UsersTabProps } from "./types";

export const UsersTab = ({
  currentUsersFetching,
  currentUsers,
  invitedUsersFetching,
  invitedUsers,
  refetchAllUsers,
  refetchAllInvites,
}: UsersTabProps) => {
  const [
    {
      isModalOpen,
      currentUserRoles,
      unifiedData,
      roleModalData,
      confirmDelete,
      confirmDeleteInvite,
      currentUser,
      confirmResend,
      removeRoleModal,
    },
    {
      handleModal,
      handleAddInvite,
      handleRemoveRole,
      handleOpenRoleModal,
      handleCloseRoleModal,
      handleAddRole,
      handleOpenDeleteUserModal,
      handleCloseDeleteUserModal,
      handleDeleteUser,
      handleOpenResendInviteModal,
      handleCloseResendInviteModal,
      handleResendInvite,
      handleOpenDeleteInviteModal,
      handleCloseDeleteInviteModal,
      handleDeleteInvite,
      handleCloseRemoveRoleModal,
      handleOpenRemoveRoleModal,
    },
  ] = useUserData({
    currentUsers,
    invitedUsers,
    refetchAllUsers,
    refetchAllInvites,
  });

  const unifiedColumns = [
    {
      name: "email",
      label: "User Id",
      selector: (row: UnifiedUser) => row.email,
      grow: 1.5,
    },
    {
      name: "name",
      label: "User Name",
      selector: (row: UnifiedUser) => row.name,
      grow: 1.5,
    },
    {
      name: "roles",
      label: "Roles",
      grow: 2,
      selector: (row: UnifiedUser) => row.roles,
      renderer(val: Role[], row: UnifiedUser) {
        if (row.isInvited) {
          return val.map((x: Role) => (
            <Chip
              style={{ marginRight: 8 }}
              color={x !== Role.COMPANY_USER ? "primary" : "default"}
              label={USER_ROLES[x]}
            />
          ));
        } else {
          return (
            <>
              {val.map((x: Role) => (
                <Chip
                  onDelete={
                    x !== Role.COMPANY_USER &&
                    currentUserRoles &&
                    hasAnyRole(currentUserRoles, [Role.COMPANY_ROOT])
                      ? () => handleOpenRemoveRoleModal(x, row.email)
                      : undefined
                  }
                  style={{ marginRight: 8 }}
                  key={x}
                  color={x !== Role.COMPANY_USER ? "primary" : "default"}
                  label={USER_ROLES[x]}
                />
              ))}
              {val.length < Object.keys(USER_ROLES).length &&
                currentUserRoles &&
                hasAnyRole(currentUserRoles, [Role.COMPANY_ROOT]) && (
                  <IconButton
                    style={{ height: 40, width: 40 }}
                    title={"Add role"}
                    onClick={() => handleOpenRoleModal(val, row.email)}
                  >
                    <Add />
                  </IconButton>
                )}
            </>
          );
        }
      },
    },
    {
      name: "status",
      label: "Status",
      selector: (row: UnifiedUser) => row.isInvited,
      grow: 0.8,
      renderer: (_val: string, row: UnifiedUser) => {
        return (
          <Chip
            sx={{
              background: row.isInvited ? "#fab4c6" : "#9fdca9",
            }}
            label={row.isInvited ? "Invited" : "Active"}
          />
        );
      },
    },
    {
      name: "actions",
      label: "Actions",
      selector: (_row: UnifiedUser) => null,
      right: true,
      renderer(_val: any, row: UnifiedUser) {
        if (row.isInvited) {
          return (
            <>
              <IconButton
                title={"Resend invitation"}
                onClick={() =>
                  handleOpenResendInviteModal(row.email, row.roles[0])
                }
              >
                <RefreshRounded />
              </IconButton>
              <IconButton
                title={"Delete invitation"}
                onClick={() => handleOpenDeleteInviteModal(row.email, row.id)}
              >
                <DeleteForeverRounded />
              </IconButton>
            </>
          );
        } else {
          return currentUser?.email !== row.email ? (
            <IconButton
              title={"Remove from organization"}
              onClick={() => handleOpenDeleteUserModal(row.email, row.userId)}
            >
              <DeleteForeverRounded />
            </IconButton>
          ) : null;
        }
      },
    },
  ];

  return (
    <Box
      sx={{
        px: 6,
        py: 8,
      }}
    >
      {isModalOpen && (
        <InviteUserToOrgDialog
          open={isModalOpen}
          handleClose={() => {
            handleModal(false);
          }}
          handleAdd={handleAddInvite}
          progressPending={false}
          currentUserRoles={currentUserRoles}
        />
      )}
      {roleModalData.isOpen && (
        <AddRoleToUserDialog
          handleClose={handleCloseRoleModal}
          modalData={roleModalData}
          handleAdd={handleAddRole}
          progressPending={false}
        />
      )}
      <AlertDialog
        textTitle={"Removing User Confirmation"}
        textContent={
          <>
            Are you sure you want to remove&nbsp;
            <strong className="danger-text">{confirmDelete.userEmail}</strong>
            &nbsp;from your organization?
          </>
        }
        open={confirmDelete.confirmDelete}
        setOpen={handleCloseDeleteUserModal}
        handleConfirm={handleDeleteUser}
        isInputConfirmation
        valueToBeDeleted={confirmDelete.userEmail}
      />
      <AlertDialog
        textTitle={"Resend Invitation Confirmation"}
        textContent={`Are you sure you want to resend the invitation to (${
          confirmResend.userEmail
        }) to join
          your organization with the role (${
            USER_ROLES[confirmResend.userRole as string]
          })?`}
        open={confirmResend.confirmResend}
        setOpen={handleCloseResendInviteModal}
        handleConfirm={handleResendInvite}
      />
      <AlertDialog
        textTitle={"Deleting Invitation Confirmation"}
        textContent={
          <>
            Are you sure you want to delete the invitation to user&nbsp;
            <strong className="danger-text">
              {confirmDeleteInvite.userEmail}
            </strong>
            &nbsp;?
          </>
        }
        open={confirmDeleteInvite.confirmDelete}
        setOpen={handleCloseDeleteInviteModal}
        handleConfirm={handleDeleteInvite}
      />
      <AlertDialog
        textTitle={"Removing Role Confirmation"}
        textContent={
          <>
            Are you sure you want to remove the{" "}
            <strong className="danger-text">
              {USER_ROLES[removeRoleModal.roleToBeRemoved]}
            </strong>{" "}
            role for user&nbsp;
            <strong>{removeRoleModal.email}</strong>
            &nbsp;?
          </>
        }
        open={removeRoleModal.isOpen}
        setOpen={handleCloseRemoveRoleModal}
        handleConfirm={handleRemoveRole}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 6,
        }}
      >
        <Box>
          <Typography variant="h4" sx={{ opacity: 0.8 }}>
            Users
          </Typography>
          <Typography variant="body1" fontSize="14px">
            Add additional users with custom roles to dictate their access to
            your Organization and subscriptions.
          </Typography>
        </Box>
        <Box>
          <StyledMainButton
            variant="contained"
            id="add-user-btn"
            startIcon={<AddCircleOutline />}
            onClick={() => {
              handleModal(true);
            }}
            sx={{ minWidth: 125 }}
          >
            Add User
          </StyledMainButton>
        </Box>
      </Box>

      <Paper variant="outlined">
        <DataTable
          customStyles={customStyles}
          progressPending={currentUsersFetching || invitedUsersFetching}
          quickSearchEnabled
          quickSearchPlaceholder="Search users"
          noDataComponent={
            <Box padding={5} fontWeight={600}>
              No users found
            </Box>
          }
          data={unifiedData}
          columns={unifiedColumns}
          pagination
        />
      </Paper>
    </Box>
  );
};
