import { Box, Chip, IconButton, Paper, Typography } from "@mui/material";
import DataTable from "components/DataTable/DataTable";
import { customStyles } from "utils/utils";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

interface InvoiceData {
  id: string;
  invoiceMonth: string;
  invoiceAmount: string;
  status: "Outstanding" | "Paid";
}

const invoiceData: InvoiceData[] = [
  {
    id: "Invoice #97252",
    invoiceMonth: "February 2024",
    invoiceAmount: "$1,000.00",
    status: "Outstanding",
  },
  {
    id: "Invoice #97251",
    invoiceMonth: "January 2024",
    invoiceAmount: "$1,000.00",
    status: "Paid",
  },
  {
    id: "Invoice #97250",
    invoiceMonth: "December 2023",
    invoiceAmount: "$1,000.00",
    status: "Paid",
  },
  {
    id: "Invoice #97249",
    invoiceMonth: "November 2023",
    invoiceAmount: "$1,000.00",
    status: "Paid",
  },
];

const columns = [
  {
    name: "id",
    label: "Invoice #",
    selector: (row: InvoiceData) => row.id,
  },
  {
    name: "invoiceMonth",
    label: "Invoice Date",
    selector: (row: InvoiceData) => row.invoiceMonth,
  },
  {
    name: "invoiceAmount",
    label: "Invoice Amount",
    selector: (row: InvoiceData) => row.invoiceAmount,
  },
  {
    name: "status",
    label: "Status",
    selector: (row: InvoiceData) => row.status,
    renderer(val: string) {
      return (
        <Chip
          sx={{
            background: val === "Paid" ? "#9fdca9" : "#fab4c6",
            fontSize: 11,
            p: 1,
          }}
          label={val}
          size="small"
        />
      );
    },
  },
  {
    name: "actions",
    label: "Actions",
    selector: (row: InvoiceData) => null,
    right: true,
    renderer(_val: any, row: any) {
      return (
        <IconButton
          title={"Download Invoice"}
          onClick={() => {
            console.log(row);
          }}
        >
          <FileDownloadOutlinedIcon />
        </IconButton>
      );
    },
  },
];

export const InvoicesTab = () => {
  return (
    <Box
      sx={{
        px: 6,
        py: 8,
      }}
    >
      <Typography variant="h4" sx={{ opacity: 0.8, mb: 6 }}>
        All Invoices
      </Typography>
      <Paper variant="outlined">
        <DataTable
          customStyles={customStyles}
          quickSearchEnabled
          quickSearchPlaceholder="Search"
          noDataComponent={
            <Box padding={5} fontWeight={600}>
              No users found
            </Box>
          }
          data={invoiceData}
          columns={columns}
          pagination
        />
      </Paper>
    </Box>
  );
};
