import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import ActionButton from "../buttons/ActionButton";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useActionWithPath } from "utils/query";
import { CustomerEnvironment } from "utils/saastypes";
import { URL_REGEX } from "constants/common";
import React, { useEffect } from "react";
import { getResponseErrorMessage } from "utils/utils";
import _pickBy from "lodash/pickBy";

export enum AuthenticationType {
  DEFAULT_ORKES_AUTH0 = "DEFAULT_ORKES_AUTH0",
  GENERIC_OIDC = "GENERIC_OIDC",
  OKTA = "OKTA",
}

export enum AuthenticationTypeLabel {
  DEFAULT_ORKES_AUTH0 = "Default Orkes Authentication",
  GENERIC_OIDC = "Generic OpenId Connect",
  OKTA = "Okta OIDC Connect",
}

export interface ChangeAuthenticationTypeDto {
  audience: string;
  authType: AuthenticationType;
  clientId: string;
  clusterName: string;
  configurationUrl: string;
  deploymentVersion: string;
  emailClaim: string;
  issuer: string;
}

export const DOMAIN_REGEX =
  /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]?\.(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/;

const labelStyle = { fontWeight: 600 };
const subLabelStyle = {
  overflow: "unset",
  overflowWrap: "break-word",
  textOverflow: "unset",
  whiteSpace: "break-spaces",
  fontStyle: "italic",
};
const fullWidthItem = {
  flexGrow: 1,
};

export interface ChangeAuthenticationTypeDialogProps {
  clusterDetail: CustomerEnvironment;
  handleClose: () => void;
  open: boolean;
  showError: (message: string) => void;
  showSuccess: (message: string) => void;
  refetchClusterDetail: Function;
}

export default function ChangeAuthenticationTypeDialog({
  clusterDetail,
  handleClose,
  open,
  showError,
  showSuccess,
  refetchClusterDetail,
}: ChangeAuthenticationTypeDialogProps) {
  const formSchema = yup.object().shape({
    authType: yup
      .mixed()
      .oneOf(
        Object.values(AuthenticationType),
        "Authentication type is invalid"
      ),
    audience: yup.string().when("authType", {
      is: (value: AuthenticationType) =>
        value !== AuthenticationType.DEFAULT_ORKES_AUTH0,
      then: (schema) => schema.required("Audience is required."),
    }),
    clientId: yup.string().when("authType", {
      is: (value: AuthenticationType) =>
        value !== AuthenticationType.DEFAULT_ORKES_AUTH0,
      then: (schema) => schema.required("Client Id is required."),
    }),
    configurationUrl: yup.string().when("authType", {
      is: (value: AuthenticationType) =>
        value === AuthenticationType.GENERIC_OIDC,
      then: (schema) =>
        schema
          .required("Configuration URL is required.")
          .matches(URL_REGEX, "URL is not valid."),
    }),
    emailClaim: yup.string().when("authType", {
      is: (value: AuthenticationType) =>
        value === AuthenticationType.GENERIC_OIDC,
      then: (schema) => schema.required("Email claim is required."),
    }),
    issuer: yup.string().when("authType", {
      is: (value: AuthenticationType) =>
        value === AuthenticationType.OKTA ||
        value === AuthenticationType.GENERIC_OIDC,
      then: (schema) => schema.required("Issuer is required."),
    }),
  });
  const defaultValues = {
    authType:
      clusterDetail.authenticationType ||
      AuthenticationType.DEFAULT_ORKES_AUTH0,
    audience:
      clusterDetail.authenticationType === AuthenticationType.GENERIC_OIDC
        ? clusterDetail?.authenticationParams?.genericOidcConfig?.audience || ""
        : "",
    clientId:
      clusterDetail.authenticationType === AuthenticationType.GENERIC_OIDC
        ? clusterDetail?.authenticationParams?.genericOidcConfig?.clientId || ""
        : "",
    configurationUrl:
      clusterDetail.authenticationType === AuthenticationType.GENERIC_OIDC
        ? clusterDetail?.authenticationParams?.genericOidcConfig
            ?.oidcConfigUrl || ""
        : "",
    emailClaim:
      clusterDetail.authenticationType === AuthenticationType.GENERIC_OIDC
        ? clusterDetail?.authenticationParams?.genericOidcConfig?.emailClaim ||
          "email"
        : "email",
    issuer:
      clusterDetail.authenticationType === AuthenticationType.GENERIC_OIDC
        ? clusterDetail?.authenticationParams?.genericOidcConfig?.issuer || ""
        : "",
  };
  const {
    control,
    formState: { errors: formErrors, isDirty },
    handleSubmit,
    unregister,
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(formSchema),
    defaultValues,
  });

  const authTypeValue = watch("authType");
  const isOkta = authTypeValue === AuthenticationType.OKTA;
  const isGenericOIDC = authTypeValue === AuthenticationType.GENERIC_OIDC;

  const changeAuthenticationTypeAction = useActionWithPath({
    onSuccess: (data: any) => {
      if (data?.status === "ERROR") {
        const fallbackMessage = "Update deployment failed";
        showError(
          data?.message
            ? `${fallbackMessage}: ${data.message}`
            : fallbackMessage
        );
      } else {
        showSuccess(`Deployment update has been scheduled successfully.`);
        handleClose();
        refetchClusterDetail();
      }
    },
    onError: async (response: Response) => {
      const message = await getResponseErrorMessage(response);
      showError(`Change Authentication type failed: ${message}`);
    },
  });

  const onSubmit = (data: ChangeAuthenticationTypeDto) => {
    // @ts-ignore
    changeAuthenticationTypeAction.mutate({
      method: "POST",
      path: "/updateAuthType",
      body: JSON.stringify({
        ..._pickBy(data, (value) => !!value),
        clusterName: clusterDetail?.name,
      }),
    });
  };

  // Remove redundancy fields while changing auth type
  useEffect(() => {
    switch (authTypeValue) {
      case AuthenticationType.OKTA:
        unregister(["configurationUrl"]);
        break;
      case AuthenticationType.GENERIC_OIDC:
        // unregister(["issuer"]);
        break;
      default:
        unregister(["audience", "clientId", "configurationUrl", "issuer"]);
    }
  }, [authTypeValue, unregister]);

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle id="change-authentication-dialog-title">
        Change Authentication Type
      </DialogTitle>
      <DialogContent id="change-authentication-dialog-container">
        <form>
          <Grid container spacing={2} sx={{ paddingTop: 2 }}>
            <Grid item md={12} sx={fullWidthItem}>
              <Grid container>
                <Grid item sm={12} md={4} sx={fullWidthItem}>
                  <InputLabel sx={labelStyle}>Authentication Type:</InputLabel>
                </Grid>
                <Grid item sm={12} md={8} sx={fullWidthItem}>
                  <FormControl fullWidth>
                    <Controller
                      control={control}
                      name="authType"
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="authTypeLabel"
                          id="authTypeSelect"
                          onChange={(
                            event: SelectChangeEvent<AuthenticationType>
                          ) => field.onChange(event as any)}
                        >
                          {Object.keys(AuthenticationType).map((k) => (
                            <MenuItem
                              key={k}
                              value={
                                AuthenticationType[
                                  k as keyof typeof AuthenticationType
                                ]
                              }
                            >
                              {
                                AuthenticationTypeLabel[
                                  k as keyof typeof AuthenticationTypeLabel
                                ]
                              }
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                      rules={{ required: true }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {watch("authType") !== AuthenticationType.DEFAULT_ORKES_AUTH0 && (
              <>
                <Grid item md={12} sx={fullWidthItem}>
                  <Grid container>
                    <Grid item sm={12} md={4} sx={fullWidthItem}>
                      <InputLabel sx={labelStyle}>Audience:</InputLabel>
                      <InputLabel sx={subLabelStyle}>
                        Usually present in the token generated by the&nbsp;
                        {isOkta ? "OKTA" : "OIDC"} as one of the claims (aud) -
                        In some cases this is the same as client id
                      </InputLabel>
                    </Grid>
                    <Grid item sm={12} md={8} sx={fullWidthItem}>
                      <FormControl fullWidth>
                        <Controller
                          control={control}
                          name="audience"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="audience"
                              required
                              error={!!formErrors?.audience?.message}
                              helperText={formErrors?.audience?.message}
                            />
                          )}
                          rules={{ required: true }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={12} sx={fullWidthItem}>
                  <Grid container>
                    <Grid item sm={12} md={4} sx={fullWidthItem}>
                      <InputLabel sx={labelStyle}>Client Id:</InputLabel>
                      <InputLabel sx={subLabelStyle}>
                        Presented in the authorization URL
                      </InputLabel>
                    </Grid>
                    <Grid item sm={12} md={8} sx={fullWidthItem}>
                      <FormControl fullWidth>
                        <Controller
                          control={control}
                          name="clientId"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              required
                              error={!!formErrors?.clientId?.message}
                              helperText={formErrors?.clientId?.message}
                            />
                          )}
                          rules={{ required: true }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                {(isOkta || isGenericOIDC) && (
                  <Grid item md={12} sx={fullWidthItem}>
                    <Grid container>
                      <Grid item sm={12} md={4} sx={fullWidthItem}>
                        <InputLabel sx={labelStyle}>Issuer:</InputLabel>
                        <InputLabel sx={subLabelStyle}>
                          JWT Token Issuer
                        </InputLabel>
                      </Grid>
                      <Grid item sm={12} md={8} sx={fullWidthItem}>
                        <FormControl fullWidth>
                          <Controller
                            control={control}
                            name="issuer"
                            render={({ field }) => (
                              <TextField
                                {...field}
                                required
                                error={!!formErrors?.issuer?.message}
                                helperText={formErrors?.issuer?.message}
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {isGenericOIDC && (
                  <Grid item md={12} sx={fullWidthItem}>
                    <Grid container>
                      <Grid item sm={12} md={4} sx={fullWidthItem}>
                        <InputLabel sx={labelStyle}>
                          OIDC Configuration URL:
                        </InputLabel>
                        <InputLabel sx={subLabelStyle}>
                          Open ID configuration
                        </InputLabel>
                        <InputLabel sx={subLabelStyle}>
                          ex: http://domain/.well-known/openid-configuration
                        </InputLabel>
                      </Grid>
                      <Grid item sm={12} md={8} sx={fullWidthItem}>
                        <FormControl fullWidth>
                          <Controller
                            control={control}
                            name="configurationUrl"
                            render={({ field }) => (
                              <TextField
                                {...field}
                                required
                                error={!!formErrors?.configurationUrl?.message}
                                helperText={
                                  formErrors?.configurationUrl?.message
                                }
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container mt={2}>
                      <Grid item sm={12} md={4} sx={fullWidthItem}>
                        <InputLabel sx={labelStyle}>Email Claim:</InputLabel>
                        <InputLabel sx={subLabelStyle}>
                          The JWT claim field that will have the user's unique
                          email id. Usually this is "email" but you can
                          customize it here if required.
                        </InputLabel>
                      </Grid>
                      <Grid item sm={12} md={8} sx={fullWidthItem}>
                        <FormControl fullWidth>
                          <Controller
                            control={control}
                            name="emailClaim"
                            render={({ field }) => (
                              <TextField
                                {...field}
                                required
                                error={!!formErrors?.emailClaim?.message}
                                helperText={formErrors?.emailClaim?.message}
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid
                  item
                  md={12}
                  sx={{ fontStyle: "italic", ...fullWidthItem }}
                >
                  <InputLabel>
                    <strong>Note:</strong> Callback URL <strong>must be</strong>{" "}
                    set on your {isOkta ? "OKTA" : "OIDC"} Provider:
                  </InputLabel>
                  <InputLabel sx={{ fontWeight: 600 }}>
                    https://&lt;conductor domain&gt;/login
                    {isOkta ? "" : "/oidc"}/callback
                  </InputLabel>
                </Grid>
              </>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClose}
          style={{ margin: 8, fontSize: 14, textTransform: "none" }}
          sx={{
            display: "inline",
            mx: 0.5,
          }}
        >
          Cancel
        </Button>
        <ActionButton
          variant="contained"
          color="primary"
          disabled={!isDirty}
          progress={false}
          onClick={() => {
            // @ts-ignore
            handleSubmit(onSubmit)();
          }}
          style={{ margin: 8, fontSize: 14, textTransform: "none" }}
          sx={{
            display: "inline",
            mx: 0.5,
          }}
        >
          Confirm
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
}
