import { Link, Typography } from "@mui/material";
import { commonStyle } from "./common";

export const TermsAndConditionText = () => (
  <Typography fontSize={10} fontWeight={300} maxWidth={350} textAlign="center">
    By providing your email address or using a single sign-on provider to create
    an account or login, you agree to our{" "}
    <Link
      target={"_blank"}
      rel={"noreferrer noopener"}
      href="https://orkes.io/terms-and-conditions/"
      sx={commonStyle.linkStyle}
    >
      Terms of Service
    </Link>{" "}
    and that you have reviewed our{" "}
    <Link
      target={"_blank"}
      rel={"noreferrer noopener"}
      href="https://orkes.io/privacy-policy/"
      sx={commonStyle.linkStyle}
    >
      Privacy Policy
    </Link>{" "}
    and{" "}
    <Link
      target={"_blank"}
      rel={"noreferrer noopener"}
      href="https://orkes.io/legal/"
      sx={commonStyle.linkStyle}
    >
      Legal Notices
    </Link>
    .
  </Typography>
);
