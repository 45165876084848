import {
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import logo from "../../images/branding/orkes-logo-purple.png";

export default function AcceptInviteDialog({
  inviteId,
  isLoading,
  createAccountPrompt,
  onAcceptInvite,
}: {
  inviteId: string;
  isLoading: boolean;
  createAccountPrompt: boolean;
  onAcceptInvite: () => void;
}) {
  return (
    <Dialog fullWidth maxWidth="sm" open>
      <DialogContent>
        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img
            style={{ width: 400, alignSelf: "center" }}
            src={logo}
            alt="Orkes Logo"
          />
          <h3
            style={{
              textAlign: "center",
              fontWeight: "normal",
              marginBottom: 0,
              paddingBottom: 0,
            }}
          >
            You have been invited to join an organization!
          </h3>
          <h4
            style={{
              textAlign: "center",
              fontWeight: "normal",
            }}
          >
            {createAccountPrompt ? (
              <Typography
                sx={{
                  cursor: "pointer",
                  color: "#2868f7",
                  fontWeight: 400,
                }}
                onClick={onAcceptInvite}
              >
                To accept the invitation, you must create an account or log in.
              </Typography>
            ) : (
              <>Invitation ID:{inviteId}</>
            )}
          </h4>
          {isLoading && <CircularProgress style={{ alignSelf: "center" }} />}
        </div>
      </DialogContent>
    </Dialog>
  );
}
