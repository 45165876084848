import { Checkbox, Grid } from "@mui/material";
import Dropdown from "./Dropdown";
import Input from "./Input";

const colHeader = 4;

export default function ClusterField({
  id,
  label,
  labelValue,
  items,
  ddWidth = 200,
  type,
  textSuffix = "",
  multiple = false,
  disabled,
  inputProps,
  showDropdownIcons = false,
  iconHandler,
  ...rest
}: {
  id: string;
  label: string;
  labelValue?: string | JSX.Element | undefined;
  items?: any;
  value?: any;
  defaultValue?: any;
  isOptionEqualToValue?: any;
  getOptionLabel?: any;
  disableCloseOnSelect?: boolean;
  type: string;
  textSuffix?: string;
  ddWidth?: number;
  error?: boolean;
  helperText?: string;
  inputValue?: any;
  multiple?: boolean;
  onChange?: (e: any, value?: any, reason?: string) => void;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  disabled?: boolean;
  inputProps?: any;
  placeholder?: string;
  showDropdownIcons?: boolean;
  iconHandler?: (va: string) => void;
}) {
  return (
    <>
      {label !== "" && (
        <Grid item xs={colHeader}>
          <div className={"headerText"}>{label}</div>
        </Grid>
      )}
      <Grid item xs={12 - colHeader}>
        {type === "select" && (
          <Dropdown
            multiple={multiple}
            size="small"
            style={{ width: ddWidth || "100%" }}
            options={items}
            getOptionLabel={(option: any) => option.title}
            showIcons={showDropdownIcons}
            iconHandler={iconHandler}
            {...rest}
          />
        )}
        {(type === "text" || type === "password") && textSuffix !== "" && (
          <div
            style={{
              width: ddWidth ? ddWidth : "100%",
              display: "flex",
            }}
          >
            <Input
              inputProps={inputProps}
              size="small"
              sendEventTarget={true}
              id={id}
              name={id}
              sx={{
                width: ddWidth ? ddWidth : "100%",
                ".MuiInputBase-root .Mui-disabled": {
                  backgroundColor: "#f3f3f3",
                },
              }}
              variant="outlined"
              disabled={disabled}
              {...rest}
            />
            <div
              style={{
                display: "flex",
                alignItems: "start",
                paddingTop: "22px",
              }}
            >
              {textSuffix}
            </div>
          </div>
        )}
        {(type === "text" || type === "password") && textSuffix === "" && (
          <Input
            inputProps={inputProps}
            size="small"
            sendEventTarget={true}
            id={id}
            name={id}
            variant="outlined"
            type={type}
            sx={{
              width: ddWidth ? ddWidth : "100%",
              ".MuiInputBase-root .Mui-disabled": {
                backgroundColor: "#f3f3f3",
              },
            }}
            disabled={disabled}
            {...rest}
          />
        )}
        {type === "label" && (
          <div className={"headerText"} style={{ marginTop: 12, fontSize: 16 }}>
            {labelValue}
          </div>
        )}
        {type === "checkbox" && <Checkbox id={id} name={id} {...rest} />}
      </Grid>
    </>
  );
}
