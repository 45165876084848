import { LegacyColumn } from "./types";

export function getColumnLabelById(columnId: string, columns: LegacyColumn[]) {
  const col = columns.find((c) => c.id === columnId || c.name === columnId);
  return col?.label || col?.name;
}

export function getColumnLabel(col: LegacyColumn) {
  return col.label || col.name;
}

export function getColumnId(col: LegacyColumn) {
  return col.id || col.name;
}

export function createTableTitle({
  filteredData = [],
  data = [],
}: {
  filteredData: string[];
  data: string[];
}): string {
  return filteredData.length === data.length
    ? `${filteredData.length} results`
    : `${filteredData.length} results (${
        data.length - filteredData.length
      } not shown)`;
}
