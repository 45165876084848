import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "./configs/auth0-config";
import React from "react";

export function useAuth() {
  const obj = useAuth0();
  if (obj.isAuthenticated) {
    obj.user.displayName = obj.user.name;
    obj.getAccessTokenSilently().then((token) => {
      //FIXME this will cause issues.
      // This promise might be resolved after obj is returned which means
      // that obj.user.stsTokenManager might be undefined.
      obj.user.stsTokenManager = token;
    });
  }

  obj.logOut = (e) => obj.logout({ returnTo: window.location.origin });
  obj.currentUser = obj.user;
  return obj;
}

const onRedirectCallback = (appState) => {
  console.log("App State", appState);
  // history.push(
  //   appState && appState.returnTo
  //     ? appState.returnTo
  //     : window.location.pathname
  // );
};

export default function AuthProvider({ children }) {
  // const [currentUser, setCurrentUser] = useState();
  // const history = useHistory();

  // Please see https://auth0.github.io/auth0-react/interfaces/auth0_provider.auth0provideroptions.html
  // for a full list of the available properties on the provider
  const config = getConfig();

  const providerConfig = {
    domain: config.domain,
    clientId: config.clientId,
    cacheLocation: "localstorage",
    ...(config.audience ? { audience: config.audience } : null),
    redirectUri: window.location.origin,
    onRedirectCallback,
  };

  return <Auth0Provider {...providerConfig}>{children}</Auth0Provider>;
}
