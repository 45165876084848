export const CheckRounded = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="123"
      height="123"
      viewBox="0 0 123 123"
      fill="none"
    >
      <path
        d="M81.6105 40.8975L90.282 49.569L57.687 82.164C56.457 83.394 54.9195 83.9475 53.3205 83.9475C51.7215 83.9475 50.184 83.3325 48.954 82.164L32.6565 65.8665L41.328 57.195L53.259 69.126L81.4875 40.8975H81.6105ZM110.7 61.5C110.7 88.6215 88.6215 110.7 61.5 110.7C34.3785 110.7 12.3 88.6215 12.3 61.5C12.3 34.3785 34.3785 12.3 61.5 12.3C88.6215 12.3 110.7 34.3785 110.7 61.5ZM104.55 61.5C104.55 37.761 85.239 18.45 61.5 18.45C37.761 18.45 18.45 37.761 18.45 61.5C18.45 85.239 37.761 104.55 61.5 104.55C85.239 104.55 104.55 85.239 104.55 61.5Z"
        fill="#40BA56"
      />
    </svg>
  );
};
