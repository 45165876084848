import { Box, Button, Chip, Typography } from "@mui/material";
import StyledMainButton from "components/buttons/StyledMainButton";
import { Helmet } from "react-helmet";
import SectionContainer from "shared/SectionContainer";
import SectionHeader from "shared/SectionHeader";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import SendIcon from "images/send-icon.svg";
import { Fragment, useState } from "react";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { useNavigate } from "react-router";
import {
  addOnCardData,
  cardStyle,
  enterpriseFeature,
  upgradeData,
} from "./common";
import { ContactUsDialog } from "./ContactUsDialog";
import { useAuth } from "auth/AuthContext";
import { useAuthMachine } from "auth/state/hook";

export enum BillingFrequency {
  MONTHLY = "monthly",
  YEARLY = "yearly",
}

const getToggleOptionStyle = (selected: boolean) => ({
  ...cardStyle.baseToggleOptionStyle,
  ...(selected ? cardStyle.selectedBillingFrequency : {}),
});

const EnterPriseCard = () => {
  const navigate = useNavigate();

  const [billingFrequency, setBillingFrequency] = useState(
    BillingFrequency.MONTHLY
  );

  const handleToggle = () => {
    setBillingFrequency((prev) =>
      prev === BillingFrequency.YEARLY
        ? BillingFrequency.MONTHLY
        : BillingFrequency.YEARLY
    );
  };

  return (
    <Box sx={cardStyle.container}>
      <Box sx={cardStyle.card}>
        <Box sx={cardStyle.enterpriseCardHeader}>
          <Typography sx={cardStyle.headerFooterTypography}>
            ENTERPRISE
          </Typography>
        </Box>

        <Box sx={{ px: 3, py: 1.5, minHeight: 450 }}>
          <Typography fontSize={14} marginTop={2}>
            Advanced functionalities and dedicated support, tailored to meet the
            needs of growing businesses and organizations.
          </Typography>
          <Typography fontSize={14} marginTop={3} fontWeight={500}>
            Features:
          </Typography>
          <ul style={{ marginTop: "6px" }}>
            {enterpriseFeature.map((item) => (
              <li style={{ fontSize: "14px", marginBottom: 3 }} key={item}>
                {item}
              </li>
            ))}
          </ul>
        </Box>

        <Box sx={cardStyle.enterpriseCardFooter}>
          <Typography sx={cardStyle.headerFooterTypography}>
            ${billingFrequency === BillingFrequency.YEARLY ? 695 : 825}/MONTH
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              pr: 3,
              gap: 2,
            }}
          >
            {billingFrequency === BillingFrequency.YEARLY && (
              <Chip
                label="15% Savings"
                size="small"
                sx={{ background: "#9FDCAA", fontSize: 11, fontWeight: 600 }}
              />
            )}
            <Box sx={cardStyle.toggleButtonStyle}>
              <Box
                sx={getToggleOptionStyle(
                  billingFrequency === BillingFrequency.YEARLY
                )}
                onClick={handleToggle}
              >
                Annually
              </Box>
              <Box
                sx={getToggleOptionStyle(
                  billingFrequency === BillingFrequency.MONTHLY
                )}
                onClick={handleToggle}
              >
                Monthly
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <StyledMainButton
        startIcon={<ArrowCircleUpOutlinedIcon />}
        style={{ width: "110px", height: "34px", alignSelf: "flex-end" }}
        onClick={() => {
          navigate(
            `/home/add-payment-method?billingFrequency=${billingFrequency}`
          );
        }}
      >
        Upgrade
      </StyledMainButton>
    </Box>
  );
};

const AddonCard = ({
  handleOpenContactDialog,
}: {
  handleOpenContactDialog: () => void;
}) => {
  const [expandedStates, setExpandedStates] = useState(
    Array(addOnCardData.length).fill(false)
  );

  const handleToggle = (index: number) => {
    const newExpandedStates = [...expandedStates];
    newExpandedStates[index] = !newExpandedStates[index];
    setExpandedStates(newExpandedStates);
  };

  const [expandedUpgradeStates, setExpandedUpgradesStates] = useState(
    Array(upgradeData.length).fill(false)
  );

  const handleUpgradeToggle = (index: number) => {
    const newExpandedStates = [...expandedUpgradeStates];
    newExpandedStates[index] = !newExpandedStates[index];
    setExpandedUpgradesStates(newExpandedStates);
  };

  return (
    <Box sx={cardStyle.container}>
      <Box sx={cardStyle.card}>
        <Box
          sx={{
            background: "#D7DCDD",
            borderRadius: "6px 6px 0px 0px",
            borderBottom: "1px solid #D7DCDD",
          }}
        >
          <Typography sx={cardStyle.headerFooterTypography}>ADD-ONS</Typography>
        </Box>

        <Box sx={{ px: 3, py: 6, minHeight: 450 }}>
          {addOnCardData.map((data, i) => (
            <Fragment key={i}>
              <Box
                sx={{
                  display: "flex",
                  color: "#1976d2",
                  cursor: "pointer",
                  alignItems: "center",
                  mb: 2,
                }}
                onClick={() => handleToggle(i)}
              >
                {expandedStates[i] ? (
                  <ExpandMoreRoundedIcon fontSize="small" />
                ) : (
                  <ChevronRightRoundedIcon fontSize="small" />
                )}
                <Typography key={i} sx={{ fontSize: 14, fontWeight: "bold" }}>
                  {data.title}
                </Typography>
              </Box>
              {expandedStates[i] && (
                <ul style={{ marginTop: "6px" }}>
                  {data.features.map((feature, index) => (
                    <li
                      key={index}
                      style={{ fontSize: "14px", marginBottom: 3 }}
                    >
                      {feature}
                    </li>
                  ))}
                </ul>
              )}
            </Fragment>
          ))}

          <Typography
            sx={{ fontSize: 14, fontWeight: "bold", mt: 5, mb: 3, ml: 2 }}
          >
            Upgrades
          </Typography>

          {upgradeData.map((data, i) => (
            <Fragment key={i}>
              <Box
                sx={{
                  display: "flex",
                  color: "#1976d2",
                  cursor: "pointer",
                  alignItems: "center",
                  mb: 2,
                }}
                onClick={() => handleUpgradeToggle(i)}
              >
                {expandedUpgradeStates[i] ? (
                  <ExpandMoreRoundedIcon fontSize="small" />
                ) : (
                  <ChevronRightRoundedIcon fontSize="small" />
                )}
                <Typography key={i} sx={{ fontSize: 14, fontWeight: "bold" }}>
                  {data.title}
                </Typography>
              </Box>
              {expandedUpgradeStates[i] && (
                <ul style={{ marginTop: "6px" }}>
                  {data.features.map((feature, index) => (
                    <li key={index} style={{ fontSize: "14px" }}>
                      {feature}
                    </li>
                  ))}
                </ul>
              )}
            </Fragment>
          ))}
        </Box>

        <Box
          sx={{
            background: "#F3F3F3",
            borderRadius: "0px 0px 6px 6px",
            borderTop: "1px solid #D7DCDD",
          }}
        >
          <Typography sx={cardStyle.headerFooterTypography}>
            FEATURE DEPENDENT
          </Typography>
        </Box>
      </Box>

      <Button
        startIcon={<img src={SendIcon} alt="" />}
        variant="outlined"
        size="small"
        sx={{
          alignSelf: "flex-end",
          textTransform: "none",
          color: "#1976d2",
          border: "1px solid #1976d2",
          px: 3,
          "&:hover": {
            border: "1px solid #1976d2",
          },
        }}
        onClick={handleOpenContactDialog}
      >
        Contact Us
      </Button>
    </Box>
  );
};

const UpgradePlan = () => {
  const [contactDialogOpen, setContactDialogOpen] = useState(false);

  const handleOpenContactDialog = () => {
    setContactDialogOpen(true);
  };

  const handleDialogClose = () => {
    setContactDialogOpen(false);
  };

  const { authService } = useAuth();
  const [{ currentOrganization }] = useAuthMachine(authService);

  return (
    <>
      <Helmet>
        <title>Orkes Cloud - Upgrade Plan</title>
      </Helmet>

      <SectionHeader title={`Upgrade Plan`} />

      <ContactUsDialog
        open={contactDialogOpen && !!currentOrganization?.name}
        onClose={handleDialogClose}
        currentOrganization={currentOrganization}
      />

      <SectionContainer>
        <Box sx={{ display: "flex", gap: 10 }}>
          <EnterPriseCard />
          <AddonCard handleOpenContactDialog={handleOpenContactDialog} />
        </Box>
      </SectionContainer>
    </>
  );
};

export default UpgradePlan;
