import Input from "components/Input";
import { modalStyle } from "./common";
import ActionButton from "components/buttons/ActionButton";
import { Typography, Stack } from "@mui/material";
import { ResentSvg } from "images/svg/ResendSvg";
import ReCAPTCHA from "react-google-recaptcha";
interface PhoneVerificationStepProps {
  verificationCode: string;
  captchaValue?: string;
  handleVerificationCode: (val: string) => void;
  handleRecaptcha: (recaptchaProof: string) => void;
  resendVerificationCode: () => void;
  countDown: number;
  buttonProgress: boolean;
  errorMessage?: string;
}

const sitekey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
export const PhoneVerificationStep = ({
  verificationCode,
  handleVerificationCode,
  resendVerificationCode,
  countDown,
  buttonProgress,
  errorMessage,
  handleRecaptcha,
  captchaValue,
}: PhoneVerificationStepProps) => {
  const showCountdown = countDown > 0;
  return (
    <Stack direction={"column"} spacing={2} sx={{ width: "100%" }} m={2}>
      <Input
        value={verificationCode}
        onChange={(val) => handleVerificationCode(val)}
        required
        label="Verification Code"
        type="text"
        sx={modalStyle.inputStyle}
        error={!!errorMessage}
        helperText={errorMessage}
        inputProps={{
          autocomplete: "off",
        }}
        disabled={!showCountdown}
      />
      {showCountdown ? (
        <Typography
          sx={{
            textAlign: "left",
            width: "100%",
            fontSize: "12px",
            mt: 2,
            left: -40,
          }}
        >
          Resend code in {countDown}s
        </Typography>
      ) : (
        <>
          <ReCAPTCHA
            sitekey={sitekey!}
            onChange={(token) => handleRecaptcha(token!)}
          />
          <ActionButton
            startIcon={
              <ResentSvg
                color={countDown > 0 || buttonProgress ? "#c5c5c5" : "#1976D2"}
              />
            }
            variant="text"
            size="medium"
            onClick={resendVerificationCode}
            disabled={
              countDown > 0 || buttonProgress || captchaValue === undefined
            }
            progress={buttonProgress}
          >
            Resend Code
          </ActionButton>
        </>
      )}
    </Stack>
  );
};
