const HelpIcon = ({ size = 20, ...props }: any) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.5 15.5C16.78 15.5 17 15.28 17 15V2.5C17 2.22 16.78 2 16.5 2H3.81C2.81 2 2 2.82 2 3.82V16.17C2 16.17 2 16.22 2 16.24C2 17.2 2.81 17.99 3.81 17.99H16.5C16.78 17.99 17 17.77 17 17.49C17 17.21 16.78 16.99 16.5 16.99C16.05 16.99 15.69 16.65 15.69 16.24C15.69 15.83 16.05 15.49 16.5 15.49V15.5ZM5.16 3H16V14.5H5V3H5.16ZM3.81 17C3.36 17 3 16.66 3 16.25C3 15.84 3.36 15.5 3.81 15.5H14.87C14.76 15.73 14.69 15.98 14.69 16.25C14.69 16.52 14.75 16.77 14.86 17H3.81ZM9.5 11.14H11.08V12.72H9.5V11.14ZM7.55 7.46C6.8 3.71 14.22 3.83 12.85 7.65C12.48 8.78 10.76 9 10.97 10.35H9.55C9.55 9.98 9.52 9.42 9.68 9.11C9.99 8.21 11.27 7.96 11.31 6.96C11.4 5.92 9.79 5.7 9.28 6.42C9.07 6.73 9.06 7.11 9.1 7.46H7.55Z"
      fill="currentColor"
    />
  </svg>
);

export default HelpIcon;
