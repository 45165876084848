import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import ActionButton from "components/buttons/ActionButton";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { ModalStep, modalStyle, stepTitles } from "./common";
import { PersonalInfoStep } from "./PersonalInfoStep";
import { PhoneVerificationStep } from "./PhoneVerificationStep";
import { TrialClusterConfirmationStep } from "./TrialClusterConfirmationStep";
import { FC } from "react";
import { useAuth } from "auth/AuthContext";
import { useAuthMachine } from "auth/state/hook";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Box } from "@mui/system";
import StyledMainButton from "components/buttons/StyledMainButton";
import { useUserInfoModalMachine } from "./state/hook";

const DialogTitleContent = ({
  step,
  uiUrl,
}: {
  step: ModalStep;
  uiUrl: string | null;
}) => {
  return step === ModalStep.ASSIGN_TRIAL_CLUSTER_CONFIRMATION_STEP && !uiUrl ? (
    <>
      Cluster Provisioning in Progress!
      <Typography sx={{ fontSize: "14px", marginTop: 5, textAlign: "center" }}>
        Click on Manage Cluster to check the progress.
      </Typography>
    </>
  ) : (
    <>
      {stepTitles[step].title}
      <Typography sx={{ fontSize: "14px", marginTop: 5 }}>
        {stepTitles[step].description}
      </Typography>
    </>
  );
};

interface UserModalProps {
  refetchVerifyUserStatus: () => void;
}

export const UserInfoModal: FC<UserModalProps> = ({
  refetchVerifyUserStatus,
}) => {
  const { authService } = useAuth();
  const [{ currentOrganization, currentUser, isOrkesUser }] =
    useAuthMachine(authService);

  const displayName = currentUser?.displayName
    ? currentUser.displayName
    : currentOrganization?.userName;
  const [
    {
      open,
      userInfo,
      currentStep,
      buttonState,
      uiUrl,
      countDown,
      verificationCode,
      otpVerificationInProgress,
      errorMessage,
      captchaValue,
    },
    {
      handleContinue,
      handleRecaptcha,
      handleCloseModal,
      handleOpenModal,
      sendOtpCode,
      handleFieldChange,
      handleVerificationCode,
      goBackToPersonalInfoStep,
    },
  ] = useUserInfoModalMachine(displayName, currentOrganization?.name);

  const stepContents = {
    [ModalStep.PERSONAL_INFO_STEP]: (
      <PersonalInfoStep
        displayName={displayName}
        userInfo={userInfo}
        handleFieldChange={handleFieldChange}
        errorMessage={errorMessage}
        handleRecaptcha={handleRecaptcha}
      />
    ),
    [ModalStep.PHONE_VERIFICATION_STEP]: (
      <PhoneVerificationStep
        verificationCode={verificationCode}
        handleVerificationCode={handleVerificationCode}
        captchaValue={captchaValue}
        handleRecaptcha={handleRecaptcha}
        resendVerificationCode={sendOtpCode}
        countDown={countDown}
        buttonProgress={otpVerificationInProgress}
        errorMessage={errorMessage}
      />
    ),
    [ModalStep.ASSIGN_TRIAL_CLUSTER_CONFIRMATION_STEP]: (
      <TrialClusterConfirmationStep
        onClose={() => {
          refetchVerifyUserStatus(); // Will yield a verification. which (for now) means it will refresh the current organization
          // This should be migrated to xstate
          handleCloseModal();
        }}
        uiUrl={uiUrl}
      />
    ),
  };

  return (
    <>
      {isOrkesUser ? null : (
        <StyledMainButton
          style={{
            width: "100%",
            fontSize: ".9rem",
            textAlign: "center",
            borderRadius: 5,
          }}
          onClick={() => handleOpenModal()}
        >
          Start Free Trial
        </StyledMainButton>
      )}

      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleCloseModal}>
        {currentStep !== ModalStep.ASSIGN_TRIAL_CLUSTER_CONFIRMATION_STEP && (
          <Box>
            <Button
              disableRipple
              sx={{
                position: "absolute",
                right: 5,
                top: 5,
                textTransform: "none",
                color: "#ABAAAA",
                fontWeight: 500,
                fontSize: 11,
              }}
              onClick={handleCloseModal}
            >
              <CancelOutlinedIcon sx={{ fontSize: 18 }} />
            </Button>
          </Box>
        )}
        <DialogTitle sx={modalStyle.dialogTitle}>
          <DialogTitleContent step={currentStep} uiUrl={uiUrl} />
        </DialogTitle>
        <DialogContent sx={modalStyle.dialogContent}>
          {stepContents[currentStep]}
        </DialogContent>
        <DialogActions sx={{ px: 13 }}>
          {currentStep === ModalStep.PHONE_VERIFICATION_STEP && (
            <ActionButton
              variant="text"
              sx={{ textTransform: "none" }}
              size="medium"
              onClick={goBackToPersonalInfoStep}
            >
              Back
            </ActionButton>
          )}
          {currentStep !== ModalStep.ASSIGN_TRIAL_CLUSTER_CONFIRMATION_STEP && (
            <ActionButton
              startIcon={<ArrowCircleRightOutlinedIcon />}
              variant="contained"
              size="medium"
              sx={modalStyle.btnStyle}
              onClick={handleContinue}
              disabled={buttonState.disabled}
              progress={buttonState.loading}
            >
              Continue
            </ActionButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
