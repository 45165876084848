import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { Appearance } from "@stripe/stripe-js";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import { Helmet } from "react-helmet";
import SectionContainer from "shared/SectionContainer";
import SectionHeader from "shared/SectionHeader";
import { PaymentForm } from "./PaymentForm";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { checkoutPageStyle } from "./common";
import { ProtectIcon } from "images/svg/ProtectIcon";
import ReplayIcon from "@mui/icons-material/Replay";
import { useCheckoutMachine } from "./state/hook";

const stripeKey =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ||
  "pk_live_51M1c9nL4uVxJwRk8L6knRx3IhWFmKCntRUja7BfvFd4ysBCw3GS5STdvNj6AbzoiueczgGsvFr8ybFoYAk7PbgKd008Z8Kols8";

const stripePromise = loadStripe(stripeKey);

const CheckoutPage = () => {
  const [
    {
      isAddingPaymentInfo,
      clientSecret,
      isClientSecretLoading,
      isClientSecretError,
      isSetupIntentProgress,
      cardName,
      isSubmitBtnDisabled,
    },
    { handleBack, handleSubmit, refetchClientSecret, handleNameChange },
  ] = useCheckoutMachine();

  const appearance: Appearance = {
    theme: "stripe",
  };
  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  };

  return (
    <>
      <Helmet>
        <title>{`Orkes Cloud - ${
          isAddingPaymentInfo ? "Add" : "Update"
        } Payment Method`}</title>
      </Helmet>

      <SectionHeader
        title={` ${isAddingPaymentInfo ? "Add" : "Update"} Payment Method`}
      />

      <SectionContainer>
        <Button
          variant="text"
          startIcon={<KeyboardBackspaceIcon />}
          sx={checkoutPageStyle.backCta}
          onClick={handleBack}
        >
          Back
        </Button>
        <Box sx={checkoutPageStyle.checkoutContainer}>
          {isClientSecretLoading ? (
            <Box sx={checkoutPageStyle.circularProgress}>
              <CircularProgress style={{ color: "#ff5d22" }} />
              <Typography>Loading. Please wait...</Typography>
            </Box>
          ) : (
            <Box>
              {clientSecret && (
                <>
                  <Box>
                    <Typography
                      fontSize={20}
                      fontWeight={500}
                      marginBottom={2}
                      textAlign="center"
                    >
                      Payment Information
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1.5,
                        mb: 5,
                        justifyContent: "center",
                      }}
                    >
                      <ProtectIcon />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Typography sx={{ fontSize: 14, color: "#0000008a" }}>
                          Protected by
                        </Typography>
                        <Typography
                          sx={checkoutPageStyle.stripeLink}
                          onClick={() => window.open("https://stripe.com/")}
                        >
                          Stripe
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Paper
                    variant="elevation"
                    elevation={3}
                    sx={{ px: 6, py: 8, minWidth: "516px" }}
                  >
                    <Elements options={options} stripe={stripePromise}>
                      <PaymentForm
                        cardName={cardName}
                        isSubmitBtnDisabled={isSubmitBtnDisabled}
                        isSetupIntentProgress={isSetupIntentProgress}
                        handleSubmit={handleSubmit}
                        handleNameChange={handleNameChange}
                      />
                    </Elements>
                  </Paper>
                </>
              )}
              {isClientSecretError && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <Typography>
                    Something went wrong while loading the form. Please try
                    again.
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={refetchClientSecret}
                    startIcon={<ReplayIcon />}
                  >
                    Retry
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </SectionContainer>
    </>
  );
};

export default CheckoutPage;
