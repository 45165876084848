import { Paper } from "@mui/material";
import { StyledTab, StyledTabs } from "components/Tab";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import SectionContainer from "shared/SectionContainer";
import SectionHeader from "shared/SectionHeader";
import {
  useActionWithPath,
  useInvitedUsers,
  useUserOrganizations,
  useUserIds,
  useCurrentUsers,
} from "utils/query";
import { InfoTab } from "./Tabs/InfoTab";
import LinearProgress from "components/LinearProgress";
import { UsersTab } from "./Tabs/UsersTab";
import { InvoicesTab } from "./Tabs/InvoicesTab";
import { SubscriptionTab } from "./Tabs/SubscriptionTab";
import { PaymentsTab } from "./Tabs/PaymentsTab";
import StyledMainButton from "components/buttons/StyledMainButton";
import AddOrganizationDialog from "components/dialogs/AddOrganizationDialog";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "auth/AuthContext";
import { useAuthMachine } from "auth/state/hook";
import { OrganizationType } from "utils/saastypes";

const BASE_PATH = `/home/organization-setting`;

enum TABS {
  INFO = "Info",
  SUBSCRIPTIONS = "Subscriptions",
  INVOICES = "Invoices",
  PAYMENTS = "Payments",
  USERS = "Users",
}

const orgTabRoutes = {
  [TABS.INFO]: `${BASE_PATH}`,
  [TABS.SUBSCRIPTIONS]: `${BASE_PATH}/subscriptions`,
  [TABS.INVOICES]: `${BASE_PATH}/invoices`,
  [TABS.PAYMENTS]: `${BASE_PATH}/payments`,
  [TABS.USERS]: `${BASE_PATH}/users`,
};

export const OrganizationSetting = () => {
  const { pathname } = useLocation();
  const [addOrgDialogOpen, setAddOrgDialogOpen] = useState(false);
  const { authService } = useAuth();
  const [
    {
      currentUser,
      cardInfo,
      isOrkesUser,
      currentOrganization,
      organizationType,
      isLoadingState,
      hasAdminOrRootRole,
    },
  ] = useAuthMachine(authService);

  const {
    data: currentUsers = [],
    isFetching: currentUsersFetching,
    refetch: refetchAllUsers,
  } = useCurrentUsers();

  const {
    data: invitedUsers,
    isFetching: invitedUsersFetching,
    refetch: refetchAllInvites,
  } = useInvitedUsers();

  const { refetch: refetchAllOrganizations } = useUserOrganizations();

  const toggleAddOrgDialog = () => {
    setAddOrgDialogOpen(!addOrgDialogOpen);
  };

  const addOrganizationAction = useActionWithPath({
    onSuccess: () => {
      setAddOrgDialogOpen(false);
      refetchAllOrganizations();
    },
  });

  const addOrganization = (orgName: string) => {
    if (orgName) {
      // @ts-ignore
      addOrganizationAction.mutate({
        method: "post",
        path: `/registerNewUserOrganization?name=${orgName}`,
        body: "",
      });
    }
  };

  const { data: currentOrgOwner } = useUserIds(
    [currentOrganization?.createdBy as string],
    { useLocalMessage: true }
  );

  const isCurrentOrgOwner = useMemo(() => {
    if (!currentOrgOwner || !currentUser?.email) return false;

    const orgOwners = Object.values(currentOrgOwner);
    const userEmail = currentUser.email;

    return orgOwners.some((owner: any) => owner.userEmail === userEmail);
  }, [currentOrgOwner, currentUser.email]);

  const tabLabels = useMemo(
    () => [
      { label: TABS.INFO, hidden: false, to: orgTabRoutes[TABS.INFO] },
      {
        label: TABS.SUBSCRIPTIONS,
        hidden: true,
        to: orgTabRoutes[TABS.SUBSCRIPTIONS],
      },
      { label: TABS.INVOICES, hidden: true, to: orgTabRoutes[TABS.INVOICES] },
      {
        label: TABS.PAYMENTS,
        hidden: organizationType === undefined || !isCurrentOrgOwner,
        to: orgTabRoutes[TABS.PAYMENTS],
      },
      {
        label: TABS.USERS,
        hidden:
          (organizationType === OrganizationType.FREE_TRIAL ||
            !hasAdminOrRootRole) &&
          !isOrkesUser,
        to: orgTabRoutes[TABS.USERS],
      },
    ],
    [isCurrentOrgOwner, organizationType, hasAdminOrRootRole, isOrkesUser]
  );

  const filteredTabs = useMemo(
    () => tabLabels.filter((tab) => !tab.hidden),
    [tabLabels]
  );

  const selectedTabIndex = useMemo(
    () => filteredTabs.findIndex((tab) => tab.to === pathname),
    [filteredTabs, pathname]
  );

  return (
    <>
      <Helmet>
        <title>Orkes Cloud - Organization Settings</title>
      </Helmet>
      {(isLoadingState || currentUsersFetching || invitedUsersFetching) && (
        <LinearProgress />
      )}
      {addOrgDialogOpen && (
        <AddOrganizationDialog
          handleClose={toggleAddOrgDialog}
          handleAdd={addOrganization}
          progressPending={addOrganizationAction.isLoading}
        />
      )}
      <SectionHeader
        title="Organization Settings"
        actions={
          isOrkesUser ? (
            <StyledMainButton
              size="small"
              color={"inherit"}
              style={{
                width: 250,
                fontSize: 14,
                marginRight: 10,
              }}
              onClick={() => {
                toggleAddOrgDialog();
              }}
            >
              Create New Organization
            </StyledMainButton>
          ) : null
        }
      />

      <SectionContainer>
        <Paper
          elevation={1}
          id="organization-settings-wrapper"
          sx={{
            marginRight: "10px",
            marginTop: "16px",
            minHeight: "350px",
          }}
        >
          <StyledTabs
            sx={{
              px: 10,
              "& .MuiTabs-flexContainer": {
                gap: "24px",
              },
            }}
            value={selectedTabIndex}
            variant="scrollable"
            scrollButtons="auto"
          >
            {filteredTabs.map((tab) => (
              <StyledTab
                key={tab.label}
                label={tab.label}
                component={Link}
                to={tab.to}
              />
            ))}
          </StyledTabs>

          {pathname === orgTabRoutes[TABS.INFO] && (
            <InfoTab
              organizationName={currentOrganization?.name}
              organizationId={currentOrganization?.id}
            />
          )}
          {pathname === orgTabRoutes[TABS.SUBSCRIPTIONS] && <SubscriptionTab />}
          {pathname === orgTabRoutes[TABS.INVOICES] && <InvoicesTab />}
          {pathname === orgTabRoutes[TABS.PAYMENTS] && (
            <PaymentsTab
              cardInfo={cardInfo}
              organizationType={organizationType}
            />
          )}
          {pathname === orgTabRoutes[TABS.USERS] && (
            <UsersTab
              currentUsersFetching={currentUsersFetching}
              currentUsers={currentUsers}
              invitedUsersFetching={invitedUsersFetching}
              invitedUsers={invitedUsers}
              refetchAllUsers={refetchAllUsers}
              refetchAllInvites={refetchAllInvites}
            />
          )}
        </Paper>
      </SectionContainer>
    </>
  );
};
