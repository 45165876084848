import { useAuth } from "auth/AuthContext";
import { useMemo, useState } from "react";
import { useActionWithPath } from "utils/query";
import { InvitedUser, Role, User } from "utils/saastypes";
import { getErrorsMessage } from "utils/utils";
import {
  ConfirmDelete,
  ConfirmDeleteInvite,
  ConfirmResend,
  RoleModalData,
  UsersToInvite,
} from "./types";
import { useAuthMachine } from "auth/state/hook";

export const USER_ROLES: Record<string, string> = {
  COMPANY_USER: "User",
  COMPANY_ROOT: "Root",
  COMPANY_ADMIN: "Admin",
};

export const useUserData = ({
  currentUsers = [],
  invitedUsers = [],
  refetchAllUsers,
  refetchAllInvites,
}: {
  currentUsers: User[];
  invitedUsers: InvitedUser[];
  refetchAllUsers: () => void;
  refetchAllInvites: () => void;
}) => {
  const { authService, setSuccessToast, setErrorToast } = useAuth();
  const [{ currentUser, currentUserRoles }] = useAuthMachine(authService);
  const users = useMemo<User[]>(() => currentUsers, [currentUsers]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModal = (val: boolean) => setIsModalOpen(val);

  const [confirmDelete, setConfirmDelete] = useState<ConfirmDelete>({
    confirmDelete: false,
  });
  const [roleModalData, setRoleModalData] = useState<RoleModalData>({
    isOpen: false,
    email: "",
    availableRoles: [],
  });

  const [removeRoleModal, setRemoveRoleModal] = useState({
    isOpen: false,
    email: "",
    roleToBeRemoved: "",
  });

  const [confirmResend, setConfirmResend] = useState<ConfirmResend>({
    confirmResend: false,
  });
  const [confirmDeleteInvite, setConfirmDeleteInvite] =
    useState<ConfirmDeleteInvite>({
      confirmDelete: false,
    });

  const unifiedData = useMemo(
    () => [
      ...users?.map((user) => ({ ...user, isInvited: false })),
      ...invitedUsers?.map((invitedUser) => {
        const { role, userEmail, ...rest } = invitedUser;
        return {
          ...rest,
          email: userEmail,
          roles: [role],
          isInvited: true,
        };
      }),
    ],
    [invitedUsers, users]
  );

  const inviteUserAction = useActionWithPath({});
  const deleteUserAction = useActionWithPath({
    onSuccess: () => {
      setSuccessToast(`Successfully removed user from organization.`);
      refetchAllUsers();
    },
  });

  const removeRoleAction = useActionWithPath({
    onSuccess: () => {
      setSuccessToast(`Successfully removed role from user.`);
      refetchAllUsers();
      setRemoveRoleModal({
        isOpen: false,
        roleToBeRemoved: "",
        email: "",
      });
    },
    onError: () => {
      setRemoveRoleModal({
        isOpen: false,
        roleToBeRemoved: "",
        email: "",
      });
    },
  });

  const addRoleAction = useActionWithPath({
    onSuccess: () => {
      setSuccessToast(`Successfully added role to user.`);
      refetchAllUsers();
    },
  });

  const resendInviteAction = useActionWithPath({
    onSuccess: () => {
      setSuccessToast(`Successfully resent invite to user.`);
      refetchAllInvites();
    },
  });

  const deleteInviteAction = useActionWithPath({
    onSuccess: () => {
      setSuccessToast(`Successfully deleted invite.`);
      refetchAllInvites();
    },
  });

  const handleAddInvite = async (usersToAdd: UsersToInvite[]) => {
    handleModal(false);
    try {
      await Promise.all(
        usersToAdd.map((x) => {
          // @ts-ignore
          return inviteUserAction.mutateAsync({
            method: "post",
            path: `/users/invite?email=${x.name}&role=${x.role.id}`,
            useLocalMessage: true,
          });
        })
      );
      if (usersToAdd.length === 1) {
        setSuccessToast(
          `Successfully invited ${decodeURIComponent(
            usersToAdd[0]?.name ?? "user"
          )} to organization`
        );
      } else {
        setSuccessToast(`Successfully invited users to organization`);
      }
    } catch (err: any) {
      try {
        const message = await getErrorsMessage(err);

        if (message) {
          setErrorToast(`Error invoking request: ${message}`);
        }
      } catch (e) {
        setErrorToast(`Error invoking request: ${JSON.stringify(err)}`);
      }
    } finally {
      refetchAllUsers();
      refetchAllInvites();
    }
  };

  const handleOpenRoleModal = (val: Role[], email: string) => {
    const availableRoles = Object.keys(USER_ROLES).filter(
      (x: any) => val.indexOf(x) === -1
    );
    setRoleModalData({
      isOpen: true,
      email,
      availableRoles,
    });
  };

  const handleCloseRoleModal = () => {
    setRoleModalData({ isOpen: false, email: "", availableRoles: [] });
  };

  const handleOpenDeleteUserModal = (email: string, userId?: string) => {
    setConfirmDelete({
      confirmDelete: true,
      userEmail: email,
      userId: userId,
    });
  };

  const handleCloseDeleteUserModal = () =>
    setConfirmDelete({ confirmDelete: false });

  const handleOpenResendInviteModal = (email: string, role: string) => {
    setConfirmResend({
      confirmResend: true,
      userEmail: encodeURIComponent(email),
      userRole: role,
    });
  };

  const handleCloseResendInviteModal = () =>
    setConfirmResend({
      confirmResend: false,
    });

  const handleCloseDeleteInviteModal = () =>
    setConfirmDeleteInvite({
      confirmDelete: false,
    });

  const handleAddRole = async (roleName: string, userId: string) => {
    setRoleModalData({ isOpen: false, email: "", availableRoles: [] });
    // @ts-ignore
    addRoleAction.mutate({
      method: "post",
      path: `/users/assignRole?roleName=${roleName}&userId=${encodeURIComponent(
        userId
      )}`,
    });
  };

  const handleOpenDeleteInviteModal = (
    userEmail: string,
    inviteId?: string
  ) => {
    setConfirmDeleteInvite({
      confirmDelete: true,
      inviteId,
      userEmail,
    });
  };

  const handleDeleteUser = () => {
    // @ts-ignore
    deleteUserAction.mutate({
      method: "delete",
      path: `/users?id=${confirmDelete.userId}`,
    });
    setConfirmDelete({ confirmDelete: false });
  };

  const handleResendInvite = () => {
    // @ts-ignore
    resendInviteAction.mutate({
      method: "post",
      path: `/users/resendInvite?email=${confirmResend.userEmail}&role=${confirmResend.userRole}`,
    });
    setConfirmResend({
      confirmResend: false,
      userEmail: undefined,
      userRole: undefined,
    });
  };

  const handleDeleteInvite = () => {
    // @ts-ignore
    deleteInviteAction.mutate({
      method: "delete",
      path: `/users/deleteInvite/${confirmDeleteInvite.inviteId}`,
    });
    setConfirmDeleteInvite({
      confirmDelete: false,
      userEmail: undefined,
      inviteId: undefined,
    });
  };

  const handleOpenRemoveRoleModal = (role: string, email: string) =>
    setRemoveRoleModal({
      isOpen: true,
      email,
      roleToBeRemoved: role,
    });

  const handleCloseRemoveRoleModal = () => {
    setRemoveRoleModal({ isOpen: false, email: "", roleToBeRemoved: "" });
  };

  const handleRemoveRole = () => {
    const { roleToBeRemoved, email } = removeRoleModal;
    if (roleToBeRemoved && email) {
      // @ts-ignore
      removeRoleAction.mutate({
        method: "post",
        path: `/users/removeRole?roleName=${
          removeRoleModal.roleToBeRemoved
        }&userId=${encodeURIComponent(email)}`,
      });
    }
  };

  return [
    {
      isModalOpen,
      currentUserRoles,
      unifiedData,
      roleModalData,
      confirmDelete,
      confirmDeleteInvite,
      currentUser,
      confirmResend,
      removeRoleModal,
    },
    {
      handleModal,
      handleAddInvite,
      handleRemoveRole,
      handleOpenRoleModal,
      handleCloseRoleModal,
      handleAddRole,
      handleOpenDeleteUserModal,
      handleCloseDeleteUserModal,
      handleDeleteUser,
      handleOpenResendInviteModal,
      handleCloseResendInviteModal,
      handleResendInvite,
      handleOpenDeleteInviteModal,
      handleCloseDeleteInviteModal,
      handleDeleteInvite,
      handleCloseRemoveRoleModal,
      handleOpenRemoveRoleModal,
    },
  ] as const;
};
