import { DoneInvokeEvent } from "xstate";

export type CheckoutContext = {
  clientSecret?: string;
  accessToken?: string;
};

export enum CheckoutStates {
  FETCHING_CLIENT_SECRET = "FETCHING_CLIENT_SECRET",
  CLIENT_SECRET_NOT_AVAILABLE = "CLIENT_SECRET_NOT_AVAILABLE",
  IDLE = "IDLE",
  SETUP_INTENT = "SETUP_INTENT",
  FETCH_SETUP_INTENT_STATUS = "FETCH_SETUP_INTENT_STATUS",
  REFETCH_SETUP_INTENT = "REFETCH_SETUP_INTENT",
  SETUP_INTENT_SUCCESS = "SETUP_INTENT_SUCCESS",
  FINAL = "FINAL",
}

export enum CheckoutEventTypes {
  REFETCH_CLIENT_SECRET = "REFETCH_CLIENT_SECRET",
  FETCH_SETUP_INTENT_STATUS = "FETCH_SETUP_INTENT_STATUS",
}

export type RefetchClientSecretEvent = {
  type: CheckoutEventTypes.REFETCH_CLIENT_SECRET;
};

export type FetchSetupIntentStatusEvent = {
  type: CheckoutEventTypes.FETCH_SETUP_INTENT_STATUS;
};

export type CheckoutEvents =
  | RefetchClientSecretEvent
  | FetchSetupIntentStatusEvent
  | DoneInvokeEvent<any>;
