import { Key, ReactNode } from "react";
import dayjs from "dayjs";
import { Avatar, Box, Chip, Tooltip, Typography } from "@mui/material";

import { TabPanel } from "./TabPanel";
import { useClusterHealth } from "utils/query";
import { AccountBox, Public, Settings } from "@mui/icons-material";
import conductor from "../../../images/conductor.png";
import { FORMAT_DATE_SHORT } from "constants/common";
import { ClusterHealthHistoryDTO, StatusHistoryType } from "utils/saastypes";

const failMild = "#fbe192";
const failModerate = "#F3C19D";
const failSevere = "#E9A2AD";

const failColor = "#E9A2AD";
const successColor = "#23B1A3";
const neutralColor = "lightgray";

const unknownColor = "darkgray";

const graphHeight = 36;
const barWidth = 6;
const barGap = 4;

const getStatusBarColor = (entry: StatusHistoryType) => {
  if (entry.down === 0) {
    return successColor;
  }

  // < 1h mild
  if (entry.down / entry.up < 0.04) {
    return failMild;
  }

  if (entry.down / entry.up < 0.25) {
    return failModerate;
  }

  // moderate < 6h and >= 6h severe
  return failSevere;
};

export default function PodsTab({
  selectedTabIndex,
  index,
  clusterDetail,
}: {
  selectedTabIndex: number;
  index: number;
  clusterDetail: any;
}) {
  const { data: health, isFetching } = useClusterHealth(clusterDetail.name);

  return (
    <TabPanel value={selectedTabIndex} index={index}>
      <Box
        style={{
          marginTop: 24,
          padding: 24,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderBottom: "1px solid lightgrey",
        }}
      >
        {isFetching && <div>Loading...</div>}
        {!isFetching && (
          <>
            <HealthDisplay
              data={health}
              name="Conductor"
              icon={
                <Avatar
                  src={conductor}
                  sx={{ width: 20, height: 20, marginRight: 1 }}
                />
              }
            />
            <HealthDisplay
              data={health}
              name="Agent"
              icon={<AccountBox sx={{ fontSize: 22, marginRight: 1 }} />}
            />
            <HealthDisplay
              data={health}
              name="Workers"
              icon={<Settings sx={{ fontSize: 22, marginRight: 1 }} />}
            />
            <HealthDisplay
              data={health}
              name="DNS"
              icon={<Public sx={{ fontSize: 22, marginRight: 1 }} />}
            />
            {health?.snapshot?.domains && (
              <SnapshotHealthDisplay domains={health.snapshot.domains} />
            )}
          </>
        )}
      </Box>
    </TabPanel>
  );
}

function formatHistory(history: StatusHistoryType[]) {
  let output: StatusHistoryType[] = [];

  // Generate a list of dates from 90 days before today
  const allDates: string[] = [];

  for (let i = 89; i >= 0; i--) {
    const date = dayjs().subtract(i, "day").format(FORMAT_DATE_SHORT);
    allDates.push(date);
  }

  // Check if each date exists in the array, if not, add it with default values
  allDates.forEach((date) => {
    const existedDate = history.find((entry) => date === entry.date);

    if (existedDate) {
      output.push(existedDate);
    } else {
      output.push({
        date,
        up: -1,
        down: -1,
      });
    }
  });

  return [...output];
}

interface StatusBarProps {
  key?: Key;
  index: number;
  entry: StatusHistoryType;
  fillColor: string;
  content: ReactNode;
}

const StatusBar = ({
  key,
  index,
  entry,
  fillColor,
  content,
}: StatusBarProps) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  return (
    <Tooltip
      key={key}
      arrow
      title={
        <div style={{ fontSize: 14, fontWeight: 600, width: 160 }}>
          <div>{`${new Date(entry.date).toLocaleString(
            "en-US",
            options
          )}`}</div>
          <div>{content}</div>
        </div>
      }
    >
      <rect
        key={index}
        x={index * (barWidth + barGap)}
        y="0"
        width={barWidth}
        height={graphHeight}
        fill={fillColor}
      />
    </Tooltip>
  );
};

function HealthDisplay({
  icon,
  name,
  data,
}: {
  icon?: any;
  name: string;
  data: ClusterHealthHistoryDTO;
}) {
  const cluster = data?.clusters[name];
  const history = cluster?.history ? formatHistory(cluster?.history) : [];
  const uptime = cluster?.uptime ? `${cluster.uptime.toFixed(1)} %` : "unknown";
  const lastReported = (
    <Tooltip
      arrow
      title={
        <div style={{ fontSize: 14, fontWeight: 600, width: 170 }}>
          <div>Last seen at</div>
          <div>{`${new Date(cluster?.last?.dateTime).toLocaleString(
            "en-US"
          )}`}</div>
        </div>
      }
    >
      <span>
        {
          {
            up: (
              <span style={{ color: successColor, fontWeight: 800 }}>
                Healthy
              </span>
            ),
            down: (
              <span style={{ color: failColor, fontWeight: 800 }}>
                Not healthy
              </span>
            ),
            unknown: <span style={{ color: neutralColor }}>Unknown</span>,
          }[(cluster?.last?.status as string) || "unknown"]
        }
      </span>
    </Tooltip>
  );

  return (
    <Box
      style={{
        padding: 18,
        paddingTop: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignSelf: "stretch",
        }}
      >
        <div
          style={{
            display: "flex",
            fontFamily: "Lexend",
            fontWeight: 600,
            width: 120,
            alignItems: "center",
          }}
        >
          {icon || ""} {name}
        </div>
        <div
          style={{
            width: 200,
            fontWeight: 600,
            fontSize: 14,
            color: "grey",
            textAlign: "center",
          }}
        >
          Uptime: {uptime}
        </div>
        <div>Last reported: {lastReported}</div>
      </Box>
      <svg
        style={{
          marginTop: 6,
          marginBottom: 6,
          cursor: "pointer",
        }}
        viewBox={`0 0 896 ${graphHeight}`}
        height={graphHeight}
      >
        {history.map((entry, index: number) => {
          if (entry.up === -1) {
            return (
              <StatusBar
                key={`no-data-available-${index}`}
                index={index}
                entry={entry}
                fillColor={neutralColor}
                content={"No data available"}
              />
            );
          }

          return (
            <StatusBar
              key={`available-status-${index}`}
              index={index}
              entry={entry}
              fillColor={getStatusBarColor(entry)}
              content={
                entry.down === 0
                  ? "No downtime reported"
                  : `${entry.down * data.window.width} ${
                      data.window.unit
                    } downtime`
              }
            />
          );
        })}
      </svg>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          fontSize: 14,
          color: "#555555",
          justifyContent: "space-between",
          alignSelf: "stretch",
        }}
      >
        <div>90 days ago</div>
        <div>Today</div>
      </Box>
    </Box>
  );
}

function SnapshotHealthDisplay({ domains }: { domains: any[] }) {
  const now = new Date();

  return (
    <Box
      style={{
        padding: 18,
        paddingTop: 0,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        flexWrap: "wrap",
        fontSize: 14,
        width: 896,
      }}
    >
      {domains?.length ? (
        domains.map((domain: any) => {
          const expiring = domain.certValidity && new Date(domain.certValidity);
          const days =
            expiring &&
            Math.ceil(
              (expiring.getTime() - now.getTime()) / (1000 * 3600 * 24)
            );
          return (
            <Box
              key={domain.domain}
              style={{
                display: "flex",
                flexDirection: "column",
                fontFamily: "Lexend",
                fontWeight: 600,
                alignItems: "center",
                paddingBottom: 5,
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Public
                  sx={{
                    fontSize: 18,
                    marginRight: 0.5,
                    color: domain.healthy ? successColor : failColor,
                  }}
                />
                {domain.domain}
              </div>
              {domain.ips?.length ? (
                <span
                  style={{
                    color: successColor,
                    fontWeight: 600,
                    fontFamily: "monospace",
                    fontSize: 12,
                  }}
                >
                  {domain.ips.map((ip: any) => (
                    <Chip
                      key={ip}
                      size="small"
                      label={ip}
                      sx={{ margin: 0.2 }}
                      variant="outlined"
                    />
                  ))}
                </span>
              ) : (
                <span style={{ color: failSevere, fontWeight: 600 }}>
                  Unreachable at the moment
                </span>
              )}
              <Typography variant="caption">
                SSL/TLS Certificate&nbsp;
                {domain.certValidity ? (
                  days < 0 ? (
                    <span style={{ color: failSevere, fontWeight: 600 }}>
                      expired in {expiring.toLocaleDateString()}
                    </span>
                  ) : days === 0 ? (
                    <span style={{ color: failSevere, fontWeight: 600 }}>
                      expires today
                    </span>
                  ) : days <= 30 ? (
                    <span style={{ color: failModerate, fontWeight: 600 }}>
                      will expire in {days} days
                    </span>
                  ) : (
                    <span style={{ color: successColor, fontWeight: 600 }}>
                      valid until {expiring.toLocaleDateString()}
                    </span>
                  )
                ) : (
                  <span style={{ color: unknownColor, fontWeight: 600 }}>
                    validity unknown
                  </span>
                )}
              </Typography>
            </Box>
          );
        })
      ) : (
        <div
          style={{
            display: "flex",
            fontFamily: "Lexend",
            fontWeight: 600,
            width: 600,
            alignItems: "top",
            color: neutralColor,
          }}
        >
          <Public
            sx={{
              fontSize: 22,
              marginRight: 1,
              marginTop: 0.3,
            }}
          />
          DNS data not available
        </div>
      )}
    </Box>
  );
}
