import { useEnv } from "./env";
import { RESET_PASSWORD_URL, VERIFY_USER_URL } from "./routePaths";

export function useFetchContext() {
  const { stack } = useEnv();
  return {
    stack,
    ready: true,
  };
}
export async function fetchWithContext(
  path: string,
  context: object,
  fetchParams: object,
  isText: boolean = false
) {
  const newParams: any = { ...fetchParams };
  const newPath = path.startsWith("/") ? `/api${path}` : `/api/${path}`;
  const cleanPath = newPath.replace(/([^:]\/)\/+/g, "$1"); // Cleanup duplicated slashes
  const res = await fetch(cleanPath, newParams);

  if (res.status === 401) {
    const authorizedExceptionPaths = [VERIFY_USER_URL, RESET_PASSWORD_URL];
    if (authorizedExceptionPaths.includes(cleanPath)) {
      return null;
    }
    // This should not be here

    // window.location.href = UNAUTHORIZED_ROUTE_URL;
    // return null;
  }

  if (!res.ok) {
    throw res;
  }

  const text = await res.text();

  if (!text || text.length === 0) {
    return null;
  } else {
    return isText ? text : JSON.parse(text);
  }
}
