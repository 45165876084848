import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ActionButton from "../buttons/ActionButton";
import ClusterField from "../../components/ClusterField";
import Input from "../Input";

const USER_ROLES = {
  COMPANY_USER: "User",
  COMPANY_ROOT: "Root",
  COMPANY_ADMIN: "Admin",
};

export default function AddRoleToUserDialog({
  handleAdd,
  handleClose,
  progressPending = false,
  modalData,
}: {
  handleAdd: (selectedRole: string, userId: string) => void;
  handleClose: () => void;
  progressPending?: boolean;
  modalData: any;
}) {
  const [selectedRole, setSelectedRole] = useState(modalData.availableRoles[0]);

  return (
    <Dialog fullWidth maxWidth="sm" open onClose={handleClose}>
      <DialogTitle>Add Role to User</DialogTitle>
      <DialogContent>
        <Box
          mt={4}
          style={{
            display: "flex",
          }}
        >
          <Input
            label="User email"
            fullWidth
            value={modalData.email}
            disabled={true}
            helperText={"Email of the user"}
            sx={{
              marginRight: 1,
              "& label": {
                marginTop: "-6px",
              },
              "& label.MuiFormLabel-filled": {
                marginTop: 0,
              },
              "& label.Mui-focused": {
                marginTop: 0,
              },
              "& input": {
                height: 8,
              },
            }}
          />
          <ClusterField
            id={"role"}
            onChange={(
              e: any,
              value: any | undefined,
              reason: string | undefined
            ) => {
              setSelectedRole(value.id);
            }}
            isOptionEqualToValue={(option: any, value: any) =>
              option.id === value.id
            }
            items={modalData.availableRoles.map((x: string) => {
              return {
                id: x,
                title: (USER_ROLES as Record<string, string>)[x],
              };
            })}
            label={""}
            value={{
              id: modalData.availableRoles[0],
              title: (USER_ROLES as Record<string, string>)[
                modalData.availableRoles[0]
              ],
            }}
            helperText={"Role"}
            type={"select"}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClose}
          style={{ margin: 8, fontSize: 14, textTransform: "none" }}
          sx={{
            display: "inline",
            mx: 0.5,
          }}
        >
          Cancel
        </Button>
        <ActionButton
          variant="contained"
          color="primary"
          progress={progressPending}
          onClick={() => {
            handleAdd(selectedRole, modalData.email);
          }}
          style={{ margin: 8, fontSize: 14, textTransform: "none" }}
          sx={{
            display: "inline",
            mx: 0.5,
          }}
        >
          Add Role to User
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
}
