const ChatIcon = ({ size = 20, ...props }: any) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.62 10H16V3.83C16 2.82 15.18 2 14.17 2H3.82C2.82 2 2 2.82 2 3.83V11.17C2 12.18 2.82 12.99 3.82 12.99H4.98V15.14C4.98 15.34 5.1 15.53 5.29 15.6C5.35 15.63 5.42 15.64 5.48 15.64C5.61 15.64 5.74 15.59 5.84 15.49L8.29 12.99H10V14.63C10 15.39 10.62 16.01 11.38 16.01H13.76L15.4 17.61C15.5 17.7 15.62 17.75 15.75 17.75C15.82 17.75 15.88 17.74 15.94 17.71C16.13 17.63 16.25 17.45 16.25 17.25V16.01H16.62C17.38 16.01 18 15.39 18 14.63V11.37C18 10.61 17.38 9.99 16.62 9.99V10ZM10 11.38V12H8.08C7.95 12 7.82 12.05 7.72 12.15L5.98 13.92V12.5C5.98 12.22 5.76 12 5.48 12H3.82C3.37 12 3 11.63 3 11.18V3.83C3 3.37 3.37 3 3.82 3H14.17C14.63 3 15 3.37 15 3.83V10H11.38C10.62 10 10 10.62 10 11.38ZM16.99 14.64C16.99 14.85 16.82 15.02 16.61 15.02H15.74C15.46 15.02 15.24 15.24 15.24 15.52V16.08L14.3 15.16C14.21 15.07 14.08 15.02 13.95 15.02H11.36C11.15 15.02 10.98 14.85 10.98 14.64V11.38C10.98 11.17 11.15 11 11.36 11H16.6C16.81 11 16.98 11.17 16.98 11.38V14.64H16.99ZM13 6H4.99V5H13V6ZM13 7.99H5V6.99H13V7.99ZM5.01 9.01H9V10.01H5.01V9.01Z"
      fill="currentColor"
    />
  </svg>
);

export default ChatIcon;
