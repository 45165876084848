import { useCallback, useState } from "react";
import AlertDialog from "components/AlertDialog";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  TextField,
  Paper,
} from "@mui/material";
import { customStyles } from "utils/utils";
import DataTable from "components/DataTable/DataTable";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

import { useDomainTags, useAction } from "utils/query";

const URL_ENTITY_TYPE = "CLUSTER_URL";

interface ConfirmDeleteTagDialogData {
  showConfirmDelete: boolean;
  entityId?: string;
  tagName?: string;
  tagValue?: string;
}

interface TagFormData {
  entityId: string;
  tagName: string;
  tagValue: string;
  editMode: boolean;
}

const TagsDialog = ({
  open,
  setOpen,
  entityId = "",
}: {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  entityId: string;
}) => {
  const { data: tags = [], refetch: refetchTags } = useDomainTags(entityId);

  const [confirmDeleteTagDialogData, setConfirmDeleteTagDialogData] =
    useState<ConfirmDeleteTagDialogData>({
      showConfirmDelete: false,
      entityId: "",
      tagName: "",
      tagValue: "",
    });

  const [tagFormData, setTagFormData] = useState<TagFormData>({
    entityId: "",
    tagName: "",
    tagValue: "",
    editMode: false,
  });

  const { mutate: saveTags } = useAction(
    "/tags",
    "post",
    {
      onSuccess: () => {
        refetchTags();
        setTagFormData({
          entityId: "",
          tagName: "",
          tagValue: "",
          editMode: false,
        });
      },
      onError: (err: any) => {
        console.error("There was an error saving tags", err);
      },
    },
    false
  );

  const { mutate: deleteTag } = useAction(
    "/tags",
    "delete",
    {
      onSuccess: () => {
        refetchTags();
      },
      onError: (err: any) => {
        console.error("There was an error saving tags", err);
      },
    },
    false
  );

  const showDeleteConfirm = useCallback(
    (entityId: string, tagName: string, tagValue: string) => {
      setConfirmDeleteTagDialogData({
        showConfirmDelete: true,
        entityId,
        tagName,
        tagValue,
      });
    },
    [setConfirmDeleteTagDialogData]
  );

  const handleSave = useCallback(() => {
    // @ts-ignore
    saveTags({
      method: "post",
      path: `/tags`,
      body: JSON.stringify({
        entityType: URL_ENTITY_TYPE,
        entityId,
        tagName: tagFormData.tagName,
        tagValue: tagFormData.tagValue,
      }),
    });
    refetchTags();
  }, [entityId, tagFormData, saveTags, refetchTags]);

  const columns = [
    {
      name: "name",
      label: "Tag Name",
      selector: (row: any) => row.tagName,
    },
    {
      name: "value",
      label: "Value",
      selector: (row: any) => row.tagValue,
    },
    {
      name: "action",
      label: "Action",
      right: true,
      selector: (row: any) => row.tagName | row.tagValue,
      renderer(val: any, row: any) {
        return (
          <>
            <Button
              variant="outlined"
              onClick={() => {
                setTagFormData({
                  entityId: row.entityId,
                  tagName: row.tagName,
                  tagValue: row.tagValue,
                  editMode: true,
                });
              }}
              sx={{
                textTransform: "none",
              }}
            >
              Edit
            </Button>
            <Button
              variant="outlined"
              color="error"
              sx={{ marginLeft: 2, textTransform: "none" }}
              onClick={() =>
                showDeleteConfirm(row.entityId, row.tagName, row.tagValue)
              }
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <AlertDialog
        textTitle={"Confirm tag deletion"}
        textContent={
          <>
            Are you sure you want to delete the tag&nbsp;
            <strong className="danger-text">
              {confirmDeleteTagDialogData?.tagName}
            </strong>{" "}
            ?
          </>
        }
        open={confirmDeleteTagDialogData.showConfirmDelete}
        setOpen={() =>
          setConfirmDeleteTagDialogData({
            showConfirmDelete: false,
            entityId: undefined,
            tagName: confirmDeleteTagDialogData.tagName,
            tagValue: confirmDeleteTagDialogData.tagValue,
          })
        }
        handleConfirm={() => {
          // @ts-ignore
          deleteTag({
            method: "delete",
            path: "/tags",
            body: JSON.stringify({
              entityId,
              entityType: URL_ENTITY_TYPE,
              tagName: confirmDeleteTagDialogData.tagName,
              tagValue: confirmDeleteTagDialogData.tagValue,
            }),
          });

          setConfirmDeleteTagDialogData({
            showConfirmDelete: false,
            entityId: undefined,
            tagName: confirmDeleteTagDialogData.tagName,
            tagValue: confirmDeleteTagDialogData.tagValue,
          });
        }}
      />
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={() => setOpen(!open)}
      >
        <DialogTitle id="alert-dialog-title">{"Tags Editor"}</DialogTitle>
        <DialogContent>
          <Box>
            <Grid container>
              <Paper
                variant="outlined"
                sx={{
                  width: "100%",
                  mb: 2,
                }}
              >
                <DataTable
                  customStyles={customStyles}
                  progressPending={false}
                  defaultShowColumns={["name", "value", "action"]}
                  data={tags}
                  defaultSortFieldId={"name"}
                  columns={columns}
                />
              </Paper>
            </Grid>
            <form>
              <Paper
                variant="outlined"
                sx={{
                  padding: 2,
                }}
              >
                <Box
                  sx={{
                    pb: 4,
                    fontSize: 12,
                    opacity: 0.6,
                  }}
                >
                  Use the form below to add or edit tags.
                </Box>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item sm={12} md={4}>
                    <FormControl fullWidth>
                      <TextField
                        value={tagFormData.tagName}
                        size="small"
                        onChange={(e) =>
                          setTagFormData({
                            ...tagFormData,
                            tagName: e.target.value,
                          })
                        }
                        label="Tag Name"
                        placeholder="Input tag name"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={4}>
                    <FormControl fullWidth>
                      <TextField
                        value={tagFormData.tagValue}
                        size="small"
                        onChange={(e) =>
                          setTagFormData({
                            ...tagFormData,
                            tagValue: e.target.value,
                          })
                        }
                        label="Tag Value"
                        placeholder="Input tag value"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={4}>
                    <Button
                      variant="outlined"
                      sx={{ textTransform: "none" }}
                      onClick={handleSave}
                    >
                      {tagFormData.editMode ? "Update" : "Save new tag"}
                    </Button>
                    {tagFormData.editMode ? (
                      <Button
                        variant="outlined"
                        color="error"
                        sx={{ textTransform: "none", ml: 2 }}
                        onClick={() => {
                          setTagFormData({
                            entityId: "",
                            tagName: "",
                            tagValue: "",
                            editMode: false,
                          });
                        }}
                      >
                        Cancel
                      </Button>
                    ) : null}
                  </Grid>
                </Grid>
              </Paper>
            </form>
          </Box>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};

export default TagsDialog;
