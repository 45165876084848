import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import { SupportType } from "utils/saastypes";
import { isSupportPlanDisabled, styles, supportContent } from "./common";
import { SupportModalProps } from "./types";
import { SupportCard } from "./SupportCard";

export const SupportModal = ({
  open,
  onClose,
  availableSupportPlan = [SupportType.BASIC],
}: SupportModalProps) => {
  const isPremiumSupportAvailable = availableSupportPlan.includes(
    SupportType.PREMIUM
  );

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      PaperProps={{
        ...styles.dialogPaper,
        maxWidth: isPremiumSupportAvailable ? 560 : 960,
      }}
      sx={{ zIndex: 1999 }}
      onClose={onClose}
    >
      <DialogTitle sx={styles.dialogTitle}>
        Support
        <Typography sx={styles.dialogSubtitle}>
          {isPremiumSupportAvailable
            ? `Please reach out for support in your preferred channel.`
            : `Please reach out according to your support add-on plan.`}
        </Typography>
      </DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        <Box
          sx={
            isPremiumSupportAvailable
              ? styles.premiumSupportContainer
              : styles.supportContainer
          }
        >
          {isPremiumSupportAvailable ? (
            <SupportCard
              support={supportContent.find((s) => s.title === "Premium")!}
              premiumSupport={true}
            />
          ) : (
            supportContent.map((support) => (
              <SupportCard
                key={support.title}
                support={support}
                disabled={isSupportPlanDisabled(
                  availableSupportPlan,
                  support.title
                )}
              />
            ))
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
