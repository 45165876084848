import { Box, IconButton, Paper } from "@mui/material";
import { Helmet } from "react-helmet";
import StyledMainButton from "components/buttons/StyledMainButton";
import SectionHeader from "shared/SectionHeader";
import { useContext, useMemo, useState } from "react";
import DataTable from "components/DataTable/DataTable";
import { customStyles } from "utils/utils";
import { DeleteForeverRounded } from "@mui/icons-material";
import { useActionWithPath, useSignupPermittedValues } from "utils/query";
import { useAuth } from "auth/AuthContext";
import PermitEmailDialog from "components/dialogs/PermitEmailDialog";
import { Message } from "components/SnackbarMessage";
import AlertDialog from "components/AlertDialog";
import LinearProgress from "components/LinearProgress";
import { MessageContext } from "utils/MessageProvider";
import { useAuthMachine } from "auth/state/hook";

interface PermittedUser {
  userId: string;
  email: string;
  name: string;
  roles: string[];
}

const PermittedUsers = () => {
  const { authService } = useAuth();
  const [{ currentUser }] = useAuthMachine(authService);

  const {
    data: signupPermittedValue,
    isFetching: signupPermittedValueFetching,
    refetch: refetchSignupPermittedValue,
  } = useSignupPermittedValues();

  const [openPermitDialog, setOpenPermitDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState<{ email: string } | null>(
    null
  );
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const { setMessage } = useContext(MessageContext);

  const showSuccess = (message: string) => {
    setMessage(new Message(message, "success"));
    setTimeout(() => setMessage(null), 10000);
  };

  const showError = (message: string) => {
    setMessage(new Message(message, "error"));
    setTimeout(() => setMessage(null), 10000);
  };

  const permitEmailAction = useActionWithPath({});

  const removePermittedEmailAction = useActionWithPath({
    onSuccess: () => {
      refetchSignupPermittedValue();
    },
  });

  const togglePermittedDialog = () => {
    setOpenPermitDialog(!openPermitDialog);
  };

  const handleAdd = async (emails: { email: string }[]) => {
    setOpenPermitDialog(false);

    try {
      await Promise.all(
        emails.map((x) => {
          // @ts-ignore
          return permitEmailAction.mutateAsync({
            method: "post",
            path: `/permitEmail?email=${x.email}`,
            useLocalMessage: true,
          });
        })
      );

      if (emails.length === 1) {
        showSuccess(`Successfully permitted ${emails[0].email}`);
      } else {
        showSuccess(`Successfully permitted users`);
      }
    } catch (err: any) {
      console.error(err);
      try {
        const json = await err.json();
        if (json) {
          showError(`Error invoking request: ${JSON.stringify(json.message)}`);
        }
      } catch (e) {
        console.log("ERROR:", e);
        showError(`Error invoking request: ${JSON.stringify(err)}`);
      }
    } finally {
      refetchSignupPermittedValue();
    }
  };

  const permittedUsers = useMemo(() => {
    const result: any[] = [];

    if (signupPermittedValue?.EMAIL?.length) {
      signupPermittedValue.EMAIL.forEach((email: string) =>
        result.push({ email })
      );
    }

    return result;
  }, [signupPermittedValue]);

  return (
    <>
      <Helmet>
        <title>Signup Permitted Users</title>
      </Helmet>

      <SectionHeader
        title={`Signup Permitted Users`}
        actions={
          <StyledMainButton
            size="small"
            color={"inherit"}
            style={{
              width: 250,
              fontSize: 14,
            }}
            onClick={togglePermittedDialog}
          >
            Add User To Permitted
          </StyledMainButton>
        }
        _deprecate_marginTop={0}
        style={{ padding: "20px 0" }}
      />

      {openPermitDialog && (
        <PermitEmailDialog
          open={openPermitDialog}
          progressPending={permitEmailAction.isLoading}
          handleAdd={handleAdd}
          handleClose={togglePermittedDialog}
        />
      )}

      <AlertDialog
        textTitle={"Removing Confirmation"}
        textContent={
          <>
            Are you sure you want to remove&nbsp;
            <strong className="danger-text">{selectedUser?.email}</strong>?
          </>
        }
        open={openConfirmation}
        setOpen={() => setOpenConfirmation(false)}
        handleConfirm={() => {
          // @ts-ignore
          removePermittedEmailAction.mutate({
            method: "delete",
            path: `/removePermittedEmail?email=${selectedUser?.email}`,
            errorMessage: "Error removing permitted user:",
            successMessage: "Successfully removed permitted user.",
          });
          setOpenConfirmation(false);
        }}
        isInputConfirmation
        valueToBeDeleted={selectedUser?.email}
      />

      {signupPermittedValueFetching && <LinearProgress />}

      <Paper elevation={1}>
        <DataTable
          customStyles={customStyles}
          progressPending={signupPermittedValueFetching}
          pagination={false}
          noDataComponent={
            <Box padding={5} fontWeight={600}>
              No users found
            </Box>
          }
          data={permittedUsers}
          columns={[
            {
              name: "email",
              label: "User Email",
              selector: (row: PermittedUser) => row.email,
              grow: 1,
            },
            {
              name: "actions",
              label: "Actions",
              selector: (row: PermittedUser) => null,
              right: true,
              renderer(val: any, row: any) {
                return currentUser?.email !== row.email ? (
                  <IconButton
                    title={"Remove permitted user"}
                    onClick={(ev) => {
                      setOpenConfirmation(true);
                      setSelectedUser(row);
                    }}
                  >
                    <DeleteForeverRounded />
                  </IconButton>
                ) : null;
              },
            },
          ]}
        />
      </Paper>
    </>
  );
};

export default PermittedUsers;
