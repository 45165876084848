export const ProtectIcon = () => (
  <svg
    className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall ProtectedByStripe_icon__vS3pi css-1k33q06"
    focusable="false"
    fill="#38b42c"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="SecurityIcon"
    width={16}
    height={16}
  >
    <path d="M12 1 3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm0 10.99h7c-.53 4.12-3.28 7.79-7 8.94V12H5V6.3l7-3.11v8.8z"></path>
  </svg>
);
