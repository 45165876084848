import AlertDialog from "components/AlertDialog";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CustomerEnvironment } from "utils/saastypes";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useActionWithPath } from "utils/query";
import { AGENT_COMMAND_PATH } from "utils/apiPaths";
import { CLUSTER_ACTION_TYPE } from "constants/common";

const ScaleResourceTypes = [
  {
    name: "Conductor Server",
    value: "CONDUCTOR",
  },
  {
    name: "Conductor Workers",
    value: "WORKERS",
  },
];

const ScaleDeploymentDialog = ({
  clusterName,
  open,
  closeDialog,
  clusterDetail,
  showError,
  showSuccess,
  refetchLogs,
}: {
  clusterName?: string;
  open: boolean;
  closeDialog: () => void;
  clusterDetail: CustomerEnvironment;
  showError: (message: string) => void;
  showSuccess: (message: string) => void;
  refetchLogs: () => void;
}) => {
  const navigate = useNavigate();

  const formSchema = yup.object().shape({
    replicas: yup
      .number()
      .required("Number of pod replicas is required")
      .integer("Number of pod replicas must be integer")
      .typeError("Number of pod replicas is required"),
    resource: yup
      .string()
      .required("Resource type is required")
      .typeError("Resource type is required"),
  });
  const defaultValues = {
    replicas: "",
    resource: ScaleResourceTypes[0].value,
  };
  const {
    control,
    formState: { errors: formErrors, isValid },
    handleSubmit,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(formSchema),
    defaultValues,
  });

  const scaleClusterAction = useActionWithPath({
    onSuccess: (data: any) => {
      if (data?.status === "ERROR") {
        showError("Scale deployment failed");
      } else {
        showSuccess(`Scale deployment has been scheduled successfully.`);
        if (data.id) {
          navigate(`/home/clusterDetails/${clusterName}/history`);
        }
      }
      refetchLogs();
    },
    onError: async (response: Response) => {
      const json = await response.json();
      showError(`Scale deployment failed: ${json.message}`);
      refetchLogs();
    },
  });

  const onSubmit = (data: { resource: string; replicas: string }) => {
    closeDialog();
    // @ts-ignore
    scaleClusterAction.mutate({
      method: "POST",
      path: AGENT_COMMAND_PATH,
      body: JSON.stringify({
        clusterName: clusterDetail?.name,
        command: CLUSTER_ACTION_TYPE.SCALE_DEPLOYMENT,
        parameters: {
          resource: data.resource,
          replicas: data.replicas,
        },
      }),
    });
  };

  return (
    <AlertDialog
      textTitle={"Scale Deployment"}
      textContent={``}
      open={open}
      setOpen={() => closeDialog()}
      isConfirmDisabled={!isValid}
      handleConfirm={() => {
        // @ts-ignore
        handleSubmit(onSubmit)();
      }}
    >
      Scale the cluster <strong>{clusterDetail?.name}</strong> to the following
      parameters:
      <form>
        <Box sx={{ minWidth: 120, marginTop: "16px", display: "flex" }}>
          <Box style={{ width: "100%" }}>
            <FormControl fullWidth>
              <InputLabel id="resource-type-label">Resource Type</InputLabel>
              <Controller
                control={control}
                name="resource"
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="resource-type-label"
                    id="resource-type-select"
                    required
                    label="Resource Type"
                    onChange={(event: any) => field.onChange(event)}
                  >
                    {ScaleResourceTypes.map((x: any) => (
                      <MenuItem key={x.value} value={x.value}>
                        {x.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                rules={{ required: true }}
              />
            </FormControl>
            <FormControl
              style={{
                marginTop: 16,
                marginBottom: 16,
              }}
              fullWidth
            >
              <Controller
                control={control}
                name="replicas"
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    label="Number of pod replicas"
                    placeholder="Number of pod replicas"
                    error={!!formErrors?.replicas?.message}
                    helperText={formErrors?.replicas?.message}
                    onChange={(event) => {
                      const { value } = event.target;
                      const regex = /^[0-9\b]+$/;

                      if (value === "" || regex.test(value)) {
                        field.onChange(event);
                      }
                    }}
                  />
                )}
                rules={{ required: true }}
              />
            </FormControl>
          </Box>
        </Box>
      </form>
    </AlertDialog>
  );
};

export default ScaleDeploymentDialog;
