import "../css/Header.css";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Tooltip,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AppBarModules from "./AppBarModules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { colorOverrides } from "../theme/colorOverrides";

export const Header = ({
  handleDrawerOpen,
  organizationName = "",
}: {
  handleDrawerOpen: any;
  organizationName?: string;
}) => {
  const theme = useTheme();
  const greaterThanSmall = useMediaQuery(theme.breakpoints.up("sm"));

  function handleDrawbarOpen() {
    handleDrawerOpen();
  }
  return (
    <AppBar
      className={`headerBar`}
      sx={{
        backgroundColor: "#ffffff",
        position: "relative",
      }}
    >
      <Toolbar sx={{ width: "100%", height: "60px" }} variant="dense">
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            height: 50,
          }}
        >
          <Box
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <Tooltip title={"Toggle menu display"} style={{ float: "left" }}>
              <IconButton
                sx={{
                  color: colorOverrides.leftBlue,
                  width: "48px",
                  height: "48px",
                  marginLeft: "-15px",
                  marginRight: "5px",
                  "& svg": {
                    fontSize: "24px",
                    color: colorOverrides.leftBlue,
                  },
                }}
                onClick={handleDrawbarOpen}
                size={"medium"}
              >
                <FontAwesomeIcon icon={faBars} />
              </IconButton>
            </Tooltip>
            <Box sx={{ width: "35%", textAlign: "left" }}>
              <Box
                style={{
                  color: colorOverrides.leftBlue,
                }}
              >
                <Box
                  sx={{
                    height: 50,
                    display: "flex",
                    alignItems: "center",
                    borderRadius: 5,
                    whiteSpace: "nowrap",
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    className="OrganizationInfoHeading"
                    style={{
                      display: !greaterThanSmall ? "none" : "block",
                    }}
                  >
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Box sx={{ float: "left" }}>{organizationName}</Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                flexGrow: 1,
              }}
            >
              <AppBarModules />
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
