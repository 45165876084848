import _trim from "lodash/trim";
import _isEmpty from "lodash/isEmpty";

export const isEmptyString = (str?: string | null) => {
  if (str == null) return true;
  const trimmedStr = _trim(str);

  return _isEmpty(trimmedStr);
};

export function isNotNilNorEmpty(str: string | undefined | null) {
  return str !== undefined && str !== null && str.trim().length > 0;
}

export function isValidUUID(uuid: string) {
  const regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return regex.test(uuid);
}
