import { StripeCardElementOptions } from "@stripe/stripe-js";
import AmexIconSvg from "images/card-brand/amex-icon.svg";
import DinersIconSvg from "images/card-brand/diners-icon.svg";
import DiscoverIconSvg from "images/card-brand/discover-icon.svg";
import JcbIconSvg from "images/card-brand/jcb-icon.svg";
import MasterIconSvg from "images/card-brand/master-card.svg";
import UnionIconSvg from "images/card-brand/union.svg";
import VisaIconSvg from "images/card-brand/visa.svg";

export const cardBrands = [
  {
    title: "amex",
    icon: AmexIconSvg,
  },
  {
    title: "diners",
    icon: DinersIconSvg,
  },
  {
    title: "discover",
    icon: DiscoverIconSvg,
  },
  {
    title: "jcb",
    icon: JcbIconSvg,
  },
  {
    title: "master",
    icon: MasterIconSvg,
  },
  {
    title: "union",
    icon: UnionIconSvg,
  },
  {
    title: "visa",
    icon: VisaIconSvg,
  },
];

export const checkoutPageStyle = {
  checkoutContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 300px)",
  },
  backCta: {
    color: " #1976d2",
  },
  circularProgress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: 5,
  },
  stripeLink: {
    fontWeight: 500,
    fontSize: 14,
    color: "#0000008a",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
};

export const paymentFormStyle = {
  typographyLabel: {
    fontSize: 14,
    color: "rgb(0 0 0 / 87%)",
    mb: 1,
  },
  cardNameField: {
    ".MuiFormHelperText-root": {
      fontSize: "11px",
      ml: 0.5,
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "2px solid #1976d2",
      },
    },
  },
  cardElementContainer: {
    border: `1px solid #0000003b`,
    borderRadius: "4px",
    height: "56px",
    padding: "17px 14px",
  },
  submitPaymentBtn: {
    display: "flex",
    mt: "20px",
    width: "100%",
    padding: "8px 22px",
    background: "#1976d2",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#1976d2",
      width: "100%",
    },
  },
};

export const cardElementOption: StripeCardElementOptions = {
  style: {
    base: {
      color: "#333333",
      fontWeight: "500",
      fontFamily: "Roboto, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        color: "rgba(0,0,0,0.8)",
      },
    },
    invalid: {
      iconColor: "#fa755a",
      color: "#fa755a",
    },
  },
};

const successMessages = {
  paymentSaved:
    "Payment method saved successfully. Redirecting to cluster(s) page...",
  paymentUpdated: "Payment method updated successfully. Redirecting...",
};
const routes = {
  home: "/home",
  paymentSetting: "/home/organization-setting/payments",
};

export const getRouteAndMessage = (isAddingPaymentInfo: boolean) => {
  if (isAddingPaymentInfo)
    return {
      route: routes.home,
      message: successMessages.paymentSaved,
    };
  return {
    route: routes.paymentSetting,
    message: successMessages.paymentUpdated,
  };
};
