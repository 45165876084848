import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import dayjs from "dayjs";

import { ReleaseDto, ReleaseStatus } from "utils/saastypes";
import ActionButton from "../buttons/ActionButton";
import { FORMAT_DATE_SHORT } from "constants/common";
import BasicDatePicker from "components/BasicDatePicker";
import { useAuth } from "auth/AuthContext";
import { useActionWithPath } from "utils/query";
import { SAVE_RELEASE_PATH } from "utils/apiPaths";
import { getErrors } from "utils/utils";

export default function ReleaseVersionDialog({
  onClose,
  progressPending = false,
  open,
  releaseVersion,
  refetch,
}: {
  onClose: () => void;
  progressPending?: boolean;
  open: boolean;
  releaseVersion: ReleaseDto;
  refetch: () => any;
}) {
  const { setErrorToast, setSuccessToast } = useAuth();

  const { mutate: saveRelease } = useActionWithPath(
    {
      onSuccess: () => {
        setSuccessToast("Update version successfully.");
        onClose();
        refetch();
      },
      onError: async (response: Response) => {
        const error = await getErrors(response);

        setErrorToast(`Error updating version: ${error.message}`);
      },
    },
    true
  );

  const formSchema = yup.object().shape({
    status: yup
      .mixed()
      .oneOf(Object.values(ReleaseStatus), "Release status type is invalid"),
    endOfLife: yup.string().nullable(),
    releaseSummary: yup.string(),
    generalNotes: yup.string(),
  });

  const defaultValues = {
    status: releaseVersion.status || ReleaseStatus.TESTING,
    endOfLife: releaseVersion.endOfLife
      ? dayjs(releaseVersion.endOfLife)
      : null,
    releaseSummary: releaseVersion.releaseSummary || "",
    generalNotes: releaseVersion.generalNotes || "",
  };

  const {
    control,
    formState: { errors: formErrors },
    handleSubmit,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(formSchema),
    defaultValues,
  });

  const onInvalid = (err: any) => {
    console.error(err);
  };

  const onSubmit = (data: ReleaseDto) => {
    // @ts-ignore
    saveRelease({
      method: "POST",
      path: SAVE_RELEASE_PATH,
      body: JSON.stringify({ ...releaseVersion, ...data }),
    });
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>
        {`${releaseVersion.version} - Release date: ${dayjs(
          releaseVersion.dateOfRelease
        ).format(FORMAT_DATE_SHORT)}`}
      </DialogTitle>
      <DialogContent>
        <form>
          <Grid container spacing={3} sx={{ paddingTop: 2 }}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="clusterTypeLabel">Status</InputLabel>
                <Controller
                  control={control}
                  name="status"
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="statusLabel"
                      id="statusSelect"
                      label="Status"
                      onChange={(event: any) => field.onChange(event)}
                    >
                      {Object.keys(ReleaseStatus).map((k) => (
                        <MenuItem
                          key={k}
                          value={ReleaseStatus[k as keyof typeof ReleaseStatus]}
                        >
                          {ReleaseStatus[k as keyof typeof ReleaseStatus]}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  rules={{ required: true }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                {/* <InputLabel id="clusterTypeLabel">Status</InputLabel> */}
                <Controller
                  control={control}
                  name="endOfLife"
                  render={({ field }) => (
                    <BasicDatePicker
                      {...field}
                      label="End of life"
                      onChange={(value) => {
                        field.onChange(dayjs(value).valueOf());
                      }}
                    />
                  )}
                  rules={{ required: false }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="releaseSummary"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      multiline
                      minRows={5}
                      label="Release notes summary (it will overwrite the notes pulled from the repo)"
                      error={!!formErrors?.releaseSummary?.message}
                      helperText={formErrors?.releaseSummary?.message}
                    />
                  )}
                  rules={{ required: false }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="generalNotes"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      multiline
                      minRows={3}
                      label="General notes (optional)"
                      error={!!formErrors?.generalNotes?.message}
                      helperText={formErrors?.generalNotes?.message}
                    />
                  )}
                  rules={{ required: false }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
          style={{ margin: 8, fontSize: 14, textTransform: "none" }}
          sx={{
            display: "inline",
            mx: 0.5,
          }}
        >
          Cancel
        </Button>
        <ActionButton
          variant="contained"
          color="primary"
          disabled={false}
          progress={progressPending}
          onClick={() => {
            // @ts-ignore
            handleSubmit(onSubmit, onInvalid)();
          }}
          style={{ margin: 8, fontSize: 14, textTransform: "none" }}
          sx={{
            display: "inline",
            mx: 0.5,
          }}
        >
          Save changes
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
}
