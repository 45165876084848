export const PERMITTED_ROUTE_URL = "/home/permitted";
export const PERMITTED_USERS_ROUTE_URL = "/home/permitted/users";
export const PERMITTED_DOMAINS_ROUTE_URL = "/home/permitted/domains";
export const PERMITTED_PROVISIONS_ROUTE_URL = "/home/permitted/provisions";
export const UNAUTHORIZED_ROUTE_URL = "/unauthorized";
export const LOGIN_ROUTE = "/";
export const NEW_CLUSTER = "/home/newcluster";
export const CLUSTER_CREATION = "/home/clusterCreation";
export const RESET_PASSWORD_URL = "api/firebase/user/resetPassword";
export const VERIFY_USER_URL = "/api/verifyUserStatus";

export const CLUSTER_DETAIL_URL = {
  ROOT: "/home/clusterDetails",
  APP_ROUTE: "clusterDetails/:clusterName/*",
  BASE: "/",
  CLUSTER_DETAILS: "clusterDetails",
  URLS: "urls",
  STATUS: "status",
  NETWORK: "network",
  PERSISTENCE: "persistence",
  PROPERTIES: "properties",
  AUTHENTICATION: "authentication",
  HISTORY: "history",
  PODS: "pods",
  RELEASES: "releases",
  CERTIFICATE: "certificate",
};
