import React, { useRef } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { ClearRounded } from "@mui/icons-material";

export default function Input({
  label,
  clearable,
  onBlur,
  onChange,
  sendEventTarget = false,
  ...props
}) {
  const inputRef = useRef();

  function handleClear() {
    inputRef.current.value = "";
    if (onBlur) return onBlur("");
    if (onChange) return onChange("");
  }

  function handleBlur(e) {
    if (onBlur) {
      if (sendEventTarget) {
        onBlur(e);
      } else {
        onBlur(e.target.value);
      }
    }
  }

  function handleChange(e) {
    if (onChange) {
      if (sendEventTarget) {
        onChange(e);
      } else {
        onChange(e.target.value);
      }
    }
  }

  return (
    <TextField
      label={label}
      inputRef={inputRef}
      InputProps={{
        endAdornment: clearable && (
          <InputAdornment position="end" style={{ marginRight: -8 }}>
            <IconButton
              size="small"
              onClick={handleClear}
              disabled={props.disabled}
            >
              <ClearRounded style={{ fontSize: 18 }} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      onBlur={handleBlur}
      onChange={handleChange}
      {...props}
    />
  );
}
