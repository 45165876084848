import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
} from "@mui/material";
import ActionButton from "../buttons/ActionButton";
import { useActionWithPath } from "utils/query";
import { useAuth } from "auth/AuthContext";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import {
  EnvironmentType,
  environmentTypeOptions as environmentTypes,
} from "utils/saastypes";
import { getEnvironmentIcon } from "utils/utils";
import { useAuthMachine } from "auth/state/hook";

export interface ChangeEnvironmentTypeDialogProps {
  clusterName: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  environmentType: EnvironmentType;
  refetchCluster?: () => void;
}

export default function ChangeEnvironmentTypeDialog({
  clusterName,
  open,
  setOpen,
  environmentType,
  refetchCluster,
}: ChangeEnvironmentTypeDialogProps) {
  const { authService, setErrorToast, setSuccessToast } = useAuth();
  const [{ isOrkesUser }] = useAuthMachine(authService);
  const [selectedEnvironmentType, setSelectedEnvironmentType] =
    useState(environmentType);

  const closeDialog = () => {
    setSelectedEnvironmentType(environmentType);
    setOpen(false);
  };

  const { mutate, isLoading } = useActionWithPath({
    onSuccess: (data: any) => {
      if (data?.status === "ERROR") {
        setErrorToast("Updated environment type failed");
      } else {
        setSuccessToast(`Updated environment type successfully.`);
        closeDialog();

        if (typeof refetchCluster === "function") {
          refetchCluster();
        }
      }
    },
    onError: async (response: any) => {
      try {
        const json = await response.json();
        setErrorToast(`Updated environment type failed: ${json.message}`);
      } catch (e) {
        console.error("[ChangeEnvironmentTypeDialog.tsx][Line 75] e =====", e);
        setErrorToast("Updated environment type failed");
      }
    },
  });

  const onSubmit = () => {
    // @ts-ignore
    mutate({
      method: "POST",
      path: `/updateEnvironmentType?environmentType=${selectedEnvironmentType}&clusterName=${clusterName}`,
    });
  };

  useEffect(() => {
    setSelectedEnvironmentType(environmentType);
  }, [environmentType]);

  const environmentTypeOptions = useMemo(() => {
    const result = [...environmentTypes];

    if (!isOrkesUser) {
      result.pop();
    }

    return result;
  }, [isOrkesUser]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={closeDialog}>
      <DialogTitle>
        Change <i>{clusterName}</i> environment type
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2} sx={{ paddingTop: 2 }}>
          <Grid item xs={12} md={12}>
            <FormControl fullWidth>
              <InputLabel id="environmentTypeLabel">
                Environment Type
              </InputLabel>
              <Select
                value={selectedEnvironmentType}
                onChange={(event) =>
                  setSelectedEnvironmentType(
                    event.target.value as EnvironmentType
                  )
                }
                labelId="environmentTypeLabel"
                id="environmentTypeSelect"
                label="Environment Type"
                sx={{ ">div": { display: "flex" } }}
              >
                {environmentTypeOptions.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    <ListItemIcon
                      sx={{
                        minWidth: "30px",
                      }}
                    >
                      <img src={getEnvironmentIcon(type.id)} alt="env" />
                    </ListItemIcon>
                    {type.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          onClick={closeDialog}
          style={{ margin: 8, fontSize: 14, textTransform: "none" }}
          sx={{
            display: "inline",
            mx: 0.5,
          }}
        >
          Cancel
        </Button>
        <ActionButton
          variant="contained"
          color="primary"
          disabled={selectedEnvironmentType === environmentType}
          progress={isLoading}
          onClick={onSubmit}
          style={{ margin: 8, fontSize: 14, textTransform: "none" }}
          sx={{
            display: "inline",
            mx: 0.5,
          }}
        >
          Confirm
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
}
