export const statusMap: Map<string, { color: string; name: string }> = new Map([
  [
    "IN_PROGRESS",
    {
      color: "orange",
      name: "In Progress",
    },
  ],
  [
    "COMPLETED",
    {
      color: "green",
      name: "Completed",
    },
  ],
  [
    "COMPLETED_WITH_ERRORS",
    {
      color: "orange",
      name: "Completed with Errors",
    },
  ],
  [
    "FAILED",
    {
      color: "red",
      name: "Failed",
    },
  ],
  [
    "FAILED_WITH_TERMINAL_ERROR",
    {
      color: "red",
      name: "Failed with Terminal Error",
    },
  ],
  [
    "SCHEDULED",
    {
      color: "orange",
      name: "In Progress",
    },
  ],
  [
    "CANCELED",
    {
      color: "red",
      name: "Cancelled",
    },
  ],
  [
    "TIMED_OUT",
    {
      color: "red",
      name: "Timed Out",
    },
  ],
  [
    "SKIPPED",
    {
      color: "orange",
      name: "Skipped",
    },
  ],
]);

export const taskDescriptions: Map<string, Array<string>> = new Map([
  [
    "create_customer_namespace",
    ["Create namespace", "Create a namespace dedicated to this cluster"],
  ],
  [
    "undo_create_customer_namespace",
    ["Remove namespace", "Remove the namespace dedicated to this cluster"],
  ],
  [
    "setup_eks_addon_core_dns",
    ["Configure K8s Addons", "Configure required K8s add-ons"],
  ],
  [
    "deploy_cilium_7w5ka_ref",
    ["Configure Network Policies", "Configure network policies"],
  ],
  [
    "setup_archive_bucket",
    ["Configure Archival Store", "Configure archival buckets/blobstore"],
  ],
  [
    "select_domain_type",
    ["Configure Domain", "Select and configure domain for the cluster"],
  ],
  [
    "domain",
    [
      "Configure Environment",
      "Configure Environment associated with the cluster",
    ],
  ],
  ["verify_tls", ["Verify TLS", "Verify the TLS endpoint setup"]],
  [
    "deploy_linkerd",
    [
      "Configure Network Access",
      "Configure network access and k8s loadbalancing between services",
    ],
  ],
  [
    "add_network_policy_lsc7d_ref",
    ["Verify Network Policy", "Verify network policy"],
  ],
  [
    "simple_wait_task",
    ["Wait before starting", "Arbitrary wait before starting the provisioning"],
  ],
  [
    "delete_cft_stack",
    ["Delete CFT Stack", "Delete CFT Stack Associated with the cluster"],
  ],
  [
    "terraform_step_1",
    [
      "Configure Networking and Server Roles",
      "Configures subnets, NAT gateways, Internet Gateways etc. and sets up IAM roles required for server",
    ],
  ],
  [
    "terraform_step_2",
    [
      "Setup Storage Infra",
      "Provisions Elasticache and Opensearch domain for Conductor",
    ],
  ],
  [
    "configure_auth0_v2",
    [
      "Configure Auth0",
      "Configure a new or existing Auth0 application to provide a default Authentication vendor when launching Conductor",
    ],
  ],
  [
    "configure_auth0",
    [
      "Configure Auth0",
      "Configure a new or existing Auth0 application to provide a default Authentication vendor when launching Conductor",
    ],
  ],
  [
    "undo_configure_auth0",
    [
      "Unset Auth0",
      "Remove the Auth0 application configured for this Conductor instance",
    ],
  ],
  [
    "deploy_conductor_to_k8s",
    [
      "Deploy Conductor Services",
      "Configure a custom conductor deployment. Deploys all the associated services required to run Conductor in Production",
    ],
  ],
  [
    "undo_deploy_conductor_to_k8s",
    [
      "Undeploy Conductor Services",
      "Undeploy the custom Conductor and its associated services",
    ],
  ],
  [
    "deploy_agent_to_k8s",
    [
      "Deploy Agent Services",
      "Configure an agent that connects to the Agent Handler to poll for work",
    ],
  ],
  [
    "undo_deploy_agent_to_k8s",
    [
      "Uninstall Agent Services",
      "Remove the agent that connects to the Agent Handler to poll for work",
    ],
  ],
  [
    "deploy_ingress_to_k8s",
    [
      "Deploy Ingress",
      "Deploy NGNIX Ingress controller into the cluster. This is the last step required to enable Conductor service.",
    ],
  ],
  [
    "terraform_step_3",
    [
      "Configure DNS",
      "Configures DNS and certificates for the domain URL selected for this cluster",
    ],
  ],
  [
    "undo_terraform_step_3",
    [
      "Remove DNS",
      "Discontinue and remove DNS and certificates for the domain URL selected for this cluster",
    ],
  ],
  [
    "configure_fixed_domain",
    [
      "Configure DNS",
      "Configures DNS and certificates for the domain URL selected for this cluster",
    ],
  ],
  [
    "configure_a_record_for_loadbalancer_ip",
    ["Configure DNS", "Configures DNS as required by this cluster"],
  ],
  [
    "deploy_prometheus_to_k8s",
    [
      "Deploy Prometheus",
      "Configure a Prometheus Operator to capture metrics from Conductor and the underlying K8s instances",
    ],
  ],
  [
    "undo_deploy_prometheus_to_k8s",
    ["Undeploy Prometheus", "Undeploy the Prometheus Operator"],
  ],
  [
    "environment_state_updater",
    [
      "Complete Provisioning",
      "Completes the provisioning and updates the cluster state accordingly",
    ],
  ],
  [
    "schedule_tasks",
    [
      "Schedule Tasks",
      "Schedules the health checking and other periodic workflows in the Agent Handler",
    ],
  ],
  [
    "undo_schedule_tasks",
    ["Remove schedules", "Remove all schedules within the Agent Handler"],
  ],
  [
    "launch_cluster_deployment",
    ["Launch Cluster Deployment", "The initial step of the cluster deployment"],
  ],
  [
    "launch_cluster_deployment_azure",
    ["Launch Cluster Deployment", "The initial step of the cluster deployment"],
  ],
  [
    "launch_cluster_deployment_gcp",
    ["Launch Cluster Deployment", "The initial step of the cluster deployment"],
  ],
  [
    "deploy_ingress_tls_to_k8s",
    [
      "Deploy HTTPS certificate to Kubernetes",
      "This step is required to ensure secure communication with the cluster",
    ],
  ],
  [
    "undo_deploy_ingress_tls_to_k8s",
    [
      "Undeploy the HTTPS certificate",
      "Remove the no longer required HTTPS certificate",
    ],
  ],
  [
    "notify_user_by_mail",
    [
      "Notify user by Email",
      "Send an email to the user to notify them of the cluster provisioning",
    ],
  ],
  [
    "terraform_rds",
    ["Deploy RDS using Terraform", "Deploy and launch the Postgres database"],
  ],
  [
    "terraform_rds_cloud_watch_alarms",
    [
      "Add CloudWatch Alarms",
      "CloudWatch is used for monitoring and alerting on the RDS instance",
    ],
  ],
  [
    "logs_uploader",
    ["Set up Logs uploader", "Set up the logs uploader service"],
  ],
  [
    "finalize_cluster_deployment",
    [
      "Finalize Cluster Deployment",
      "All steps for cluster deployment are now complete",
    ],
  ],
  [
    "finalize_cluster_deployment_azure",
    [
      "Finalize Cluster Deployment",
      "All steps for cluster deployment are now complete",
    ],
  ],
  [
    "finalize_cluster_deployment_gcp",
    [
      "Finalize Cluster Deployment",
      "All steps for cluster deployment are now complete",
    ],
  ],
  [
    "add_certificates",
    ["Add TLS Certificates", "Add TLS Certificates for Ingress"],
  ],
  [
    "add_azure_certificates",
    ["Add TLS Certificates", "Add TLS Certificates for Ingress"],
  ],
  [
    "add_iam_roles",
    ["Add IAM Roles", "Add IAM Roles required for the Conductor server"],
  ],
  [
    "undo_add_iam_role",
    ["Remove IAM Roles", "Remove the IAM Roles used by this Conductor server"],
  ],
  [
    "add_azure_roles",
    ["Add Azure Roles", "Add Azure Roles required for the Conductor server"],
  ],
  [
    "add_gcp_roles",
    ["Add GCP Roles", "Add GCP Roles required for the Conductor server"],
  ],
  [
    "allocate_elasticache_subworkflow",
    [
      "Configure the Elasticache",
      "Configure an Elasticache resource for using with Conductor",
    ],
  ],
  [
    "allocate_azureredis_subworkflow",
    [
      "Configure the Redis",
      "Configure the Azure Redis resource for using with Conductor",
    ],
  ],
  [
    "allocate_gcpredis_subworkflow",
    [
      "Configure the Redis",
      "Configure the Redis resource for using with Conductor",
    ],
  ],
  [
    "create_azureredis_instance",
    [
      "Configure the Redis",
      "Configure the Redis resource for using with Conductor",
    ],
  ],
  [
    "create_gcpredis_instance",
    [
      "Configure the Redis",
      "Configure the Redis resource for using with Conductor",
    ],
  ],
  [
    "allocate_redis_capacity",
    [
      "Configure the Redis",
      "Configure the Redis resource for using with Conductor",
    ],
  ],
  [
    "undo_allocate_redis_capacity",
    [
      "Uninstall the Redis",
      "Uninstall the Redis resource used by this Conductor instance",
    ],
  ],
  [
    "allocate_redis_capacity_with_script",
    [
      "Configure the Redis",
      "Configure the Redis resource for using with Conductor",
    ],
  ],
  [
    "allocate_rds_subworkflow",
    ["Configure the database", "Configure a database for using with Conductor"],
  ],
  [
    "allocate_azuredb_subworkflow",
    ["Configure the database", "Configure a database for using with Conductor"],
  ],
  [
    "allocate_gcpdb_subworkflow",
    ["Configure the database", "Configure a database for using with Conductor"],
  ],
  [
    "create_azuredb_instance",
    ["Configure the database", "Configure a database for using with Conductor"],
  ],
  [
    "create_gcpdb_instance",
    ["Configure the database", "Configure a database for using with Conductor"],
  ],
  [
    "allocate_postgres_capacity",
    ["Configure the database", "Configure a database for using with Conductor"],
  ],
  [
    "undo_allocate_postgres_capacity",
    ["Drop the database", "Drop the database used by this Conductor instance"],
  ],
  [
    "allocate_postgres_capacity_with_script",
    ["Configure the database", "Configure a database for using with Conductor"],
  ],
  [
    "create_node_group",
    [
      "Create Aws Node Group",
      "Create Node Group to manage Conductor resources",
    ],
  ],
  [
    "create_azure_node_pool",
    [
      "Create Azure Node Pool",
      "Create Node Pool to manage Conductor resources",
    ],
  ],
  [
    "create_gcp_node_pool",
    ["Create GCP Node Pool", "Create Node Pool to manage Conductor resources"],
  ],
  [
    "deploy_cilium",
    [
      "Deploy Cilium",
      "Deploy and launch a Cilium container for enabling observability",
    ],
  ],
  [
    "add_network_policy",
    [
      "Adjust Network policies",
      "Configure Network policies required by grafana and prometheus",
    ],
  ],
  [
    "notify_on_slack_channel",
    [
      "Notify on Slack",
      "Notify on Orkes Slack Channel about provisioning status",
    ],
  ],
  [
    "notify_end_on_slack_channel",
    [
      "Notify on Slack",
      "Notify on Orkes Slack Channel about provisioning ending",
    ],
  ],
  [
    "check_if_backup_complete",
    [
      "Backup Check",
      "Check if Conductor Instance is backed up before deletion",
    ],
  ],
  [
    "deploy_k8s_metric_server",
    [
      "Deploy a Metrics server",
      "Deploy and launch a Conductor Metrics server for collecting operational data",
    ],
  ],
  [
    "deploy_service_monitor",
    [
      "Deploy Service Monitor",
      "Deploy Service Monitor to monitor and collect metrics from services within cluster.",
    ],
  ],
  ["setup_eks_addon_vpc_cni", ["Setup Eks VPC CNI", "Configure EKS VPC CNI"]],
  [
    "setup_aws_ebs_csi_driver_addon",
    ["Setup AWS EBS CST driver", "Deploy AWS EBS CST driver"],
  ],
  [
    "allocate_kubernetes_subworkflow",
    [
      "Configure Kubernetes",
      "Configure the Kubernetes instance to accommodate the provisioning resources",
    ],
  ],
  [
    "allocate_aks_capacity",
    [
      "Configure Kubernetes",
      "Configure the Kubernetes instance to accommodate the provisioning resources",
    ],
  ],
  [
    "undo_allocate_aks_capacity",
    [
      "Deallocate Kubernetes",
      "Deallocate the Kubernetes instance used to accommodate the provisioning resources",
    ],
  ],
  [
    "allocate_eks_capacity",
    [
      "Configure Kubernetes",
      "Configure the Kubernetes instance to accommodate the provisioning resources",
    ],
  ],
  [
    "undo_allocate_eks_capacity",
    [
      "Deallocate Kubernetes",
      "Deallocate the Kubernetes instance used to accommodate the provisioning resources",
    ],
  ],
  [
    "allocate_gke_capacity",
    [
      "Configure Kubernetes",
      "Configure the Kubernetes instance to accommodate the provisioning resources",
    ],
  ],
  [
    "download_scripts_from_storage",
    [
      "Download provisioning scripts",
      "Fetch the scripts that will be used for the provisioning",
    ],
  ],
  [
    "allocate_gke_capacity",
    [
      "Configure Kubernetes",
      "Configure the Kubernetes instance to accommodate the provisioning resources",
    ],
  ],
  [
    "allocate_gke_capacity",
    [
      "Configure Kubernetes",
      "Configure the Kubernetes instance to accommodate the provisioning resources",
    ],
  ],
  [
    "setup_azure_networking",
    ["Setup Azure Networking", "Setup the Virtual Network and Subnets"],
  ],
  [
    "create_azure_storage_account",
    ["Configure a Storage Account on Azure", "Create the Storage Account"],
  ],
  [
    "create_azure_key_vault",
    [
      "Configure a Key Vault on Azure",
      "Create the Key Vault for storing secrets",
    ],
  ],
  [
    "create_aks_instance",
    [
      "Create an AKS instance",
      "Setup an Azure Kubernetes Service (AKS) instance",
    ],
  ],
  [
    "create_az_resources_fork",
    [
      "Setup the Node Pool, Roles, Redis, and Database",
      "Setup the Namespace, Node Pool, Roles, Redis, and Database in parallel",
    ],
  ],
  [
    "create_azure_workload_identity",
    ["Setup the AWI", "Setup the Azure Workload Identity"],
  ],
  ["create_azure_ingress", ["Deploy Ingress", "Setup the Azure Ingress"]],
  [
    "create_aws_ingress_controller",
    ["Deploy Ingress", "Setup the AWS Ingress Controller"],
  ],
  [
    "deploy_metrics_proxy",
    ["Deploy the Metrics Proxy", "Deploy the Conductor Metrics Proxy"],
  ],
  [
    "restrict_ips",
    ["Restrict Cluster Access", "Restrict cluster access to Orkes network"],
  ],
  [
    "create_cert_manager",
    ["Deploy Certificate Manager", "Deploy Certificate Manager on Azure"],
  ],
]);
