import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import { Link } from "react-router-dom";

export const styleObj = {
  ssoBtn: {
    marginBottom: 5,
    borderRadius: "40px",
    border: "2px solid #1976D2",
    color: "#000",
    textTransform: "none",
    "&:hover": {
      border: "2px solid #1976D2",
    },
  },
  emailBtn: {
    background: "#0D94DB",
    borderRadius: "40px",
    color: "#fff",
    textTransform: "none",
    width: "240px",
  },
  textLink: {
    color: "#1976D2",
    cursor: "pointer",
    fontWeight: "bold",
  },
};

type LoginSectionProps = {
  isSignUpActive: boolean;
  inviteId?: string;
  toggleSignupHandler: () => void;
  attemptLoginWithGoogle: () => void;
  showFormField: () => void;
};

const LoginSection = ({
  isSignUpActive,
  inviteId,
  toggleSignupHandler,
  attemptLoginWithGoogle,
  showFormField,
}: LoginSectionProps) => {
  const getUrlWithInvite = (baseUrl: string) =>
    inviteId ? `${baseUrl}?inviteId=${inviteId}` : baseUrl;

  const linkContent = isSignUpActive
    ? { text: "Have an account?", actionText: "Login now", url: "/" }
    : { text: "Don't have an account?", actionText: "Sign up", url: "/signup" };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Button
        startIcon={<GoogleIcon />}
        variant="outlined"
        size="large"
        sx={{ ...styleObj.ssoBtn, borderColor: "#1976D2" }}
        onClick={attemptLoginWithGoogle}
      >
        Continue with Google
      </Button>
      <Button
        startIcon={<EmailIcon />}
        variant="outlined"
        size="large"
        sx={{ ...styleObj.ssoBtn, borderColor: "#1976D2" }}
        onClick={showFormField}
      >
        Continue with Email
      </Button>

      <Typography sx={{ textAlign: "center" }} component="div">
        <Divider sx={{ my: 4 }}>Or</Divider>

        <Box sx={{ marginBottom: 4 }}>
          {linkContent.text}{" "}
          <Typography
            onClick={toggleSignupHandler}
            component={Link}
            to={getUrlWithInvite(linkContent.url)}
            sx={{ ...styleObj.textLink }}
          >
            {linkContent.actionText}
          </Typography>
        </Box>
      </Typography>
    </Grid>
  );
};

export default LoginSection;
