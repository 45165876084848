import { CLUSTER_DETAIL_URL } from "utils/routePaths";
import { CLUSTER_DETAILS_TABS } from "./constants";

export const selectedTabIndex = (relativePath: string) => {
  if (relativePath?.startsWith(`/${CLUSTER_DETAIL_URL.URLS}`)) {
    return CLUSTER_DETAILS_TABS.URLS;
  }
  if (relativePath?.startsWith(`/${CLUSTER_DETAIL_URL.STATUS}`)) {
    return CLUSTER_DETAILS_TABS.STATUS;
  }
  if (relativePath?.startsWith(`/${CLUSTER_DETAIL_URL.NETWORK}`)) {
    return CLUSTER_DETAILS_TABS.NETWORK;
  }
  if (relativePath?.startsWith(`/${CLUSTER_DETAIL_URL.PERSISTENCE}`)) {
    return CLUSTER_DETAILS_TABS.PERSISTENCE;
  }
  if (relativePath?.startsWith(`/${CLUSTER_DETAIL_URL.PROPERTIES}`)) {
    return CLUSTER_DETAILS_TABS.PROPERTIES;
  }
  if (relativePath?.startsWith(`/${CLUSTER_DETAIL_URL.AUTHENTICATION}`)) {
    return CLUSTER_DETAILS_TABS.AUTHENTICATION;
  }
  if (relativePath?.startsWith(`/${CLUSTER_DETAIL_URL.HISTORY}`)) {
    return CLUSTER_DETAILS_TABS.HISTORY;
  }
  if (relativePath?.startsWith(`/${CLUSTER_DETAIL_URL.PODS}`)) {
    return CLUSTER_DETAILS_TABS.PODS;
  }
  if (relativePath?.startsWith(`/${CLUSTER_DETAIL_URL.RELEASES}`)) {
    return CLUSTER_DETAILS_TABS.RELEASES;
  }
  if (relativePath?.startsWith(`/${CLUSTER_DETAIL_URL.CERTIFICATE}`)) {
    return CLUSTER_DETAILS_TABS.CERTIFICATE;
  }
  return CLUSTER_DETAILS_TABS.DETAILS;
};
