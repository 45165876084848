import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import ActionButton from "../buttons/ActionButton";
import { CustomerCftDto } from "../../utils/saastypes";
import { DownloadRounded } from "@mui/icons-material";

export default function AuthorizeAccountDialog({
  cftData,
  handleClose,
  progressPending = false,
}: {
  cftData: CustomerCftDto;
  verifyMethod: () => void;
  handleClose: () => void;
  progressPending?: boolean;
}) {
  return (
    <Dialog fullWidth maxWidth="sm" open onClose={handleClose}>
      <DialogTitle>Authorize Account using CFT for AWS</DialogTitle>
      <DialogContent>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div className={"headerText"} style={{ fontSize: 16 }}>
            Cloud Formation Template:
          </div>
          <div
            className={"headerText"}
            style={{ marginLeft: 10, fontSize: 16 }}
          >
            Template File
            <IconButton
              component={"a"}
              target={"noreferrer noopener"}
              href={cftData.s3Url}
            >
              <DownloadRounded />
            </IconButton>
          </div>
        </Box>
        <Box mt={4}>
          <p className={"headerValue"}>
            Clicking Authorize will open a new window to create a cloud
            formation stack in AWS console. If you are not logged in, please
            login and click this button again.
          </p>
        </Box>
      </DialogContent>
      <DialogActions>
        <ActionButton
          variant="contained"
          color="primary"
          progress={progressPending}
          // @ts-ignore
          component={"a"}
          target="_blank"
          // @ts-ignore
          rel={"noreferrer noopener"}
          href={cftData.customerUrl}
          style={{
            margin: 8,
            fontSize: 15,
            textTransform: "none",
            padding: 10,
          }}
          sx={{
            display: "inline",
            mx: 0.5,
          }}
        >
          Authorize (Opens AWS Console)
        </ActionButton>
        <Button
          variant="outlined"
          onClick={handleClose}
          style={{ margin: 8, fontSize: 14, textTransform: "none" }}
          sx={{
            display: "inline",
            mx: 0.5,
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
