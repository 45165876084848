import { SupportType } from "utils/saastypes";
import { SupportContent } from "./types";

export const styles = {
  dialogPaper: { maxWidth: "960px", p: 4 },
  dialogTitle: { textAlign: "center", fontSize: 30, fontWeight: 500 },
  dialogSubtitle: { fontSize: 16, mt: 3 },
  dialogContent: { px: 10 },
  supportContainer: {
    display: "flex",
    justifyContent: "space-between",
    mt: 6,
  },
  premiumSupportContainer: {
    display: "flex",
    justifyContent: "center",
    mt: 2,
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  paper: {
    maxWidth: 250,
    height: 160,
    display: "flex",
    flexDirection: "column",
    gap: 1.5,
    px: 3.5,
    py: 4,
    borderRadius: 1.5,
  },
  supportTitle: { textAlign: "center", fontSize: 20, fontWeight: 600 },
  supportDescription: { textAlign: "center", fontSize: 16 },
  supportPrimaryCta: {
    textTransform: "none",
    mt: 2,
    fontSize: 16,
    fontWeight: 600,
  },
  premiumCtaContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  premiumSupportCtaContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    gap: 4,
    mt: 4,
  },
  premiumSecondaryCta: { textTransform: "none", fontSize: 16, fontWeight: 600 },
  basicEmailText: { fontSize: 13, mt: 2, textAlign: "center" },
  disabledCard: {
    opacity: 0.4,
    pointerEvents: "none",
  },
};

export const supportContent: SupportContent[] = [
  {
    title: "Basic",
    description:
      "Support handled through email, with a 36 hour response window ",
  },
  {
    title: "Standard",
    description:
      "Support handled through Orkes Support Portal, with an 8 hour response window",
  },
  {
    title: "Premium",
    description:
      "Support handled through Orkes Support or Private Slack/Team channel, with an 4 hour response window",
  },
];

export const isSupportPlanDisabled = (
  availableSupportPlan: SupportType[],
  supportTitle: string
): boolean =>
  !availableSupportPlan.includes(`${supportTitle} Support` as SupportType);
