import Button, { ButtonProps } from "@mui/material/Button";
import { fontFamilyList } from "./CustomButtons";

const StyledErrorButton = ({ children, ...props }: ButtonProps) => {
  return (
    <Button
      sx={{
        fontFamily: fontFamilyList,
        textTransform: "none",
        height: 40,
        opacity: 1,
        backgroundColor: "#c44141",
      }}
      variant="contained"
      color="error"
      {...props}
    >
      {children}
    </Button>
  );
};

export default StyledErrorButton;
