import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import ActionButton from "../buttons/ActionButton";
import { AzureScriptDto } from "utils/saastypes";
import { DownloadRounded } from "@mui/icons-material";

export default function AzureAuthorizeAccountDialog({
  azureScriptData,
  handleClose,
  progressPending = false,
}: {
  azureScriptData: AzureScriptDto;
  verifyMethod: () => void;
  handleClose: () => void;
  progressPending?: boolean;
}) {
  return (
    <Dialog fullWidth maxWidth="sm" open onClose={handleClose}>
      <DialogTitle>Authorize Azure Account</DialogTitle>
      <DialogContent>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div className={"headerText"} style={{ fontSize: 16 }}>
            Script File:
          </div>
          <div
            className={"headerText"}
            style={{
              marginLeft: 20,
              fontSize: 16,
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            Download Script
            <IconButton
              component={"a"}
              target={"noreferrer noopener"}
              href={azureScriptData.s3Url}
            >
              <DownloadRounded />
            </IconButton>
          </div>
        </Box>
        <Box mt={4}>
          <p className={"headerValue"} style={{ fontSize: 15 }}>
            Download this file and run this in your Azure Cloud Account. Review
            the script to understand the permissions that will be assigned to
            the applications.
            <br />
            <br />
            <span>
              <b>Note:&nbsp;</b>
            </span>
            <br />
            When you run this script, it will print the credentials that you
            will need to supply further in this page to continue provisioning.
          </p>
        </Box>
      </DialogContent>
      <DialogActions>
        <ActionButton
          variant="contained"
          color="primary"
          progress={progressPending}
          // @ts-ignore
          component={"a"}
          target="_blank"
          // @ts-ignore
          rel={"noreferrer noopener"}
          href={azureScriptData.s3Url}
          style={{
            margin: 8,
            fontSize: 15,
            textTransform: "none",
            padding: 10,
          }}
          sx={{
            display: "inline",
            mx: 0.5,
          }}
        >
          Download Script File
        </ActionButton>
        <Button
          variant="outlined"
          onClick={handleClose}
          style={{ margin: 8, fontSize: 14, textTransform: "none" }}
          sx={{
            display: "inline",
            mx: 0.5,
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
