import { useInterpret, useSelector } from "@xstate/react";
import { useAuthHeaders } from "utils/query";
import { userInfoModalMachine } from "./machine";
import {
  AuthHeaders,
  UserInfoModalContext,
  UserInfoModalEventTypes,
  UserInfoModalStates,
} from "./types";
import { useContext, useMemo } from "react";
import { MessageContext } from "utils/MessageProvider";
import { Message } from "components/SnackbarMessage";
import { State } from "xstate";
import { ModalStep } from "../common";
import { useAuth } from "auth/AuthContext";
import { useAuthMachine } from "auth/state/hook";
import { OTP_DISABLED } from "constants/properties";

export const useUserInfoModalMachine = (
  displayName?: string,
  organizationName?: string
) => {
  const authHeader = useAuthHeaders();
  const { authService } = useAuth();
  const [{ currentUser }] = useAuthMachine(authService);
  const { setMessage } = useContext(MessageContext);

  const userInfoDetails = {
    name: displayName ?? "",
    company: organizationName ?? "",
    phoneNumber: "",
    marketingPreference: true,
    termsAndConditionsAccepted: true,
  };

  const service = useInterpret(userInfoModalMachine, {
    ...(process.env.NODE_ENV === "development" ? { devTools: true } : {}),
    context: {
      authHeader: authHeader as AuthHeaders,
      userInfo: userInfoDetails,
      currentUserEmail: currentUser?.email,
    },
    actions: {
      setSuccessMessage: (context, { data: { successMessage } }) => {
        setMessage(new Message(successMessage, "success"));
      },
    },
    services: {
      runCountDown: (context) => (callback) => {
        let counter = context.countDown;
        const interval = setInterval(() => {
          if (counter > 0) {
            callback(UserInfoModalEventTypes.TICK);
            counter--;
          } else {
            callback(UserInfoModalEventTypes.STOP_TICKING);
          }
        }, 1000);
        return () => clearInterval(interval);
      },
    },
  });

  const open = useSelector(service, (state) => state.context.open);

  const userInfo = useSelector(service, (state) => state.context.userInfo);

  const currentStep = useSelector(service, (state) => {
    if (state.matches([UserInfoModalStates.PERSONAL_INFO_STEP])) {
      return ModalStep.PERSONAL_INFO_STEP;
    }
    if (state.matches([UserInfoModalStates.PHONE_VERIFICATION_STEP])) {
      return ModalStep.PHONE_VERIFICATION_STEP;
    }
    return ModalStep.ASSIGN_TRIAL_CLUSTER_CONFIRMATION_STEP;
  });

  const uiUrl = useSelector(service, (state) => state.context.uiUrl);

  const selectedRegion = useSelector(
    service,
    (state) => state.context.selectedRegion
  );
  const verificationCode = useSelector(
    service,
    (state) => state.context.verificationCode
  );

  const errorMessage = useSelector(
    service,
    (state) => state.context.errorMessage
  );

  const countDown = useSelector(service, (state) => state.context.countDown);

  const isPersonalInfoValid = useMemo(() => {
    const { name, company, phoneNumber, termsAndConditionsAccepted } =
      userInfo!;
    return OTP_DISABLED
      ? (name?.trim() || displayName) &&
          company?.trim() &&
          termsAndConditionsAccepted
      : (name?.trim() || displayName) &&
          company?.trim() &&
          phoneNumber &&
          termsAndConditionsAccepted;
  }, [displayName, userInfo]);

  const isLoadingState = useSelector(
    service,
    (state: State<UserInfoModalContext>) => state.hasTag("loading")
  );
  const captchaValue = useSelector(
    service,
    (state: State<UserInfoModalContext>) => state.context.captchaValue
  );

  const buttonState = useMemo(() => {
    switch (currentStep) {
      case ModalStep.PERSONAL_INFO_STEP:
        return {
          disabled: !isPersonalInfoValid || !captchaValue,
          loading: isLoadingState,
        };
      case ModalStep.PHONE_VERIFICATION_STEP:
        return {
          disabled: !verificationCode || !captchaValue,
          loading: isLoadingState,
        };
      default:
        return {};
    }
  }, [
    currentStep,
    isPersonalInfoValid,
    isLoadingState,
    verificationCode,
    captchaValue,
  ]);

  const handleContinue = () =>
    service.send({
      type: UserInfoModalEventTypes.HANDLE_CONTINUE_EVENT,
    });

  const handleRecaptcha = (captchaValue: string) => {
    service.send({
      type: UserInfoModalEventTypes.HANDLE_PERSIST_CAPTCHA_EVENT,
      captchaValue,
    });
  };

  const handleCloseModal = () =>
    service.send({
      type: UserInfoModalEventTypes.HANDLE_CLOSE_MODAL_EVENT,
    });

  const handleOpenModal = () =>
    service.send({
      type: UserInfoModalEventTypes.HANDLE_OPEN_MODAL_EVENT,
    });

  const sendOtpCode = () =>
    service.send({
      type: UserInfoModalEventTypes.SEND_OTP_EVENT,
    });

  const handleFieldChange = (value: string | boolean, name: string) =>
    service.send({
      type: UserInfoModalEventTypes.HANDLE_FIELD_CHANGE_EVENT,
      value,
      name,
    });

  const handleVerificationCode = (value: string) =>
    service.send({
      type: UserInfoModalEventTypes.HANDLE_VERIFICATION_CODE_EVENT,
      value,
    });

  const goBackToPersonalInfoStep = () =>
    service.send({
      type: UserInfoModalEventTypes.GO_BACK_TO_PERSONAL_INFO_STEP_EVENT,
    });

  return [
    {
      open,
      userInfo,
      currentStep,
      uiUrl,
      selectedRegion,
      verificationCode,
      errorMessage,
      buttonState,
      otpVerificationInProgress: isLoadingState,
      countDown,
      captchaValue,
    },
    {
      handleContinue,
      handleRecaptcha,
      handleCloseModal,
      handleOpenModal,
      sendOtpCode,
      handleFieldChange,
      handleVerificationCode,
      goBackToPersonalInfoStep,
    },
  ] as const;
};
