export const enterpriseFeature = [
  "Orkes Hosted",
  "Event Tasks and Handlers",
  "Metrics Dashboard",
  "Human Task and Task Forms",
  "LLM Provider Integrations",
  "Vector DB Integrations",
  "Webhooks",
  "1 Event Topic Integration",
  "Select Cloud Geo",
  "7 Day History Retained",
  "99.9% SLA",
  "SOC2 Compliant",
  "Size: 1GB Execution History",
];

export const addOnCardData = [
  {
    title: "Secure Workflows",
    features: [
      "Role Based Access Control (RBAC)",
      "User Groups",
      "Application Permissions",
      "Secrets",
    ],
  },
  {
    title: "SSO",
    features: [
      "Single Sign On - Cluster",
      "Single Sign On - Cloud Portal",
      "User Groups Sync",
    ],
  },
  {
    title: "Governance",
    features: ["Audit Logs", "Change Data Capture"],
  },
  {
    title: "Advanced Analytics",
    features: [
      "Advanced Metrics Dashboard",
      "Workflow Heatmaps",
      "Prometheus Endpoint",
      "Grafana Dashboard",
      "Query Processor Task",
    ],
  },
  {
    title: "Tailored Orkes Hosted",
    features: [
      "Choice of Cloud Provider and Region",
      "Dedicated VPC",
      "Private Link",
      "VPC Peering",
      "Upgrade Windows (Orkes Hosted)",
    ],
  },
  {
    title: "Standard/Premium Support",
    features: ["Enterprise Standard Support", "Enterprise Premium Support"],
  },
];

export const upgradeData = [
  { title: "Cluster Size", features: ["Standard", "Expansion", "High Volume"] },
];

export const cardStyle = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    width: 400,
  },
  card: {
    border: "1px solid #D7DCDD",
    borderRadius: "8px",
  },

  headerFooterTypography: {
    px: 3,
    py: 2,
    fontSize: 15,
    letterSpacing: "1px",
  },
  enterpriseCardHeader: {
    background: "#D7DCDD",
    borderRadius: "6px 6px 0px 0px",
    borderBottom: "1px solid #D7DCDD",
  },
  enterpriseCardFooter: {
    background: "#F3F3F3",
    borderRadius: "0px 0px 6px 6px",
    borderTop: "1px solid #D7DCDD",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  toggleButtonStyle: {
    display: "flex",
    alignItems: "center",
    borderRadius: "40px",
    overflow: "hidden",
    background: "#DDDDDD",
  },
  baseToggleOptionStyle: {
    padding: "4px 8px",
    cursor: "pointer",
    fontWeight: 500,
    fontSize: 11,
    borderRadius: "20px",
    backgroundColor: "#DDD",
    color: "#000",
    transition: "background-color 0.3s, color 0.3s",
  },
  selectedBillingFrequency: {
    backgroundColor: "#1976D2",
    color: "#FFF",
  },
};

export const contactUsDialogStyle = {
  checkbox: {
    "& .MuiTypography-root": {
      fontSize: "14px",
      whiteSpace: "nowrap",
    },
  },
  cancelBtn: {
    textTransform: "none",
    "& .MuiButton-icon": {
      "& svg": {
        fontSize: 18,
      },
    },
  },
};
