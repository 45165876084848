import Button, { ButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

export interface IActionButtonProps extends ButtonProps {
  progress?: boolean;
  component?: any;
  target?: string;
}

const ActionButton = ({
  progress,
  children,
  onClick,
  component,
  disabled,
  ...props
}: IActionButtonProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <div style={{ position: "relative", width: "100%" }}>
        <Button
          disabled={disabled || progress}
          onClick={onClick}
          component={component}
          {...props}
        >
          {children}
        </Button>
        {progress && (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
            color="secondary"
          />
        )}
      </div>
    </div>
  );
};

export default ActionButton;
