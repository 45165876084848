import React from "react";
import _ from "lodash";
import { FormControl, InputLabel, Select as MuiSelect } from "@mui/material";

export default function Select({
  label,
  fullWidth,
  nullable = true,
  children,
  onChange,
  style,
  value,
  renderValue,
  displayEmpty,
  multiple,
  ...props
}: {
  label: string;
  fullWidth?: boolean;
  displayEmpty?: boolean;
  value?: any;
  nullable?: boolean;
  renderValue?: any;
  children?: any;
  props?: any;
  style?: any;
  onChange?: any;
  multiple?: boolean;
}) {
  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        variant="outlined"
        fullWidth={fullWidth}
        displayEmpty={nullable}
        renderValue={(v: any) => (_.isNil(v) ? "" : v)}
        multiple={multiple}
        {...props}
      />
    </FormControl>
  );
}
