import Box from "@mui/material/Box";
import { FunctionComponent } from "react";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useLocalStorage } from "utils/localstorage";
import { LOCAL_STORAGE_ENABLED_CLUSTERS_CREATION_KEY } from "constants/localStorage";

export const CreatorFlags: FunctionComponent = () => {
  const [clustersCreation, setClustersCreation] = useLocalStorage(
    LOCAL_STORAGE_ENABLED_CLUSTERS_CREATION_KEY,
    false
  );

  const handleChangeBetaDiagram = (event: any) => {
    setClustersCreation(event.target.checked);
    window.location.reload();
  };

  return (
    <div
      style={{
        width: "30%",
        margin: "auto",
        marginTop: 50,
      }}
    >
      <Paper elevation={2} sx={{ padding: 6 }}>
        <Typography variant="h5">Feature flags</Typography>
        <Box sx={{ display: "flex" }}>
          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clustersCreation}
                    onChange={handleChangeBetaDiagram}
                    name="clustersCreation"
                  />
                }
                label="Enable Clusters Creation"
              />
            </FormGroup>
          </FormControl>
        </Box>
      </Paper>
    </div>
  );
};
