import { Box, Grid, IconButton, Typography } from "@mui/material";
import { EditIcon } from "components/icons/EditIcon";

interface InfoRowProps {
  label: string;
  value: string;
  editable?: boolean;
  onEdit?: () => void;
}

const InfoRow: React.FC<InfoRowProps> = ({
  label,
  value,
  editable = false,
  onEdit,
}) => (
  <Grid container>
    <Grid item xs={6} sm={5} md={3} lg={2} sx={{ fontWeight: 600 }}>
      {label}:
    </Grid>
    <Grid
      item
      xs={6}
      sm={7}
      md={9}
      lg={10}
      sx={{ display: "flex", alignItems: "center", gap: 2 }}
    >
      <Typography variant="body1">{value}</Typography>
      {editable && (
        <IconButton onClick={onEdit} sx={{ cursor: "pointer" }}>
          <EditIcon />
        </IconButton>
      )}
    </Grid>
  </Grid>
);

export const InfoTab = ({
  organizationName,
  organizationId,
}: {
  organizationName?: string;
  organizationId?: string;
}) => {
  return (
    <Box
      sx={{
        px: 6,
        py: 8,
      }}
      id="org-settings-info-section"
    >
      <Typography variant="h4" sx={{ opacity: 0.8, mb: 6 }}>
        My Account
      </Typography>

      <Grid container rowGap={2}>
        <InfoRow
          label="Organization Name"
          value={organizationName ?? "N/A"}
          // editable
        />
        <InfoRow label="Organization Id" value={organizationId ?? "N/A"} />
      </Grid>
    </Box>
  );
};
