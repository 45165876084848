import { EmailOutlined } from "@mui/icons-material";
import { Button, Typography, Link } from "@mui/material";
import { Box } from "@mui/system";
import { ErrorMessageType } from "auth/state/types";
import PlaygroundSvg from "images/svg/playground-svg.svg";

export const EmailVerificationMessage = ({
  showLogin,
  messageType,
  resendVerificationEmail,
  resendMagicLink,
  resendResetPasswordEmail,
  inviteId,
}: {
  showLogin: () => void;
  messageType: string;
  resendVerificationEmail: () => void;
  resendMagicLink: () => void;
  resendResetPasswordEmail: () => void;
  inviteId?: string;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: messageType === ErrorMessageType.PERMISSION_DENIED ? 6 : 8,
      }}
    >
      {messageType === ErrorMessageType.VERIFICATION_EMAIL_SENT && (
        <>
          <Typography mt={12} mb={3} fontSize={28} fontWeight={300}>
            Verify email
          </Typography>
          <Typography component="div">
            Please check your email for a link{" "}
            <Typography>to verify your account and login.</Typography>
          </Typography>
          {inviteId && (
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              PLEASE{" "}
              <Link
                sx={{ textDecoration: "none", cursor: "pointer" }}
                onClick={showLogin}
              >
                LOGIN
              </Link>{" "}
              IN THIS WINDOW
            </Typography>
          )}
          <Typography
            sx={{
              backgroundColor: "#9FDCAA",
              padding: 3,
              width: "100%",
              fontWeight: "bold",
            }}
          >
            Email sent!
          </Typography>

          <Button
            sx={{ color: "#1876d2", fontWeight: 600, textTransform: "none" }}
            startIcon={<EmailOutlined />}
            onClick={resendVerificationEmail}
          >
            Resend email
          </Button>
        </>
      )}
      {messageType === ErrorMessageType.PERMISSION_DENIED && (
        <>
          <Typography mt={12} mb={3} fontSize={24} fontWeight={300}>
            Business Email Required
          </Typography>
          {[
            "It appears you're attempting to sign up for an Enterprise account using a personal email address.",
            "For personal use, please explore our playground.",
            "To proceed, please enter your business email address.",
          ].map((text) => (
            <Typography key={text} px={12} fontSize={14}>
              {text}
            </Typography>
          ))}
          <Button
            variant="outlined"
            startIcon={<img src={PlaygroundSvg} alt="" />}
            onClick={() => {
              window.open("https://play.orkes.io/");
              showLogin();
            }}
            sx={{
              mt: 2,
              textTransform: "none",
            }}
          >
            Take me to the Playground
          </Button>
        </>
      )}
      {messageType === ErrorMessageType.EMAIL_NOT_VERIFIED && (
        <>
          <Typography mt={12} mb={3} fontSize={28} fontWeight={300}>
            Email Not Verified
          </Typography>
          <Typography>
            I'm sorry but that email isn't verified yet.{" "}
            <Typography>Would you like a new email?</Typography>
          </Typography>
          <Button
            sx={{ color: "#0D94DB" }}
            startIcon={<EmailOutlined />}
            onClick={resendVerificationEmail}
          >
            Resend email
          </Button>
        </>
      )}
      {messageType === ErrorMessageType.MAGIC_LINK_SENT && (
        <>
          <Typography mt={12} mb={3} fontSize={28} fontWeight={300}>
            Check your email
          </Typography>
          <Typography component="div">
            Please check your email for a magic link{" "}
            <Typography>to verify your account and login.</Typography>
          </Typography>
          <Typography
            sx={{
              backgroundColor: "#9FDCAA",
              padding: 3,
              width: "100%",
              fontWeight: "bold",
            }}
          >
            Email sent!
          </Typography>

          <Button
            sx={{ color: "#1876d2", fontWeight: 600, textTransform: "none" }}
            startIcon={<EmailOutlined />}
            onClick={resendMagicLink}
          >
            Resend email
          </Button>
        </>
      )}
      {messageType === ErrorMessageType.RESET_PASSWORD_LINK_SENT && (
        <>
          <Typography mt={12} mb={3} fontSize={28} fontWeight={300}>
            Check your email
          </Typography>
          <Typography component="div">
            Please check your email for a link{" "}
            <Typography>to reset the password for your account</Typography>
          </Typography>
          <Typography
            sx={{
              backgroundColor: "#9FDCAA",
              padding: 3,
              width: "100%",
              fontWeight: "bold",
            }}
          >
            Email sent!
          </Typography>

          <Button
            sx={{ color: "#1876d2", fontWeight: 600, textTransform: "none" }}
            startIcon={<EmailOutlined />}
            onClick={resendResetPasswordEmail}
          >
            Resend email
          </Button>
        </>
      )}
    </Box>
  );
};
