import Input from "components/Input";
import { EmailVerificationMessage } from "./EmailVerificationMessage";
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { styleObj } from "./LoginSection";
import { navyBlue } from "theme/colors";
import { PasswordInput } from "components/PasswordInput";
import {
  PasswordStrengthIndicator,
  getValidationItems,
} from "components/PasswordStrengthIndicator";
import ActionButton from "components/buttons/ActionButton";

type FormComponentProps = {
  userEmail: string;
  inviteId?: string;
  userPassword: string;
  isSignUpActive: boolean;
  isPasswordLess: boolean;
  messageType?: string;
  isResetPasswordSection: boolean;
  isPasswordVisible: boolean;
  handleEmailInput: (val: string) => void;
  handlePasswordInput: (val: string) => void;
  isButtonLoading: boolean;
  handleLoginOrSignup: () => void;
  togglePasswordLessLogin: (e: any) => void;
  goBackToLoginSection: () => void;
  showForgotPasswordSection: () => void;
  triggerResetPasswordEmail: () => void;
  togglePasswordVisibility: () => void;
  resendVerificationEmail: () => void;
  resendMagicLink: () => void;
};

export const FormComponent = ({
  userEmail,
  userPassword,
  handleEmailInput,
  handlePasswordInput,
  isResetPasswordSection,
  isButtonLoading,
  isPasswordLess,
  isSignUpActive,
  isPasswordVisible,
  messageType,
  inviteId,
  handleLoginOrSignup,
  togglePasswordLessLogin,
  goBackToLoginSection,
  showForgotPasswordSection,
  triggerResetPasswordEmail,
  togglePasswordVisibility,
  resendVerificationEmail,
  resendMagicLink,
}: FormComponentProps) => {
  const validationItems = getValidationItems(userPassword);
  const isInvalidPassword =
    validationItems.some((item) => !item.condition) && isSignUpActive;

  return (
    <Box>
      {messageType ? (
        <EmailVerificationMessage
          resendMagicLink={resendMagicLink}
          showLogin={goBackToLoginSection}
          messageType={messageType}
          resendVerificationEmail={resendVerificationEmail}
          resendResetPasswordEmail={triggerResetPasswordEmail}
          inviteId={inviteId}
        />
      ) : (
        <>
          <Typography mt={12} mb={3} fontSize={31} fontWeight={300}>
            {isSignUpActive
              ? "Create an account"
              : isResetPasswordSection
              ? "Reset Password"
              : "Welcome back!"}
          </Typography>

          {isResetPasswordSection ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                px: 4,
                mt: 10,
                gap: 8,
                alignItems: "center",
                ">div": {
                  width: "90%",
                  margin: "auto",
                },
              }}
            >
              <Input
                type="email"
                value={userEmail}
                onChange={handleEmailInput}
                required
                label="Email address"
                placeholder="Enter your email address"
              />
              <Button
                variant="contained"
                size="large"
                sx={styleObj.emailBtn}
                onClick={triggerResetPasswordEmail}
                disabled={!userEmail}
              >
                Reset Password
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                my: 10,
                px: 4,
                ">div": {
                  width: "90%",
                },
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Input
                  placeholder="Enter your email address"
                  value={userEmail}
                  onChange={handleEmailInput}
                  required
                  label="Email address"
                  type="email"
                />
                <RadioGroup
                  row
                  sx={{
                    justifyContent: "space-between",
                  }}
                  value={isPasswordLess}
                  onChange={togglePasswordLessLogin}
                >
                  <FormControlLabel
                    value={false}
                    sx={{ fontSize: "14px" }}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          "&.Mui-checked": {
                            color: navyBlue,
                          },
                        }}
                      />
                    }
                    label="Enter password"
                  />
                  <FormControlLabel
                    value={true}
                    sx={{ fontSize: "14px" }}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          "&.Mui-checked": {
                            color: navyBlue,
                          },
                        }}
                      />
                    }
                    disabled={!!inviteId}
                    label="Email a magic link"
                  />
                </RadioGroup>
                {!isPasswordLess && (
                  <PasswordInput
                    label="Password"
                    value={userPassword}
                    onChange={handlePasswordInput}
                    showPassword={isPasswordVisible}
                    togglePasswordVisibility={() => togglePasswordVisibility()}
                    customStyle={{ marginTop: "20px" }}
                  />
                )}
                {!isSignUpActive && !isPasswordLess && (
                  <Typography
                    sx={{
                      cursor: "pointer",
                      color: "#1976D2",
                      fontWeight: "400",
                      fontSize: 12,
                      mt: 1,
                      textAlign: "right",
                    }}
                    onClick={showForgotPasswordSection}
                  >
                    Forgot your password?
                  </Typography>
                )}
                {isSignUpActive && !isPasswordLess && (
                  <Box sx={{ mt: 4 }}>
                    <PasswordStrengthIndicator password={userPassword} />
                  </Box>
                )}
              </Box>

              <Box sx={{ mt: 8 }}>
                <ActionButton
                  variant="contained"
                  size="large"
                  sx={styleObj.emailBtn}
                  onClick={handleLoginOrSignup}
                  disabled={
                    isPasswordLess
                      ? !userEmail
                      : !userEmail || !userPassword || isInvalidPassword
                  }
                  progress={isButtonLoading}
                >
                  {isSignUpActive ? "Signup" : "Login"}
                </ActionButton>
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
