export type AuthHeaders = Record<string, string | undefined>;

type UserInfo = {
  name: string;
  company: string;
  phoneNumber: string;
  marketingPreference: boolean;
  termsAndConditionsAccepted: boolean;
};

export type UserInfoModalContext = {
  authHeader?: AuthHeaders;
  userInfo: UserInfo;
  verificationCode: string;
  selectedRegion: string;
  open: boolean;
  errorMessage?: string;
  uiUrl: null | string;
  countDown: number;
  currentUserEmail?: string;
  captchaValue?: string;
};

export enum UserInfoModalStates {
  IDLE = "IDLE",
  PERSONAL_INFO_STEP = "PERSONAL_INFO_STEP",
  PHONE_VERIFICATION_STEP = "PHONE_VERIFICATION_STEP",
  REGION_SELECTION_STEP = "REGION_SELECTION_STEP",
  ASSIGN_TRIAL_CLUSTER_CONFIRMATION_STEP = "ASSIGN_TRIAL_CLUSTER_CONFIRMATION_STEP",
  TRIGGER_OTP = "TRIGGER_OTP",
  VALIDATE_OTP = "VALIDATE_OTP",
  SUBMIT_USER_INFO = "SUBMIT_USER_INFO",
  RUNNED_OUT_OF_TIME = "RUNNED_OUT_OF_TIME",
}

export enum UserInfoModalEventTypes {
  HANDLE_FIELD_CHANGE_EVENT = "HANDLE_FIELD_CHANGE_EVENT",
  HANDLE_SELECT_REGION_EVENT = "HANDLE_SELECT_REGION_EVENT",
  HANDLE_VERIFICATION_CODE_EVENT = "HANDLE_VERIFICATION_CODE_EVENT",
  HANDLE_OPEN_MODAL_EVENT = "HANDLE_OPEN_MODAL_EVENT",
  HANDLE_CLOSE_MODAL_EVENT = "HANDLE_CLOSE_MODAL_EVENT",
  HANDLE_CONTINUE_EVENT = "HANDLE_CONTINUE_EVENT",
  SEND_OTP_EVENT = "SEND_OTP_EVENT",
  GO_BACK_TO_PERSONAL_INFO_STEP_EVENT = "GO_BACK_TO_PERSONAL_INFO_STEP_EVENT",
  SET_ERROR_MESSAGE_EVENT = "SET_ERROR_MESSAGE_EVENT",
  TICK = "TICK",
  STOP_TICKING = "STOP_TICKING",
  HANDLE_PERSIST_CAPTCHA_EVENT = "HANDLE_PERSIST_CAPTCHA_EVENT",
}

export type HandleFieldChangeEvent = {
  type: UserInfoModalEventTypes.HANDLE_FIELD_CHANGE_EVENT;
  value: string | boolean;
  name: string;
};

export type HandleVerificationCodeEvent = {
  type: UserInfoModalEventTypes.HANDLE_VERIFICATION_CODE_EVENT;
  value: string;
};

export type HandleSelectedRegionEvent = {
  type: UserInfoModalEventTypes.HANDLE_SELECT_REGION_EVENT;
  value: string;
};

export type GoBackToPersonalInfoStepEvent = {
  type: UserInfoModalEventTypes.GO_BACK_TO_PERSONAL_INFO_STEP_EVENT;
};

export type HandleCloseModalEvent = {
  type: UserInfoModalEventTypes.HANDLE_CLOSE_MODAL_EVENT;
};

export type HandleOpenModalEvent = {
  type: UserInfoModalEventTypes.HANDLE_OPEN_MODAL_EVENT;
};

export type SendOtpEvent = {
  type: UserInfoModalEventTypes.SEND_OTP_EVENT;
};

export type HandleContinueEvent = {
  type: UserInfoModalEventTypes.HANDLE_CONTINUE_EVENT;
};
export type HandlePersistCaptchaEvent = {
  type: UserInfoModalEventTypes.HANDLE_PERSIST_CAPTCHA_EVENT;
  captchaValue: string;
};

export type SetErrorMessageEvent = {
  type: UserInfoModalEventTypes.SET_ERROR_MESSAGE_EVENT;
  data: Error;
};

export type UserInfoModalEvents =
  | HandleFieldChangeEvent
  | HandleVerificationCodeEvent
  | HandleSelectedRegionEvent
  | GoBackToPersonalInfoStepEvent
  | HandleCloseModalEvent
  | HandleOpenModalEvent
  | SendOtpEvent
  | HandleContinueEvent
  | SetErrorMessageEvent
  | HandlePersistCaptchaEvent;
