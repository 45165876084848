import { MenuItem, Popover } from "@mui/material";

import { getColumnId, getColumnLabel, getColumnLabelById } from "./utils";
import Input from "components/Input";
import Select from "components/Select";
import { ChangeEvent, useState } from "react";
import { LegacyColumn } from "./types";

export function Filter({
  columns,
  filterObj,
  setFilterObj,
}: {
  columns: LegacyColumn[];
  filterObj: { columnName: string; substring: string };
  setFilterObj: any;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleValueChange = (v: string) => {
    setFilterObj({
      columnName: filterObj.columnName,
      substring: v,
    });
  };

  const handleColumnChange = (c: string) => {
    setFilterObj({
      columnName: c,
      substring: "",
    });
  };

  return (
    <Popover
      onClose={handleClose}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        style: { padding: 10, display: "flex", flexDirection: "row" },
      }}
    >
      <Select
        label="Field"
        style={{ marginRight: 15, width: 200 }}
        onChange={(e: ChangeEvent<{ value: string }>) =>
          handleColumnChange(e.target.value)
        }
        value={filterObj.columnName}
        renderValue={(v: string) => getColumnLabelById(v, columns)}
        displayEmpty={true}
      >
        {columns
          .filter((col) => col.searchable !== false)
          .map((col) => (
            <MenuItem value={getColumnId(col)} key={getColumnId(col)}>
              {getColumnLabel(col)}
            </MenuItem>
          ))}
      </Select>
      <Input
        clearable
        label="Substring"
        style={{ marginRight: 15, width: 200 }}
        value={filterObj.substring}
        onChange={handleValueChange}
        onBlur={handleValueChange}
      />
    </Popover>
  );
}
