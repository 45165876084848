export const EditIcon = ({ size = 20, ...props }: any) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Edit">
      <path
        id="Vector"
        d="M15.4531 9.98L16.4531 8.98V15.49C16.4531 16.87 15.3331 17.99 13.9531 17.99H4.95312C3.57313 17.99 2.45312 16.87 2.45312 15.49V6.49C2.45312 5.11 3.57313 3.99 4.95312 3.99H11.4431L10.4431 4.99H4.95312C4.12313 4.99 3.45312 5.66 3.45312 6.49V15.49C3.45312 16.32 4.12313 16.99 4.95312 16.99H13.9531C14.7731 16.99 15.4531 16.32 15.4531 15.49V9.98ZM18.2731 5.17C18.2331 5.24 18.1831 5.31 18.1231 5.37L17.9831 5.51L17.1831 6.31L16.4531 7.04L15.4531 8.04L12.6631 10.83L9.20312 14.29C9.13312 14.36 9.05312 14.41 8.95312 14.43L5.93313 15.06C5.93313 15.06 5.86313 15.07 5.83313 15.07C5.70312 15.07 5.57312 15.02 5.47312 14.92C5.35313 14.8 5.30313 14.63 5.34313 14.47L5.97312 11.44C5.99312 11.35 6.03313 11.26 6.10313 11.19L10.1831 7.11L12.3031 4.99L13.3031 3.99L14.0831 3.21L14.3131 2.98L14.8831 2.41L15.0231 2.27C15.3831 1.91 15.9731 1.91 16.3231 2.27L17.0231 2.97L18.1231 4.07C18.1231 4.07 18.1631 4.12 18.1831 4.14C18.4231 4.44 18.4531 4.84 18.2731 5.17ZM14.9331 7.15L13.2431 5.46L10.7531 7.95L7.15312 11.55L8.84312 13.24L12.1031 9.98L14.9331 7.15ZM16.1231 5.96L15.4531 5.29L15.1531 4.99L14.4331 4.27L13.9531 4.75L14.2031 4.99L15.4531 6.25L15.6431 6.44L16.1231 5.96Z"
        fill="#858585"
      />
    </g>
  </svg>
);
