const LogOutIcon = ({ size = 20, ...props }: any) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.98 15H8.97V4.96C8.97 4.76 8.85 4.57 8.66 4.5L5.01 3H12.96V7H13.96V2.5C13.96 2.22 13.74 2 13.46 2H2.48C2.48 2 2.46 2 2.45 2C2.42 2 2.4 2 2.38 2.01C2.33 2.02 2.28 2.04 2.24 2.06C2.23 2.06 2.22 2.06 2.21 2.07C2.2 2.07 2.2 2.09 2.19 2.1C2.15 2.13 2.11 2.17 2.08 2.22C2.07 2.24 2.05 2.25 2.05 2.27C2.02 2.34 2 2.41 2 2.49V15.83C2 16.06 2.15 16.25 2.37 16.31L8.36 17.94C8.36 17.94 8.45 17.96 8.49 17.96C8.6 17.96 8.71 17.92 8.79 17.86C8.91 17.77 8.99 17.62 8.99 17.46V15.98H13.5C13.78 15.98 14 15.76 14 15.48V10.99H13V14.98L12.98 15ZM7.97 16.83L2.98 15.47V3.25L7.97 5.3V16.83ZM15.42 11.75V9.51H9.98V8.51H15.42V6.27L18.16 9.01L15.42 11.75Z"
      fill="currentColor"
    />
  </svg>
);

export default LogOutIcon;
