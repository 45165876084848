import { FunctionComponent } from "react";
import { Box, Grid } from "@mui/material";
import Input from "components/Input";

export interface QuickSearchProps {
  onChange: (val: string) => void;
  searchTerm: string;
  quickSearchPlaceholder: string;
}

export const QuickSearch: FunctionComponent<QuickSearchProps> = ({
  quickSearchPlaceholder,
  searchTerm,
  onChange,
}) => {
  return (
    <Box padding={3}>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={6}>
          <Input
            id="quick-search-field"
            fullWidth
            placeholder={quickSearchPlaceholder}
            clearable
            value={searchTerm}
            onChange={onChange}
            size="small"
            sx={{ maxWidth: "350px" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
