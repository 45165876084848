import { Box } from "@mui/material";

const SectionContainer = ({ children }) => {
  return (
    <Box
      style={{
        padding: 20,
      }}
    >
      {children}
    </Box>
  );
};

export default SectionContainer;
