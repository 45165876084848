export const verifyUserStatusPath = `/verifyUserStatus`;
export const AGENT_COMMAND_PATH = "/agent/command";
export const DEPLOY_METRICS_PROXY_PATH = "/deployMetricsProxy";
export const FETCH_ALL_RELEASES_PATH = "/release/fetchAllReleases";
export const SYNC_RELEASES_PATH = "/release/syncAndFetchReleases";
export const SAVE_RELEASE_PATH = "/release/update";
export const FETCH_AVAILABLE_USER_PROFILES =
  "/user/profile/fetchAvailableUserProfiles";
export const FETCH_CURRENT_USER_PROFILE =
  "/user/profile/fetchCurrentUserProfile";
export const UPDATE_CURRENT_USER_PROFILE =
  "/user/profile/updateCurrentUserProfile";
export const FETCH_COMPATIBILITY_LIST = "/getCompatibilityList";
export const FETCH_BRAAVOS_BILLING_PROFILE =
  "/organization/braavosBillingProfile";
export const FETCH_BRAAVOS_SUBSCRIPTION = "/organization/braavosSubscription";
