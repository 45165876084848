import { Alert, Snackbar } from "@mui/material";
import { AlertColor } from "@mui/material/Alert/Alert";
import { LINEAR_PROGRESS_INDEX } from "constants/common";

interface IMessage {
  text: string;
  severity: AlertColor;
}

export class Message implements IMessage {
  text: string;
  severity: AlertColor;
  constructor(text: string, severity?: AlertColor) {
    this.text = text;
    if (severity) {
      this.severity = severity;
    } else {
      this.severity = "info";
    }
  }
}

export const SnackbarMessage = ({
  message,
  onDismiss,
  autoHideDuration = 5000,
}: {
  message: Message;
  onDismiss?: () => void;
  autoHideDuration?: number;
}) => {
  const open = !!message;
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={() => onDismiss && onDismiss()}
      open={open}
      autoHideDuration={autoHideDuration}
      sx={{ zIndex: LINEAR_PROGRESS_INDEX + 1 }}
    >
      <Alert
        variant="filled"
        elevation={6}
        onClose={() => onDismiss && onDismiss()}
        severity={message.severity}
      >
        {message.text}
      </Alert>
    </Snackbar>
  );
};
