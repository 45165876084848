import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { CheckRounded } from "images/svg/CheckRounded";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import logo from "../images/branding/orkes-logo-purple-2x.png";
import LoginIcon from "@mui/icons-material/Login";
import { useAuth } from "auth/AuthContext";
import { useAuthMachine } from "auth/state/hook";

const pageContent = {
  emailVerified: {
    title: "Success!",
    description:
      "Email was successfully verified. For the final test, please click below.",
  },
  emailVerificationFailed: {
    title: "Failed!",
    description:
      "There appears to be an issue with email verification. Please try again.",
  },
  invalidUrl: {
    title: "Invalid URL!",
    description:
      "The URL appears to be invalid. Please try again by clicking on the email link we sent.",
  },
};

const getPageContent = (isError: boolean, isInvalidUrl: boolean) => {
  if (isError) return pageContent.emailVerificationFailed;
  if (isInvalidUrl) return pageContent.invalidUrl;
  return pageContent.emailVerified;
};

const UserVerify = () => {
  const { authService } = useAuth();

  const [
    {
      isVerifyingEmail,
      isEmailVerificationFailed,
      isEmailVerificationUrlInvalid,
    },
    { navigateToLogin },
  ] = useAuthMachine(authService);

  const { title, description } = getPageContent(
    isEmailVerificationFailed,
    isEmailVerificationUrlInvalid
  );

  const isError = isEmailVerificationFailed || isEmailVerificationUrlInvalid;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        p: 2,
      }}
    >
      <Box sx={{ width: "100%" }}>
        <img
          src={logo}
          alt="Orkes Cloud"
          style={{
            height: 80,
          }}
        />
      </Box>
      {isVerifyingEmail ? (
        <CircularProgress size="3rem" sx={{ margin: "auto", mt: 10 }} />
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={2}
          mt={10}
        >
          {isError ? (
            <CancelOutlinedIcon color="error" sx={{ fontSize: 100 }} />
          ) : (
            <CheckRounded />
          )}
          <Typography sx={{ fontSize: 33, fontWeight: 700 }}>
            {title}
          </Typography>
          <Typography sx={{ fontSize: 16, color: "#494949" }}>
            {description}
          </Typography>
          {isError ? null : (
            <Button
              onClick={navigateToLogin}
              variant="contained"
              color="primary"
              sx={{
                textTransform: "none",
                mt: 4,
              }}
              startIcon={<LoginIcon />}
            >
              Login
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default UserVerify;
