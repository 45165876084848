import Button, { ButtonProps } from "@mui/material/Button";
import { fontFamilyList } from "./CustomButtons";

const StyledMainButton = ({ children, ...props }: ButtonProps) => {
  return (
    <Button
      sx={{
        fontFamily: fontFamilyList,
        textTransform: "none",
        height: 40,
        color: "#ffffff",
        backgroundColor: "#1976d2",
        opacity: 1,
        "&:hover": {
          backgroundColor: "#193f6b",
          color: "#ffffff",
        },
      }}
      variant="contained"
      {...props}
    >
      {children}
    </Button>
  );
};

export default StyledMainButton;
