import { CheckCircleOutlined, RefreshRounded } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import { AlertColor } from "@mui/material/Alert/Alert";
import { useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";

import ProvisioningProgress from "components/progress/ProvisioningProgress";
import { statusMap, taskDescriptions } from "constants/provisioning";
import { useClusterDetail, useWorkflowStatus } from "utils/query";
import { calculateTimeFromMillis, customStyles } from "utils/utils";
import ClusterDetailDisplay from "../components/ClusterDetailCard/ClusterDetailDisplay";
import DataTable from "../components/DataTable/DataTable";
import LinearProgress from "../components/LinearProgress";
import "../css/ClusterListings.css";
import {
  ProvisioningStatus,
  TaskStatus,
  WorkflowStatus,
} from "../utils/saastypes";
import useInterval from "../utils/useInterval";

const localCustomStyles = {
  ...customStyles,
  rows: {
    style: {
      minHeight: "60px", // override the row height
    },
  },
};

const ruleOutSystemTasks = (task: any) =>
  ["SWITCH"].indexOf(task.parentTask) < 0;

let clusterProgressIntervalId: NodeJS.Timeout;

function TimeSinceStart({
  startTime,
  currentTime,
}: {
  startTime: number;
  currentTime: number;
}) {
  const [timer, setTimer] = useState<number>(0);

  useEffect(() => {
    if (startTime) {
      setTimer(Math.round((currentTime - startTime) / 1000));
    }
  }, [startTime, currentTime]);

  useEffect(() => {
    if (clusterProgressIntervalId) clearInterval(clusterProgressIntervalId);

    clusterProgressIntervalId = setInterval(() => {
      setTimer((prevState) => prevState + 1);
    }, 1000);

    return () => {
      if (clusterProgressIntervalId) clearInterval(clusterProgressIntervalId);
    };
  }, []);

  return (
    <div
      style={{
        color: "black",
        paddingTop: 8,
      }}
    >
      {startTime ? calculateTimeFromMillis(timer) : "N/A"}
    </div>
  );
}

export default function ClusterProgress() {
  const [searchParams] = useSearchParams();
  const dataTableRef = useRef<HTMLDivElement>(null);
  const [currentTime, setCurrentTime] = useState(Date.now());

  const {
    data: clusterDetail,
    isFetching: clusterDetailFetching,
    refetch: refetchClusterDetail,
  } = useClusterDetail(searchParams.get("clusterName") as string);

  const {
    data: workflowStatus,
    isFetching: workflowStatusFetching,
    refetch: refetchWorkflowStatus,
  } = useWorkflowStatus(searchParams.get("clusterName") as string);

  class AlertMessage {
    color: AlertColor;
    text: string;

    private constructor(color: AlertColor, text: string) {
      this.color = color;
      this.text = text;
    }

    static info(text: string): AlertMessage {
      return new AlertMessage("info", text);
    }

    static warning(text: string): AlertMessage {
      return new AlertMessage("warning", text);
    }

    static error(text: string): AlertMessage {
      return new AlertMessage("error", text);
    }

    static success(text: string): AlertMessage {
      return new AlertMessage("success", text);
    }
  }

  useInterval(
    () => {
      refetchWorkflowStatus();
      setCurrentTime(Date.now());
    },
    workflowStatus?.status === ProvisioningStatus.RUNNING ? 5000 : null
  );

  function getDescription(
    taskStatus: TaskStatus | undefined
  ): string | undefined {
    if (taskStatus === undefined) {
      return undefined;
    }

    const descriptor = taskDescriptions.get(taskStatus.label);

    return (descriptor && descriptor[0]) || undefined;
  }

  function mapString(text: string | undefined, message: string): string {
    return (text && message.replace("{}", text)) || "";
  }

  function getSeverity(
    workflowStatus: WorkflowStatus | undefined
  ): AlertMessage | null {
    if (workflowStatus === undefined) {
      return null;
    }

    const status = workflowStatus.status;

    const scheduledTask = workflowStatus.taskStatusList.find(
      (t) => t.status === ProvisioningStatus.SCHEDULED
    );
    const canceledTask = workflowStatus.taskStatusList.find(
      (t) => t.status === ProvisioningStatus.CANCELED
    );
    const failedTask = workflowStatus.taskStatusList.find(
      (t) => t.status === ProvisioningStatus.FAILED
    );

    switch (status) {
      case ProvisioningStatus.RUNNING:
        return AlertMessage.info(
          `Provisioning workflow is in progress${mapString(
            getDescription(scheduledTask),
            ": {}"
          )}`
        );

      case ProvisioningStatus.PAUSED:
        return AlertMessage.warning(
          `Provisioning workflow is paused${mapString(
            getDescription(scheduledTask),
            " at: {}"
          )}`
        );

      case ProvisioningStatus.TERMINATED:
        return AlertMessage.error(
          `Provisioning workflow has been terminated${mapString(
            getDescription(canceledTask),
            " at: {}"
          )}`
        );

      case ProvisioningStatus.FAILED:
        return AlertMessage.error(
          `Provisioning workflow has failed${mapString(
            getDescription(failedTask),
            " at: {}"
          )}`
        );

      case ProvisioningStatus.TIMED_OUT:
        return AlertMessage.error("Provisioning workflow has timed out.");

      case ProvisioningStatus.COMPLETED:
        return AlertMessage.success(
          "Provisioning workflow is complete. Your environment is ready for use."
        );

      default:
        return AlertMessage.warning("Unable to determine the workflow status.");
    }
  }

  function refetchAll() {
    refetchClusterDetail();
    refetchWorkflowStatus();
  }

  let alertMessage = getSeverity(workflowStatus);

  // Refetch cluster's information after workflow's status changing
  // to update the latest state
  useEffect(() => {
    if (workflowStatus?.status) {
      refetchClusterDetail();
    }
  }, [workflowStatus?.status, refetchClusterDetail]);

  const provisioningProgresses = useMemo(
    () => workflowStatus?.taskStatusList?.filter(ruleOutSystemTasks) || [],
    [workflowStatus]
  );

  return (
    <>
      <Helmet>
        <title>
          Orkes Cloud - Monitor Progress -{" "}
          {searchParams.get("clusterName") as string}
        </title>
      </Helmet>

      {(clusterDetailFetching || workflowStatusFetching) && <LinearProgress />}
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          borderBottom: "1px solid rgb(200,200,200, 0.5)",
          marginTop: 9,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            px: 6,
          }}
        >
          <h2 style={{ marginBottom: 15 }}>
            Monitor Progress for Cluster -{" "}
            {searchParams.get("clusterName") as string}
          </h2>
          <Button
            aria-controls="refresh"
            onClick={refetchAll}
            startIcon={<RefreshRounded />}
            variant="contained"
            sx={{
              fontSize: "inherit",
              textTransform: "none",
              height: "30px",
            }}
          >
            Refresh
          </Button>
        </Box>
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "left",
          flexDirection: "column",
          paddingRight: 0,
          paddingLeft: 0,
          paddingTop: 5,
        }}
      >
        {alertMessage !== null && (
          <Box style={{ padding: "5px 29px 0px 24px" }}>
            <Alert
              severity={alertMessage.color}
              sx={{
                margin: "0 20px 20x 0",
                ".MuiAlert-message": {
                  width: "100%",
                },
              }}
            >
              <AlertTitle>{workflowStatus?.status}</AlertTitle>
              <ProvisioningProgress
                provisioningProgresses={provisioningProgresses}
                dataTableRef={dataTableRef}
                message={alertMessage.text}
              />
            </Alert>
          </Box>
        )}

        <ClusterDetailDisplay
          paperStyles={{ marginTop: 5, marginLeft: 25, marginRight: 30 }}
          key={`clusterDetails`}
          skipMiddleBar={false}
          custEnv={clusterDetail}
        />

        <Box style={{ padding: "5px 29px 0px 35px" }} ref={dataTableRef}>
          <DataTable
            pagination={false}
            customStyles={localCustomStyles}
            // progressPending={workflowStatusFetching}
            defaultShowColumns={[
              "parentTask",
              "description",
              "status",
              "timeOfExecution",
            ]}
            noDataComponent={
              <Box padding={5} fontWeight={600}>
                {"Unable to load provisioning status"}
              </Box>
            }
            data={provisioningProgresses}
            columns={[
              {
                name: "parentTask",
                label: "Provisioning Step",
                grow: 1.2,
                selector: (row: any) => row.parentTask,
                renderer(val: any, row: any) {
                  const strings = taskDescriptions.get(row.parentTask);
                  return strings ? strings[0] : val;
                },
              },
              {
                name: "description",
                label: "Description",
                grow: 4,
                selector: () => null,
                renderer(val: any, row: any) {
                  const strings = taskDescriptions.get(row.parentTask);
                  return strings ? strings[1] : "Not Available";
                },
              },
              {
                name: "status",
                label: "Status",
                grow: 1.0,
                selector: (row: any) => row.status,
                renderer(val: string, row: any) {
                  let status = statusMap.get(val);
                  if (!status) {
                    return <span>{val}</span>;
                  }
                  const isInProgress =
                    val === ProvisioningStatus.IN_PROGRESS ||
                    val === ProvisioningStatus.SCHEDULED;
                  return (
                    <div id={row?.label}>
                      <div
                        style={{
                          color: status.color,
                          display: "flex",
                          alignItems: "center",
                          justifyItems: "center",
                        }}
                      >
                        {val === ProvisioningStatus.COMPLETED && (
                          <CheckCircleOutlined style={{ marginRight: 8 }} />
                        )}
                        {isInProgress && (
                          <CircularProgress
                            style={{ marginRight: 8 }}
                            size={16}
                          />
                        )}
                        {status.name}
                      </div>
                      {isInProgress && (
                        <TimeSinceStart
                          currentTime={currentTime}
                          startTime={row.timeOfExecution}
                        />
                      )}
                    </div>
                  );
                },
              },
              {
                name: "timeOfExecution",
                label: "Start Time",
                grow: 0.8,
                selector: (row: any) => row.timeOfExecution,
                renderer(val: any, row: any) {
                  return val ? new Date(val).toLocaleString() : "NA";
                },
              },
            ]}
          />
        </Box>
      </Box>
    </>
  );
}
