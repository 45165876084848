import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import DataTable from "components/DataTable/DataTable";
import LinearProgress from "components/LinearProgress";
import StyledMainButton from "components/buttons/StyledMainButton";
import ReleaseVersionDialog from "components/dialogs/ReleaseVersionDialog";
import SectionHeader from "shared/SectionHeader";
import {
  useAllReleases,
  useGetAllReleases,
  useSyncReleases,
} from "utils/query";
import { ReleaseDto } from "utils/saastypes";
import { customStyles, optionalPropertySort, sortByVersion } from "utils/utils";
import { RefreshIcon } from "./RefreshIcon";
import Input from "components/Input";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import useInterval from "utils/useInterval";

const deploymentOptions = [
  { value: "ALL", name: "ALL" },
  { value: "AGENT", name: "AGENT" },
  { value: "CONDUCTOR", name: "CONDUCTOR" },
  { value: "METRICS_PROXY", name: "METRICS_PROXY" },
  { value: "WORKERS", name: "WORKERS" },
];

const statusOptions = [
  { value: "ALL", name: "ALL" },
  { value: "TESTING", name: "TESTING" },
  { value: "AVAILABLE", name: "AVAILABLE" },
  { value: "RETIRED", name: "RETIRED" },
];

export default function VersionManagement() {
  const { data, isFetching, refetch } = useAllReleases();
  const {
    data: workflowData,
    isFetching: isSyncFetching,
    refetch: refetchSync,
  } = useSyncReleases({
    enabled: false,
    cacheTime: 0,
  });

  const {
    data: releaseData,
    isFetching: isFetchingReleases,
    refetch: refetchAllReleases,
  } = useGetAllReleases(workflowData?.workflowId ?? "", {
    enabled: false,
  });

  const [selectedRow, setSelectedRow] = useState<ReleaseDto | null>(null);

  useInterval(
    async () => {
      if (releaseData?.ready === false) {
        await refetchAllReleases();
      }
    },
    releaseData?.ready === false ? 5000 : null
  );

  const closeDialog = () => {
    setSelectedRow(null);
  };

  const [versionInputVal, setVersionInputVal] = useState("");
  const [selectedDeploymentType, setSelectedDeploymentType] = useState("ALL");
  const [selectedStatusVal, setSelectedStatusVal] = useState("ALL");

  const filteredData = useMemo(() => {
    const selectedData = releaseData?.releases ?? data;
    if (selectedData) {
      return selectedData.filter((row) => {
        const versionMatch =
          !versionInputVal ||
          row.version.toLowerCase().includes(versionInputVal.toLowerCase());
        const deploymentMatch =
          selectedDeploymentType === "ALL" ||
          row.deploymentType === selectedDeploymentType;
        const statusMatch =
          selectedStatusVal === "ALL" || row.status === selectedStatusVal;

        return versionMatch && deploymentMatch && statusMatch;
      });
    }
  }, [
    releaseData?.releases,
    data,
    versionInputVal,
    selectedDeploymentType,
    selectedStatusVal,
  ]);

  const isPending =
    isFetchingReleases ||
    releaseData?.ready === false ||
    isSyncFetching ||
    isFetching;

  return (
    <Box sx={{ padding: 5, paddingTop: 1.5 }}>
      <Helmet>
        <title>Version Management</title>
      </Helmet>

      {selectedRow && (
        <ReleaseVersionDialog
          onClose={closeDialog}
          open={true}
          refetch={refetch}
          releaseVersion={selectedRow}
        />
      )}

      <SectionHeader
        title={"Version Management"}
        _deprecate_marginTop={0}
        style={{ padding: "20px 0" }}
        actions={
          <>
            <StyledMainButton
              size="small"
              color={"inherit"}
              style={{
                fontSize: 14,
                marginLeft: 10,
              }}
              onClick={() => refetchSync()}
              disabled={isPending}
              startIcon={<RefreshIcon disabled={isPending} />}
            >
              Refresh
            </StyledMainButton>
          </>
        }
      />

      <Grid container spacing={4} my={4}>
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <Input
            label="Version"
            sx={{ minWidth: "100%" }}
            value={versionInputVal}
            onChange={(val) => setVersionInputVal(val)}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <FormControl size="small" sx={{ minWidth: "100%" }}>
            <InputLabel>Deployment Type</InputLabel>
            <Select
              value={selectedDeploymentType}
              label="Deployment Type"
              onChange={(e) => {
                setSelectedDeploymentType(e.target.value);
              }}
            >
              {deploymentOptions.map((opt, i) => (
                <MenuItem key={`${i}+${opt.value}`} value={opt.value}>
                  {opt.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <FormControl size="small" sx={{ minWidth: "100%" }}>
            <InputLabel>Status</InputLabel>
            <Select
              value={selectedStatusVal}
              label="Status"
              onChange={(e) => {
                setSelectedStatusVal(e.target.value);
              }}
            >
              {statusOptions.map((opt, i) => (
                <MenuItem key={`${i}+${opt.value}`} value={opt.value}>
                  {opt.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {isPending && <LinearProgress />}

      <Paper elevation={1}>
        <DataTable
          localStorageKey="versions"
          customStyles={customStyles}
          progressPending={isPending}
          pagination
          noDataComponent={
            <Box padding={5} fontWeight={600}>
              No versions found
            </Box>
          }
          data={filteredData}
          columns={[
            {
              name: "deploymentType",
              selector: (row: ReleaseDto) => row.deploymentType,
              label: "Deployment Type",
              grow: 1,
            },
            {
              name: "status",
              selector: (row: ReleaseDto) => row.status,
              label: "Status",
              grow: 1,
              sortFunction: (rowA: ReleaseDto, rowB: ReleaseDto) =>
                optionalPropertySort({ rowA, rowB, selector: "status" }),
            },
            {
              name: "version",
              selector: (row: ReleaseDto) => row.version,
              label: "Version",
              grow: 1,
              renderer(val: string, row: ReleaseDto) {
                return (
                  <Button
                    disableRipple
                    onClick={() => {
                      setSelectedRow(row);
                    }}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      ":hover": {
                        color: "#2196f3",
                        backgroundColor: "unset",
                      },
                    }}
                  >
                    {val}
                  </Button>
                );
              },
              sortFunction: (rowA: ReleaseDto, rowB: ReleaseDto) =>
                sortByVersion(rowA, rowB),
            },
            {
              name: "endOfLife",
              selector: (row: ReleaseDto) => row.endOfLife,
              label: "EOL",
              type: "date",
              grow: 1,
              sortFunction: (rowA: ReleaseDto, rowB: ReleaseDto) =>
                optionalPropertySort({ rowA, rowB, selector: "endOfLife" }),
            },
            {
              name: "dateOfRelease",
              selector: (row: ReleaseDto) => row.dateOfRelease,
              label: "Release Date",
              type: "date",
              grow: 1,
            },
            {
              name: "generalNotes",
              selector: (row: ReleaseDto) => row.generalNotes,
              label: "General notes",
              grow: 1,
              sortFunction: (rowA: ReleaseDto, rowB: ReleaseDto) =>
                optionalPropertySort({ rowA, rowB, selector: "generalNotes" }),
            },
          ]}
        />
      </Paper>
    </Box>
  );
}
