import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import _last from "lodash/last";
import { RefObject, useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import LinearProgress from "components/LinearProgress";
import { LINEAR_PROGRESS_INDEX } from "constants/common";
import { ProvisioningStatus } from "utils/saastypes";

export interface ProvisioningProgressProps {
  provisioningProgresses: any[];
  dataTableRef: RefObject<HTMLDivElement>;
  message: string;
}

type ProgressColor =
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning"
  | "inherit";

export default function ProvisioningProgress({
  provisioningProgresses,
  dataTableRef,
  message,
}: ProvisioningProgressProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const scrollToId = location.hash;

  const completedProgresses = useMemo(
    () =>
      provisioningProgresses?.filter(
        (item) => item.status === ProvisioningStatus.COMPLETED
      ) || [],
    [provisioningProgresses]
  );

  const lastProgress = useMemo(
    () =>
      provisioningProgresses.length !== completedProgresses.length
        ? provisioningProgresses[completedProgresses.length]
        : _last(completedProgresses),
    [provisioningProgresses, completedProgresses]
  );

  const progressColor = useMemo<ProgressColor>(() => {
    switch (lastProgress?.status) {
      case ProvisioningStatus.FAILED:
      case ProvisioningStatus.TERMINATED:
      case ProvisioningStatus.TIMED_OUT:
      case ProvisioningStatus.CANCELED:
        return "error";

      case ProvisioningStatus.RUNNING:
      case ProvisioningStatus.SCHEDULED:
        return "info";

      case ProvisioningStatus.COMPLETED:
        return "success";

      default:
        return "warning";
    }
  }, [lastProgress]);

  const scrollToElement = useCallback(
    (elementId: string) => {
      if (lastProgress && elementId && dataTableRef.current) {
        const element = dataTableRef.current?.querySelector(elementId);

        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    },
    [lastProgress, dataTableRef]
  );

  const handleClick = () => {
    if (lastProgress) {
      const elementId = `#${lastProgress.label}`;

      if (scrollToId !== elementId) {
        navigate(`${location.pathname}${location.search}${elementId}`);
      } else {
        scrollToElement(elementId);
      }
    }
  };

  useEffect(() => {
    scrollToElement(scrollToId);
  }, [scrollToId, scrollToElement]);

  return (
    <Tooltip
      arrow
      title="Click to see detailed progress information"
      placement="top"
    >
      <Box>
        <LinearProgress
          variant="determinate"
          value={
            (completedProgresses.length / provisioningProgresses.length) * 100
          }
          color={progressColor}
          sx={{
            minHeight: "30px",
            height: "100%",
            my: 1,
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 2,

            ":after": {
              content: `"${message} - ${completedProgresses.length}/${provisioningProgresses.length}"`,
              color: "white",
              zIndex: LINEAR_PROGRESS_INDEX + 1,
              pointerEvents: "none",
            },
          }}
          onClick={handleClick}
        />
      </Box>
    </Tooltip>
  );
}
