import { useCallback, useState } from "react";
import { TabPanel } from "./TabPanel";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  useActionWithPath,
  useDeploymentInfoFull,
  useHistoryEntry,
  useUserIds,
} from "../../../utils/query";
import LinearProgress from "../../../components/LinearProgress";
import ClipboardCopy from "../../../components/ClipboardCopy";
import StyledMainButton from "../../../components/buttons/StyledMainButton";
import { ExpandMoreRounded, InfoRounded, Refresh } from "@mui/icons-material";
import { uniq } from "lodash";

enum ApplicationProperties {
  ORKES_AGENT_DEPLOYMENT = "orkes-agent-deployment",
  ORKES_CONDUCTOR_DEPLOYMENT = "orkes-conductor-deployment",
  ORKES_WORKER_DEPLOYMENT = "orkes-workers-deployment",
  //special case where keys are different for conductor server and worker
  CUSTOM_ORKES_CONDUCTOR_DEPLOYMENT = "orkes-conductor",
  CUSTOM_ORKES_WORKER_DEPLOYMENT = "orkes-conductor-workers",
}

function KVPairEntry({
  even = false,
  name,
  value,
}: {
  even?: boolean;
  name: string;
  value: string;
}) {
  return (
    <div
      style={{
        padding: "12px 6px",
        background: even ? "#f2f2f2" : "#fafafa",
      }}
    >
      <Typography
        style={{
          fontSize: 14,
          color: "grey",
          marginBottom: 8,
        }}
      >
        {name}
      </Typography>
      <ClipboardCopy
        style={{
          display: "inline-flex",
        }}
        value={value}
      >
        <Typography
          style={{
            fontFamily: "monospace",
          }}
        >
          {value}
        </Typography>
      </ClipboardCopy>
    </div>
  );
}

export default function ApplicationPropertiesTab({
  selectedTabIndex,
  index,
  clusterDetail,
}: {
  selectedTabIndex: number;
  index: number;
  clusterDetail: any;
}) {
  const {
    data: deploymentInfo,
    isFetching,
    error,
  } = useDeploymentInfoFull(clusterDetail?.name);

  const { data: userIds } = useUserIds(
    getIdsFromDeploymentInfo(deploymentInfo)
  );

  function formatDeploymentInfo(deploymentInfo: any) {
    let tmp: any = {};
    if (!deploymentInfo) {
      return tmp;
    }
    for (let i of Object.keys(deploymentInfo)) {
      tmp[i] = deploymentInfo[i].data;
    }
    return tmp;
  }

  const noData =
    !deploymentInfo ||
    !Object.values(ApplicationProperties).some((key) => deploymentInfo[key]);

  function getIdsFromDeploymentInfo(deploymentInfo: any) {
    if (!deploymentInfo) {
      return [];
    }
    return uniq(
      Object.values(ApplicationProperties).flatMap((key) => [
        deploymentInfo[key]?.createdBy,
        deploymentInfo[key]?.updatedBy,
      ])
    );
  }

  const [entryId, setEntryId] = useState("");
  const { data: historyEntry, isFetching: isEntryFetching } = useHistoryEntry(
    clusterDetail?.name,
    entryId
  );

  const applicationsPropertiesData = historyEntry?.result
    ? historyEntry?.result
    : formatDeploymentInfo(deploymentInfo);

  const isWorking = isFetching || isEntryFetching;

  // Note: useMutation is always return a new Object each render
  const getApplicationPropertiesAction = useActionWithPath({}).mutateAsync;

  const fetchApplicationProperties = useCallback(async () => {
    try {
      // @ts-ignore
      let res = await getApplicationPropertiesAction({
        method: "post",
        path: `/agent/command`,
        body: JSON.stringify({
          clusterName: clusterDetail?.name,
          command: "GET_DEPLOYMENTS_INFO",
          parameters: {},
        }),
      });
      if (res && res.id && res.status === "SUCCESS") {
        setEntryId(res.id);
      }
    } catch (err) {
      console.error(err);
    }
  }, [getApplicationPropertiesAction, clusterDetail?.name, setEntryId]);

  const renderAccordionPanel = (
    deploymentKey: string,
    fallbackKey: string,
    title: string
  ) => {
    const deploymentInfoData =
      deploymentInfo &&
      (deploymentInfo[deploymentKey] || deploymentInfo[fallbackKey]);
    const applicationsPropertiesDataInfo =
      applicationsPropertiesData &&
      (applicationsPropertiesData[deploymentKey] ||
        applicationsPropertiesData[fallbackKey]);

    if (applicationsPropertiesDataInfo) {
      return (
        <AccordionPanel
          key={title.toLowerCase()}
          deploymentInfo={deploymentInfoData}
          data={applicationsPropertiesDataInfo}
          userIds={userIds}
          title={title}
          deployedVersion={clusterDetail?.appVersions?.[title.toLowerCase()]}
        />
      );
    }
    return null;
  };

  const renderAccordionPanels = () => {
    return (
      <>
        {renderAccordionPanel(
          ApplicationProperties.ORKES_CONDUCTOR_DEPLOYMENT,
          ApplicationProperties.CUSTOM_ORKES_CONDUCTOR_DEPLOYMENT,
          "Conductor"
        )}
        {renderAccordionPanel(
          ApplicationProperties.ORKES_WORKER_DEPLOYMENT,
          ApplicationProperties.CUSTOM_ORKES_WORKER_DEPLOYMENT,
          "Workers"
        )}
        {applicationsPropertiesData[
          ApplicationProperties.ORKES_AGENT_DEPLOYMENT
        ] && (
          <AccordionPanel
            key="agent"
            deploymentInfo={
              deploymentInfo[ApplicationProperties.ORKES_AGENT_DEPLOYMENT]
            }
            data={
              applicationsPropertiesData[
                ApplicationProperties.ORKES_AGENT_DEPLOYMENT
              ]
            }
            userIds={userIds}
            title="Agent"
            deployedVersion={clusterDetail?.appVersions?.agent}
          />
        )}
      </>
    );
  };

  return (
    <TabPanel value={selectedTabIndex} index={index}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          borderBottom: "1px solid lightgrey",
        }}
      >
        <Box>
          <StyledMainButton
            startIcon={<Refresh />}
            id="refresh-app-props-btn"
            style={{
              margin: 16,
            }}
            disabled={isWorking}
            onClick={() => {
              fetchApplicationProperties();
            }}
          >
            Refresh Application Properties
          </StyledMainButton>
        </Box>
        {error && !isWorking && (
          <Box
            style={{
              margin: 16,
            }}
          >
            Error while fetching Application Properties, please try again.
          </Box>
        )}
        {isWorking && <LinearProgress />}
        {!isWorking && noData && (
          <Box
            style={{
              margin: 16,
            }}
          >
            No Application Properties available for this cluster.
          </Box>
        )}

        {!error && renderAccordionPanels()}
      </Box>
    </TabPanel>
  );
}

function AccordionPanel({
  deploymentInfo,
  userIds,
  data,
  title,
  deployedVersion,
}: {
  deploymentInfo: any;
  userIds: any;
  data: any;
  title: string;
  deployedVersion: string | undefined;
}) {
  const [expanded, setExpanded] = useState(false);
  return (
    <Accordion
      expanded={expanded}
      style={{
        borderBottom: "1px solid #f2f2f2",
        boxShadow: "none",
      }}
      onChange={(e, expanded) => {
        setExpanded(expanded);
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreRounded />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          Orkes {title} Deployment
          <Tooltip
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              marginLeft: 8,
              marginBottom: 0,
            }}
            title={
              <>
                <div>
                  Created At:{" "}
                  {new Date(deploymentInfo.createdAt).toLocaleString()}
                </div>
                <div>
                  Created By:{" "}
                  {(userIds && userIds[deploymentInfo.createdBy]?.userEmail) ||
                    "Unknown"}
                </div>
                <div>
                  Updated At:{" "}
                  {new Date(deploymentInfo.updatedAt).toLocaleString()}
                </div>
                <div>
                  Updated By:{" "}
                  {(userIds && userIds[deploymentInfo.updatedBy]?.userEmail) ||
                    "Unknown"}
                </div>
              </>
            }
          >
            <InfoRounded />
          </Tooltip>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {deployedVersion && (
          <KVPairEntry
            even={false}
            name="Deployed version"
            value={deployedVersion}
          />
        )}
        {data?.map((x: { name: string; value: string }, index: number) => {
          return (
            <KVPairEntry
              key={index}
              even={index % 2 === 0}
              name={x.name}
              value={x.value}
            />
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
}
