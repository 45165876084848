import { fetchWithContext } from "utils/fetch";
import { UserInfoModalContext } from "./types";
import { getErrorsMessage } from "utils/utils";
import { postDataLayer } from "utils/postToDataLayer";

export const validateOtp = async ({
  authHeader,
  userInfo,
  verificationCode,
  currentUserEmail,
}: UserInfoModalContext) => {
  const url = `/validateOtpCode`;
  try {
    if (userInfo?.phoneNumber && verificationCode) {
      const result = await fetchWithContext(
        url,
        {},
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ...authHeader,
          },
          body: JSON.stringify({
            phone: userInfo?.phoneNumber,
            code: verificationCode,
          }),
        }
      );
      if (currentUserEmail) {
        postDataLayer("free_trial_phone_confirmed", currentUserEmail);
      }
      return { result, successMessage: "Phone number verified." };
    } else {
      return Promise.reject({
        message: "Phone number or verification code not available",
      });
    }
  } catch (error: any) {
    const message = await getErrorsMessage(error);
    return Promise.reject({
      message:
        message || "Wrong code entered. Please check your code and try again.",
    });
  }
};

export const sendOtpCode = async ({
  authHeader,
  userInfo,
  currentUserEmail,
  captchaValue,
}: UserInfoModalContext) => {
  const url = `/sendOtpCode`;
  try {
    if (userInfo?.phoneNumber) {
      const result = await fetchWithContext(
        url,
        {},
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ...authHeader,
          },
          body: JSON.stringify({
            phone: userInfo?.phoneNumber,
            captcha: captchaValue,
          }),
        }
      );
      if (currentUserEmail) {
        postDataLayer("free_trial_phone_submitted", currentUserEmail);
      }
      return { result, successMessage: "Verification code sent." };
    }
    return Promise.reject({
      message: "Phone number is not available",
    });
  } catch (error: any) {
    const message = await getErrorsMessage(error);
    if (message) {
      return Promise.reject({
        message: message,
      });
    }
    return Promise.reject({
      message: "Failed to send the verification code.",
    });
  }
};

export const submitUserInfo = async ({
  authHeader,
  userInfo,
  captchaValue,
}: UserInfoModalContext) => {
  const {
    name,
    company,
    phoneNumber,
    termsAndConditionsAccepted,
    marketingPreference,
  } = userInfo!;
  const trimmedUserName = name?.trim();
  const trimmedOrgName = company?.trim();
  const url = `/assignTrialOrganization`;
  try {
    if (trimmedUserName && trimmedOrgName) {
      const result = await fetchWithContext(
        url,
        {},
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ...authHeader,
          },
          body: JSON.stringify({
            organizationName: trimmedOrgName,
            clusterLocation: "AME",
            //clusterLocation: selectedRegion,
            userName: trimmedUserName,
            captcha: captchaValue,
            phone: phoneNumber,
            emailPreferred: marketingPreference,
            termsAndConditionsAccepted: termsAndConditionsAccepted,
          }),
        }
      );
      return {
        result,
        successMessage: "Information has been successfully updated.",
      };
    }
    return Promise.reject({
      message: "Username or Organization name is not available",
    });
  } catch (error: any) {
    const message = await getErrorsMessage(error);
    return Promise.reject({
      message: message || "Something went wrong. Please try again.",
    });
  }
};
