import { Box, Button, Chip, Typography } from "@mui/material";
import DataTable from "components/DataTable/DataTable";
import { customStyles } from "utils/utils";

type SubscriptionData = {
  name: string;
  status: "Active" | "Disabled";
};

const subscriptionData: SubscriptionData[] = [
  {
    name: "Secure Workflows",
    status: "Active",
  },
  { name: "Single Sign On", status: "Disabled" },
  {
    name: "Governance",
    status: "Active",
  },
  {
    name: "Advance Analytics",
    status: "Disabled",
  },
  {
    name: "Tailored Orkes Hosted",
    status: "Active",
  },
  {
    name: "Support",
    status: "Disabled",
  },
  {
    name: "Cluster Size",
    status: "Disabled",
  },
  {
    name: "Additional Human Task Executions",
    status: "Disabled",
  },
];

const columns = [
  {
    name: "email",
    label: "Feature Name",
    selector: (row: SubscriptionData) => row.name,
  },
  {
    name: "status",
    label: "Status",
    selector: (row: SubscriptionData) => row.status,
    renderer(val: string) {
      return (
        <Chip
          sx={{
            background: val === "Active" ? "#9fdca9" : "#fab4c6",
            fontSize: 11,
            p: 1,
          }}
          label={val}
          size="small"
        />
      );
    },
  },
  {
    name: "action",
    label: "Action",
    center: true,
    selector: (row: SubscriptionData) => row,
    renderer(val: string, row: SubscriptionData) {
      return row.status === "Disabled" ? (
        <Button
          variant="outlined"
          size="small"
          sx={{ textTransform: "none", fontSize: "10px" }}
        >
          Request feature
        </Button>
      ) : null;
    },
  },
];

export const SubscriptionTab = () => {
  return (
    <Box
      sx={{
        px: 6,
        py: 8,
        ".rdt_TableHeader": {
          display: "none",
        },
      }}
    >
      <Typography variant="h4" sx={{ opacity: 0.8, mb: 6 }}>
        Subscriptions
      </Typography>

      <DataTable
        customStyles={customStyles}
        noDataComponent={
          <Box padding={5} fontWeight={600}>
            No subscriptions found
          </Box>
        }
        data={subscriptionData}
        columns={columns}
        pagination={false}
        showColumnSelector={false}
        titleComponent={<></>}
      />
    </Box>
  );
};
