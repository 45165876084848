import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import Input from "components/Input";
import { modalStyle } from "components/WelcomeUserModal/common";
import ActionButton from "components/buttons/ActionButton";
import SendIcon from "images/send-icon-white.svg";
import { useState } from "react";
import { addOnCardData, contactUsDialogStyle } from "./common";
import { UserOrganization, useActionWithPath } from "utils/query";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useAuth } from "auth/AuthContext";

export type FormData = {
  instructions: string;
  selectedFeatureOptions: string[];
  selectedUpgradeOptions: string[];
  allFeaturesChecked: boolean;
  allUpgradesChecked: boolean;
};

interface ContactUsDialogProps {
  open: boolean;
  onClose: () => void;
  currentOrganization?: UserOrganization;
}

const featureOptions = addOnCardData.map((feature) => feature.title);
const upgradeOptions = ["Cluster Size", "Extra Human Task Executions"];

const initialState = {
  selectedFeatureOptions: [],
  selectedUpgradeOptions: [],
  instructions: "",
  allFeaturesChecked: false,
  allUpgradesChecked: false,
};

export const ContactUsDialog = ({
  open,
  onClose,
  currentOrganization,
}: ContactUsDialogProps) => {
  const { setSuccessToast } = useAuth();
  const [formData, setFormData] = useState<FormData>(initialState);

  const handleInstructions = (value: string) => {
    setFormData((prev) => ({
      ...prev,
      instructions: value,
    }));
  };

  const handleAllFeaturesChange = () => {
    setFormData((prev) => ({
      ...prev,
      allFeaturesChecked: !prev.allFeaturesChecked,
      selectedFeatureOptions: !prev.allFeaturesChecked ? featureOptions : [],
    }));
  };

  const handleAllUpgradesChange = () => {
    setFormData((prev) => ({
      ...prev,
      allUpgradesChecked: !prev.allUpgradesChecked,
      selectedUpgradeOptions: !prev.allUpgradesChecked ? upgradeOptions : [],
    }));
  };

  const handleFeatureOrUpgradeOption = (
    option: string,
    optionToUse: "updateFeature" | "updateUpgrade"
  ) => {
    const selectedOptions =
      optionToUse === "updateFeature"
        ? formData.selectedFeatureOptions
        : formData.selectedUpgradeOptions;
    const setKey =
      optionToUse === "updateFeature"
        ? "selectedFeatureOptions"
        : "selectedUpgradeOptions";

    const allOptions =
      optionToUse === "updateFeature" ? featureOptions : upgradeOptions;

    const updatedSelectedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((item) => item !== option)
      : [...selectedOptions, option];

    setFormData((prev) => ({
      ...prev,
      [setKey]: updatedSelectedOptions,
      allFeaturesChecked:
        optionToUse === "updateFeature"
          ? updatedSelectedOptions.length === allOptions.length
          : prev.allFeaturesChecked,
      allUpgradesChecked:
        optionToUse === "updateUpgrade"
          ? updatedSelectedOptions.length === allOptions.length
          : prev.allUpgradesChecked,
    }));
  };

  const submitAddOnAction = useActionWithPath({
    onSuccess: () => {
      onClose();
      setSuccessToast("Request submitted.");
      setFormData(initialState);
    },
    onError: (error: Error) => {
      console.log("error while submitting the form:", error);
    },
  });

  const handleSubmit = () => {
    const { selectedFeatureOptions, selectedUpgradeOptions, instructions } =
      formData;

    // @ts-ignore
    submitAddOnAction.mutate({
      method: "post",
      path: `/hubspot/contactus`,
      body: JSON.stringify({
        selectedFeatureOptions: selectedFeatureOptions.join(","),
        selectedUpgradeOptions: selectedUpgradeOptions.join(","),
        instructions,
      }),
    });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      scroll="body"
      sx={{
        "& .MuiDialog-paper": {
          maxHeight: "90vh",
        },
      }}
    >
      <DialogTitle sx={modalStyle.dialogTitle}>
        What features are you interested in?
        <Typography
          sx={{ fontSize: "14px", marginTop: 3, textAlign: "center" }}
        >
          Please complete the following fields, and we'll get back to you asap.
        </Typography>
      </DialogTitle>
      <DialogContent sx={modalStyle.dialogContent}>
        <Input
          value={currentOrganization?.name}
          label="Company Name"
          type="text"
          sx={modalStyle.inputStyle}
          size="small"
          disabled
        />
        <Input
          value={currentOrganization?.userName}
          label="Name"
          type="text"
          sx={modalStyle.inputStyle}
          size="small"
          disabled
        />
        <Grid container spacing={2} sx={{ mt: 3, px: 8 }}>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.allFeaturesChecked}
                  onChange={handleAllFeaturesChange}
                  name="allFeatures"
                  size="small"
                />
              }
              label="All Features"
              sx={contactUsDialogStyle.checkbox}
            />
            <FormGroup sx={{ ml: 3 }}>
              {featureOptions.map((option) => (
                <FormControlLabel
                  key={option}
                  control={
                    <Checkbox
                      checked={formData.selectedFeatureOptions.includes(option)}
                      onChange={() =>
                        handleFeatureOrUpgradeOption(option, "updateFeature")
                      }
                      name={option}
                      size="small"
                    />
                  }
                  label={option}
                  sx={contactUsDialogStyle.checkbox}
                />
              ))}
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.allUpgradesChecked}
                  onChange={handleAllUpgradesChange}
                  name="allUpgrades"
                  size="small"
                />
              }
              label="Upgrade Options"
              sx={contactUsDialogStyle.checkbox}
            />
            <FormGroup sx={{ ml: 3 }}>
              {upgradeOptions.map((option) => (
                <FormControlLabel
                  key={option}
                  control={
                    <Checkbox
                      checked={formData.selectedUpgradeOptions.includes(option)}
                      onChange={() =>
                        handleFeatureOrUpgradeOption(option, "updateUpgrade")
                      }
                      name={option}
                      size="small"
                    />
                  }
                  label={option}
                  sx={contactUsDialogStyle.checkbox}
                />
              ))}
            </FormGroup>
          </Grid>
        </Grid>

        <Input
          value={formData.instructions}
          onChange={(val) => handleInstructions(val)}
          label="Additional request/information"
          type="text"
          multiline
          minRows={3}
          sx={modalStyle.inputStyle}
          size="small"
        />
      </DialogContent>
      <DialogActions sx={{ px: 13 }}>
        <ActionButton
          variant="text"
          sx={contactUsDialogStyle.cancelBtn}
          onClick={onClose}
          startIcon={<CancelOutlinedIcon />}
        >
          Cancel
        </ActionButton>
        <ActionButton
          startIcon={<img src={SendIcon} alt="" />}
          variant="contained"
          sx={modalStyle.btnStyle}
          onClick={handleSubmit}
          disabled={
            !formData.instructions &&
            formData.selectedFeatureOptions.length === 0 &&
            formData.selectedUpgradeOptions.length === 0
          }
          progress={submitAddOnAction.isLoading}
        >
          Submit
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
};
