import { ColumnHeader, TabPanel } from "./TabPanel";
import { Box, Typography } from "@mui/material";

export default function InfoColumnTab({
  selectedTabIndex,
  index,
  dataPage,
  nrColumns = 3,
}: {
  selectedTabIndex: number;
  index: number;
  dataPage: any;
  nrColumns?: number;
}) {
  return (
    <TabPanel value={selectedTabIndex} index={index}>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          borderBottom: "1px solid lightgrey",
        }}
      >
        {dataPage.map((entry: any, index: number, originArr: any[]) => {
          return (
            <Box
              key={entry.name}
              style={{
                width: (100 / nrColumns).toFixed(2) + "%",
                borderBottom:
                  index <= originArr.length - nrColumns
                    ? "1px solid lightgrey"
                    : "none",
              }}
              sx={{ p: 4 }}
            >
              {entry.name && (
                <>
                  <ColumnHeader>{entry.name}</ColumnHeader>
                  <Typography component="span">{entry.value}</Typography>
                </>
              )}
              {!entry.name && <>{entry.value}</>}
            </Box>
          );
        })}
      </Box>
    </TabPanel>
  );
}
