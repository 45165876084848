import { useAuth } from "auth/AuthContext";
import { useAuthMachine } from "auth/state/hook";
import { CLUSTER_STATE } from "constants/common";
import { usePopupState } from "material-ui-popup-state/hooks";
import { useMemo, useState } from "react";
import { useActionWithPath, useClusterListing, useUserIds } from "utils/query";
import { CustomerEnvironment } from "utils/saastypes";

interface ConfirmDelete {
  confirmDelete: boolean;
  clusterName: string | undefined;
}

const CLUSTER_STATES = Object.values(CLUSTER_STATE);
const sortingFunctions = {
  descending: (l: CustomerEnvironment, r: CustomerEnvironment): number => {
    if (l.createdAt < r.createdAt) {
      return 1;
    } else if (l.createdAt > r.createdAt) {
      return -1;
    } else {
      return 0;
    }
  },
  ascending: (l: CustomerEnvironment, r: CustomerEnvironment): number => {
    if (l.createdAt > r.createdAt) {
      return 1;
    } else if (l.createdAt < r.createdAt) {
      return -1;
    } else {
      return 0;
    }
  },
};

const MY_CLUSTERS = "myClusters";

export const useClusterListings = () => {
  const { authService } = useAuth();
  const [
    { currentUser, organizationType, currentProfile },
    { refetchVerifyUserStatus },
  ] = useAuthMachine(authService);

  const filterPopupState = usePopupState({
    variant: "popover",
    popupId: "filters",
  });
  const sortPopupState = usePopupState({ variant: "popover", popupId: "sort" });

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [confirmEnableDelete, setConfirmEnableDelete] = useState<ConfirmDelete>(
    { confirmDelete: false, clusterName: undefined }
  );
  const [confirmDelete, setConfirmDelete] = useState<ConfirmDelete>({
    confirmDelete: false,
    clusterName: undefined,
  });

  const [sortingCriteria, setSortingCriteria] = useState<
    "ascending" | "descending"
  >("descending");

  const handleSearchValueChange = (newValue: string) => {
    setSearchQuery(newValue);
  };

  const {
    data: clusterData,
    isFetching: clusterListFetching,
    refetch: refetchAllClusters,
  } = useClusterListing();

  const { data: clusterOwner } = useUserIds(
    clusterData?.map(({ createdBy }) => createdBy as string) || []
  );

  const [filters, setFilters] = useState<{ [key: string]: any }>({
    myClusters: false,
    regions: [],
    states: CLUSTER_STATES.filter((state) => state !== CLUSTER_STATE.DELETED),
    accountIds: [],
  });

  const toggleFilter = (filterType: string) => {
    setFilters({ ...filters, [filterType]: !filters[filterType] });
  };

  const filteredEnvironments = useMemo<CustomerEnvironment[] | undefined>(
    () =>
      clusterData
        ?.filter(({ name, state, createdBy, region, accountId }) => {
          let userFilter = true;
          let accountFilter = true;
          let regionFilter = true;
          let clusterStateFilter = true;
          if (filters[MY_CLUSTERS]) {
            const userData = clusterOwner[createdBy];
            if (userData != null && userData.userEmail !== currentUser.email) {
              userFilter = false;
            }
          }
          if (!filters[MY_CLUSTERS] && filters.accountIds.length !== 0) {
            accountFilter = filters.accountIds.indexOf(accountId) !== -1;
          }
          if (filters.regions.length !== 0) {
            regionFilter = filters.regions.indexOf(region) !== -1;
          }
          if (filters.states.length !== 0) {
            clusterStateFilter = filters.states.indexOf(state) !== -1;
          }
          return (
            userFilter &&
            accountFilter &&
            regionFilter &&
            clusterStateFilter &&
            name.toLowerCase().includes(searchQuery.toLowerCase())
          );
        })
        .sort(sortingFunctions[sortingCriteria]),
    [
      clusterData,
      clusterOwner,
      currentUser?.email,
      filters,
      searchQuery,
      sortingCriteria,
    ]
  );

  const deleteEnvironmentAction = useActionWithPath({
    onSuccess: () => {
      setConfirmDelete({ confirmDelete: false, clusterName: undefined });
      refetchAllClusters();
    },
  });

  const enableClusterDeletionAction = useActionWithPath({
    onSuccess: () => {
      setConfirmDelete({ confirmDelete: false, clusterName: undefined });
      refetchAllClusters();
    },
  });

  const triggerEnableClusterDeletion = (
    confirmed: boolean,
    clusterName: string | undefined
  ) => {
    if (confirmed && clusterName) {
      setConfirmEnableDelete({
        confirmDelete: false,
        clusterName: clusterName,
      });
      // @ts-ignore
      enableClusterDeletionAction.mutate({
        method: "post",
        path: `/setClusterDeletion?clusterName=${clusterName}&deleteProduction=true`,
        successMessage: `Deletion has been enabled for Cluster ${clusterName}`,
      });
    } else {
      setConfirmEnableDelete({
        confirmDelete: true,
        clusterName: clusterName,
      });
    }
  };

  const triggerDeletionFlow = (
    confirmed: boolean,
    clusterName: string | undefined
  ) => {
    if (confirmed && confirmDelete.clusterName) {
      setConfirmDelete({ confirmDelete: false, clusterName: clusterName });
      // @ts-ignore
      deleteEnvironmentAction.mutate({
        method: "post",
        path: `/deleteEnvironment`,
        body: JSON.stringify({
          clusterName: confirmDelete.clusterName,
        }),
        successMessage: `Cluster ${confirmDelete.clusterName} has been scheduled for deletion`,
      });
    } else {
      setConfirmDelete({
        confirmDelete: true,
        clusterName: clusterName,
      });
    }
  };

  return [
    {
      filterPopupState,
      sortPopupState,
      clusterListFetching,
      organizationType,
      currentUser,
      refetchVerifyUserStatus,
      confirmDelete,
      filteredEnvironments,
      currentProfile,
      confirmEnableDelete,
      clusterData,
      filters,
      sortingCriteria,
    },
    {
      toggleFilter,
      refetchAllClusters,
      setConfirmDelete,
      triggerDeletionFlow,
      triggerEnableClusterDeletion,
      setConfirmEnableDelete,
      handleSearchValueChange,
      setSortingCriteria,
      setFilters,
    },
  ] as const;
};
