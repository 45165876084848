import { TabPanel } from "./TabPanel";
import { ToggleOffRounded, ToggleOnRounded } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useActionWithPath, useUserIds } from "utils/query";
import AlertDialog from "components/AlertDialog";
import StyledErrorButton from "components/buttons/StyledErrorButton";
import { useAuth } from "auth/AuthContext";
import { resolveVersion } from "utils/utils";
import OutlinedButton from "components/buttons/OutlinedButton";
import _delay from "lodash/delay";
import { useAuthMachine } from "auth/state/hook";

export default function DetailsTab({
  selectedTabIndex,
  index,
  clusterDetail,
  refetchClusterDetail,
}: {
  selectedTabIndex: number;
  index: number;
  clusterDetail: any;
  refetchClusterDetail: Function;
}) {
  // Is it hosted on any of our accounts?
  const orkesAccount = clusterDetail?.orkesAccount;
  const { authService } = useAuth();
  const [{ isOrkesUser }] = useAuthMachine(authService);

  const region = clusterDetail?.region;
  const azIds = clusterDetail?.azIds?.join(", ");
  const computeUnitDescription = clusterDetail?.computeUnitDescription;
  const accountId = clusterDetail?.accountId;
  const eksVersion = clusterDetail?.eksVersion;
  const cloudProvider = clusterDetail?.cloudProvider;

  const createdBy = clusterDetail?.createdBy;
  const createdAt = clusterDetail?.createdAt;
  const updatedBy = clusterDetail?.updatedBy;
  const updatedAt = clusterDetail?.updatedAt;

  const isDeletionEnabled = clusterDetail?.deleteProduction;

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [cancelDelete, setCancelDelete] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmEnableDelete, setConfirmEnableDelete] = useState(false);
  const [isCurrentlyEnablingDeletion, setIsCurrentlyEnablingDeletion] =
    useState(false);

  const [typedClusterName, setTypedClusterName] = useState<String>("");

  const { data: userData } = useUserIds([createdBy, updatedBy]);

  const toggleDeletionProtectionAction = useActionWithPath({
    onSuccess: () => {
      setIsCurrentlyEnablingDeletion(false);
      refetchClusterDetail();
    },
    onError: () => {
      setIsCurrentlyEnablingDeletion(false);
      refetchClusterDetail();
    },
  });

  const deleteEnvironmentAction = useActionWithPath({
    onSuccess: () => {
      refetchClusterDetail();
      setIsSubmitting(false);
    },
    onError: () => {
      refetchClusterDetail();
      setIsSubmitting(false);
    },
  });

  const cancelDeletionAction = useActionWithPath({
    onSuccess: () => {
      _delay(
        function () {
          refetchClusterDetail();
          setIsSubmitting(false);
        },
        10000,
        "later"
      );
    },
    onError: () => {
      refetchClusterDetail();
      setIsSubmitting(false);
    },
  });

  function triggerDeletionFlow() {
    if (clusterDetail) {
      setConfirmDelete(false);
      setIsSubmitting(true);
      // @ts-ignore
      deleteEnvironmentAction.mutate({
        method: "post",
        path: `/deleteEnvironment`,
        body: JSON.stringify({
          clusterName: clusterDetail.name,
        }),
        successMessage: `Cluster ${clusterDetail.name} has been scheduled for deletion`,
      });
    }
  }

  function triggerEnableDeletionFlow() {
    setIsCurrentlyEnablingDeletion(true);
    // @ts-ignore
    toggleDeletionProtectionAction.mutate({
      method: "post",
      path: `/setClusterDeletion?clusterName=${
        clusterDetail.name
      }&deleteProduction=${!clusterDetail.deleteProduction}`,
      body: JSON.stringify({}),
      successMessage: `Deleting has been ${
        isDeletionEnabled ? "disabled" : "enabled"
      } for the cluster ${clusterDetail.name}`,
    });
  }

  function triggerCancelDeletion() {
    if (clusterDetail) {
      setCancelDelete(false);
      setIsSubmitting(true);
      // @ts-ignore
      cancelDeletionAction.mutate({
        method: "post",
        path: `/cancelDeleteEnvironment`,
        body: JSON.stringify({
          clusterName: clusterDetail.name,
        }),
        successMessage: `Cancellation of deletion request for ${clusterDetail.name} was successful. Please wait a few seconds to get the updated cluster state`,
      });
    }
  }

  const shortRowTitleStyle = {
    display: "inline-block",
    width: 180,
    fontWeight: "500",
    marginBottom: "8px",
  };
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg")); //1439px

  return (
    <TabPanel value={selectedTabIndex} index={index}>
      <AlertDialog
        textTitle={"Deleting Confirmation"}
        textContent={
          <>
            Please confirm if you want to proceed to delete the cluster&nbsp;
            <strong className="danger-text">{clusterDetail.name}</strong>. This
            action cannot be undone. All data will be permanently lost.
          </>
        }
        open={confirmDelete}
        setOpen={() => setConfirmDelete(false)}
        handleConfirm={() => {
          triggerDeletionFlow();
        }}
        isInputConfirmation
        valueToBeDeleted={clusterDetail.name}
      />
      <AlertDialog
        textTitle={""}
        textContent={`You are now ${
          isDeletionEnabled ? "disabling" : "enabling"
        } the option to delete this cluster.
            Please type in the cluster name to ${
              isDeletionEnabled ? "disable" : "enable"
            } deletion:`}
        open={confirmEnableDelete}
        setOpen={setConfirmEnableDelete}
        handleConfirm={() => {
          setConfirmEnableDelete(false);
          triggerEnableDeletionFlow();
        }}
        isConfirmDisabled={typedClusterName !== clusterDetail.name}
      >
        <FormControl
          style={{
            marginTop: 16,
            width: "100%",
          }}
        >
          <TextField
            id="confirm-cluster-name-input-field"
            label="Cluster Name"
            placeholder="Cluster Name"
            value={typedClusterName}
            onChange={(ev) => {
              setTypedClusterName(ev.target.value);
            }}
          />
        </FormControl>
      </AlertDialog>
      <AlertDialog
        textTitle={"Cancel Cluster Deletion"}
        textContent={`You are now cancelling the request for deletion of your cluster ${
          clusterDetail?.name ?? ""
        }`}
        open={cancelDelete}
        setOpen={setCancelDelete}
        handleConfirm={() => triggerCancelDeletion()}
      />
      <Box
        sx={{
          display: "flex",
          flexFlow: "row wrap",
          borderBottom: "1px solid lightgrey",
          padding: "16px",
        }}
      >
        <Box sx={{ display: "flex", gap: 25, width: "100%" }}>
          <Box
            sx={{
              width: "50%",
              minWidth: isLargeScreen ? "fit-content" : "unset",
              display: "flex",
              flexDirection: isLargeScreen ? "row" : "column",
              gap: isLargeScreen ? 25 : 0,
            }}
          >
            <Box>
              <Box>
                <Typography component="span" sx={shortRowTitleStyle}>
                  Cloud Environment:
                </Typography>{" "}
                {cloudProvider}{" "}
              </Box>
              {region && (
                <Box>
                  <Typography component="span" sx={shortRowTitleStyle}>
                    Region:
                  </Typography>{" "}
                  {region}{" "}
                </Box>
              )}
              {azIds && (!orkesAccount || isOrkesUser) && (
                <Box>
                  <Typography component="span" sx={shortRowTitleStyle}>
                    Availability Zones:
                  </Typography>{" "}
                  {azIds}
                </Box>
              )}
              {computeUnitDescription && (
                <Box>
                  <Typography component="span" sx={shortRowTitleStyle}>
                    Capacity:{" "}
                  </Typography>{" "}
                  {computeUnitDescription} OCUs
                </Box>
              )}
              {accountId && (!orkesAccount || isOrkesUser) && (
                <Box>
                  <Typography component="span" sx={shortRowTitleStyle}>
                    Account ID:{" "}
                  </Typography>{" "}
                  {accountId}{" "}
                </Box>
              )}
              {eksVersion && (!orkesAccount || isOrkesUser) && (
                <Box>
                  <Typography component="span" sx={shortRowTitleStyle}>
                    EKS Version:
                  </Typography>{" "}
                  {eksVersion}
                </Box>
              )}
            </Box>
            <Box>
              <Box>
                <Typography component="span" sx={shortRowTitleStyle}>
                  Conductor Server:
                </Typography>{" "}
                {resolveVersion(clusterDetail?.appVersions?.conductor) ||
                  "Not Available"}
              </Box>

              <Box>
                <Typography component="span" sx={shortRowTitleStyle}>
                  Conductor Workers:
                </Typography>{" "}
                {resolveVersion(clusterDetail?.appVersions?.workers) ||
                  "Not Available"}
              </Box>

              <Box>
                <Typography component="span" sx={shortRowTitleStyle}>
                  Orkes Agent:
                </Typography>{" "}
                {resolveVersion(clusterDetail?.appVersions?.agent) ||
                  "Not Available"}
              </Box>

              <Box>
                <Typography component="span" sx={shortRowTitleStyle}>
                  Metrics Proxy:
                </Typography>{" "}
                {resolveVersion(clusterDetail?.appVersions?.metricsProxy) ||
                  "Not Available"}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "50%",
            }}
          >
            {createdBy && (
              <Box>
                <Typography component="span" sx={shortRowTitleStyle}>
                  Created By:
                </Typography>{" "}
                {userData && userData[createdBy]
                  ? userData[createdBy].userEmail
                  : "Unknown"}
              </Box>
            )}
            {createdAt && (
              <Box>
                <Typography component="span" sx={shortRowTitleStyle}>
                  Created At:
                </Typography>{" "}
                {new Date(createdAt).toLocaleString()}{" "}
              </Box>
            )}
            {updatedBy && (
              <Box>
                <Typography component="span" sx={shortRowTitleStyle}>
                  Last Updated By:
                </Typography>{" "}
                {userData && userData[updatedBy]
                  ? userData[updatedBy].userEmail
                  : "Unknown"}
              </Box>
            )}
            {updatedAt && (
              <Box>
                <Typography component="span" sx={shortRowTitleStyle}>
                  Last Updated at:{" "}
                </Typography>{" "}
                {new Date(updatedAt).toLocaleString()}{" "}
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
          }}
        >
          {!isDeletionEnabled && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                id="delete-protection-switch-enabled-btn"
                onClick={() => {
                  setConfirmEnableDelete(true);
                }}
              >
                <ToggleOnRounded style={{ fontSize: 48, color: "#1976d2" }} />
              </IconButton>
              Delete Protection Enabled
            </Box>
          )}
          {isDeletionEnabled && clusterDetail?.state !== "SCHEDULE_DELETION" && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                disabled={isCurrentlyEnablingDeletion}
                id="delete-protection-switch-disabled-btn"
                onClick={() => {
                  triggerEnableDeletionFlow();
                }}
              >
                <ToggleOffRounded
                  style={{
                    fontSize: 48,
                    stroke: "#1976d2",
                    color: "transparent",
                  }}
                />
              </IconButton>{" "}
              Delete Protection Disabled
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
            }}
          >
            {clusterDetail?.state !== "SCHEDULE_DELETION" && (
              <StyledErrorButton
                sx={{
                  "&.Mui-disabled": {
                    color: "red",
                  },
                }}
                onClick={() => {
                  setConfirmDelete(true);
                }}
                disabled={
                  !isDeletionEnabled ||
                  clusterDetail.state !== "PROVISIONED" ||
                  isSubmitting
                }
                color="error"
                style={{
                  marginRight: 16,
                }}
                variant="contained"
                id="delete-cluster-btn"
              >
                {isSubmitting && (
                  <CircularProgress
                    size={20}
                    style={{ marginRight: 8, color: "gray" }}
                  />
                )}
                Delete Cluster
              </StyledErrorButton>
            )}
            {clusterDetail?.state === "SCHEDULE_DELETION" && (
              <OutlinedButton
                onClick={() => {
                  setCancelDelete(true);
                }}
                disabled={isSubmitting}
                variant="contained"
              >
                {isSubmitting && (
                  <CircularProgress
                    size={20}
                    style={{ marginRight: 8, color: "inherit" }}
                  />
                )}
                Cancel Scheduled Deletion
              </OutlinedButton>
            )}
          </Box>
        </Box>
      </Box>
    </TabPanel>
  );
}
