import { RolloutRestartType } from "utils/saastypes";

export const INTEGER_AT_LEAST_ONE_NUMBER = "^[0-9]+$";

export const rolloutRestartResourceBaseTypes = [
  {
    name: "Conductor Server",
    value: RolloutRestartType.CONDUCTOR,
  },
  {
    name: "Conductor Workers",
    value: RolloutRestartType.WORKERS,
  },
  {
    name: "Orkes Agent",
    value: RolloutRestartType.AGENT,
  },
];

export const contextFlagOptions = [
  { name: "None", value: "N" },
  { name: "Before -B", value: "B" },
  { name: "After -A", value: "A" },
  { name: "Context -C", value: "C" },
];
export const fetchOptions = [
  { name: "Last (tail)", value: "tail" },
  { name: "First (head)", value: "head" },
];

export const CLUSTER_DETAILS_TABS = {
  DETAILS: "Details",
  URLS: "URLs",
  STATUS: "Status",
  NETWORK: "Network",
  PERSISTENCE: "Persistence",
  PROPERTIES: "Application Properties",
  AUTHENTICATION: "Authentication",
  HISTORY: "Actions History",
  PODS: "Pods",
  RELEASES: "Releases",
  CERTIFICATE: "Certificates",
};
