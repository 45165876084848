import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import ActionButton from "../buttons/ActionButton";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

enum ClusterType {
  STARTER = "STARTER",
  STANDARD = "STANDARD",
  ENTERPRISE = "ENTERPRISE",
}
enum CloudProvider {
  AWS = "AWS",
  AZURE = "AZURE",
  GCP = "GCP",
}
export enum AuthRequestType {
  ADD = "ADD",
  REVOKE = "REVOKE",
}
enum DataType {
  DOMAIN = "DOMAIN",
  EMAIL = "EMAIL",
}

export interface IPermittedProvisioningForm {
  authRequestType?: AuthRequestType;
  cloudProvider: CloudProvider;
  dataType: DataType;
  clusterType: ClusterType;
  value: string;
  type?: ClusterType;
}

export const DOMAIN_REGEX =
  /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]?\.(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/;

export default function PermitProvisionDialog({
  handleAdd,
  handleClose,
  progressPending = false,
  open,
}: {
  handleAdd: (domains: IPermittedProvisioningForm) => void;
  handleClose: () => void;
  progressPending?: boolean;
  open: boolean;
}) {
  const formSchema = yup.object().shape({
    authRequestType: yup
      .mixed()
      .oneOf(Object.values(AuthRequestType), "Auth request type is invalid"),
    cloudProvider: yup
      .mixed()
      .oneOf(Object.values(CloudProvider), "Cloud provider is invalid"),
    dataType: yup
      .mixed()
      .oneOf(Object.values(DataType), "Data type is invalid"),
    clusterType: yup
      .mixed()
      .oneOf(Object.values(ClusterType), "Cluster type is invalid"),
    value: yup
      .string()
      .required("Value is required")
      .when("dataType", {
        is: (value: DataType) => value === DataType.EMAIL,
        then: (schema) => schema.email("Invalid email"),
        otherwise: (schema) => schema.matches(DOMAIN_REGEX, "Invalid domain"),
      }),
  });
  const defaultValues = {
    authRequestType: AuthRequestType.ADD,
    cloudProvider: CloudProvider.AWS,
    dataType: DataType.DOMAIN,
    clusterType: ClusterType.STARTER,
    value: "",
  };
  const {
    control,
    formState: { errors: formErrors },
    handleSubmit,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(formSchema),
    defaultValues,
  });

  const onSubmit = (data: IPermittedProvisioningForm) => {
    handleAdd({ ...data, authRequestType: AuthRequestType.ADD });
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle>Permit Provisioning Value</DialogTitle>
      <DialogContent>
        <form>
          <Grid container spacing={2} sx={{ paddingTop: 2 }}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="clusterTypeLabel">Cluster Type</InputLabel>
                <Controller
                  control={control}
                  name="clusterType"
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="clusterTypeLabel"
                      id="clusterTypeSelect"
                      label="Cluster Type"
                      onChange={(event: any) => field.onChange(event)}
                    >
                      {Object.keys(ClusterType).map((k) => (
                        <MenuItem
                          key={k}
                          value={ClusterType[k as keyof typeof ClusterType]}
                        >
                          {ClusterType[k as keyof typeof ClusterType]}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  rules={{ required: true }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="cloudProviderLabel">Cloud Provider</InputLabel>
                <Controller
                  control={control}
                  name="cloudProvider"
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="cloudProviderLabel"
                      id="cloudProviderSelect"
                      label="Cloud Provider"
                      onChange={(event: any) => field.onChange(event)}
                    >
                      {Object.keys(CloudProvider).map((k) => (
                        <MenuItem
                          key={k}
                          value={CloudProvider[k as keyof typeof CloudProvider]}
                        >
                          {CloudProvider[k as keyof typeof CloudProvider]}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  rules={{ required: true }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="dataTypeLabel">Data Type</InputLabel>
                <Controller
                  control={control}
                  name="dataType"
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="dataTypeLabel"
                      id="dataTypeSelect"
                      label="Data Type"
                      onChange={(event: any) => field.onChange(event)}
                    >
                      {Object.keys(DataType).map((k) => (
                        <MenuItem
                          key={k}
                          value={DataType[k as keyof typeof DataType]}
                        >
                          {DataType[k as keyof typeof DataType]}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  rules={{ required: true }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="value"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      label="Value"
                      placeholder="Ex: orkes.io"
                      error={!!formErrors?.value?.message}
                      helperText={formErrors?.value?.message}
                    />
                  )}
                  rules={{ required: true }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClose}
          style={{ margin: 8, fontSize: 14, textTransform: "none" }}
          sx={{
            display: "inline",
            mx: 0.5,
          }}
        >
          Cancel
        </Button>
        <ActionButton
          variant="contained"
          color="primary"
          disabled={false}
          progress={progressPending}
          onClick={() => {
            // @ts-ignore
            handleSubmit(onSubmit)();
          }}
          style={{ margin: 8, fontSize: 14, textTransform: "none" }}
          sx={{
            display: "inline",
            mx: 0.5,
          }}
        >
          Permit Provision
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
}
