import { StrictMode } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import AuthProvider from "./auth/AuthContext";
import PostLoginContent from "./components/PostLoginContent";
import ClusterListings from "./pages/ClusterListings/ClusterListings";
import AcceptInvite from "./pages/AcceptInvite";
import ClusterDetails from "./pages/ClusterDetails/ClusterDetailsLegacy";
import { ThemeProvider } from "./theme";
import CreateNewEnvironment from "./pages/CreateNewEnvironment";
import CreateNewEnvironmentCustomCluster from "./pages/CreateNewEnvironmentCustomCluster";
import CreateNewEnvironmentOrkesCluster from "./pages/CreateNewEnvironmentOrkesCluster";
import CreateNewEnvironmentCustomClusterNextStep from "./pages/CreateNewEnvironmentCustomClusterNextStep";
import ClusterProgress from "./pages/ClusterProgress";
import UnauthorizedPage from "pages/UnauthorizedPage";
import PermittedManagement from "pages/PermittedManagement/PermittedManagement";
import PermittedUsers from "pages/PermittedManagement/PermittedUsers";
import PermittedDomains from "pages/PermittedManagement/PermittedDomains";
import NotFoundPage from "pages/NotFound";
import ProvisioningPermittedList from "pages/PermittedManagement/ProvisioningPermittedList";
import { PrivateRoute, PrivateRouteWithOrganization } from "components/auth";
import { Role, OrganizationType } from "utils/saastypes";
import { CreatorFlags } from "pages/CreatorFlags";
import UserVerify from "pages/UserVerify";
import VersionManagement from "pages/versions/VersionManagement";
import MessageProvider from "utils/MessageProvider";
import ResetPasswordPage from "pages/ResetPasswordPage";
import UpgradePlan from "pages/UpgradePage/UpgradePlan";
import CheckoutPage from "pages/Stripe/CheckoutPage";
import { OrganizationSetting } from "pages/OrganizationSettings/OrganizationSetting";
import { inspect } from "@xstate/inspect";
import LogRocket from "logrocket";

if (process.env.REACT_APP_XSTATE_INSPECT === "true") {
  inspect({
    url: "https://stately.ai/viz?inspect=1",
    iframe: false,
  });
}

LogRocket.init("orkes/orkes-saas");

ReactDOM.render(
  <StrictMode>
    <MessageProvider>
      <ThemeProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <AuthProvider>
                  <App />
                </AuthProvider>
              }
            >
              <Route>
                <Route index element={<LoginPage />} />
                <Route
                  index
                  path="/:signup"
                  element={<LoginPage signup={true} />}
                />
              </Route>
              <Route path="resetPassword" element={<ResetPasswordPage />} />
              <Route
                path="acceptInvite/:inviteId"
                element={<AcceptInvite />}
              ></Route>
              <Route
                path="home"
                element={
                  <PrivateRoute>
                    <PostLoginContent />
                  </PrivateRoute>
                }
              >
                <Route index element={<ClusterListings />} />
                <Route path="clusters" element={<ClusterListings />} />
                <Route
                  path="newcluster"
                  element={
                    <PrivateRouteWithOrganization
                      restrictedOrganizationTypes={[
                        OrganizationType.ENTERPRISE,
                        OrganizationType.FREE_TRIAL,
                      ]}
                    >
                      <CreateNewEnvironment />
                    </PrivateRouteWithOrganization>
                  }
                />
                <Route
                  path="neworkescluster"
                  element={
                    <PrivateRouteWithOrganization
                      restrictedOrganizationTypes={[
                        OrganizationType.ENTERPRISE,
                        OrganizationType.FREE_TRIAL,
                      ]}
                    >
                      <CreateNewEnvironmentOrkesCluster />
                    </PrivateRouteWithOrganization>
                  }
                />
                <Route
                  path="newcustomcluster"
                  element={
                    <PrivateRouteWithOrganization
                      restrictedOrganizationTypes={[
                        OrganizationType.ENTERPRISE,
                        OrganizationType.FREE_TRIAL,
                      ]}
                    >
                      <CreateNewEnvironmentCustomCluster />
                    </PrivateRouteWithOrganization>
                  }
                />
                <Route
                  path="newcustomcluster-step2"
                  element={
                    <PrivateRouteWithOrganization
                      restrictedOrganizationTypes={[
                        OrganizationType.ENTERPRISE,
                        OrganizationType.FREE_TRIAL,
                      ]}
                    >
                      <CreateNewEnvironmentCustomClusterNextStep />
                    </PrivateRouteWithOrganization>
                  }
                />
                <Route
                  path="clusterDetails/:clusterName/*"
                  element={
                    <PrivateRouteWithOrganization>
                      <ClusterDetails />
                    </PrivateRouteWithOrganization>
                  }
                />
                <Route path="clusterprogess" element={<ClusterProgress />} />
                <Route
                  path="permitted"
                  element={
                    <PrivateRouteWithOrganization
                      hasAnyRoles={[Role.COMPANY_ROOT, Role.COMPANY_ADMIN]}
                      requiresOrkesUser
                    >
                      <PermittedManagement />
                    </PrivateRouteWithOrganization>
                  }
                >
                  <Route path="users" element={<PermittedUsers />} />
                  <Route path="domains" element={<PermittedDomains />} />
                  <Route
                    path="provisions"
                    element={<ProvisioningPermittedList />}
                  />
                </Route>
                {/*
                isEnableClustersCreation() && (
                  <Route
                    path="clusterCreation"
                    element={
                      <PrivateRouteWithOrganization
                        hasAnyRoles={[Role.COMPANY_ROOT, Role.COMPANY_ADMIN]}
                        requiresOrkesUser
                      >
                        <ClusterCreationV1 />
                      </PrivateRouteWithOrganization>
                    }
                  ></Route>
                )
                */}
                <Route
                  path="versions"
                  element={
                    <PrivateRouteWithOrganization
                      hasAnyRoles={[Role.COMPANY_ROOT, Role.COMPANY_ADMIN]}
                      requiresOrkesUser
                    >
                      <VersionManagement />
                    </PrivateRouteWithOrganization>
                  }
                />
                <Route path="upgrade" element={<UpgradePlan />} />
                <Route path="add-payment-method" element={<CheckoutPage />} />
                <Route
                  path="update-payment-method"
                  element={<CheckoutPage />}
                />
                <Route
                  path="organization-setting/*"
                  element={<OrganizationSetting />}
                />
              </Route>
              <Route
                path="flags"
                element={
                  <PrivateRouteWithOrganization
                    hasAnyRoles={[
                      Role.COMPANY_ROOT,
                      Role.COMPANY_ADMIN,
                      Role.COMPANY_USER,
                    ]}
                    requiresOrkesUser
                  >
                    <CreatorFlags />
                  </PrivateRouteWithOrganization>
                }
              />
              <Route path="/user/verify" element={<UserVerify />} />
              <Route path="unauthorized" element={<UnauthorizedPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </MessageProvider>
  </StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
