export default function LogoSelector({
  src,
  isEnabled = true,
  isActive = false,
  onClick = () => {},
}: {
  src: string;
  isActive?: boolean;
  isEnabled?: boolean;
  onClick?: () => void;
}) {
  const selectorStyle = {
    padding: 20,
    border: "1px solid #eee",
    borderRadius: 20,
    marginRight: 20,
    width: 250,
    height: 125,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      border: "1px solid lightgrey",
    },
  };

  const selectorIsActiveStyle = {
    padding: 20,
    border: "1px solid #1976d2",
    borderRadius: 20,
    marginRight: 20,
    width: 250,
    height: 125,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  };
  return (
    <div
      onClick={onClick}
      style={isActive ? selectorIsActiveStyle : selectorStyle}
    >
      <img
        style={{
          width: "100%",
          filter: isEnabled ? "" : "grayscale(100%)",
        }}
        alt=""
        src={src}
      />
    </div>
  );
}
