import { CheckoutContext } from "./types";
import { fetchWithContext } from "utils/fetch";
import { BillingFrequency } from "pages/UpgradePage/UpgradePlan";
import { defaultHeaders } from "utils/query";

export const getClientSecret = async ({ accessToken }: CheckoutContext) => {
  const isAddingPaymentInfo =
    window.location.pathname === "/home/add-payment-method";
  const searchParams = new URLSearchParams(window.location.search);
  const billingFrequency = searchParams.get("billingFrequency");

  const isValidBillingFrequency =
    !!billingFrequency &&
    Object.values(BillingFrequency).includes(
      billingFrequency as BillingFrequency
    );

  if (isAddingPaymentInfo && !isValidBillingFrequency) {
    console.log("Invalid billing frequency:", billingFrequency);
    return Promise.reject({
      message:
        "Invalid billing frequency found. Please try upgrading from homepage.",
    });
  }

  try {
    if (accessToken) {
      const result = await fetchWithContext(
        `/payment/stripe/createSetupIntent`,
        {},
        {
          headers: defaultHeaders(accessToken),
          method: "POST",
          body: JSON.stringify(isAddingPaymentInfo ? { billingFrequency } : {}),
        }
      );
      return result;
    }
  } catch (error: any) {
    const errorBody = await error.json();
    return Promise.reject({
      message: errorBody.message || "Failed to initialize the payment form",
    });
  }
};

export const getSetupIntentStatus = async ({
  accessToken,
}: CheckoutContext) => {
  try {
    if (accessToken) {
      const result = await fetchWithContext(
        `/payment/stripe/getIntentSetupStatus`,
        {},
        { headers: defaultHeaders(accessToken), method: "GET" }
      );
      return result.status;
    }
  } catch (error: any) {
    const errorBody = await error.json();
    return Promise.reject({
      message: errorBody.message || "Unable to fetch setupIntentStatus",
    });
  }
};
