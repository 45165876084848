const DropdownIcon = (props: any) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.45 8.66C13.49 8.75 13.48 8.85 13.42 8.92L10.19 12.96C10.14 13.02 10.07 13.05 9.99 13.05C9.91 13.05 9.84 13.02 9.79 12.96L6.56 8.92C6.5 8.84 6.49 8.74 6.53 8.66C6.57 8.57 6.66 8.52 6.76 8.52H13.22C13.32 8.52 13.4 8.58 13.45 8.66ZM18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10ZM17 10C17 6.14 13.86 3 10 3C6.14 3 3 6.14 3 10C3 13.86 6.14 17 10 17C13.86 17 17 13.86 17 10Z"
      fill="currentColor"
    />
  </svg>
);

export default DropdownIcon;
