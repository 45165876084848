import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import AcceptInviteDialog from "components/dialogs/AcceptInviteDialog";
import { useAuth } from "utils/AuthProvider";
import { useAuthMachine } from "auth/state/hook";

export default function AcceptInvite() {
  const { inviteId } = useParams();
  const { authService } = useAuth();
  const [, { acceptInvite }] = useAuthMachine(authService);

  const [isLoading, setIsLoading] = useState(true);
  const [createAccountPrompt, setCreateAccountPrompt] = useState(false);

  useEffect(() => {
    if (inviteId) {
      setIsLoading(false);
      setCreateAccountPrompt(true);
    }
  }, [inviteId]);

  if (!inviteId) {
    return <div>Missing invite ID</div>;
  } else {
    return (
      <>
        <Helmet>
          <title>Orkes Cloud - Accept Invite</title>
        </Helmet>
        <AcceptInviteDialog
          createAccountPrompt={createAccountPrompt}
          isLoading={isLoading}
          inviteId={inviteId}
          onAcceptInvite={() => acceptInvite(inviteId)}
        />
      </>
    );
  }
}
