import { Box } from "@mui/material";
import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import "./App.css";

function App() {
  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-4400JPTLRF"
        />
        <script>
          {`window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-4400JPTLRF');`}
        </script>
      </Helmet>
      <Box style={{ height: "100%" }}>
        <Outlet />
      </Box>
    </>
  );
}

export default App;
