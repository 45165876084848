import { Avatar, Chip, SxProps } from "@mui/material";
import { Help, Storage } from "@mui/icons-material";
import OrkesIcon from "../../images/branding/orkes-favicon-purple.png";
import { CustomerEnvironment, ClusterType } from "utils/saastypes";
import StarterClusterIcon from "images/svg/chip/starter-cloud-cluster.svg";
import StandardClusterIcon from "images/svg/chip/standard-cloud-cluster.svg";
import EnterpriseClusterIcon from "images/svg/chip/enterprise-cloud-cluster.svg";

const hostingChipSx = {
  opacity: 1,
  paddingLeft: 1,
  "& span": {
    fontFamily: "Lexend, sans-serif",
    fontWeight: 400,
    fontSize: 14,
    paddingLeft: 2.5,
    paddingRight: 2.5,
    paddingTop: 0.5,
  },
};

const OrkesHostedChip = ({
  sx,
  clusterType,
  icon,
}: {
  clusterType?: String;
  sx: SxProps;
  icon?: string;
}) => (
  <Chip
    label={`${clusterType} Cluster`}
    avatar={
      <Avatar
        sx={{ margin: 0, padding: 0 }}
        alt="O"
        src={icon ? icon : OrkesIcon}
      />
    }
    color="info"
    variant="outlined"
    sx={{ ...hostingChipSx, ...sx }}
  />
);

const CustomerHostedChip = ({ sx }: { sx: SxProps }) => (
  <Chip
    label="Customer Hosted"
    icon={<Storage />}
    color="info"
    variant="outlined"
    sx={{ ...hostingChipSx, ...sx }}
  />
);

const UnknownChip = ({ sx }: { sx: SxProps }) => (
  <Chip
    label="Unknown Cluster Type"
    icon={<Help />}
    color="warning"
    variant="outlined"
    sx={{ ...hostingChipSx, ...sx }}
  />
);

const ChipWithoutIcon = ({
  sx,
  clusterType,
}: {
  sx: SxProps;
  clusterType: String;
}) => (
  <Chip
    label={clusterType}
    color="info"
    variant="outlined"
    sx={{ ...hostingChipSx, ...sx }}
  />
);

export const HostingChip = ({
  cluster,
  sx = {},
}: {
  cluster: CustomerEnvironment;
  sx?: SxProps;
}) => {
  switch (cluster?.type) {
    case ClusterType.STARTER:
      return (
        <OrkesHostedChip
          sx={sx}
          clusterType={"Starter"}
          icon={StarterClusterIcon}
        />
      );
    case ClusterType.STANDARD:
      return (
        <OrkesHostedChip
          sx={sx}
          clusterType={"Standard"}
          icon={StandardClusterIcon}
        />
      );
    case ClusterType.TRIAL:
      return <ChipWithoutIcon sx={sx} clusterType={"Enterprise Cluster"} />;
    case ClusterType.ENTERPRISE:
      if (cluster.orkesAccount) {
        return (
          <OrkesHostedChip
            sx={sx}
            clusterType={"Enterprise"}
            icon={EnterpriseClusterIcon}
          />
        );
      } else {
        return <CustomerHostedChip sx={sx} />;
      }
    default:
      return cluster.orkesAccount ? (
        <OrkesHostedChip sx={sx} />
      ) : (
        <UnknownChip sx={sx} />
      );
  }
};
