import "../App.css";
import { Box, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { Login } from "components/Login/Login";
import logo from "../images/branding/orkes-logo-purple-2x.png";
import Footer from "components/Login/LoginFooter";
import { useMemo } from "react";

const getPageMetadata = (signup: boolean) => {
  if (signup) {
    return {
      pageTitle: "Sign Up | Orkes Cloud",
      metaDescription:
        "Sign up for the Orkes Cloud platform to manage and monitor your Conductor clusters.",
    };
  }
  return {
    pageTitle: " Log In | Orkes Cloud",
    metaDescription:
      "Log in to the Orkes Cloud platform to manage and monitor your Conductor clusters.",
  };
};

function LoginPage({ signup = false }: { signup: boolean }) {
  const { pageTitle, metaDescription } = useMemo(
    () => getPageMetadata(signup),
    [signup]
  );
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <Box className={"container"}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginY: 2,
          }}
        >
          <img src={logo} alt="logo" width="300px" />
          <Typography
            sx={{ fontSize: 36, fontWeight: "bold", textAlign: "center" }}
            component="h1"
          >
            Orkes Cloud Platform
          </Typography>
        </Box>
        <main
          style={{
            overflowX: "hidden",
            marginTop: 10,
          }}
        >
          <Login signup={signup} />
        </main>
        <Footer />
      </Box>
    </>
  );
}

export default LoginPage;
